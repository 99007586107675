import { RetrofitData } from '../../types';

const retrofits: RetrofitData[] = [
  {
    id: 1,
    uuid: '1bda5dc2-a199-4814-ac57-c8ced0ef02c3',
    retrofitDistributorId: 2,
    retrofitRegionId: 1,
    corporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    ownerCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    siteId: '65a5a0fc-ae01-4fae-8f8a-406faf1fb0e4',
    corporationName: '株式会社クオリカ建築　テスト事業部',
    ownerId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    ownerName: '株式会社クオリカ建築　テスト事業部',
    description: 'ownerId == corporationId のレトロフィット',
    macAddress: '',
    latitude: 35.696489,
    longitude: 139.689392,
    lastOnlineTime: '2020-03-06T01:01:01.000Z',
    isError: false,
    isOnline: true,
    basicInfoManufacturer: 'Komatsu Ltd.',
    basicInfoModel: 'RF001-01',
    basicInfoProductNumber: 'PROD001',
    basicInfoSerialNumber: 'SERIAL_1',
    controllerManufacturer: 'Company 1',
    controllerModel: 'CONT001',
    controllerFirmwareVersion: 'CONT_FW01',
    gnssReceiverMainManufacturer: 'Company 5',
    gnssReceiverMainModel: 'GNSS001',
    gnssReceiverMainFirmwareVersion: 'GNSS_FW01',
    gnssReceiverSubManufacturer: 'Company10',
    gnssReceiverSubModel: 'GNSS002',
    gnssReceiverSubFirmwareVersion: 'GNSS_FW02',
    gnssSettingId: 'b1d7cc36-6d37-9570-98b8-c7c86f88760b',
    machineInfoCompanyName: 'Komatsu Ltd.',
    machineInfoMachineType: 'Excavator',
    machineInfoMachineName: 'PC200-10-0000016789012345678901234567890',
    machineInfoMachineId: 'EX01',
    rentalStatus: 'Returned',
    lastUpdated: '2020-03-06T01:01:01.000Z',
    hasLicense: true,
    licenseGrantInfo: 'All',
    inSendingBackSupported: false,
    needsNtripDataReration: false,
  },
  {
    id: 2,
    uuid: '1bda5dc2-a199-4814-ac57-c8ced0ef02c4',
    retrofitDistributorId: 2,
    corporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    ownerCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    siteId: '65a5a0fc-ae01-4fae-8f8a-406faf1fb0e4',
    corporationName: '株式会社クオリカ建築　テスト事業部',
    ownerId: 'd087e444-ded2-de5e-087b-76f3ce864ae3',
    ownerName: 'LANDLOG',
    description: 'ownerId != corporationId のレトロフィット (貸与: 承認済)',
    macAddress: '',
    latitude: 35.696476,
    longitude: 139.691157,
    lastOnlineTime: '2020-03-06T01:02:01.000Z',
    isError: false,
    isOnline: true,
    basicInfoManufacturer: 'Komatsu Ltd.',
    basicInfoModel: 'RF001-01',
    basicInfoProductNumber: 'PROD002',
    basicInfoSerialNumber: 'SERIAL002',
    controllerManufacturer: 'Company 1',
    controllerModel: 'CONT001',
    controllerFirmwareVersion: 'CONT_FW01',
    gnssReceiverMainManufacturer: 'Company 5',
    gnssReceiverMainModel: 'GNSS001',
    gnssReceiverMainFirmwareVersion: 'GNSS_FW01',
    gnssReceiverSubManufacturer: 'Company10',
    gnssReceiverSubModel: 'GNSS002',
    gnssReceiverSubFirmwareVersion: 'GNSS_FW02',
    gnssSettingId: 'e21267ca-f3b4-adc0-27a1-0cc02b769ebd',
    machineInfoCompanyName: 'Komatsu Ltd.',
    machineInfoMachineType: 'Excavator',
    machineInfoMachineName: 'PC200-10-000002',
    machineInfoMachineId: 'EX02',
    rentalStatus: 'Approved',
    lastUpdated: '2020-03-06T01:02:01.000Z',
    hasLicense: true,
    licenseGrantInfo: 'Temporary',
    inSendingBackSupported: true,
    needsNtripDataReration: false,
  },
  {
    id: 3,
    uuid: '1bda5dc2-a199-4814-ac57-c8ced0ef02c5',
    retrofitDistributorId: 2,
    corporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    ownerCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    siteId: '65a5a0fc-ae01-4fae-8f8a-406faf1fb0e4',
    corporationName: '株式会社クオリカ建築　テスト事業部',
    ownerId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    ownerName: '株式会社クオリカ建築　テスト事業部',
    description: 'ownerId == corporationId のレトロフィット (貸与: 未承認）',
    macAddress: '',
    latitude: 35.696127,
    longitude: 139.69099,
    lastOnlineTime: '2020-03-06T01:03:01.000Z',
    isError: false,
    isOnline: false,
    basicInfoManufacturer: 'Komatsu Ltd.',
    basicInfoModel: 'RF001-01',
    basicInfoProductNumber: 'PROD003',
    basicInfoSerialNumber: 'SERIAL003',
    controllerManufacturer: 'Company 1',
    controllerModel: 'CONT001',
    controllerFirmwareVersion: 'CONT_FW01',
    gnssReceiverMainManufacturer: 'Company 5',
    gnssReceiverMainModel: 'GNSS001',
    gnssReceiverMainFirmwareVersion: 'GNSS_FW01',
    gnssReceiverSubManufacturer: 'Company10',
    gnssReceiverSubModel: 'GNSS002',
    gnssReceiverSubFirmwareVersion: 'GNSS_FW02',
    gnssSettingId: 'b2547913-f868-a927-27de-d19fc4fb415a',
    machineInfoCompanyName: 'Komatsu Ltd.',
    machineInfoMachineType: 'Excavator',
    machineInfoMachineName: 'PC200-10-000003',
    machineInfoMachineId: 'EX03',
    rentalStatus: 'Unapproved',
    lastUpdated: '2020-03-06T01:03:01.000Z',
    hasLicense: true,
    licenseGrantInfo: 'MachineGuidance',
    inSendingBackSupported: false,
    needsNtripDataReration: false,
  },
  {
    id: 4,
    uuid: '1bda5dc2-a199-4814-ac57-c8ced0ef02c6',
    retrofitDistributorId: 2,
    corporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    ownerCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    siteId: '65a5a0fc-ae01-4fae-8f8a-406faf1fb0e4',
    corporationName: '',
    description: '',
    macAddress: '',
    latitude: 35.6958,
    longitude: 139.690867,
    lastOnlineTime: '2020-03-06T01:04:01.000Z',
    isError: true,
    isOnline: false,
    basicInfoManufacturer: 'Komatsu Ltd.',
    basicInfoModel: 'RF001-01',
    basicInfoProductNumber: 'PROD004',
    basicInfoSerialNumber: 'SERIAL004',
    controllerManufacturer: 'Company 1',
    controllerModel: 'CONT001',
    controllerFirmwareVersion: 'CONT_FW01',
    gnssReceiverMainManufacturer: 'Company 5',
    gnssReceiverMainModel: 'GNSS001',
    gnssReceiverMainFirmwareVersion: 'GNSS_FW01',
    gnssReceiverSubManufacturer: 'Company10',
    gnssReceiverSubModel: 'GNSS002',
    gnssReceiverSubFirmwareVersion: 'GNSS_FW02',
    gnssSettingId: '3be73c81-3fef-16f6-3570-23219ac088d1',
    machineInfoCompanyName: 'Komatsu Ltd.',
    machineInfoMachineType: 'Excavator',
    machineInfoMachineName: 'PC200-10-000004',
    machineInfoMachineId: 'EX04',
    rentalStatus: 'Returned',
    lastUpdated: '2020-03-06T01:04:01.000Z',
    hasLicense: true,
    licenseGrantInfo: 'PayloadMeter',
    inSendingBackSupported: false,
    needsNtripDataReration: false,
  },
  {
    id: 5,
    uuid: '1bda5dc2-a199-4814-ac57-c8ced0ef02c7',
    retrofitDistributorId: 2,
    corporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    ownerCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    siteId: '65a5a0fc-ae01-4fae-8f8a-406faf1fb0e4',
    corporationName: '',
    description: '',
    macAddress: '',
    latitude: 35.695805,
    longitude: 139.690433,
    lastOnlineTime: '2020-03-06T01:05:01.000Z',
    isError: true,
    isOnline: true,
    basicInfoManufacturer: 'Komatsu Ltd.',
    basicInfoModel: 'RF001-01',
    basicInfoProductNumber: 'PROD005',
    basicInfoSerialNumber: 'SERIAL005',
    controllerManufacturer: 'Company 1',
    controllerModel: 'CONT001',
    controllerFirmwareVersion: 'CONT_FW01',
    gnssReceiverMainManufacturer: 'Company 5',
    gnssReceiverMainModel: 'GNSS001',
    gnssReceiverMainFirmwareVersion: 'GNSS_FW01',
    gnssReceiverSubManufacturer: 'Company10',
    gnssReceiverSubModel: 'GNSS002',
    gnssReceiverSubFirmwareVersion: 'GNSS_FW02',
    gnssSettingId: 'e6bf09ae-e294-dfe4-31df-9b56bf5d0279',
    machineInfoCompanyName: 'Komatsu Ltd.',
    machineInfoMachineType: 'Excavator',
    machineInfoMachineName: 'PC200-10-000005',
    machineInfoMachineId: 'EX05',
    rentalStatus: 'Returned',
    lastUpdated: '2020-03-06T01:05:01.000Z',
    hasLicense: true,
    licenseGrantInfo: 'Asbuilt',
    inSendingBackSupported: false,
    needsNtripDataReration: false,
  },
  {
    id: 6,
    uuid: '3b2ec2aa-cf6a-549a-0458-6bdacd771960',
    retrofitDistributorId: 2,
    corporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    ownerCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    siteId: '14b2149c-9d45-f3cb-bc93-7916ecc3778d',
    corporationName: '',
    description: '',
    macAddress: '',
    latitude: 35.671528,
    longitude: 139.742509,
    lastOnlineTime: '2020-02-01T08:00:01.000Z',
    isError: false,
    isOnline: true,
    basicInfoManufacturer: 'akasakatec',
    basicInfoModel: 'RF001-01',
    basicInfoProductNumber: 'PROD001',
    basicInfoSerialNumber: 'SERIAL06',
    controllerManufacturer: 'akasakatec',
    controllerModel: 'AKSK-CONT001',
    controllerFirmwareVersion: 'CONT_FW01',
    gnssReceiverMainManufacturer: 'akasakatec',
    gnssReceiverMainModel: 'AKSK-M-GNSS001',
    gnssReceiverMainFirmwareVersion: 'GNSS_FW01',
    gnssReceiverSubManufacturer: 'akasakatec',
    gnssReceiverSubModel: 'AKSK-S-GNSS001',
    gnssReceiverSubFirmwareVersion: 'GNSS_FW02',
    gnssSettingId: 'd83f9b34-2920-7ca0-cddd-86213e6169a9',
    machineInfoCompanyName: 'Komatsu Ltd.',
    machineInfoMachineType: 'Excavator',
    machineInfoMachineName: '建機１',
    machineInfoMachineId: 'MS001',
    rentalStatus: 'Returned',
    lastUpdated: '2020-02-01T08:00:01.000Z',
    hasLicense: false,
    inSendingBackSupported: false,
    needsNtripDataReration: false,
  },
  {
    id: 7,
    uuid: '75ec6eef-6166-c3e3-7e7f-336c82958318',
    retrofitDistributorId: 2,
    corporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    ownerCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    siteId: '6b7741a1-c2cf-4ba3-bc2d-7d19bac78922',
    corporationName: '',
    description: '',
    macAddress: '40A108D0894C',
    latitude: 35.671391,
    longitude: 139.741659,
    lastOnlineTime: '2020-02-01T08:00:02.000Z',
    isError: false,
    isOnline: true,
    basicInfoManufacturer: 'akasakatec',
    basicInfoModel: 'RF001-01',
    basicInfoProductNumber: 'PROD002',
    basicInfoSerialNumber: 'SERIAL001',
    controllerManufacturer: 'akasakatec',
    controllerModel: 'AKSK-CONT001',
    controllerFirmwareVersion: 'CONT_FW01',
    gnssReceiverMainManufacturer: 'akasakatec',
    gnssReceiverMainModel: 'AKSK-M-GNSS001',
    gnssReceiverMainFirmwareVersion: 'GNSS_FW01',
    gnssReceiverSubManufacturer: 'akasakatec',
    gnssReceiverSubModel: 'AKSK-S-GNSS001',
    gnssReceiverSubFirmwareVersion: 'GNSS_FW02',
    gnssSettingId: '61ea898a-9369-3044-fa80-4893a1e6f518',
    machineInfoCompanyName: 'Komatsu Ltd.',
    machineInfoMachineType: 'Excavator',
    machineInfoMachineName: '建機２(Lenovo TB-X505L)',
    machineInfoMachineId: 'MS002',
    rentalStatus: 'Returned',
    lastUpdated: '2020-02-01T08:00:02.000Z',
    hasLicense: true,
    licenseGrantInfo: 'MachineGuidance,PayloadMeter,Asbuilt',
    inSendingBackSupported: false,
    needsNtripDataReration: false,
  },
  {
    id: 8,
    uuid: '3bafa195-3917-6266-89e3-754731b07e66',
    retrofitDistributorId: 2,
    corporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    ownerCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    siteId: '6b7741a1-c2cf-4ba3-bc2d-7d19bac78922',
    corporationName: '',
    description: '',
    macAddress: '',
    latitude: 35.671125,
    longitude: 139.741688,
    lastOnlineTime: '2020-02-01T08:00:03.000Z',
    isError: false,
    isOnline: true,
    basicInfoManufacturer: 'akasakatec',
    basicInfoModel: 'RF001-01',
    basicInfoProductNumber: 'PROD003',
    basicInfoSerialNumber: 'SERIAL001',
    controllerManufacturer: 'akasakatec',
    controllerModel: 'AKSK-CONT001',
    controllerFirmwareVersion: 'CONT_FW01',
    gnssReceiverMainManufacturer: 'akasakatec',
    gnssReceiverMainModel: 'AKSK-M-GNSS001',
    gnssReceiverMainFirmwareVersion: 'GNSS_FW01',
    gnssReceiverSubManufacturer: 'akasakatec',
    gnssReceiverSubModel: 'AKSK-S-GNSS001',
    gnssReceiverSubFirmwareVersion: 'GNSS_FW02',
    gnssSettingId: '6cbc9e4f-67da-b0ab-ef04-32ddc5e3c6a9',
    machineInfoCompanyName: 'Komatsu Ltd.',
    machineInfoMachineType: 'Excavator',
    machineInfoMachineName: '建機３',
    machineInfoMachineId: 'MS003',
    rentalStatus: 'Returned',
    lastUpdated: '2020-02-01T08:00:03.000Z',
    hasLicense: true,
    licenseGrantInfo: 'MachineGuidance,PayloadMeter',
    inSendingBackSupported: false,
    needsNtripDataReration: false,
  },
  {
    id: 9,
    uuid: 'f956743e-d3c1-808b-5ec8-69792b3fe9c5',
    retrofitDistributorId: 2,
    corporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    ownerCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    siteId: '6b7741a1-c2cf-4ba3-bc2d-7d19bac78922',
    corporationName: '',
    description: '',
    macAddress: '',
    latitude: 35.670818,
    longitude: 139.742273,
    lastOnlineTime: '2020-02-01T08:00:04.000Z',
    isError: false,
    isOnline: true,
    basicInfoManufacturer: 'akasakatec',
    basicInfoModel: 'RF001-01',
    basicInfoProductNumber: 'PROD004',
    basicInfoSerialNumber: 'SERIAL001',
    controllerManufacturer: 'akasakatec',
    controllerModel: 'AKSK-CONT001',
    controllerFirmwareVersion: 'CONT_FW01',
    gnssReceiverMainManufacturer: 'akasakatec',
    gnssReceiverMainModel: 'AKSK-M-GNSS001',
    gnssReceiverMainFirmwareVersion: 'GNSS_FW01',
    gnssReceiverSubManufacturer: 'akasakatec',
    gnssReceiverSubModel: 'AKSK-S-GNSS001',
    gnssReceiverSubFirmwareVersion: 'GNSS_FW02',
    gnssSettingId: '7931cc1d-5683-c1cb-1bb1-12434560ce46',
    machineInfoCompanyName: 'Komatsu Ltd.',
    machineInfoMachineType: 'ROADROLLER',
    machineInfoMachineName: '建機４',
    machineInfoMachineId: 'MS004',
    rentalStatus: 'Returned',
    lastUpdated: '2020-02-01T08:00:04.000Z',
    hasLicense: false,
    inSendingBackSupported: false,
    needsNtripDataReration: false,
  },
  {
    id: 10,
    uuid: 'b409dcc3-c591-9953-bad3-0422c741d818',
    retrofitDistributorId: 2,
    corporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    ownerCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    siteId: '',
    corporationName: '',
    description: '',
    macAddress: '',
    latitude: 35.671103,
    longitude: 139.743534,
    lastOnlineTime: '2020-02-01T08:00:05.000Z',
    isError: false,
    isOnline: true,
    basicInfoManufacturer: 'akasakatec',
    basicInfoModel: 'RF001-01',
    basicInfoProductNumber: 'PROD005',
    basicInfoSerialNumber: 'SERIAL001',
    controllerManufacturer: 'akasakatec',
    controllerModel: 'AKSK-CONT001',
    controllerFirmwareVersion: 'CONT_FW01',
    gnssReceiverMainManufacturer: 'akasakatec',
    gnssReceiverMainModel: 'AKSK-M-GNSS001',
    gnssReceiverMainFirmwareVersion: 'GNSS_FW01',
    gnssReceiverSubManufacturer: 'akasakatec',
    gnssReceiverSubModel: 'AKSK-S-GNSS001',
    gnssReceiverSubFirmwareVersion: 'GNSS_FW02',
    gnssSettingId: 'c28aed65-e111-5b08-a5f4-456e79bcfe70',
    machineInfoCompanyName: 'Komatsu Ltd.',
    machineInfoMachineType: 'ROADROLLER',
    machineInfoMachineName: '建機５',
    machineInfoMachineId: 'MS005',
    rentalStatus: 'Returned',
    lastUpdated: '2020-02-01T08:00:05.000Z',
    hasLicense: true,
    licenseGrantInfo: 'FlexUnlimited',
    inSendingBackSupported: false,
    needsNtripDataReration: false,
  },
  {
    id: 11,
    uuid: '1bda5dc2-a199-4814-ac57-c8ced0ef02c8',
    retrofitDistributorId: 2,
    corporationId: 'd856114c-0bed-44c5-8185-0ef87c5f678c',
    ownerCorporationId: 'd856114c-0bed-44c5-8185-0ef87c5f678c',
    siteId: 'f837c090-f5ac-4973-8b65-53ee677c1dcb',
    corporationName: '',
    description: '',
    macAddress: '',
    latitude: 36.632953,
    longitude: 136.957135,
    lastOnlineTime: '2020-03-06T01:06:01.000Z',
    isError: false,
    isOnline: true,
    basicInfoManufacturer: 'Komatsu Ltd.',
    basicInfoModel: 'RF001-02',
    basicInfoProductNumber: 'PROD006',
    basicInfoSerialNumber: 'SERIAL006',
    controllerManufacturer: 'Company 1',
    controllerModel: 'CONT002',
    controllerFirmwareVersion: 'CONT_FW02',
    gnssReceiverMainManufacturer: 'Company 5',
    gnssReceiverMainModel: 'GNSS002',
    gnssReceiverMainFirmwareVersion: 'GNSS_FW02',
    gnssReceiverSubManufacturer: 'Company10',
    gnssReceiverSubModel: 'GNSS003',
    gnssReceiverSubFirmwareVersion: 'GNSS_FW03',
    gnssSettingId: '81297680-41c7-1077-8444-ab6307682459',
    machineInfoCompanyName: 'Komatsu Ltd.',
    machineInfoMachineType: 'Excavator',
    machineInfoMachineName: '砺波建機１',
    machineInfoMachineId: 'EX06',
    rentalStatus: 'Returned',
    lastUpdated: '2020-03-06T01:06:01.000Z',
    hasLicense: false,
    inSendingBackSupported: false,
    needsNtripDataReration: false,
  },
  {
    id: 12,
    uuid: '1bda5dc2-a199-4814-ac57-c8ced0ef02c9',
    retrofitDistributorId: 2,
    corporationId: 'd856114c-0bed-44c5-8185-0ef87c5f678c',
    ownerCorporationId: 'd856114c-0bed-44c5-8185-0ef87c5f678c',
    adminCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    adminCorporations: [
      {
        corporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
        corporationName: '株式会社クオリカ建築　テスト事業部',
      },
    ],
    siteId: 'f837c090-f5ac-4973-8b65-53ee677c1dcb',
    corporationName: '',
    description: '',
    macAddress: '',
    latitude: 36.633438,
    longitude: 136.957253,
    lastOnlineTime: '2020-03-06T01:07:01.000Z',
    isError: false,
    isOnline: true,
    basicInfoManufacturer: 'Komatsu Ltd.',
    basicInfoModel: 'RF001-02',
    basicInfoProductNumber: 'PROD007',
    basicInfoSerialNumber: 'SERIAL007',
    controllerManufacturer: 'Company 1',
    controllerModel: 'CONT002',
    controllerFirmwareVersion: 'CONT_FW02',
    gnssReceiverMainManufacturer: 'Company 5',
    gnssReceiverMainModel: 'GNSS002',
    gnssReceiverMainFirmwareVersion: 'GNSS_FW02',
    gnssReceiverSubManufacturer: 'Company10',
    gnssReceiverSubModel: 'GNSS003',
    gnssReceiverSubFirmwareVersion: 'GNSS_FW03',
    gnssSettingId: '5980c64a-2676-9418-9a96-fefd92160bdc',
    machineInfoCompanyName: 'Komatsu Ltd.',
    machineInfoMachineType: 'Excavator',
    machineInfoMachineName: '砺波建機２',
    machineInfoMachineId: 'EX07',
    rentalStatus: 'Returned',
    lastUpdated: '2020-03-06T01:07:01.000Z',
    hasLicense: false,
    inSendingBackSupported: false,
    needsNtripDataReration: false,
  },
  {
    id: 13,
    uuid: '1bda5dc2-a199-4814-ac57-c8ced0ef02ca',
    retrofitDistributorId: 2,
    corporationId: 'd087e444-ded2-de5e-087b-76f3ce864ae3',
    ownerCorporationId: 'd087e444-ded2-de5e-087b-76f3ce864ae3',
    siteId: '',
    corporationName: 'LANDLOG',
    ownerId: 'd087e444-ded2-de5e-087b-76f3ce864ae3',
    ownerName: 'LANDLOG',
    description: '',
    macAddress: '',
    latitude: 36.632465,
    longitude: 136.957049,
    lastOnlineTime: '2020-03-06T01:08:01.000Z',
    isError: false,
    isOnline: false,
    basicInfoManufacturer: 'Komatsu Ltd.',
    basicInfoModel: 'RF001-02',
    basicInfoProductNumber: 'PROD008',
    basicInfoSerialNumber: 'Retro-008',
    controllerManufacturer: 'Company 1',
    controllerModel: 'CONT002',
    controllerFirmwareVersion: 'CONT_FW02',
    gnssReceiverMainManufacturer: 'Company 5',
    gnssReceiverMainModel: 'GNSS002',
    gnssReceiverMainFirmwareVersion: 'GNSS_FW02',
    gnssReceiverSubManufacturer: 'Company10',
    gnssReceiverSubModel: 'GNSS003',
    gnssReceiverSubFirmwareVersion: 'GNSS_FW03',
    gnssSettingId: '163abcfa-d76d-00ca-31a8-f42fcd3786b6',
    machineInfoCompanyName: 'Komatsu Ltd.',
    machineInfoMachineType: 'Excavator',
    machineInfoMachineName: '砺波建機３',
    machineInfoMachineId: 'EX08',
    rentalStatus: 'Returned',
    lastUpdated: '2020-03-06T01:08:01.000Z',
    hasLicense: false,
    inSendingBackSupported: false,
    needsNtripDataReration: false,
  },
  {
    id: 14,
    uuid: '1bda5dc2-a199-4814-ac57-c8ced0ef02cb',
    retrofitDistributorId: 2,
    corporationId: 'd087e444-ded2-de5e-087b-76f3ce864ae3',
    ownerCorporationId: 'd087e444-ded2-de5e-087b-76f3ce864ae3',
    siteId: '',
    corporationName: 'LANDLOG',
    description: '',
    macAddress: '',
    latitude: 36.632448,
    longitude: 136.956185,
    lastOnlineTime: '2020-03-06T01:09:01.000Z',
    isError: true,
    isOnline: false,
    basicInfoManufacturer: 'Komatsu Ltd.',
    basicInfoModel: 'RF001-02',
    basicInfoProductNumber: 'PROD009',
    basicInfoSerialNumber: 'Retro-009',
    controllerManufacturer: 'Company 1',
    controllerModel: 'CONT002',
    controllerFirmwareVersion: 'CONT_FW02',
    gnssReceiverMainManufacturer: 'Company 5',
    gnssReceiverMainModel: 'GNSS002',
    gnssReceiverMainFirmwareVersion: 'GNSS_FW02',
    gnssReceiverSubManufacturer: 'Company10',
    gnssReceiverSubModel: 'GNSS003',
    gnssReceiverSubFirmwareVersion: 'GNSS_FW03',
    gnssSettingId: 'c004cfcc-0102-e48a-9847-8e5a6fc4b9e9',
    machineInfoCompanyName: 'Komatsu Ltd.',
    machineInfoMachineType: 'Excavator',
    machineInfoMachineName: '砺波建機４',
    machineInfoMachineId: 'EX09',
    rentalStatus: 'Returned',
    lastUpdated: '2020-03-06T01:09:01.000Z',
    hasLicense: false,
    inSendingBackSupported: false,
    needsNtripDataReration: false,
  },
  {
    id: 15,
    uuid: '1bda5dc2-a199-4814-ac57-c8ced0ef02cc',
    retrofitDistributorId: 2,
    corporationId: '20a2fa47-4b90-4714-a166-d36e80768581',
    ownerCorporationId: '20a2fa47-4b90-4714-a166-d36e80768581',
    siteId: '',
    corporationName: 'EARTHBRAIN',
    description: '',
    macAddress: '',
    latitude: 36.632671,
    longitude: 136.957306,
    lastOnlineTime: '2020-03-06T01:10:01.000Z',
    isError: true,
    isOnline: true,
    basicInfoManufacturer: 'Komatsu Ltd.',
    basicInfoModel: 'RF001-02',
    basicInfoProductNumber: 'PROD010',
    basicInfoSerialNumber: 'Retro-010',
    controllerManufacturer: 'Company 1',
    controllerModel: 'CONT002',
    controllerFirmwareVersion: 'CONT_FW02',
    gnssReceiverMainManufacturer: 'Company 5',
    gnssReceiverMainModel: 'GNSS002',
    gnssReceiverMainFirmwareVersion: 'GNSS_FW02',
    gnssReceiverSubManufacturer: 'Company10',
    gnssReceiverSubModel: 'GNSS003',
    gnssReceiverSubFirmwareVersion: 'GNSS_FW03',
    gnssSettingId: 'bc53abc1-b4be-2f31-c7a4-3bce4835d985',
    machineInfoCompanyName: 'Komatsu Ltd.',
    machineInfoMachineType: 'Excavator',
    machineInfoMachineName: '砺波建機５',
    machineInfoMachineId: 'EX10',
    rentalStatus: 'Returned',
    lastUpdated: '2020-03-06T01:10:01.000Z',
    hasLicense: false,
    inSendingBackSupported: false,
    needsNtripDataReration: false,
  },
  {
    id: 16,
    uuid: 'c1857654-f1d1-83e4-babc-8d53d21d6470',
    retrofitDistributorId: 2,
    corporationId: 'd856114c-0bed-44c5-8185-0ef87c5f678c',
    ownerCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    adminCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    adminCorporations: [
      {
        corporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
        corporationName: '株式会社クオリカ建築　テスト事業部',
      },
    ],
    siteId: 'f837c090-f5ac-4973-8b65-53ee677c1dcb',
    corporationName: '',
    description: '',
    macAddress: '',
    latitude: 35.696227,
    longitude: 139.691062,
    lastOnlineTime: '2020-02-01T08:00:01.000Z',
    isError: false,
    isOnline: true,
    basicInfoManufacturer: 'akasakatec',
    basicInfoModel: 'RF001-01',
    basicInfoProductNumber: 'PROD001',
    basicInfoSerialNumber: 'SERIAL016',
    controllerManufacturer: 'akasakatec',
    controllerModel: 'AKSK-CONT002',
    controllerFirmwareVersion: 'CONT_FW01',
    gnssReceiverMainManufacturer: 'akasakatec',
    gnssReceiverMainModel: 'AKSK-M-GNSS002',
    gnssReceiverMainFirmwareVersion: 'GNSS_FW01',
    gnssReceiverSubManufacturer: 'akasakatec',
    gnssReceiverSubModel: 'AKSK-S-GNSS001',
    gnssReceiverSubFirmwareVersion: 'GNSS_FW02',
    gnssSettingId: '5de9b67b-6c4f-30b8-6a58-43b96e6f90b5',
    machineInfoCompanyName: 'Komatsu Ltd.',
    machineInfoMachineType: 'Excavator',
    machineInfoMachineName: 'レンタル建機１',
    machineInfoMachineId: 'RK001',
    rentalStatus: 'Approved',
    lastUpdated: '2020-02-01T08:00:01.000Z',
    hasLicense: false,
    inSendingBackSupported: false,
    needsNtripDataReration: false,
  },
  {
    id: 17,
    uuid: 'dd16c22c-a281-60a3-d486-c4a2d9f17997',
    retrofitDistributorId: 2,
    corporationId: 'd087e444-ded2-de5e-087b-76f3ce864ae3',
    ownerCorporationId: 'd087e444-ded2-de5e-087b-76f3ce864ae3',
    siteId: '',
    corporationName: 'LANDLOG',
    description: '',
    macAddress: '',
    latitude: 35.696227,
    longitude: 139.691062,
    lastOnlineTime: '2020-02-01T08:00:02.000Z',
    isError: false,
    isOnline: true,
    basicInfoManufacturer: 'akasakatec',
    basicInfoModel: 'RF001-01',
    basicInfoProductNumber: 'PROD002',
    basicInfoSerialNumber: 'SERIAL017',
    controllerManufacturer: 'akasakatec',
    controllerModel: 'AKSK-CONT002',
    controllerFirmwareVersion: 'CONT_FW01',
    gnssReceiverMainManufacturer: 'akasakatec',
    gnssReceiverMainModel: 'AKSK-M-GNSS002',
    gnssReceiverMainFirmwareVersion: 'GNSS_FW01',
    gnssReceiverSubManufacturer: 'akasakatec',
    gnssReceiverSubModel: 'AKSK-S-GNSS001',
    gnssReceiverSubFirmwareVersion: 'GNSS_FW02',
    gnssSettingId: '612dfabc-3962-a47a-ccae-1674186f4b72',
    machineInfoCompanyName: 'Komatsu Ltd.',
    machineInfoMachineType: 'Excavator',
    machineInfoMachineName: 'レンタル建機２',
    machineInfoMachineId: 'RK002',
    rentalStatus: 'Approved',
    lastUpdated: '2020-02-01T08:00:02.000Z',
    hasLicense: false,
    inSendingBackSupported: false,
    needsNtripDataReration: false,
  },
  {
    id: 18,
    uuid: '3070a935-35af-bcce-2e17-f2d825718b5e',
    retrofitDistributorId: 2,
    corporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    ownerCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    siteId: '74bdcc81-330b-4d66-900a-48b0d452c784',
    corporationName: '',
    description: '',
    macAddress: '',
    latitude: 35.696227,
    longitude: 139.691062,
    lastOnlineTime: '2020-02-01T08:00:03.000Z',
    isError: false,
    isOnline: true,
    basicInfoManufacturer: 'akasakatec',
    basicInfoModel: 'RF001-01',
    basicInfoProductNumber: 'PROD003',
    basicInfoSerialNumber: 'SERIAL001',
    controllerManufacturer: 'akasakatec',
    controllerModel: 'AKSK-CONT002',
    controllerFirmwareVersion: 'CONT_FW01',
    gnssReceiverMainManufacturer: 'akasakatec',
    gnssReceiverMainModel: 'AKSK-M-GNSS002',
    gnssReceiverMainFirmwareVersion: 'GNSS_FW01',
    gnssReceiverSubManufacturer: 'akasakatec',
    gnssReceiverSubModel: 'AKSK-S-GNSS001',
    gnssReceiverSubFirmwareVersion: 'GNSS_FW02',
    gnssSettingId: '611be486-82e2-f639-c2bf-9658eb66908a',
    machineInfoCompanyName: 'Komatsu Ltd.',
    machineInfoMachineType: 'Excavator',
    machineInfoMachineName: 'レンタル建機３',
    machineInfoMachineId: 'RK003',
    rentalStatus: 'Unapproved',
    lastUpdated: '2020-02-01T08:00:03.000Z',
    hasLicense: false,
    inSendingBackSupported: false,
    needsNtripDataReration: false,
  },
  {
    id: 19,
    uuid: '31354550-2b5a-3d94-2a23-7b156e635ee9',
    retrofitDistributorId: 2,
    corporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    ownerCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    siteId: '74bdcc81-330b-4d66-900a-48b0d452c784',
    corporationName: '',
    description: '',
    macAddress: '',
    latitude: 35.696227,
    longitude: 139.691062,
    lastOnlineTime: '2020-02-01T08:00:04.000Z',
    isError: false,
    isOnline: true,
    basicInfoManufacturer: 'akasakatec',
    basicInfoModel: 'RF001-01',
    basicInfoProductNumber: 'PROD004',
    basicInfoSerialNumber: 'SERIAL001',
    controllerManufacturer: 'akasakatec',
    controllerModel: 'AKSK-CONT002',
    controllerFirmwareVersion: 'CONT_FW01',
    gnssReceiverMainManufacturer: 'akasakatec',
    gnssReceiverMainModel: 'AKSK-M-GNSS002',
    gnssReceiverMainFirmwareVersion: 'GNSS_FW01',
    gnssReceiverSubManufacturer: 'akasakatec',
    gnssReceiverSubModel: 'AKSK-S-GNSS001',
    gnssReceiverSubFirmwareVersion: 'GNSS_FW02',
    gnssSettingId: 'ec7bc267-c64f-37ee-3e1d-60e2420ac6be',
    machineInfoCompanyName: 'Komatsu Ltd.',
    machineInfoMachineType: 'ROADROLLER',
    machineInfoMachineName: 'レンタル建機４',
    machineInfoMachineId: 'RK004',
    rentalStatus: 'Unapproved',
    lastUpdated: '2020-02-01T08:00:04.000Z',
    hasLicense: false,
    inSendingBackSupported: false,
    needsNtripDataReration: false,
  },
  {
    id: 20,
    uuid: 'd372c410-6261-b566-c182-37fd545fcf06',
    retrofitDistributorId: 2,
    corporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    ownerCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    siteId: '74bdcc81-330b-4d66-900a-48b0d452c784',
    corporationName: '',
    description: '',
    macAddress: '',
    latitude: 35.696227,
    longitude: 139.691062,
    lastOnlineTime: '2020-02-01T08:00:05.000Z',
    isError: false,
    isOnline: true,
    basicInfoManufacturer: 'akasakatec',
    basicInfoModel: 'RF001-01',
    basicInfoProductNumber: 'PROD005',
    basicInfoSerialNumber: 'SERIAL001',
    controllerManufacturer: 'akasakatec',
    controllerModel: 'AKSK-CONT002',
    controllerFirmwareVersion: 'CONT_FW01',
    gnssReceiverMainManufacturer: 'akasakatec',
    gnssReceiverMainModel: 'AKSK-M-GNSS002',
    gnssReceiverMainFirmwareVersion: 'GNSS_FW01',
    gnssReceiverSubManufacturer: 'akasakatec',
    gnssReceiverSubModel: 'AKSK-S-GNSS001',
    gnssReceiverSubFirmwareVersion: 'GNSS_FW02',
    gnssSettingId: 'd84f56f1-8edb-9a70-d1e3-c7d05dbed46b',
    machineInfoCompanyName: 'Komatsu Ltd.',
    machineInfoMachineType: 'ROADROLLER',
    machineInfoMachineName: 'レンタル建機５',
    machineInfoMachineId: 'RK005',
    rentalStatus: 'Returned',
    lastUpdated: '2020-02-01T08:00:05.000Z',
    hasLicense: false,
    inSendingBackSupported: false,
    needsNtripDataReration: false,
  },
  {
    id: 21,
    uuid: '254a2af2-ff0f-91a0-5e21-a8d8dd4f049d',
    retrofitDistributorId: 2,
    corporationId: 'd856114c-0bed-44c5-8185-0ef87c5f678c',
    ownerCorporationId: 'd856114c-0bed-44c5-8185-0ef87c5f678c',
    siteId: 'ebe0e417-a2ba-4a24-9052-2d78f69f122f',
    corporationName: '',
    description: '',
    macAddress: '',
    latitude: 36.362261,
    longitude: 136.425454,
    lastOnlineTime: '2020-02-01T08:00:01.000Z',
    isError: false,
    isOnline: true,
    basicInfoManufacturer: 'akasakatec',
    basicInfoModel: 'RF001-01',
    basicInfoProductNumber: 'PROD001',
    basicInfoSerialNumber: 'SERIAL001',
    controllerManufacturer: 'akasakatec',
    controllerModel: 'AKSK-CONT002',
    controllerFirmwareVersion: 'CONT_FW01',
    gnssReceiverMainManufacturer: 'akasakatec',
    gnssReceiverMainModel: 'AKSK-M-GNSS002',
    gnssReceiverMainFirmwareVersion: 'GNSS_FW01',
    gnssReceiverSubManufacturer: 'akasakatec',
    gnssReceiverSubModel: 'AKSK-S-GNSS001',
    gnssReceiverSubFirmwareVersion: 'GNSS_FW02',
    gnssSettingId: '8cc897bd-962c-ee4e-261a-325c8ff9010a',
    machineInfoCompanyName: 'Komatsu Ltd.',
    machineInfoMachineType: 'Excavator',
    machineInfoMachineName: '粟津建機１',
    machineInfoMachineId: 'PC170-001',
    rentalStatus: 'Returned',
    lastUpdated: '2020-02-01T08:00:01.000Z',
    hasLicense: false,
    inSendingBackSupported: false,
    needsNtripDataReration: false,
  },
  {
    id: 22,
    uuid: '9bb9c196-b250-f69c-2a26-202c9ea00909',
    retrofitDistributorId: 2,
    corporationId: 'd856114c-0bed-44c5-8185-0ef87c5f678c',
    ownerCorporationId: 'd856114c-0bed-44c5-8185-0ef87c5f678c',
    siteId: 'ebe0e417-a2ba-4a24-9052-2d78f69f122f',
    corporationName: '',
    description: '',
    macAddress: '',
    latitude: 36.361872,
    longitude: 136.425508,
    lastOnlineTime: '2020-02-01T08:00:02.000Z',
    isError: false,
    isOnline: true,
    basicInfoManufacturer: 'akasakatec',
    basicInfoModel: 'RF001-01',
    basicInfoProductNumber: 'PROD002',
    basicInfoSerialNumber: 'SERIAL001',
    controllerManufacturer: 'akasakatec',
    controllerModel: 'AKSK-CONT002',
    controllerFirmwareVersion: 'CONT_FW01',
    gnssReceiverMainManufacturer: 'akasakatec',
    gnssReceiverMainModel: 'AKSK-M-GNSS002',
    gnssReceiverMainFirmwareVersion: 'GNSS_FW01',
    gnssReceiverSubManufacturer: 'akasakatec',
    gnssReceiverSubModel: 'AKSK-S-GNSS001',
    gnssReceiverSubFirmwareVersion: 'GNSS_FW02',
    gnssSettingId: 'addc0b71-9768-bde4-5f63-9edca04e61cf',
    machineInfoCompanyName: 'Komatsu Ltd.',
    machineInfoMachineType: 'Excavator',
    machineInfoMachineName: '粟津建機２',
    machineInfoMachineId: 'PC170-002',
    rentalStatus: 'Returned',
    lastUpdated: '2020-02-01T08:00:02.000Z',
    hasLicense: false,
    inSendingBackSupported: false,
    needsNtripDataReration: false,
  },
  {
    id: 23,
    uuid: '9ea4dd9c-be09-dba7-be62-b5ed078525af',
    retrofitDistributorId: 2,
    corporationId: 'd856114c-0bed-44c5-8185-0ef87c5f678c',
    ownerCorporationId: 'd856114c-0bed-44c5-8185-0ef87c5f678c',
    siteId: 'ebe0e417-a2ba-4a24-9052-2d78f69f122f',
    corporationName: '',
    description: '',
    macAddress: '',
    latitude: 36.36138,
    longitude: 136.425551,
    lastOnlineTime: '2020-02-01T08:00:03.000Z',
    isError: false,
    isOnline: true,
    basicInfoManufacturer: 'akasakatec',
    basicInfoModel: 'RF001-01',
    basicInfoProductNumber: 'PROD003',
    basicInfoSerialNumber: 'SERIAL001',
    controllerManufacturer: 'akasakatec',
    controllerModel: 'AKSK-CONT002',
    controllerFirmwareVersion: 'CONT_FW01',
    gnssReceiverMainManufacturer: 'akasakatec',
    gnssReceiverMainModel: 'AKSK-M-GNSS002',
    gnssReceiverMainFirmwareVersion: 'GNSS_FW01',
    gnssReceiverSubManufacturer: 'akasakatec',
    gnssReceiverSubModel: 'AKSK-S-GNSS001',
    gnssReceiverSubFirmwareVersion: 'GNSS_FW02',
    gnssSettingId: '728dc8b6-9c9d-b577-5207-d4d05ad02ca7',
    machineInfoCompanyName: 'Komatsu Ltd.',
    machineInfoMachineType: 'Excavator',
    machineInfoMachineName: '粟津建機３',
    machineInfoMachineId: 'PC170-003',
    rentalStatus: 'Returned',
    lastUpdated: '2020-02-01T08:00:03.000Z',
    hasLicense: false,
    inSendingBackSupported: false,
    needsNtripDataReration: false,
  },
  {
    id: 24,
    uuid: '38ee3937-2177-556b-a498-e7f7e6305b97',
    retrofitDistributorId: 2,
    corporationId: 'd856114c-0bed-44c5-8185-0ef87c5f678c',
    ownerCorporationId: 'd856114c-0bed-44c5-8185-0ef87c5f678c',
    siteId: 'ebe0e417-a2ba-4a24-9052-2d78f69f122f',
    corporationName: '',
    description: '',
    macAddress: '',
    latitude: 36.360991,
    longitude: 136.425508,
    lastOnlineTime: '2020-02-01T08:00:04.000Z',
    isError: false,
    isOnline: true,
    basicInfoManufacturer: 'akasakatec',
    basicInfoModel: 'RF001-01',
    basicInfoProductNumber: 'PROD004',
    basicInfoSerialNumber: 'SERIAL001',
    controllerManufacturer: 'akasakatec',
    controllerModel: 'AKSK-CONT002',
    controllerFirmwareVersion: 'CONT_FW01',
    gnssReceiverMainManufacturer: 'akasakatec',
    gnssReceiverMainModel: 'AKSK-M-GNSS002',
    gnssReceiverMainFirmwareVersion: 'GNSS_FW01',
    gnssReceiverSubManufacturer: 'akasakatec',
    gnssReceiverSubModel: 'AKSK-S-GNSS001',
    gnssReceiverSubFirmwareVersion: 'GNSS_FW02',
    gnssSettingId: 'b794495a-7485-41ad-3b3e-04d274126f5e',
    machineInfoCompanyName: 'Komatsu Ltd.',
    machineInfoMachineType: 'ROADROLLER',
    machineInfoMachineName: '粟津建機４',
    machineInfoMachineId: 'BW115C-001',
    rentalStatus: 'Returned',
    lastUpdated: '2020-02-01T08:00:04.000Z',
    hasLicense: false,
    inSendingBackSupported: false,
    needsNtripDataReration: false,
  },
  {
    id: 25,
    uuid: '4fb695eb-f9e4-22ef-66c8-3f394c797379',
    retrofitDistributorId: 2,
    corporationId: 'd856114c-0bed-44c5-8185-0ef87c5f678c',
    ownerCorporationId: 'd856114c-0bed-44c5-8185-0ef87c5f678c',
    siteId: 'ebe0e417-a2ba-4a24-9052-2d78f69f122f',
    corporationName: '',
    description: '',
    macAddress: '',
    latitude: 36.360153,
    longitude: 136.417161,
    lastOnlineTime: '2020-02-01T08:00:05.000Z',
    isError: false,
    isOnline: true,
    basicInfoManufacturer: 'akasakatec',
    basicInfoModel: 'RF001-01',
    basicInfoProductNumber: 'PROD005',
    basicInfoSerialNumber: 'SERIAL001',
    controllerManufacturer: 'akasakatec',
    controllerModel: 'AKSK-CONT002',
    controllerFirmwareVersion: 'CONT_FW01',
    gnssReceiverMainManufacturer: 'akasakatec',
    gnssReceiverMainModel: 'AKSK-M-GNSS002',
    gnssReceiverMainFirmwareVersion: 'GNSS_FW01',
    gnssReceiverSubManufacturer: 'akasakatec',
    gnssReceiverSubModel: 'AKSK-S-GNSS001',
    gnssReceiverSubFirmwareVersion: 'GNSS_FW02',
    gnssSettingId: '1c28d065-b6da-7096-7fdb-7b73eb53d78d',
    machineInfoCompanyName: 'Komatsu Ltd.',
    machineInfoMachineType: 'ROADROLLER',
    machineInfoMachineName: '粟津建機５',
    machineInfoMachineId: 'BW115C-002',
    rentalStatus: 'Returned',
    lastUpdated: '2020-02-01T08:00:05.000Z',
    hasLicense: false,
    inSendingBackSupported: false,
    needsNtripDataReration: false,
  },
  {
    id: 26,
    uuid: 'ea127e83-2acd-39e9-b26a-48a4046e8bf6',
    retrofitDistributorId: 2,
    corporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    ownerCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    siteId: '74bdcc81-330b-4d66-900a-48b0d452c784',
    corporationName: '',
    description: '',
    macAddress: '',
    latitude: 36.3601,
    longitude: 136.4171,
    lastOnlineTime: '2020-02-01T08:00:01.000Z',
    isError: false,
    isOnline: true,
    basicInfoManufacturer: 'akasakatec',
    basicInfoModel: 'RF001-01',
    basicInfoProductNumber: 'PROD001',
    basicInfoSerialNumber: 'SERIAL001',
    controllerManufacturer: 'akasakatec',
    controllerModel: 'AKSK-CONT002',
    controllerFirmwareVersion: 'CONT_FW01',
    gnssReceiverMainManufacturer: 'akasakatec',
    gnssReceiverMainModel: 'AKSK-M-GNSS002',
    gnssReceiverMainFirmwareVersion: 'GNSS_FW01',
    gnssReceiverSubManufacturer: 'akasakatec',
    gnssReceiverSubModel: 'AKSK-S-GNSS001',
    gnssReceiverSubFirmwareVersion: 'GNSS_FW02',
    gnssSettingId: '94fc22df-5b77-2b54-86ee-ff850f1104f1',
    machineInfoCompanyName: 'Komatsu Ltd.',
    machineInfoMachineType: 'Excavator',
    machineInfoMachineName: 'レンタル建機６',
    machineInfoMachineId: 'RK006',
    rentalStatus: 'Unapproved',
    lastUpdated: '2020-02-01T08:00:01.000Z',
    hasLicense: false,
    inSendingBackSupported: false,
    needsNtripDataReration: false,
  },
  {
    id: 27,
    uuid: '3213047f-5cdc-7e4d-cb32-58b025969711',
    retrofitDistributorId: 2,
    corporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    ownerCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    siteId: '74bdcc81-330b-4d66-900a-48b0d452c784',
    corporationName: '',
    description: '',
    macAddress: '',
    latitude: 36.3602,
    longitude: 136.4172,
    lastOnlineTime: '2020-02-01T08:00:02.000Z',
    isError: false,
    isOnline: true,
    basicInfoManufacturer: 'akasakatec',
    basicInfoModel: 'RF001-01',
    basicInfoProductNumber: 'PROD002',
    basicInfoSerialNumber: 'SERIAL001',
    controllerManufacturer: 'akasakatec',
    controllerModel: 'AKSK-CONT002',
    controllerFirmwareVersion: 'CONT_FW01',
    gnssReceiverMainManufacturer: 'akasakatec',
    gnssReceiverMainModel: 'AKSK-M-GNSS002',
    gnssReceiverMainFirmwareVersion: 'GNSS_FW01',
    gnssReceiverSubManufacturer: 'akasakatec',
    gnssReceiverSubModel: 'AKSK-S-GNSS001',
    gnssReceiverSubFirmwareVersion: 'GNSS_FW02',
    gnssSettingId: 'c204e96b-ba25-852a-9d08-3bc7368a8403',
    machineInfoCompanyName: 'Komatsu Ltd.',
    machineInfoMachineType: 'Excavator',
    machineInfoMachineName: 'レンタル建機７',
    machineInfoMachineId: 'RK007',
    rentalStatus: 'Unapproved',
    lastUpdated: '2020-02-01T08:00:02.000Z',
    hasLicense: false,
    inSendingBackSupported: false,
    needsNtripDataReration: false,
  },
  {
    id: 28,
    uuid: '79c141fc-9498-bab4-c8f5-072b64c5738a',
    retrofitDistributorId: 2,
    corporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    ownerCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    siteId: '',
    corporationName: '',
    description: '',
    macAddress: '',
    latitude: 35.669403,
    longitude: 139.744883,
    lastOnlineTime: '2020-02-01T08:00:03.000Z',
    isError: false,
    isOnline: true,
    basicInfoManufacturer: 'akasakatec',
    basicInfoModel: 'RF001-01',
    basicInfoProductNumber: 'PROD003',
    basicInfoSerialNumber: 'SERIAL001',
    controllerManufacturer: 'akasakatec',
    controllerModel: 'AKSK-CONT002',
    controllerFirmwareVersion: 'CONT_FW01',
    gnssReceiverMainManufacturer: 'akasakatec',
    gnssReceiverMainModel: 'AKSK-M-GNSS002',
    gnssReceiverMainFirmwareVersion: 'GNSS_FW01',
    gnssReceiverSubManufacturer: 'akasakatec',
    gnssReceiverSubModel: 'AKSK-S-GNSS001',
    gnssReceiverSubFirmwareVersion: 'GNSS_FW02',
    gnssSettingId: 'a00b7e2b-ef98-7abe-1a16-b45ab50ab5b1',
    machineInfoCompanyName: 'Komatsu Ltd.',
    machineInfoMachineType: 'Excavator',
    machineInfoMachineName: 'レンタル建機８',
    machineInfoMachineId: 'RK008',
    rentalStatus: 'Approved',
    lastUpdated: '2020-02-01T08:00:03.000Z',
    hasLicense: false,
    inSendingBackSupported: false,
    needsNtripDataReration: false,
  },
  {
    id: 29,
    uuid: 'a8e7b4e6-0dc5-0abc-74bf-3fb6a8081f57',
    retrofitDistributorId: 2,
    corporationId: '0da5298e-0081-08af-c9dd-3c8ae99fa742',
    ownerCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    siteId: '5fc8b9e5-996e-4f4e-acae-0dc5d4ffae96',
    corporationName: '',
    description: '',
    macAddress: '',
    latitude: 35.669022,
    longitude: 139.74433,
    lastOnlineTime: '2020-02-01T08:00:04.000Z',
    isError: false,
    isOnline: true,
    basicInfoManufacturer: 'akasakatec',
    basicInfoModel: 'RF001-01',
    basicInfoProductNumber: 'PROD004',
    basicInfoSerialNumber: 'SERIAL001',
    controllerManufacturer: 'akasakatec',
    controllerModel: 'AKSK-CONT002',
    controllerFirmwareVersion: 'CONT_FW01',
    gnssReceiverMainManufacturer: 'akasakatec',
    gnssReceiverMainModel: 'AKSK-M-GNSS002',
    gnssReceiverMainFirmwareVersion: 'GNSS_FW01',
    gnssReceiverSubManufacturer: 'akasakatec',
    gnssReceiverSubModel: 'AKSK-S-GNSS001',
    gnssReceiverSubFirmwareVersion: 'GNSS_FW02',
    gnssSettingId: 'fef037f2-02ff-1263-2f9e-973a4fe0ace1',
    machineInfoCompanyName: 'Komatsu Ltd.',
    machineInfoMachineType: 'ROADROLLER',
    machineInfoMachineName: 'レンタル建機９',
    machineInfoMachineId: 'RK009',
    rentalStatus: 'Approved',
    lastUpdated: '2020-02-01T08:00:04.000Z',
    hasLicense: false,
    inSendingBackSupported: false,
    needsNtripDataReration: false,
  },
  {
    id: 30,
    uuid: 'd8b79362-62b3-5733-9251-cc2eaffe0f20',
    retrofitDistributorId: 2,
    corporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    ownerCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    siteId: '74bdcc81-330b-4d66-900a-48b0d452c784',
    corporationName: '',
    description: '',
    macAddress: '',
    latitude: 36.3605,
    longitude: 136.4175,
    lastOnlineTime: '2020-02-01T08:00:05.000Z',
    isError: false,
    isOnline: true,
    basicInfoManufacturer: 'akasakatec',
    basicInfoModel: 'RF001-01',
    basicInfoProductNumber: 'PROD005',
    basicInfoSerialNumber: 'SERIAL001',
    controllerManufacturer: 'akasakatec',
    controllerModel: 'AKSK-CONT002',
    controllerFirmwareVersion: 'CONT_FW01',
    gnssReceiverMainManufacturer: 'akasakatec',
    gnssReceiverMainModel: 'AKSK-M-GNSS002',
    gnssReceiverMainFirmwareVersion: 'GNSS_FW01',
    gnssReceiverSubManufacturer: 'akasakatec',
    gnssReceiverSubModel: 'AKSK-S-GNSS001',
    gnssReceiverSubFirmwareVersion: 'GNSS_FW02',
    gnssSettingId: '82563fa6-cf5f-3302-ed70-b54d24ad0003',
    machineInfoCompanyName: 'Komatsu Ltd.',
    machineInfoMachineType: 'ROADROLLER',
    machineInfoMachineName: 'レンタル建機１０',
    machineInfoMachineId: 'RK010',
    rentalStatus: 'Returned',
    lastUpdated: '2020-02-01T08:00:05.000Z',
    hasLicense: false,
    inSendingBackSupported: false,
    needsNtripDataReration: false,
  },
  {
    id: 31,
    uuid: '1bda5dc2-a199-4814-ac57-c8ced0ef02cd',
    retrofitDistributorId: 2,
    corporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    ownerCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    siteId: '65a5a0fc-ae01-4fae-8f8a-406faf1fb0e4',
    corporationName: '',
    description: '',
    macAddress: '',
    latitude: 35.696227,
    longitude: 139.691062,
    lastOnlineTime: '2020-03-06T01:10:01.000Z',
    isError: false,
    isOnline: true,
    basicInfoManufacturer: 'Komatsu Ltd.',
    basicInfoModel: 'RF001-03',
    basicInfoProductNumber: 'PROD011',
    basicInfoSerialNumber: 'SERIAL011',
    controllerManufacturer: 'Company 1',
    controllerModel: 'CONT003',
    controllerFirmwareVersion: 'CONT_FW03',
    gnssReceiverMainManufacturer: 'Company 5',
    gnssReceiverMainModel: 'GNSS003',
    gnssReceiverMainFirmwareVersion: 'GNSS_FW03',
    gnssReceiverSubManufacturer: 'Company10',
    gnssReceiverSubModel: 'GNSS004',
    gnssReceiverSubFirmwareVersion: 'GNSS_FW04',
    gnssSettingId: '3d48464c-acbc-488c-6e96-b1e858226195',
    machineInfoCompanyName: 'Komatsu Ltd.',
    machineInfoMachineType: 'Excavator',
    machineInfoMachineName: 'PC200-12-000001',
    machineInfoMachineId: 'EX11',
    rentalStatus: 'Returned',
    lastUpdated: '2020-03-06T01:10:01.000Z',
    hasLicense: false,
    inSendingBackSupported: false,
    needsNtripDataReration: false,
  },
  {
    id: 32,
    uuid: '1bda5dc2-a199-4814-ac57-c8ced0ef02ce',
    retrofitDistributorId: 2,
    corporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    ownerCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    siteId: '65a5a0fc-ae01-4fae-8f8a-406faf1fb0e4',
    corporationName: '',
    description: '',
    macAddress: '',
    latitude: 35.696227,
    longitude: 139.691062,
    lastOnlineTime: '2020-03-06T01:11:01.000Z',
    isError: false,
    isOnline: true,
    basicInfoManufacturer: 'Komatsu Ltd.',
    basicInfoModel: 'RF001-03',
    basicInfoProductNumber: 'PROD012',
    basicInfoSerialNumber: 'SERIAL012',
    controllerManufacturer: 'Company 1',
    controllerModel: 'CONT003',
    controllerFirmwareVersion: 'CONT_FW03',
    gnssReceiverMainManufacturer: 'Company 5',
    gnssReceiverMainModel: 'GNSS003',
    gnssReceiverMainFirmwareVersion: 'GNSS_FW03',
    gnssReceiverSubManufacturer: 'Company10',
    gnssReceiverSubModel: 'GNSS004',
    gnssReceiverSubFirmwareVersion: 'GNSS_FW04',
    gnssSettingId: 'e7276d3c-221b-6b94-da37-343df5d511d0',
    machineInfoCompanyName: 'Komatsu Ltd.',
    machineInfoMachineType: 'Excavator',
    machineInfoMachineName: 'PC200-12-000002',
    machineInfoMachineId: 'EX12',
    rentalStatus: 'Returned',
    lastUpdated: '2020-03-06T01:11:01.000Z',
    hasLicense: false,
    inSendingBackSupported: false,
    needsNtripDataReration: false,
  },
  {
    id: 33,
    uuid: '1bda5dc2-a199-4814-ac57-c8ced0ef02cf',
    retrofitDistributorId: 2,
    corporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    ownerCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    siteId: '65a5a0fc-ae01-4fae-8f8a-406faf1fb0e4',
    corporationName: '',
    description: '',
    macAddress: '',
    latitude: 35.696227,
    longitude: 139.691062,
    lastOnlineTime: '2020-03-06T01:12:01.000Z',
    isError: false,
    isOnline: false,
    basicInfoManufacturer: 'Komatsu Ltd.',
    basicInfoModel: 'RF001-03',
    basicInfoProductNumber: 'PROD013',
    basicInfoSerialNumber: 'SERIAL013',
    controllerManufacturer: 'Company 1',
    controllerModel: 'CONT003',
    controllerFirmwareVersion: 'CONT_FW03',
    gnssReceiverMainManufacturer: 'Company 5',
    gnssReceiverMainModel: 'GNSS003',
    gnssReceiverMainFirmwareVersion: 'GNSS_FW03',
    gnssReceiverSubManufacturer: 'Company10',
    gnssReceiverSubModel: 'GNSS004',
    gnssReceiverSubFirmwareVersion: 'GNSS_FW04',
    gnssSettingId: '0eb03572-9712-21f5-f08c-11a7e2c46e8d',
    machineInfoCompanyName: 'Komatsu Ltd.',
    machineInfoMachineType: 'Dozer',
    machineInfoMachineName: 'PC200-12-000003',
    machineInfoMachineId: 'EX13',
    rentalStatus: 'Returned',
    lastUpdated: '2020-03-06T01:12:01.000Z',
    hasLicense: false,
    inSendingBackSupported: false,
    needsNtripDataReration: false,
  },
  {
    id: 34,
    uuid: '1bda5dc2-a199-4814-ac57-c8ced0ef02d0',
    retrofitDistributorId: 2,
    corporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    ownerCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    siteId: '65a5a0fc-ae01-4fae-8f8a-406faf1fb0e4',
    corporationName: '',
    description: '',
    macAddress: '',
    latitude: 35.696227,
    longitude: 139.691062,
    lastOnlineTime: '2020-03-06T01:13:01.000Z',
    isError: true,
    isOnline: false,
    basicInfoManufacturer: 'Komatsu Ltd.',
    basicInfoModel: 'RF001-03',
    basicInfoProductNumber: 'PROD014',
    basicInfoSerialNumber: 'SERIAL014',
    controllerManufacturer: 'Company 1',
    controllerModel: 'CONT003',
    controllerFirmwareVersion: 'CONT_FW03',
    gnssReceiverMainManufacturer: 'Company 5',
    gnssReceiverMainModel: 'GNSS003',
    gnssReceiverMainFirmwareVersion: 'GNSS_FW03',
    gnssReceiverSubManufacturer: 'Company10',
    gnssReceiverSubModel: 'GNSS004',
    gnssReceiverSubFirmwareVersion: 'GNSS_FW04',
    gnssSettingId: '6d213445-bed6-eb18-24e1-bf608158ba98',
    machineInfoCompanyName: 'Komatsu Ltd.',
    machineInfoMachineType: 'DOZER',
    machineInfoMachineName: 'PC200-12-000004',
    machineInfoMachineId: 'EX14',
    rentalStatus: 'Returned',
    lastUpdated: '2020-03-06T01:13:01.000Z',
    hasLicense: true,
    licenseGrantInfo: 'All',
    inSendingBackSupported: false,
    needsNtripDataReration: false,
  },
  {
    id: 35,
    uuid: '1bda5dc2-a199-4814-ac57-c8ced0ef02d1',
    retrofitDistributorId: 2,
    corporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    ownerCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    siteId: '65a5a0fc-ae01-4fae-8f8a-406faf1fb0e4',
    corporationName: '',
    description: '',
    macAddress: '',
    latitude: 35.696227,
    longitude: 139.691062,
    lastOnlineTime: '2020-03-06T01:14:01.000Z',
    isError: true,
    isOnline: true,
    basicInfoManufacturer: 'Komatsu Ltd.',
    basicInfoModel: 'RF001-03',
    basicInfoProductNumber: 'PROD015',
    basicInfoSerialNumber: 'SERIAL015',
    controllerManufacturer: 'Company 1',
    controllerModel: 'CONT003',
    controllerFirmwareVersion: 'CONT_FW03',
    gnssReceiverMainManufacturer: 'Company 5',
    gnssReceiverMainModel: 'GNSS003',
    gnssReceiverMainFirmwareVersion: 'GNSS_FW03',
    gnssReceiverSubManufacturer: 'Company10',
    gnssReceiverSubModel: 'GNSS004',
    gnssReceiverSubFirmwareVersion: 'GNSS_FW04',
    gnssSettingId: '9c496ed3-24db-9f07-4e53-e4297a8c879d',
    machineInfoCompanyName: 'Komatsu Ltd.',
    machineInfoMachineType: 'Rigiddump',
    machineInfoMachineName: 'PC200-12-000005',
    machineInfoMachineId: 'EX15',
    rentalStatus: 'Returned',
    lastUpdated: '2020-03-06T01:14:01.000Z',
    hasLicense: false,
    inSendingBackSupported: false,
    needsNtripDataReration: false,
  },
  {
    id: 36,
    uuid: '1bda5dc2-a199-4814-ac57-c8ced0ef02d2',
    retrofitDistributorId: 2,
    corporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    ownerCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    siteId: '65a5a0fc-ae01-4fae-8f8a-406faf1fb0e4',
    corporationName: '',
    description: '',
    macAddress: '',
    latitude: 35.696227,
    longitude: 139.691062,
    lastOnlineTime: '2020-03-06T01:15:01.000Z',
    isError: false,
    isOnline: true,
    basicInfoManufacturer: 'Komatsu Ltd.',
    basicInfoModel: 'RF001-04',
    basicInfoProductNumber: 'PROD016',
    basicInfoSerialNumber: 'SERIAL016',
    controllerManufacturer: 'Company 1',
    controllerModel: 'CONT004',
    controllerFirmwareVersion: 'CONT_FW04',
    gnssReceiverMainManufacturer: 'Company 5',
    gnssReceiverMainModel: 'GNSS004',
    gnssReceiverMainFirmwareVersion: 'GNSS_FW04',
    gnssReceiverSubManufacturer: 'Company10',
    gnssReceiverSubModel: 'GNSS005',
    gnssReceiverSubFirmwareVersion: 'GNSS_FW05',
    gnssSettingId: 'fdb7d343-2ad4-512b-899b-72d2f22e6c87',
    machineInfoCompanyName: 'Komatsu Ltd.',
    machineInfoMachineType: 'RIGIDDUMP',
    machineInfoMachineName: 'PC200-13-000001',
    machineInfoMachineId: 'EX16',
    rentalStatus: 'Returned',
    lastUpdated: '2020-03-06T01:15:01.000Z',
    hasLicense: true,
    licenseGrantInfo: 'PayloadMeter',
    inSendingBackSupported: false,
    needsNtripDataReration: false,
  },
  {
    id: 37,
    uuid: '1bda5dc2-a199-4814-ac57-c8ced0ef02d3',
    retrofitDistributorId: 2,
    corporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    ownerCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    siteId: '65a5a0fc-ae01-4fae-8f8a-406faf1fb0e4',
    corporationName: '',
    description: '',
    macAddress: '',
    latitude: 35.696227,
    longitude: 139.691062,
    lastOnlineTime: '2020-03-06T01:16:01.000Z',
    isError: false,
    isOnline: true,
    basicInfoManufacturer: 'Komatsu Ltd.',
    basicInfoModel: 'RF001-04',
    basicInfoProductNumber: 'PROD017',
    basicInfoSerialNumber: 'SERIAL017',
    controllerManufacturer: 'Company 1',
    controllerModel: 'CONT004',
    controllerFirmwareVersion: 'CONT_FW04',
    gnssReceiverMainManufacturer: 'Company 5',
    gnssReceiverMainModel: 'GNSS004',
    gnssReceiverMainFirmwareVersion: 'GNSS_FW04',
    gnssReceiverSubManufacturer: 'Company10',
    gnssReceiverSubModel: 'GNSS005',
    gnssReceiverSubFirmwareVersion: 'GNSS_FW05',
    gnssSettingId: '7f557e1b-3514-44e9-6c85-9d65edf8efa2',
    machineInfoCompanyName: 'Komatsu Ltd.',
    machineInfoMachineType: 'Wheelloader',
    machineInfoMachineName: 'PC200-13-000002',
    machineInfoMachineId: 'EX17',
    rentalStatus: 'Returned',
    lastUpdated: '2020-03-06T01:16:01.000Z',
    hasLicense: false,
    inSendingBackSupported: false,
    needsNtripDataReration: false,
  },
  {
    id: 38,
    uuid: '1bda5dc2-a199-4814-ac57-c8ced0ef02d4',
    retrofitDistributorId: 2,
    corporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    ownerCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    siteId: '65a5a0fc-ae01-4fae-8f8a-406faf1fb0e4',
    corporationName: '',
    description: '',
    macAddress: '',
    latitude: 35.696227,
    longitude: 139.691062,
    lastOnlineTime: '2020-03-06T01:17:01.000Z',
    isError: false,
    isOnline: false,
    basicInfoManufacturer: 'Komatsu Ltd.',
    basicInfoModel: 'RF001-04',
    basicInfoProductNumber: 'PROD018',
    basicInfoSerialNumber: 'SERIAL018',
    controllerManufacturer: 'Company 1',
    controllerModel: 'CONT004',
    controllerFirmwareVersion: 'CONT_FW04',
    gnssReceiverMainManufacturer: 'Company 5',
    gnssReceiverMainModel: 'GNSS004',
    gnssReceiverMainFirmwareVersion: 'GNSS_FW04',
    gnssReceiverSubManufacturer: 'Company10',
    gnssReceiverSubModel: 'GNSS005',
    gnssReceiverSubFirmwareVersion: 'GNSS_FW05',
    gnssSettingId: '1ad72789-0988-4292-532f-fbe395ca19ac',
    machineInfoCompanyName: 'Komatsu Ltd.',
    machineInfoMachineType: 'WHEELLOADER',
    machineInfoMachineName: 'PC200-13-000003',
    machineInfoMachineId: 'EX18',
    rentalStatus: 'Returned',
    lastUpdated: '2020-03-06T01:17:01.000Z',
    hasLicense: true,
    licenseGrantInfo: 'MachineGuidance',
    inSendingBackSupported: false,
    needsNtripDataReration: false,
  },
  {
    id: 39,
    uuid: '1bda5dc2-a199-4814-ac57-c8ced0ef02d5',
    retrofitDistributorId: 2,
    corporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    ownerCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    siteId: '65a5a0fc-ae01-4fae-8f8a-406faf1fb0e4',
    corporationName: '',
    description: '',
    macAddress: '',
    latitude: 35.696227,
    longitude: 139.691062,
    lastOnlineTime: '2020-03-06T01:18:01.000Z',
    isError: true,
    isOnline: false,
    basicInfoManufacturer: 'Komatsu Ltd.',
    basicInfoModel: 'RF001-04',
    basicInfoProductNumber: 'PROD019',
    basicInfoSerialNumber: 'SERIAL019',
    controllerManufacturer: 'Company 1',
    controllerModel: 'CONT004',
    controllerFirmwareVersion: 'CONT_FW04',
    gnssReceiverMainManufacturer: 'Company 5',
    gnssReceiverMainModel: 'GNSS004',
    gnssReceiverMainFirmwareVersion: 'GNSS_FW04',
    gnssReceiverSubManufacturer: 'Company10',
    gnssReceiverSubModel: 'GNSS005',
    gnssReceiverSubFirmwareVersion: 'GNSS_FW05',
    gnssSettingId: '853daa2c-0021-9c68-6a7c-ee79bb2f6743',
    machineInfoCompanyName: 'Komatsu Ltd.',
    machineInfoMachineType: 'Grader',
    machineInfoMachineName: 'PC200-13-000004',
    machineInfoMachineId: 'EX19',
    rentalStatus: 'Returned',
    lastUpdated: '2020-03-06T01:18:01.000Z',
    hasLicense: false,
    inSendingBackSupported: false,
    needsNtripDataReration: false,
  },
  {
    id: 40,
    uuid: '1bda5dc2-a199-4814-ac57-c8ced0ef02d6',
    retrofitDistributorId: 2,
    corporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    ownerCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    siteId: '65a5a0fc-ae01-4fae-8f8a-406faf1fb0e4',
    corporationName: '',
    description: '',
    macAddress: '',
    latitude: 35.696227,
    longitude: 139.691062,
    lastOnlineTime: '2020-03-06T01:19:01.000Z',
    isError: true,
    isOnline: true,
    basicInfoManufacturer: 'Komatsu Ltd.',
    basicInfoModel: 'RF001-04',
    basicInfoProductNumber: 'PROD020',
    basicInfoSerialNumber: 'SERIAL020',
    controllerManufacturer: 'Company 1',
    controllerModel: 'CONT004',
    controllerFirmwareVersion: 'CONT_FW04',
    gnssReceiverMainManufacturer: 'Company 5',
    gnssReceiverMainModel: 'GNSS004',
    gnssReceiverMainFirmwareVersion: 'GNSS_FW04',
    gnssReceiverSubManufacturer: 'Company10',
    gnssReceiverSubModel: 'GNSS005',
    gnssReceiverSubFirmwareVersion: 'GNSS_FW05',
    gnssSettingId: '474d09a2-c193-f761-1e26-f9a429e2c6b7',
    machineInfoCompanyName: 'Komatsu Ltd.',
    machineInfoMachineType: 'GRADER',
    machineInfoMachineName: 'PC200-13-000005',
    machineInfoMachineId: 'EX20',
    rentalStatus: 'Returned',
    lastUpdated: '2020-03-06T01:19:01.000Z',
    hasLicense: true,
    licenseGrantInfo: 'Asbuilt',
    inSendingBackSupported: false,
    needsNtripDataReration: false,
  },
  {
    id: 41,
    uuid: '1bda5dc2-a199-4814-ac57-c8ced0ef02d7',
    retrofitDistributorId: 2,
    corporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    ownerCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    siteId: '65a5a0fc-ae01-4fae-8f8a-406faf1fb0e4',
    corporationName: '',
    description: '',
    macAddress: '',
    latitude: 35.696227,
    longitude: 139.691062,
    lastOnlineTime: '2020-03-06T01:20:01.000Z',
    isError: false,
    isOnline: true,
    basicInfoManufacturer: 'Komatsu Ltd.',
    basicInfoModel: 'RF001-05',
    basicInfoProductNumber: 'PROD021',
    basicInfoSerialNumber: 'SERIAL021',
    controllerManufacturer: 'Company 1',
    controllerModel: 'CONT005',
    controllerFirmwareVersion: 'CONT_FW05',
    gnssReceiverMainManufacturer: 'Company 5',
    gnssReceiverMainModel: 'GNSS005',
    gnssReceiverMainFirmwareVersion: 'GNSS_FW05',
    gnssReceiverSubManufacturer: 'Company10',
    gnssReceiverSubModel: 'GNSS006',
    gnssReceiverSubFirmwareVersion: 'GNSS_FW06',
    gnssSettingId: 'dcca8904-a73d-1600-e0da-b8227cbec6ae',
    machineInfoCompanyName: 'Komatsu Ltd.',
    machineInfoMachineType: 'Automobile',
    machineInfoMachineName: 'JV25CW-6-000001',
    machineInfoMachineId: 'EX21',
    rentalStatus: 'Returned',
    lastUpdated: '2020-03-06T01:20:01.000Z',
    hasLicense: false,
    inSendingBackSupported: false,
    needsNtripDataReration: false,
  },
  {
    id: 42,
    uuid: '1bda5dc2-a199-4814-ac57-c8ced0ef02d8',
    retrofitDistributorId: 2,
    corporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    ownerCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    siteId: '65a5a0fc-ae01-4fae-8f8a-406faf1fb0e4',
    corporationName: '',
    description: '',
    macAddress: '',
    latitude: 35.696227,
    longitude: 139.691062,
    lastOnlineTime: '2020-03-06T01:21:01.000Z',
    isError: false,
    isOnline: true,
    basicInfoManufacturer: 'Komatsu Ltd.',
    basicInfoModel: 'RF001-05',
    basicInfoProductNumber: 'PROD022',
    basicInfoSerialNumber: 'SERIAL022',
    controllerManufacturer: 'Company 1',
    controllerModel: 'CONT005',
    controllerFirmwareVersion: 'CONT_FW05',
    gnssReceiverMainManufacturer: 'Company 5',
    gnssReceiverMainModel: 'GNSS005',
    gnssReceiverMainFirmwareVersion: 'GNSS_FW05',
    gnssReceiverSubManufacturer: 'Company10',
    gnssReceiverSubModel: 'GNSS006',
    gnssReceiverSubFirmwareVersion: 'GNSS_FW06',
    gnssSettingId: '0af85762-87b3-71b2-deb7-32abde22fc7d',
    machineInfoCompanyName: 'Komatsu Ltd.',
    machineInfoMachineType: 'AUTOMOBILE',
    machineInfoMachineName: 'JV25CW-6-000002',
    machineInfoMachineId: 'EX22',
    rentalStatus: 'Returned',
    lastUpdated: '2020-03-06T01:21:01.000Z',
    hasLicense: true,
    licenseGrantInfo: 'Temporary',
    inSendingBackSupported: false,
    needsNtripDataReration: false,
  },
  {
    id: 43,
    uuid: '1bda5dc2-a199-4814-ac57-c8ced0ef02d9',
    retrofitDistributorId: 2,
    corporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    ownerCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    siteId: '65a5a0fc-ae01-4fae-8f8a-406faf1fb0e4',
    corporationName: '',
    description: '',
    macAddress: '',
    latitude: 35.696227,
    longitude: 139.691062,
    lastOnlineTime: '2020-03-06T01:22:01.000Z',
    isError: false,
    isOnline: false,
    basicInfoManufacturer: 'Komatsu Ltd.',
    basicInfoModel: 'RF001-05',
    basicInfoProductNumber: 'PROD023',
    basicInfoSerialNumber: 'SERIAL023',
    controllerManufacturer: 'Company 1',
    controllerModel: 'CONT005',
    controllerFirmwareVersion: 'CONT_FW05',
    gnssReceiverMainManufacturer: 'Company 5',
    gnssReceiverMainModel: 'GNSS005',
    gnssReceiverMainFirmwareVersion: 'GNSS_FW05',
    gnssReceiverSubManufacturer: 'Company10',
    gnssReceiverSubModel: 'GNSS006',
    gnssReceiverSubFirmwareVersion: 'GNSS_FW06',
    gnssSettingId: '97e03475-c54f-f5cf-87c5-e9bd002b3d76',
    machineInfoCompanyName: 'Komatsu Ltd.',
    machineInfoMachineType: 'ROADROLLER',
    machineInfoMachineName: 'JV25CW-6-000003',
    machineInfoMachineId: 'EX23',
    rentalStatus: 'Returned',
    lastUpdated: '2020-03-06T01:22:01.000Z',
    hasLicense: false,
    inSendingBackSupported: false,
    needsNtripDataReration: false,
  },
  {
    id: 44,
    uuid: '1bda5dc2-a199-4814-ac57-c8ced0ef02e0',
    retrofitDistributorId: 2,
    corporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    ownerCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    siteId: '65a5a0fc-ae01-4fae-8f8a-406faf1fb0e4',
    corporationName: '',
    description: '',
    macAddress: '',
    latitude: 35.696227,
    longitude: 139.691062,
    lastOnlineTime: '2020-03-06T01:23:01.000Z',
    isError: true,
    isOnline: false,
    basicInfoManufacturer: 'Komatsu Ltd.',
    basicInfoModel: 'RF001-05',
    basicInfoProductNumber: 'PROD024',
    basicInfoSerialNumber: 'SERIAL024',
    controllerManufacturer: 'Company 1',
    controllerModel: 'CONT005',
    controllerFirmwareVersion: 'CONT_FW05',
    gnssReceiverMainManufacturer: 'Company 5',
    gnssReceiverMainModel: 'GNSS005',
    gnssReceiverMainFirmwareVersion: 'GNSS_FW05',
    gnssReceiverSubManufacturer: 'Company10',
    gnssReceiverSubModel: 'GNSS006',
    gnssReceiverSubFirmwareVersion: 'GNSS_FW06',
    gnssSettingId: 'dec070bd-ec6e-d8ea-09e4-605f1f8eaffd',
    machineInfoCompanyName: 'Komatsu Ltd.',
    machineInfoMachineType: 'ROADROLLER',
    machineInfoMachineName: 'JV25CW-6-000004',
    machineInfoMachineId: 'EX24',
    rentalStatus: 'Returned',
    lastUpdated: '2020-03-06T01:23:01.000Z',
    hasLicense: false,
    inSendingBackSupported: false,
    needsNtripDataReration: false,
  },
  {
    id: 45,
    uuid: '1bda5dc2-a199-4814-ac57-c8ced0ef02e1',
    retrofitDistributorId: 2,
    corporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    ownerCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    siteId: '65a5a0fc-ae01-4fae-8f8a-406faf1fb0e4',
    corporationName: '',
    description: '',
    macAddress: '',
    latitude: 35.696227,
    longitude: 139.691062,
    lastOnlineTime: '2020-03-06T01:24:01.000Z',
    isError: true,
    isOnline: true,
    basicInfoManufacturer: 'Komatsu Ltd.',
    basicInfoModel: 'RF001-05',
    basicInfoProductNumber: 'PROD025',
    basicInfoSerialNumber: 'SERIAL025',
    controllerManufacturer: 'Company 1',
    controllerModel: 'CONT005',
    controllerFirmwareVersion: 'CONT_FW05',
    gnssReceiverMainManufacturer: 'Company 5',
    gnssReceiverMainModel: 'GNSS005',
    gnssReceiverMainFirmwareVersion: 'GNSS_FW05',
    gnssReceiverSubManufacturer: 'Company10',
    gnssReceiverSubModel: 'GNSS006',
    gnssReceiverSubFirmwareVersion: 'GNSS_FW06',
    gnssSettingId: '51a5265d-2550-2475-a98d-a4aa81203a49',
    machineInfoCompanyName: 'Komatsu Ltd.',
    machineInfoMachineType: 'ROADROLLER',
    machineInfoMachineName: 'JV25CW-6-000005',
    machineInfoMachineId: 'EX25',
    rentalStatus: 'Returned',
    lastUpdated: '2020-03-06T01:24:01.000Z',
    hasLicense: false,
    inSendingBackSupported: false,
    needsNtripDataReration: false,
  },
  {
    id: 46,
    uuid: '1bda5dc2-a199-4814-ac57-c8ced0ef02e2',
    retrofitDistributorId: 2,
    corporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    ownerCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    siteId: '65a5a0fc-ae01-4fae-8f8a-406faf1fb0e4',
    corporationName: '',
    description: '',
    macAddress: '',
    latitude: 35.696227,
    longitude: 139.691062,
    lastOnlineTime: '2020-03-06T01:25:01.000Z',
    isError: false,
    isOnline: true,
    basicInfoManufacturer: 'Hatachi Ltd.',
    basicInfoModel: 'RF002-01',
    basicInfoProductNumber: 'PROD026',
    basicInfoSerialNumber: 'SERIAL026',
    controllerManufacturer: 'Company 2',
    controllerModel: 'CONT011',
    controllerFirmwareVersion: 'CONT_FW11',
    gnssReceiverMainManufacturer: 'Company 6',
    gnssReceiverMainModel: 'GNSS011',
    gnssReceiverMainFirmwareVersion: 'GNSS_FW11',
    gnssReceiverSubManufacturer: 'Company11',
    gnssReceiverSubModel: 'GNSS012',
    gnssReceiverSubFirmwareVersion: 'GNSS_FW12',
    gnssSettingId: 'a62b0d26-ea1a-d741-a959-a00fe73d7dab',
    machineInfoCompanyName: 'Hatachi Ltd.',
    machineInfoMachineType: 'Excavator',
    machineInfoMachineName: 'HD300-10-000001',
    machineInfoMachineId: 'EX26',
    rentalStatus: 'Returned',
    lastUpdated: '2020-03-06T01:25:01.000Z',
    hasLicense: false,
    inSendingBackSupported: false,
    needsNtripDataReration: false,
  },
  {
    id: 47,
    uuid: '1bda5dc2-a199-4814-ac57-c8ced0ef02e3',
    retrofitDistributorId: 2,
    corporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    ownerCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    siteId: '65a5a0fc-ae01-4fae-8f8a-406faf1fb0e4',
    corporationName: '',
    description: '',
    macAddress: '',
    latitude: 35.696227,
    longitude: 139.691062,
    lastOnlineTime: '2020-03-06T01:26:01.000Z',
    isError: false,
    isOnline: true,
    basicInfoManufacturer: 'Hatachi Ltd.',
    basicInfoModel: 'RF002-01',
    basicInfoProductNumber: 'PROD027',
    basicInfoSerialNumber: 'SERIAL027',
    controllerManufacturer: 'Company 2',
    controllerModel: 'CONT011',
    controllerFirmwareVersion: 'CONT_FW11',
    gnssReceiverMainManufacturer: 'Company 6',
    gnssReceiverMainModel: 'GNSS011',
    gnssReceiverMainFirmwareVersion: 'GNSS_FW11',
    gnssReceiverSubManufacturer: 'Company11',
    gnssReceiverSubModel: 'GNSS012',
    gnssReceiverSubFirmwareVersion: 'GNSS_FW12',
    gnssSettingId: '0018968d-ea52-cfc0-3511-e7eb58b45eea',
    machineInfoCompanyName: 'Hatachi Ltd.',
    machineInfoMachineType: 'Excavator',
    machineInfoMachineName: 'HD300-10-000002',
    machineInfoMachineId: 'EX27',
    rentalStatus: 'Returned',
    lastUpdated: '2020-03-06T01:26:01.000Z',
    hasLicense: false,
    inSendingBackSupported: false,
    needsNtripDataReration: false,
  },
  {
    id: 48,
    uuid: '1bda5dc2-a199-4814-ac57-c8ced0ef02e4',
    retrofitDistributorId: 2,
    corporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    ownerCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    siteId: '65a5a0fc-ae01-4fae-8f8a-406faf1fb0e4',
    corporationName: '',
    description: '',
    macAddress: '',
    latitude: 35.696227,
    longitude: 139.691062,
    lastOnlineTime: '2020-03-06T01:27:01.000Z',
    isError: false,
    isOnline: false,
    basicInfoManufacturer: 'Hatachi Ltd.',
    basicInfoModel: 'RF002-01',
    basicInfoProductNumber: 'PROD028',
    basicInfoSerialNumber: 'SERIAL028',
    controllerManufacturer: 'Company 2',
    controllerModel: 'CONT011',
    controllerFirmwareVersion: 'CONT_FW11',
    gnssReceiverMainManufacturer: 'Company 6',
    gnssReceiverMainModel: 'GNSS011',
    gnssReceiverMainFirmwareVersion: 'GNSS_FW11',
    gnssReceiverSubManufacturer: 'Company11',
    gnssReceiverSubModel: 'GNSS012',
    gnssReceiverSubFirmwareVersion: 'GNSS_FW12',
    gnssSettingId: 'ee0c0e91-f7b0-3dc7-3694-23d4a9c0eea8',
    machineInfoCompanyName: 'Hatachi Ltd.',
    machineInfoMachineType: 'Excavator',
    machineInfoMachineName: 'HD300-10-000003',
    machineInfoMachineId: 'EX28',
    rentalStatus: 'Returned',
    lastUpdated: '2020-03-06T01:27:01.000Z',
    hasLicense: false,
    inSendingBackSupported: false,
    needsNtripDataReration: false,
  },
  {
    id: 49,
    uuid: '1bda5dc2-a199-4814-ac57-c8ced0ef02e5',
    retrofitDistributorId: 2,
    corporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    ownerCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    siteId: '65a5a0fc-ae01-4fae-8f8a-406faf1fb0e4',
    corporationName: '',
    description: '',
    macAddress: '',
    latitude: 35.696227,
    longitude: 139.691062,
    lastOnlineTime: '2020-03-06T01:28:01.000Z',
    isError: true,
    isOnline: false,
    basicInfoManufacturer: 'Hatachi Ltd.',
    basicInfoModel: 'RF002-01',
    basicInfoProductNumber: 'PROD029',
    basicInfoSerialNumber: 'SERIAL029',
    controllerManufacturer: 'Company 2',
    controllerModel: 'CONT011',
    controllerFirmwareVersion: 'CONT_FW11',
    gnssReceiverMainManufacturer: 'Company 6',
    gnssReceiverMainModel: 'GNSS011',
    gnssReceiverMainFirmwareVersion: 'GNSS_FW11',
    gnssReceiverSubManufacturer: 'Company11',
    gnssReceiverSubModel: 'GNSS012',
    gnssReceiverSubFirmwareVersion: 'GNSS_FW12',
    gnssSettingId: '378f9d2e-3ed4-ee2a-ed45-22069a6c933e',
    machineInfoCompanyName: 'Hatachi Ltd.',
    machineInfoMachineType: 'Excavator',
    machineInfoMachineName: 'HD300-10-000004',
    machineInfoMachineId: 'EX29',
    rentalStatus: 'Returned',
    lastUpdated: '2020-03-06T01:28:01.000Z',
    hasLicense: false,
    inSendingBackSupported: false,
    needsNtripDataReration: false,
  },
  {
    id: 50,
    uuid: '1bda5dc2-a199-4814-ac57-c8ced0ef02e6',
    retrofitDistributorId: 2,
    corporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    ownerCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    siteId: '65a5a0fc-ae01-4fae-8f8a-406faf1fb0e4',
    corporationName: '',
    description: '',
    macAddress: '',
    latitude: 35.696227,
    longitude: 139.691062,
    lastOnlineTime: '2020-03-06T01:29:01.000Z',
    isError: true,
    isOnline: true,
    basicInfoManufacturer: 'Hatachi Ltd.',
    basicInfoModel: 'RF002-01',
    basicInfoProductNumber: 'PROD030',
    basicInfoSerialNumber: 'SERIAL030',
    controllerManufacturer: 'Company 2',
    controllerModel: 'CONT011',
    controllerFirmwareVersion: 'CONT_FW11',
    gnssReceiverMainManufacturer: 'Company 6',
    gnssReceiverMainModel: 'GNSS011',
    gnssReceiverMainFirmwareVersion: 'GNSS_FW11',
    gnssReceiverSubManufacturer: 'Company11',
    gnssReceiverSubModel: 'GNSS012',
    gnssReceiverSubFirmwareVersion: 'GNSS_FW12',
    gnssSettingId: 'e709dfc4-6f8a-76f4-4741-33939ae8f2f1',
    machineInfoCompanyName: 'Hatachi Ltd.',
    machineInfoMachineType: 'Excavator',
    machineInfoMachineName: 'HD300-10-000005',
    machineInfoMachineId: 'EX30',
    rentalStatus: 'Returned',
    lastUpdated: '2020-03-06T01:29:01.000Z',
    hasLicense: false,
    inSendingBackSupported: false,
    needsNtripDataReration: false,
  },
  {
    id: 51,
    uuid: '1bda5dc2-a199-4814-ac57-c8ced0ef02e7',
    retrofitDistributorId: 2,
    corporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    ownerCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    siteId: '65a5a0fc-ae01-4fae-8f8a-406faf1fb0e4',
    corporationName: '',
    description: '',
    macAddress: '',
    latitude: 35.696227,
    longitude: 139.691062,
    lastOnlineTime: '2020-03-06T01:30:01.000Z',
    isError: false,
    isOnline: true,
    basicInfoManufacturer: 'Hatachi Ltd.',
    basicInfoModel: 'RF002-02',
    basicInfoProductNumber: 'PROD031',
    basicInfoSerialNumber: 'SERIAL031',
    controllerManufacturer: 'Company 2',
    controllerModel: 'CONT012',
    controllerFirmwareVersion: 'CONT_FW12',
    gnssReceiverMainManufacturer: 'Company 6',
    gnssReceiverMainModel: 'GNSS012',
    gnssReceiverMainFirmwareVersion: 'GNSS_FW12',
    gnssReceiverSubManufacturer: 'Company11',
    gnssReceiverSubModel: 'GNSS013',
    gnssReceiverSubFirmwareVersion: 'GNSS_FW13',
    gnssSettingId: '4794565d-f91b-e040-864b-5d7c43f41d07',
    machineInfoCompanyName: 'Hatachi Ltd.',
    machineInfoMachineType: 'Excavator',
    machineInfoMachineName: 'HD300-11-000001',
    machineInfoMachineId: 'EX31',
    rentalStatus: 'Returned',
    lastUpdated: '2020-03-06T01:30:01.000Z',
    hasLicense: false,
    inSendingBackSupported: false,
    needsNtripDataReration: false,
  },
  {
    id: 52,
    uuid: '1bda5dc2-a199-4814-ac57-c8ced0ef02e8',
    retrofitDistributorId: 2,
    corporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    ownerCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    siteId: '65a5a0fc-ae01-4fae-8f8a-406faf1fb0e4',
    corporationName: '',
    description: '',
    macAddress: '',
    latitude: 35.696227,
    longitude: 139.691062,
    lastOnlineTime: '2020-03-06T01:31:01.000Z',
    isError: false,
    isOnline: true,
    basicInfoManufacturer: 'Hatachi Ltd.',
    basicInfoModel: 'RF002-02',
    basicInfoProductNumber: 'PROD032',
    basicInfoSerialNumber: 'SERIAL032',
    controllerManufacturer: 'Company 2',
    controllerModel: 'CONT012',
    controllerFirmwareVersion: 'CONT_FW12',
    gnssReceiverMainManufacturer: 'Company 6',
    gnssReceiverMainModel: 'GNSS012',
    gnssReceiverMainFirmwareVersion: 'GNSS_FW12',
    gnssReceiverSubManufacturer: 'Company11',
    gnssReceiverSubModel: 'GNSS013',
    gnssReceiverSubFirmwareVersion: 'GNSS_FW13',
    gnssSettingId: '0c7ebb96-6394-6a23-3e9b-98a67303c10c',
    machineInfoCompanyName: 'Hatachi Ltd.',
    machineInfoMachineType: 'Excavator',
    machineInfoMachineName: 'HD300-11-000002',
    machineInfoMachineId: 'EX32',
    rentalStatus: 'Returned',
    lastUpdated: '2020-03-06T01:31:01.000Z',
    hasLicense: false,
    inSendingBackSupported: false,
    needsNtripDataReration: false,
  },
  {
    id: 53,
    uuid: '1bda5dc2-a199-4814-ac57-c8ced0ef02e9',
    retrofitDistributorId: 2,
    corporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    ownerCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    siteId: '65a5a0fc-ae01-4fae-8f8a-406faf1fb0e4',
    corporationName: '',
    description: '',
    macAddress: '',
    latitude: 35.696227,
    longitude: 139.691062,
    lastOnlineTime: '2020-03-06T01:32:01.000Z',
    isError: false,
    isOnline: false,
    basicInfoManufacturer: 'Hatachi Ltd.',
    basicInfoModel: 'RF002-02',
    basicInfoProductNumber: 'PROD033',
    basicInfoSerialNumber: 'SERIAL033',
    controllerManufacturer: 'Company 2',
    controllerModel: 'CONT012',
    controllerFirmwareVersion: 'CONT_FW12',
    gnssReceiverMainManufacturer: 'Company 6',
    gnssReceiverMainModel: 'GNSS012',
    gnssReceiverMainFirmwareVersion: 'GNSS_FW12',
    gnssReceiverSubManufacturer: 'Company11',
    gnssReceiverSubModel: 'GNSS013',
    gnssReceiverSubFirmwareVersion: 'GNSS_FW13',
    gnssSettingId: 'defcf5b9-4b52-1684-0b2d-11c8b7cbd261',
    machineInfoCompanyName: 'Hatachi Ltd.',
    machineInfoMachineType: 'Excavator',
    machineInfoMachineName: 'HD300-11-000003',
    machineInfoMachineId: 'EX33',
    rentalStatus: 'Returned',
    lastUpdated: '2020-03-06T01:32:01.000Z',
    hasLicense: false,
    inSendingBackSupported: false,
    needsNtripDataReration: false,
  },
  {
    id: 54,
    uuid: '1bda5dc2-a199-4814-ac57-c8ced0ef02f0',
    retrofitDistributorId: 2,
    corporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    ownerCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    siteId: '65a5a0fc-ae01-4fae-8f8a-406faf1fb0e4',
    corporationName: '',
    description: '',
    macAddress: '',
    latitude: 35.696227,
    longitude: 139.691062,
    lastOnlineTime: '2020-03-06T01:33:01.000Z',
    isError: true,
    isOnline: false,
    basicInfoManufacturer: 'Hatachi Ltd.',
    basicInfoModel: 'RF002-02',
    basicInfoProductNumber: 'PROD034',
    basicInfoSerialNumber: 'SERIAL034',
    controllerManufacturer: 'Company 2',
    controllerModel: 'CONT012',
    controllerFirmwareVersion: 'CONT_FW12',
    gnssReceiverMainManufacturer: 'Company 6',
    gnssReceiverMainModel: 'GNSS012',
    gnssReceiverMainFirmwareVersion: 'GNSS_FW12',
    gnssReceiverSubManufacturer: 'Company11',
    gnssReceiverSubModel: 'GNSS013',
    gnssReceiverSubFirmwareVersion: 'GNSS_FW13',
    gnssSettingId: '1bcf9b3e-1bc5-aa85-ca14-34097cb87705',
    machineInfoCompanyName: 'Hatachi Ltd.',
    machineInfoMachineType: 'Excavator',
    machineInfoMachineName: 'HD300-11-000004',
    machineInfoMachineId: 'EX34',
    rentalStatus: 'Returned',
    lastUpdated: '2020-03-06T01:33:01.000Z',
    hasLicense: false,
    inSendingBackSupported: false,
    needsNtripDataReration: false,
  },
  {
    id: 55,
    uuid: '1bda5dc2-a199-4814-ac57-c8ced0ef02f1',
    retrofitDistributorId: 2,
    corporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    ownerCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    siteId: '65a5a0fc-ae01-4fae-8f8a-406faf1fb0e4',
    corporationName: '',
    description: '',
    macAddress: '',
    latitude: 35.696227,
    longitude: 139.691062,
    lastOnlineTime: '2020-03-06T01:34:01.000Z',
    isError: true,
    isOnline: true,
    basicInfoManufacturer: 'Hatachi Ltd.',
    basicInfoModel: 'RF002-02',
    basicInfoProductNumber: 'PROD035',
    basicInfoSerialNumber: 'SERIAL035',
    controllerManufacturer: 'Company 2',
    controllerModel: 'CONT012',
    controllerFirmwareVersion: 'CONT_FW12',
    gnssReceiverMainManufacturer: 'Company 6',
    gnssReceiverMainModel: 'GNSS012',
    gnssReceiverMainFirmwareVersion: 'GNSS_FW12',
    gnssReceiverSubManufacturer: 'Company11',
    gnssReceiverSubModel: 'GNSS013',
    gnssReceiverSubFirmwareVersion: 'GNSS_FW13',
    gnssSettingId: '3203a69e-d080-f4a8-4bff-002f8ef7d275',
    machineInfoCompanyName: 'Hatachi Ltd.',
    machineInfoMachineType: 'Excavator',
    machineInfoMachineName: 'HD300-11-000005',
    machineInfoMachineId: 'EX35',
    rentalStatus: 'Returned',
    lastUpdated: '2020-03-06T01:34:01.000Z',
    hasLicense: false,
    inSendingBackSupported: false,
    needsNtripDataReration: false,
  },
  {
    id: 56,
    uuid: '1bda5dc2-a199-4814-ac57-c8ced0ef02f2',
    retrofitDistributorId: 2,
    corporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    ownerCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    siteId: '65a5a0fc-ae01-4fae-8f8a-406faf1fb0e4',
    corporationName: '',
    description: '',
    macAddress: '',
    latitude: 35.696227,
    longitude: 139.691062,
    lastOnlineTime: '2020-03-06T01:35:01.000Z',
    isError: false,
    isOnline: true,
    basicInfoManufacturer: 'Hatachi Ltd.',
    basicInfoModel: 'RF002-03',
    basicInfoProductNumber: 'PROD036',
    basicInfoSerialNumber: 'SERIAL036',
    controllerManufacturer: 'Company 2',
    controllerModel: 'CONT013',
    controllerFirmwareVersion: 'CONT_FW13',
    gnssReceiverMainManufacturer: 'Company 6',
    gnssReceiverMainModel: 'GNSS013',
    gnssReceiverMainFirmwareVersion: 'GNSS_FW13',
    gnssReceiverSubManufacturer: 'Company11',
    gnssReceiverSubModel: 'GNSS014',
    gnssReceiverSubFirmwareVersion: 'GNSS_FW14',
    gnssSettingId: '1f75e400-b9fc-8c77-a447-aebe382e9aa9',
    machineInfoCompanyName: 'Hatachi Ltd.',
    machineInfoMachineType: 'Excavator',
    machineInfoMachineName: 'HD300-12-000001',
    machineInfoMachineId: 'EX36',
    rentalStatus: 'Returned',
    lastUpdated: '2020-03-06T01:35:01.000Z',
    hasLicense: false,
    inSendingBackSupported: false,
    needsNtripDataReration: false,
  },
  {
    id: 57,
    uuid: '1bda5dc2-a199-4814-ac57-c8ced0ef02f3',
    retrofitDistributorId: 2,
    corporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    ownerCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    siteId: '65a5a0fc-ae01-4fae-8f8a-406faf1fb0e4',
    corporationName: '',
    description: '',
    macAddress: '',
    latitude: 35.696227,
    longitude: 139.691062,
    lastOnlineTime: '2020-03-06T01:36:01.000Z',
    isError: false,
    isOnline: true,
    basicInfoManufacturer: 'Hatachi Ltd.',
    basicInfoModel: 'RF002-03',
    basicInfoProductNumber: 'PROD037',
    basicInfoSerialNumber: 'SERIAL037',
    controllerManufacturer: 'Company 2',
    controllerModel: 'CONT013',
    controllerFirmwareVersion: 'CONT_FW13',
    gnssReceiverMainManufacturer: 'Company 6',
    gnssReceiverMainModel: 'GNSS013',
    gnssReceiverMainFirmwareVersion: 'GNSS_FW13',
    gnssReceiverSubManufacturer: 'Company11',
    gnssReceiverSubModel: 'GNSS014',
    gnssReceiverSubFirmwareVersion: 'GNSS_FW14',
    gnssSettingId: 'c86a8942-2dce-e513-29e7-c7731274b205',
    machineInfoCompanyName: 'Hatachi Ltd.',
    machineInfoMachineType: 'Excavator',
    machineInfoMachineName: 'HD300-12-000002',
    machineInfoMachineId: 'EX37',
    rentalStatus: 'Returned',
    lastUpdated: '2020-03-06T01:36:01.000Z',
    hasLicense: false,
    inSendingBackSupported: false,
    needsNtripDataReration: false,
  },
  {
    id: 58,
    uuid: '1bda5dc2-a199-4814-ac57-c8ced0ef02f4',
    retrofitDistributorId: 2,
    corporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    ownerCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    siteId: '65a5a0fc-ae01-4fae-8f8a-406faf1fb0e4',
    corporationName: '',
    description: '',
    macAddress: '',
    latitude: 35.696227,
    longitude: 139.691062,
    lastOnlineTime: '2020-03-06T01:37:01.000Z',
    isError: false,
    isOnline: false,
    basicInfoManufacturer: 'Hatachi Ltd.',
    basicInfoModel: 'RF002-03',
    basicInfoProductNumber: 'PROD038',
    basicInfoSerialNumber: 'SERIAL038',
    controllerManufacturer: 'Company 2',
    controllerModel: 'CONT013',
    controllerFirmwareVersion: 'CONT_FW13',
    gnssReceiverMainManufacturer: 'Company 6',
    gnssReceiverMainModel: 'GNSS013',
    gnssReceiverMainFirmwareVersion: 'GNSS_FW13',
    gnssReceiverSubManufacturer: 'Company11',
    gnssReceiverSubModel: 'GNSS014',
    gnssReceiverSubFirmwareVersion: 'GNSS_FW14',
    gnssSettingId: '2688bda7-b694-100e-e93b-07ceead42c10',
    machineInfoCompanyName: 'Hatachi Ltd.',
    machineInfoMachineType: 'Excavator',
    machineInfoMachineName: 'HD300-12-000003',
    machineInfoMachineId: 'EX38',
    rentalStatus: 'Returned',
    lastUpdated: '2020-03-06T01:37:01.000Z',
    hasLicense: false,
    inSendingBackSupported: false,
    needsNtripDataReration: false,
  },
  {
    id: 59,
    uuid: '1bda5dc2-a199-4814-ac57-c8ced0ef02f5',
    retrofitDistributorId: 2,
    corporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    ownerCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    siteId: '65a5a0fc-ae01-4fae-8f8a-406faf1fb0e4',
    corporationName: '',
    description: '',
    macAddress: '',
    latitude: 35.696227,
    longitude: 139.691062,
    lastOnlineTime: '2020-03-06T01:38:01.000Z',
    isError: true,
    isOnline: false,
    basicInfoManufacturer: 'Hatachi Ltd.',
    basicInfoModel: 'RF002-03',
    basicInfoProductNumber: 'PROD039',
    basicInfoSerialNumber: 'SERIAL039',
    controllerManufacturer: 'Company 2',
    controllerModel: 'CONT013',
    controllerFirmwareVersion: 'CONT_FW13',
    gnssReceiverMainManufacturer: 'Company 6',
    gnssReceiverMainModel: 'GNSS013',
    gnssReceiverMainFirmwareVersion: 'GNSS_FW13',
    gnssReceiverSubManufacturer: 'Company11',
    gnssReceiverSubModel: 'GNSS014',
    gnssReceiverSubFirmwareVersion: 'GNSS_FW14',
    gnssSettingId: '94520b79-de73-f137-c13c-42d3066e252c',
    machineInfoCompanyName: 'Hatachi Ltd.',
    machineInfoMachineType: 'Excavator',
    machineInfoMachineName: 'HD300-12-000004',
    machineInfoMachineId: 'EX39',
    rentalStatus: 'Returned',
    lastUpdated: '2020-03-06T01:38:01.000Z',
    hasLicense: false,
    inSendingBackSupported: false,
    needsNtripDataReration: false,
  },
  {
    id: 60,
    uuid: '1bda5dc2-a199-4814-ac57-c8ced0ef02f6',
    retrofitDistributorId: 2,
    corporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    ownerCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    siteId: '65a5a0fc-ae01-4fae-8f8a-406faf1fb0e4',
    corporationName: '',
    description: '',
    macAddress: '',
    latitude: 35.696227,
    longitude: 139.691062,
    lastOnlineTime: '2020-03-06T01:39:01.000Z',
    isError: true,
    isOnline: true,
    basicInfoManufacturer: 'Hatachi Ltd.',
    basicInfoModel: 'RF002-03',
    basicInfoProductNumber: 'PROD040',
    basicInfoSerialNumber: 'SERIAL040',
    controllerManufacturer: 'Company 2',
    controllerModel: 'CONT013',
    controllerFirmwareVersion: 'CONT_FW13',
    gnssReceiverMainManufacturer: 'Company 6',
    gnssReceiverMainModel: 'GNSS013',
    gnssReceiverMainFirmwareVersion: 'GNSS_FW13',
    gnssReceiverSubManufacturer: 'Company11',
    gnssReceiverSubModel: 'GNSS014',
    gnssReceiverSubFirmwareVersion: 'GNSS_FW14',
    gnssSettingId: '475a9c40-6a53-f267-38ab-dc6015ffeed5',
    machineInfoCompanyName: 'Hatachi Ltd.',
    machineInfoMachineType: 'Excavator',
    machineInfoMachineName: 'HD300-12-000005',
    machineInfoMachineId: 'EX40',
    rentalStatus: 'Returned',
    lastUpdated: '2020-03-06T01:39:01.000Z',
    hasLicense: false,
    inSendingBackSupported: false,
    needsNtripDataReration: false,
  },
  {
    id: 61,
    uuid: '1bda5dc2-a199-4814-ac57-c8ced0ef02f7',
    retrofitDistributorId: 2,
    corporationId: 'd087e444-ded2-de5e-087b-76f3ce864ae3',
    ownerCorporationId: 'd087e444-ded2-de5e-087b-76f3ce864ae3',
    siteId: '',
    corporationName: '',
    description: '',
    macAddress: '',
    latitude: 35.696227,
    longitude: 139.691062,
    lastOnlineTime: '2020-03-06T01:40:01.000Z',
    isError: false,
    isOnline: true,
    basicInfoManufacturer: 'Hatachi Ltd.',
    basicInfoModel: 'RF002-04',
    basicInfoProductNumber: 'PROD041',
    basicInfoSerialNumber: 'SERIAL041',
    controllerManufacturer: 'Company 2',
    controllerModel: 'CONT014',
    controllerFirmwareVersion: 'CONT_FW14',
    gnssReceiverMainManufacturer: 'Company 6',
    gnssReceiverMainModel: 'GNSS014',
    gnssReceiverMainFirmwareVersion: 'GNSS_FW14',
    gnssReceiverSubManufacturer: 'Company11',
    gnssReceiverSubModel: 'GNSS015',
    gnssReceiverSubFirmwareVersion: 'GNSS_FW15',
    gnssSettingId: '94128772-38d4-256b-1e2e-8754f2a97e54',
    machineInfoCompanyName: 'Hatachi Ltd.',
    machineInfoMachineType: 'Excavator',
    machineInfoMachineName: 'HD300-13-000001',
    machineInfoMachineId: 'EX41',
    rentalStatus: 'Returned',
    lastUpdated: '2020-03-06T01:40:01.000Z',
    hasLicense: false,
    inSendingBackSupported: false,
    needsNtripDataReration: false,
  },
  {
    id: 62,
    uuid: '1bda5dc2-a199-4814-ac57-c8ced0ef02f8',
    retrofitDistributorId: 2,
    corporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    ownerCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    siteId: '',
    corporationName: '',
    description: '',
    macAddress: '',
    latitude: 35.696227,
    longitude: 139.691062,
    lastOnlineTime: '2020-03-06T01:41:01.000Z',
    isError: false,
    isOnline: true,
    basicInfoManufacturer: 'Hatachi Ltd.',
    basicInfoModel: 'RF002-04',
    basicInfoProductNumber: 'PROD042',
    basicInfoSerialNumber: 'SERIAL042',
    controllerManufacturer: 'Company 2',
    controllerModel: 'CONT014',
    controllerFirmwareVersion: 'CONT_FW14',
    gnssReceiverMainManufacturer: 'Company 6',
    gnssReceiverMainModel: 'GNSS014',
    gnssReceiverMainFirmwareVersion: 'GNSS_FW14',
    gnssReceiverSubManufacturer: 'Company11',
    gnssReceiverSubModel: 'GNSS015',
    gnssReceiverSubFirmwareVersion: 'GNSS_FW15',
    gnssSettingId: '4280253b-873d-6ca5-f176-475059911fd9',
    machineInfoCompanyName: 'Hatachi Ltd.',
    machineInfoMachineType: 'Excavator',
    machineInfoMachineName: 'HD300-13-000002',
    machineInfoMachineId: 'EX42',
    rentalStatus: 'Returned',
    lastUpdated: '2020-03-06T01:41:01.000Z',
    hasLicense: false,
    inSendingBackSupported: false,
    needsNtripDataReration: false,
  },
  {
    id: 63,
    uuid: '1bda5dc2-a199-4814-ac57-c8ced0ef02f9',
    retrofitDistributorId: 2,
    corporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    ownerCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    siteId: '',
    corporationName: '',
    description: '',
    macAddress: '',
    latitude: 35.696227,
    longitude: 139.691062,
    lastOnlineTime: '2020-03-06T01:42:01.000Z',
    isError: false,
    isOnline: false,
    basicInfoManufacturer: 'Hatachi Ltd.',
    basicInfoModel: 'RF002-04',
    basicInfoProductNumber: 'PROD043',
    basicInfoSerialNumber: 'SERIAL043',
    controllerManufacturer: 'Company 2',
    controllerModel: 'CONT014',
    controllerFirmwareVersion: 'CONT_FW14',
    gnssReceiverMainManufacturer: 'Company 6',
    gnssReceiverMainModel: 'GNSS014',
    gnssReceiverMainFirmwareVersion: 'GNSS_FW14',
    gnssReceiverSubManufacturer: 'Company11',
    gnssReceiverSubModel: 'GNSS015',
    gnssReceiverSubFirmwareVersion: 'GNSS_FW15',
    gnssSettingId: 'a6425a2d-0e19-d78f-ea57-0cb3c871317e',
    machineInfoCompanyName: 'Hatachi Ltd.',
    machineInfoMachineType: 'Excavator',
    machineInfoMachineName: 'HD300-13-000003',
    machineInfoMachineId: 'EX43',
    rentalStatus: 'Returned',
    lastUpdated: '2020-03-06T01:42:01.000Z',
    hasLicense: false,
    inSendingBackSupported: false,
    needsNtripDataReration: false,
  },
  {
    id: 64,
    uuid: '1bda5dc2-a199-4814-ac57-c8ced0ef0300',
    retrofitDistributorId: 2,
    corporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    ownerCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    siteId: '',
    corporationName: '',
    description: '',
    macAddress: '',
    latitude: 35.696227,
    longitude: 139.691062,
    lastOnlineTime: '2020-03-06T01:43:01.000Z',
    isError: true,
    isOnline: false,
    basicInfoManufacturer: 'Hatachi Ltd.',
    basicInfoModel: 'RF002-04',
    basicInfoProductNumber: 'PROD044',
    basicInfoSerialNumber: 'SERIAL044',
    controllerManufacturer: 'Company 2',
    controllerModel: 'CONT014',
    controllerFirmwareVersion: 'CONT_FW14',
    gnssReceiverMainManufacturer: 'Company 6',
    gnssReceiverMainModel: 'GNSS014',
    gnssReceiverMainFirmwareVersion: 'GNSS_FW14',
    gnssReceiverSubManufacturer: 'Company11',
    gnssReceiverSubModel: 'GNSS015',
    gnssReceiverSubFirmwareVersion: 'GNSS_FW15',
    gnssSettingId: '568b1408-6fcf-ad90-8c31-433fb02d184b',
    machineInfoCompanyName: 'Hatachi Ltd.',
    machineInfoMachineType: 'Excavator',
    machineInfoMachineName: 'HD300-13-000004',
    machineInfoMachineId: 'EX44',
    rentalStatus: 'Returned',
    lastUpdated: '2020-03-06T01:43:01.000Z',
    hasLicense: false,
    inSendingBackSupported: false,
    needsNtripDataReration: false,
  },
  {
    id: 65,
    uuid: '1bda5dc2-a199-4814-ac57-c8ced0ef0301',
    retrofitDistributorId: 2,
    corporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    ownerCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    siteId: '',
    corporationName: '',
    description: '',
    macAddress: '',
    latitude: 35.696227,
    longitude: 139.691062,
    lastOnlineTime: '2020-03-06T01:44:01.000Z',
    isError: true,
    isOnline: true,
    basicInfoManufacturer: 'Hatachi Ltd.',
    basicInfoModel: 'RF002-04',
    basicInfoProductNumber: 'PROD045',
    basicInfoSerialNumber: 'SERIAL045',
    controllerManufacturer: 'Company 2',
    controllerModel: 'CONT014',
    controllerFirmwareVersion: 'CONT_FW14',
    gnssReceiverMainManufacturer: 'Company 6',
    gnssReceiverMainModel: 'GNSS014',
    gnssReceiverMainFirmwareVersion: 'GNSS_FW14',
    gnssReceiverSubManufacturer: 'Company11',
    gnssReceiverSubModel: 'GNSS015',
    gnssReceiverSubFirmwareVersion: 'GNSS_FW15',
    gnssSettingId: '67b41560-917b-7faa-a01c-18d793e1a621',
    machineInfoCompanyName: 'Hatachi Ltd.',
    machineInfoMachineType: 'Excavator',
    machineInfoMachineName: 'HD300-13-000005',
    machineInfoMachineId: 'EX45',
    rentalStatus: 'Returned',
    lastUpdated: '2020-03-06T01:44:01.000Z',
    hasLicense: false,
    inSendingBackSupported: false,
    needsNtripDataReration: false,
  },
  {
    id: 66,
    uuid: '1bda5dc2-a199-4814-ac57-c8ced0ef0302',
    retrofitDistributorId: 2,
    corporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    ownerCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    siteId: '',
    corporationName: '',
    description: '',
    macAddress: '',
    latitude: 35.696227,
    longitude: 139.691062,
    lastOnlineTime: '2020-03-06T01:45:01.000Z',
    isError: false,
    isOnline: true,
    basicInfoManufacturer: 'Hatachi Ltd.',
    basicInfoModel: 'RF002-05',
    basicInfoProductNumber: 'PROD046',
    basicInfoSerialNumber: 'SERIAL046',
    controllerManufacturer: 'Company 2',
    controllerModel: 'CONT015',
    controllerFirmwareVersion: 'CONT_FW15',
    gnssReceiverMainManufacturer: 'Company 6',
    gnssReceiverMainModel: 'GNSS015',
    gnssReceiverMainFirmwareVersion: 'GNSS_FW15',
    gnssReceiverSubManufacturer: 'Company11',
    gnssReceiverSubModel: 'GNSS016',
    gnssReceiverSubFirmwareVersion: 'GNSS_FW16',
    gnssSettingId: '4f969748-5efc-7461-b0e8-5ecdda96e836',
    machineInfoCompanyName: 'Hatachi Ltd.',
    machineInfoMachineType: 'ROADROLLER',
    machineInfoMachineName: 'HV25DW-6-000001',
    machineInfoMachineId: 'EX46',
    rentalStatus: 'Returned',
    lastUpdated: '2020-03-06T01:45:01.000Z',
    hasLicense: false,
    inSendingBackSupported: false,
    needsNtripDataReration: false,
  },
  {
    id: 67,
    uuid: '1bda5dc2-a199-4814-ac57-c8ced0ef0303',
    retrofitDistributorId: 2,
    corporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    ownerCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    siteId: '',
    corporationName: '',
    description: '',
    macAddress: '',
    latitude: 35.696227,
    longitude: 139.691062,
    lastOnlineTime: '2020-03-06T01:46:01.000Z',
    isError: false,
    isOnline: true,
    basicInfoManufacturer: 'Hatachi Ltd.',
    basicInfoModel: 'RF002-05',
    basicInfoProductNumber: 'PROD047',
    basicInfoSerialNumber: 'SERIAL047',
    controllerManufacturer: 'Company 2',
    controllerModel: 'CONT015',
    controllerFirmwareVersion: 'CONT_FW15',
    gnssReceiverMainManufacturer: 'Company 6',
    gnssReceiverMainModel: 'GNSS015',
    gnssReceiverMainFirmwareVersion: 'GNSS_FW15',
    gnssReceiverSubManufacturer: 'Company11',
    gnssReceiverSubModel: 'GNSS016',
    gnssReceiverSubFirmwareVersion: 'GNSS_FW16',
    gnssSettingId: '1e142b67-b447-8868-6e21-aae9d21089ba',
    machineInfoCompanyName: 'Hatachi Ltd.',
    machineInfoMachineType: 'ROADROLLER',
    machineInfoMachineName: 'HV25DW-6-000002',
    machineInfoMachineId: 'EX47',
    rentalStatus: 'Returned',
    lastUpdated: '2020-03-06T01:46:01.000Z',
    hasLicense: false,
    inSendingBackSupported: false,
    needsNtripDataReration: false,
  },
  {
    id: 68,
    uuid: '1bda5dc2-a199-4814-ac57-c8ced0ef0304',
    retrofitDistributorId: 2,
    corporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    ownerCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    siteId: '',
    corporationName: '',
    description: '',
    macAddress: '',
    latitude: 35.696227,
    longitude: 139.691062,
    lastOnlineTime: '2020-03-06T01:47:01.000Z',
    isError: false,
    isOnline: false,
    basicInfoManufacturer: 'Hatachi Ltd.',
    basicInfoModel: 'RF002-05',
    basicInfoProductNumber: 'PROD048',
    basicInfoSerialNumber: 'SERIAL048',
    controllerManufacturer: 'Company 2',
    controllerModel: 'CONT015',
    controllerFirmwareVersion: 'CONT_FW15',
    gnssReceiverMainManufacturer: 'Company 6',
    gnssReceiverMainModel: 'GNSS015',
    gnssReceiverMainFirmwareVersion: 'GNSS_FW15',
    gnssReceiverSubManufacturer: 'Company11',
    gnssReceiverSubModel: 'GNSS016',
    gnssReceiverSubFirmwareVersion: 'GNSS_FW16',
    gnssSettingId: '83bb62d6-bd67-6d9f-6612-1e51c55250e9',
    machineInfoCompanyName: 'Hatachi Ltd.',
    machineInfoMachineType: 'ROADROLLER',
    machineInfoMachineName: 'HV25DW-6-000003',
    machineInfoMachineId: 'EX48',
    rentalStatus: 'Returned',
    lastUpdated: '2020-03-06T01:47:01.000Z',
    hasLicense: false,
    inSendingBackSupported: false,
    needsNtripDataReration: false,
  },
  {
    id: 69,
    uuid: '1bda5dc2-a199-4814-ac57-c8ced0ef0305',
    retrofitDistributorId: 2,
    corporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    ownerCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    siteId: '',
    corporationName: '',
    description: '',
    macAddress: '',
    latitude: 35.696227,
    longitude: 139.691062,
    lastOnlineTime: '2020-03-06T01:48:01.000Z',
    isError: true,
    isOnline: false,
    basicInfoManufacturer: 'Hatachi Ltd.',
    basicInfoModel: 'RF002-05',
    basicInfoProductNumber: 'PROD049',
    basicInfoSerialNumber: 'SERIAL049',
    controllerManufacturer: 'Company 2',
    controllerModel: 'CONT015',
    controllerFirmwareVersion: 'CONT_FW15',
    gnssReceiverMainManufacturer: 'Company 6',
    gnssReceiverMainModel: 'GNSS015',
    gnssReceiverMainFirmwareVersion: 'GNSS_FW15',
    gnssReceiverSubManufacturer: 'Company11',
    gnssReceiverSubModel: 'GNSS016',
    gnssReceiverSubFirmwareVersion: 'GNSS_FW16',
    gnssSettingId: '964dd2b9-d30e-1cf0-4aed-2444687d3e45',
    machineInfoCompanyName: 'Hatachi Ltd.',
    machineInfoMachineType: 'ROADROLLER',
    machineInfoMachineName: 'HV25DW-6-000004',
    machineInfoMachineId: 'EX49',
    rentalStatus: 'Returned',
    lastUpdated: '2020-03-06T01:48:01.000Z',
    hasLicense: false,
    inSendingBackSupported: false,
    needsNtripDataReration: false,
  },
  {
    id: 70,
    uuid: '1bda5dc2-a199-4814-ac57-c8ced0ef0306',
    retrofitDistributorId: 2,
    corporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    ownerCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    siteId: '',
    corporationName: '',
    description: '',
    macAddress: '',
    latitude: 35.696227,
    longitude: 139.691062,
    lastOnlineTime: '2020-03-06T01:49:01.000Z',
    isError: true,
    isOnline: true,
    basicInfoManufacturer: 'Hatachi Ltd.',
    basicInfoModel: 'RF002-05',
    basicInfoProductNumber: 'PROD050',
    basicInfoSerialNumber: 'SERIAL050',
    controllerManufacturer: 'Company 2',
    controllerModel: 'CONT015',
    controllerFirmwareVersion: 'CONT_FW15',
    gnssReceiverMainManufacturer: 'Company 6',
    gnssReceiverMainModel: 'GNSS015',
    gnssReceiverMainFirmwareVersion: 'GNSS_FW15',
    gnssReceiverSubManufacturer: 'Company11',
    gnssReceiverSubModel: 'GNSS016',
    gnssReceiverSubFirmwareVersion: 'GNSS_FW16',
    gnssSettingId: 'f295b33d-bfa0-1c91-e0b0-a232d64aa0eb',
    machineInfoCompanyName: 'Hatachi Ltd.',
    machineInfoMachineType: 'ROADROLLER',
    machineInfoMachineName: 'HV25DW-6-000005',
    machineInfoMachineId: 'EX50',
    rentalStatus: 'Returned',
    lastUpdated: '2020-03-06T01:49:01.000Z',
    hasLicense: false,
    inSendingBackSupported: false,
    needsNtripDataReration: false,
  },
  {
    id: 71,
    uuid: '1bda5dc2-a199-4814-ac57-c8ced0ef0307',
    retrofitDistributorId: 2,
    corporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    ownerCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    siteId: '14b2149c-9d45-f3cb-bc93-7916ecc3778d',
    corporationName: '',
    description: '',
    macAddress: '484520296778',
    latitude: 35.696227,
    longitude: 139.691062,
    lastOnlineTime: '2020-03-06T01:50:01.000Z',
    isError: false,
    isOnline: true,
    basicInfoManufacturer: 'Kabelc Ltd.',
    basicInfoModel: 'RF003-01',
    basicInfoProductNumber: 'PROD051',
    basicInfoSerialNumber: 'SERIAL051',
    controllerManufacturer: 'Company 3',
    controllerModel: 'CONT021',
    controllerFirmwareVersion: 'CONT_FW21',
    gnssReceiverMainManufacturer: 'Company 7',
    gnssReceiverMainModel: 'GNSS021',
    gnssReceiverMainFirmwareVersion: 'GNSS_FW21',
    gnssReceiverSubManufacturer: 'Company12',
    gnssReceiverSubModel: 'GNSS022',
    gnssReceiverSubFirmwareVersion: 'GNSS_FW22',
    gnssSettingId: 'b65a6455-d503-3f67-56d9-e397042b021f',
    machineInfoCompanyName: 'Kabelc Ltd.',
    machineInfoMachineType: 'Excavator',
    machineInfoMachineName: 'KE400-10-000001',
    machineInfoMachineId: 'EX51',
    rentalStatus: 'Returned',
    lastUpdated: '2020-03-06T01:50:01.000Z',
    hasLicense: false,
    inSendingBackSupported: false,
    needsNtripDataReration: false,
  },
  {
    id: 72,
    uuid: '1bda5dc2-a199-4814-ac57-c8ced0ef0308',
    retrofitDistributorId: 2,
    corporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    ownerCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    siteId: '14b2149c-9d45-f3cb-bc93-7916ecc3778d',
    corporationName: '',
    description: '',
    macAddress: '484520296779',
    latitude: 35.696124,
    longitude: 139.69055,
    lastOnlineTime: '2020-03-06T01:51:01.000Z',
    isError: false,
    isOnline: true,
    basicInfoManufacturer: 'Kabelc Ltd.',
    basicInfoModel: 'RF003-01',
    basicInfoProductNumber: 'PROD052',
    basicInfoSerialNumber: 'SERIAL052',
    controllerManufacturer: 'Company 3',
    controllerModel: 'CONT021',
    controllerFirmwareVersion: 'CONT_FW21',
    gnssReceiverMainManufacturer: 'Company 7',
    gnssReceiverMainModel: 'GNSS021',
    gnssReceiverMainFirmwareVersion: 'GNSS_FW21',
    gnssReceiverSubManufacturer: 'Company12',
    gnssReceiverSubModel: 'GNSS022',
    gnssReceiverSubFirmwareVersion: 'GNSS_FW22',
    gnssSettingId: '89b4398b-f52a-14fb-2597-3dabe1a7d740',
    machineInfoCompanyName: 'Kabelc Ltd.',
    machineInfoMachineType: 'Excavator',
    machineInfoMachineName: 'KE400-10-000002',
    machineInfoMachineId: 'EX52',
    rentalStatus: 'Returned',
    lastUpdated: '2020-03-06T01:51:01.000Z',
    hasLicense: false,
    inSendingBackSupported: false,
    needsNtripDataReration: false,
  },
  {
    id: 73,
    uuid: '1bda5dc2-a199-4814-ac57-c8ced0ef0309',
    retrofitDistributorId: 2,
    corporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    ownerCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    siteId: '14b2149c-9d45-f3cb-bc93-7916ecc3778d',
    corporationName: '',
    description: '',
    macAddress: '847BEB10B0A3',
    latitude: 35.695739,
    longitude: 139.690266,
    lastOnlineTime: '2020-03-06T01:52:01.000Z',
    isError: false,
    isOnline: true,
    basicInfoManufacturer: 'Kabelc Ltd.',
    basicInfoModel: 'RF003-01',
    basicInfoProductNumber: 'PROD053',
    basicInfoSerialNumber: 'SERIAL053',
    controllerManufacturer: 'Company 3',
    controllerModel: 'CONT021',
    controllerFirmwareVersion: 'CONT_FW21',
    gnssReceiverMainManufacturer: 'Company 7',
    gnssReceiverMainModel: 'GNSS021',
    gnssReceiverMainFirmwareVersion: 'GNSS_FW21',
    gnssReceiverSubManufacturer: 'Company12',
    gnssReceiverSubModel: 'GNSS022',
    gnssReceiverSubFirmwareVersion: 'GNSS_FW22',
    gnssSettingId: 'b3b4f67e-fdd0-03c5-9aff-ef931b4f2367',
    machineInfoCompanyName: 'Kabelc Ltd.',
    machineInfoMachineType: 'Excavator',
    machineInfoMachineName: 'KE400-10-000003',
    machineInfoMachineId: 'EX53',
    rentalStatus: 'Returned',
    lastUpdated: '2020-03-06T01:52:01.000Z',
    hasLicense: false,
    inSendingBackSupported: false,
    needsNtripDataReration: false,
  },
  {
    id: 74,
    uuid: '1bda5dc2-a199-4814-ac57-c8ced0ef0310',
    retrofitDistributorId: 2,
    corporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    ownerCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    siteId: '14b2149c-9d45-f3cb-bc93-7916ecc3778d',
    corporationName: '',
    description: '',
    macAddress: '',
    latitude: 35.696372,
    longitude: 139.690695,
    lastOnlineTime: '2020-03-06T01:53:01.000Z',
    isError: true,
    isOnline: false,
    basicInfoManufacturer: 'Kabelc Ltd.',
    basicInfoModel: 'RF003-01',
    basicInfoProductNumber: 'PROD054',
    basicInfoSerialNumber: 'SERIAL054',
    controllerManufacturer: 'Company 3',
    controllerModel: 'CONT021',
    controllerFirmwareVersion: 'CONT_FW21',
    gnssReceiverMainManufacturer: 'Company 7',
    gnssReceiverMainModel: 'GNSS021',
    gnssReceiverMainFirmwareVersion: 'GNSS_FW21',
    gnssReceiverSubManufacturer: 'Company12',
    gnssReceiverSubModel: 'GNSS022',
    gnssReceiverSubFirmwareVersion: 'GNSS_FW22',
    gnssSettingId: '5cd1eed3-c0a2-dcbc-d640-2216f2ee14e0',
    machineInfoCompanyName: 'Kabelc Ltd.',
    machineInfoMachineType: 'Excavator',
    machineInfoMachineName: 'KE400-10-000004',
    machineInfoMachineId: 'EX54',
    rentalStatus: 'Returned',
    lastUpdated: '2020-03-06T01:53:01.000Z',
    hasLicense: false,
    inSendingBackSupported: false,
    needsNtripDataReration: false,
  },
  {
    id: 75,
    uuid: '1bda5dc2-a199-4814-ac57-c8ced0ef0311',
    retrofitDistributorId: 2,
    corporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    ownerCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    siteId: '14b2149c-9d45-f3cb-bc93-7916ecc3778d',
    corporationName: '',
    description: '',
    macAddress: '',
    latitude: 35.69622,
    longitude: 139.691038,
    lastOnlineTime: '2020-03-06T01:54:01.000Z',
    isError: true,
    isOnline: true,
    basicInfoManufacturer: 'Kabelc Ltd.',
    basicInfoModel: 'RF003-01',
    basicInfoProductNumber: 'PROD055',
    basicInfoSerialNumber: 'SERIAL055',
    controllerManufacturer: 'Company 3',
    controllerModel: 'CONT021',
    controllerFirmwareVersion: 'CONT_FW21',
    gnssReceiverMainManufacturer: 'Company 7',
    gnssReceiverMainModel: 'GNSS021',
    gnssReceiverMainFirmwareVersion: 'GNSS_FW21',
    gnssReceiverSubManufacturer: 'Company12',
    gnssReceiverSubModel: 'GNSS022',
    gnssReceiverSubFirmwareVersion: 'GNSS_FW22',
    gnssSettingId: 'c9c80b07-8f35-e1c0-bcdd-97acf3e89329',
    machineInfoCompanyName: 'Kabelc Ltd.',
    machineInfoMachineType: 'Excavator',
    machineInfoMachineName: 'KE400-10-000005',
    machineInfoMachineId: 'EX55',
    rentalStatus: 'Returned',
    lastUpdated: '2020-03-06T01:54:01.000Z',
    hasLicense: false,
    inSendingBackSupported: false,
    needsNtripDataReration: false,
  },
  {
    id: 76,
    uuid: '1bda5dc2-a199-4814-ac57-c8ced0ef0312',
    retrofitDistributorId: 2,
    corporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    ownerCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    siteId: '14b2149c-9d45-f3cb-bc93-7916ecc3778d',
    corporationName: '',
    description: '',
    macAddress: '',
    latitude: 35.69646,
    longitude: 139.691076,
    lastOnlineTime: '2020-03-06T01:55:01.000Z',
    isError: false,
    isOnline: true,
    basicInfoManufacturer: 'Kabelc Ltd.',
    basicInfoModel: 'RF003-02',
    basicInfoProductNumber: 'PROD056',
    basicInfoSerialNumber: 'SERIAL056',
    controllerManufacturer: 'Company 3',
    controllerModel: 'CONT022',
    controllerFirmwareVersion: 'CONT_FW22',
    gnssReceiverMainManufacturer: 'Company 7',
    gnssReceiverMainModel: 'GNSS022',
    gnssReceiverMainFirmwareVersion: 'GNSS_FW22',
    gnssReceiverSubManufacturer: 'Company12',
    gnssReceiverSubModel: 'GNSS023',
    gnssReceiverSubFirmwareVersion: 'GNSS_FW23',
    gnssSettingId: 'f016912f-abe5-2f5d-dae8-cd7400ef3bae',
    machineInfoCompanyName: 'Kabelc Ltd.',
    machineInfoMachineType: 'Excavator',
    machineInfoMachineName: 'KE400-11-000001',
    machineInfoMachineId: 'EX56',
    rentalStatus: 'Returned',
    lastUpdated: '2020-03-06T01:55:01.000Z',
    hasLicense: false,
    inSendingBackSupported: false,
    needsNtripDataReration: false,
  },
  {
    id: 77,
    uuid: '1bda5dc2-a199-4814-ac57-c8ced0ef0313',
    retrofitDistributorId: 2,
    corporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    ownerCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    siteId: '14b2149c-9d45-f3cb-bc93-7916ecc3778d',
    corporationName: '',
    description: '',
    macAddress: '',
    latitude: 35.696227,
    longitude: 139.691062,
    lastOnlineTime: '2020-03-06T01:56:01.000Z',
    isError: false,
    isOnline: true,
    basicInfoManufacturer: 'Kabelc Ltd.',
    basicInfoModel: 'RF003-02',
    basicInfoProductNumber: 'PROD057',
    basicInfoSerialNumber: 'SERIAL057',
    controllerManufacturer: 'Company 3',
    controllerModel: 'CONT022',
    controllerFirmwareVersion: 'CONT_FW22',
    gnssReceiverMainManufacturer: 'Company 7',
    gnssReceiverMainModel: 'GNSS022',
    gnssReceiverMainFirmwareVersion: 'GNSS_FW22',
    gnssReceiverSubManufacturer: 'Company12',
    gnssReceiverSubModel: 'GNSS023',
    gnssReceiverSubFirmwareVersion: 'GNSS_FW23',
    gnssSettingId: '0a30b729-4452-6b37-752f-15a32a65a903',
    machineInfoCompanyName: 'Kabelc Ltd.',
    machineInfoMachineType: 'Excavator',
    machineInfoMachineName: 'KE400-11-000002',
    machineInfoMachineId: 'EX57',
    rentalStatus: 'Returned',
    lastUpdated: '2020-03-06T01:56:01.000Z',
    hasLicense: false,
    inSendingBackSupported: false,
    needsNtripDataReration: false,
  },
  {
    id: 78,
    uuid: '1bda5dc2-a199-4814-ac57-c8ced0ef0314',
    retrofitDistributorId: 2,
    corporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    ownerCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    siteId: '14b2149c-9d45-f3cb-bc93-7916ecc3778d',
    corporationName: '',
    description: '',
    macAddress: '',
    latitude: 35.696227,
    longitude: 139.691062,
    lastOnlineTime: '2020-03-06T01:57:01.000Z',
    isError: false,
    isOnline: false,
    basicInfoManufacturer: 'Kabelc Ltd.',
    basicInfoModel: 'RF003-02',
    basicInfoProductNumber: 'PROD058',
    basicInfoSerialNumber: 'SERIAL058',
    controllerManufacturer: 'Company 3',
    controllerModel: 'CONT022',
    controllerFirmwareVersion: 'CONT_FW22',
    gnssReceiverMainManufacturer: 'Company 7',
    gnssReceiverMainModel: 'GNSS022',
    gnssReceiverMainFirmwareVersion: 'GNSS_FW22',
    gnssReceiverSubManufacturer: 'Company12',
    gnssReceiverSubModel: 'GNSS023',
    gnssReceiverSubFirmwareVersion: 'GNSS_FW23',
    gnssSettingId: 'f60eea4b-a918-85ff-4ea8-db7cc75a9ae0',
    machineInfoCompanyName: 'Kabelc Ltd.',
    machineInfoMachineType: 'Excavator',
    machineInfoMachineName: 'KE400-11-000003',
    machineInfoMachineId: 'EX58',
    rentalStatus: 'Returned',
    lastUpdated: '2020-03-06T01:57:01.000Z',
    hasLicense: false,
    inSendingBackSupported: false,
    needsNtripDataReration: false,
  },
  {
    id: 79,
    uuid: '1bda5dc2-a199-4814-ac57-c8ced0ef0315',
    retrofitDistributorId: 2,
    corporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    ownerCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    siteId: '14b2149c-9d45-f3cb-bc93-7916ecc3778d',
    corporationName: '',
    description: '',
    macAddress: '',
    latitude: 35.696227,
    longitude: 139.691062,
    lastOnlineTime: '2020-03-06T01:58:01.000Z',
    isError: true,
    isOnline: false,
    basicInfoManufacturer: 'Kabelc Ltd.',
    basicInfoModel: 'RF003-02',
    basicInfoProductNumber: 'PROD059',
    basicInfoSerialNumber: 'SERIAL059',
    controllerManufacturer: 'Company 3',
    controllerModel: 'CONT022',
    controllerFirmwareVersion: 'CONT_FW22',
    gnssReceiverMainManufacturer: 'Company 7',
    gnssReceiverMainModel: 'GNSS022',
    gnssReceiverMainFirmwareVersion: 'GNSS_FW22',
    gnssReceiverSubManufacturer: 'Company12',
    gnssReceiverSubModel: 'GNSS023',
    gnssReceiverSubFirmwareVersion: 'GNSS_FW23',
    gnssSettingId: '3a6fd62c-f738-cae7-7122-52a4c26e4d44',
    machineInfoCompanyName: 'Kabelc Ltd.',
    machineInfoMachineType: 'Excavator',
    machineInfoMachineName: 'KE400-11-000004',
    machineInfoMachineId: 'EX59',
    rentalStatus: 'Returned',
    lastUpdated: '2020-03-06T01:58:01.000Z',
    hasLicense: false,
    inSendingBackSupported: false,
    needsNtripDataReration: false,
  },
  {
    id: 80,
    uuid: '1bda5dc2-a199-4814-ac57-c8ced0ef0316',
    retrofitDistributorId: 2,
    corporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    ownerCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    siteId: '14b2149c-9d45-f3cb-bc93-7916ecc3778d',
    corporationName: '',
    description: '',
    macAddress: '',
    latitude: 35.696227,
    longitude: 139.691062,
    lastOnlineTime: '2020-03-06T01:59:01.000Z',
    isError: true,
    isOnline: true,
    basicInfoManufacturer: 'Kabelc Ltd.',
    basicInfoModel: 'RF003-02',
    basicInfoProductNumber: 'PROD060',
    basicInfoSerialNumber: 'SERIAL060',
    controllerManufacturer: 'Company 3',
    controllerModel: 'CONT022',
    controllerFirmwareVersion: 'CONT_FW22',
    gnssReceiverMainManufacturer: 'Company 7',
    gnssReceiverMainModel: 'GNSS022',
    gnssReceiverMainFirmwareVersion: 'GNSS_FW22',
    gnssReceiverSubManufacturer: 'Company12',
    gnssReceiverSubModel: 'GNSS023',
    gnssReceiverSubFirmwareVersion: 'GNSS_FW23',
    gnssSettingId: 'db732888-fadb-dee4-7e1f-abf485a2640a',
    machineInfoCompanyName: 'Kabelc Ltd.',
    machineInfoMachineType: 'Excavator',
    machineInfoMachineName: 'KE400-11-000005',
    machineInfoMachineId: 'EX60',
    rentalStatus: 'Returned',
    lastUpdated: '2020-03-06T01:59:01.000Z',
    hasLicense: false,
    inSendingBackSupported: false,
    needsNtripDataReration: false,
  },
  {
    id: 81,
    uuid: '1bda5dc2-a199-4814-ac57-c8ced0ef0317',
    retrofitDistributorId: 2,
    corporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    ownerCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    siteId: '14b2149c-9d45-f3cb-bc93-7916ecc3778d',
    corporationName: '',
    description: '',
    macAddress: '',
    latitude: 35.696227,
    longitude: 139.691062,
    lastOnlineTime: '2020-03-06T02:00:01.000Z',
    isError: false,
    isOnline: true,
    basicInfoManufacturer: 'Kabelc Ltd.',
    basicInfoModel: 'RF003-03',
    basicInfoProductNumber: 'PROD061',
    basicInfoSerialNumber: 'SERIAL061',
    controllerManufacturer: 'Company 3',
    controllerModel: 'CONT023',
    controllerFirmwareVersion: 'CONT_FW23',
    gnssReceiverMainManufacturer: 'Company 7',
    gnssReceiverMainModel: 'GNSS023',
    gnssReceiverMainFirmwareVersion: 'GNSS_FW23',
    gnssReceiverSubManufacturer: 'Company12',
    gnssReceiverSubModel: 'GNSS024',
    gnssReceiverSubFirmwareVersion: 'GNSS_FW24',
    gnssSettingId: 'adfed798-9610-fe83-ba6c-7914e1b15748',
    machineInfoCompanyName: 'Kabelc Ltd.',
    machineInfoMachineType: 'Excavator',
    machineInfoMachineName: 'KE400-12-000001',
    machineInfoMachineId: 'EX61',
    rentalStatus: 'Returned',
    lastUpdated: '2020-03-06T02:00:01.000Z',
    hasLicense: false,
    inSendingBackSupported: false,
    needsNtripDataReration: false,
  },
  {
    id: 82,
    uuid: '1bda5dc2-a199-4814-ac57-c8ced0ef0318',
    retrofitDistributorId: 2,
    corporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    ownerCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    siteId: '14b2149c-9d45-f3cb-bc93-7916ecc3778d',
    corporationName: '',
    description: '',
    macAddress: '',
    latitude: 35.696227,
    longitude: 139.691062,
    lastOnlineTime: '2020-03-06T02:01:01.000Z',
    isError: false,
    isOnline: true,
    basicInfoManufacturer: 'Kabelc Ltd.',
    basicInfoModel: 'RF003-03',
    basicInfoProductNumber: 'PROD062',
    basicInfoSerialNumber: 'SERIAL062',
    controllerManufacturer: 'Company 3',
    controllerModel: 'CONT023',
    controllerFirmwareVersion: 'CONT_FW23',
    gnssReceiverMainManufacturer: 'Company 7',
    gnssReceiverMainModel: 'GNSS023',
    gnssReceiverMainFirmwareVersion: 'GNSS_FW23',
    gnssReceiverSubManufacturer: 'Company12',
    gnssReceiverSubModel: 'GNSS024',
    gnssReceiverSubFirmwareVersion: 'GNSS_FW24',
    gnssSettingId: '3475451b-63bf-1641-20e5-42144d672f23',
    machineInfoCompanyName: 'Kabelc Ltd.',
    machineInfoMachineType: 'Excavator',
    machineInfoMachineName: 'KE400-12-000002',
    machineInfoMachineId: 'EX62',
    rentalStatus: 'Returned',
    lastUpdated: '2020-03-06T02:01:01.000Z',
    hasLicense: false,
    inSendingBackSupported: false,
    needsNtripDataReration: false,
  },
  {
    id: 83,
    uuid: '1bda5dc2-a199-4814-ac57-c8ced0ef0319',
    retrofitDistributorId: 2,
    corporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    ownerCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    siteId: '14b2149c-9d45-f3cb-bc93-7916ecc3778d',
    corporationName: '',
    description: '',
    macAddress: '',
    latitude: 35.696227,
    longitude: 139.691062,
    lastOnlineTime: '2020-03-06T02:02:01.000Z',
    isError: false,
    isOnline: false,
    basicInfoManufacturer: 'Kabelc Ltd.',
    basicInfoModel: 'RF003-03',
    basicInfoProductNumber: 'PROD063',
    basicInfoSerialNumber: 'SERIAL063',
    controllerManufacturer: 'Company 3',
    controllerModel: 'CONT023',
    controllerFirmwareVersion: 'CONT_FW23',
    gnssReceiverMainManufacturer: 'Company 7',
    gnssReceiverMainModel: 'GNSS023',
    gnssReceiverMainFirmwareVersion: 'GNSS_FW23',
    gnssReceiverSubManufacturer: 'Company12',
    gnssReceiverSubModel: 'GNSS024',
    gnssReceiverSubFirmwareVersion: 'GNSS_FW24',
    gnssSettingId: '5dede9ff-7adc-071d-c5f9-49e7dc86fae9',
    machineInfoCompanyName: 'Kabelc Ltd.',
    machineInfoMachineType: 'Excavator',
    machineInfoMachineName: 'KE400-12-000003',
    machineInfoMachineId: 'EX63',
    rentalStatus: 'Returned',
    lastUpdated: '2020-03-06T02:02:01.000Z',
    hasLicense: false,
    inSendingBackSupported: false,
    needsNtripDataReration: false,
  },
  {
    id: 84,
    uuid: '1bda5dc2-a199-4814-ac57-c8ced0ef0320',
    retrofitDistributorId: 2,
    corporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    ownerCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    siteId: '14b2149c-9d45-f3cb-bc93-7916ecc3778d',
    corporationName: '',
    description: '',
    macAddress: '',
    latitude: 35.696227,
    longitude: 139.691062,
    lastOnlineTime: '2020-03-06T02:03:01.000Z',
    isError: true,
    isOnline: false,
    basicInfoManufacturer: 'Kabelc Ltd.',
    basicInfoModel: 'RF003-03',
    basicInfoProductNumber: 'PROD064',
    basicInfoSerialNumber: 'SERIAL064',
    controllerManufacturer: 'Company 3',
    controllerModel: 'CONT023',
    controllerFirmwareVersion: 'CONT_FW23',
    gnssReceiverMainManufacturer: 'Company 7',
    gnssReceiverMainModel: 'GNSS023',
    gnssReceiverMainFirmwareVersion: 'GNSS_FW23',
    gnssReceiverSubManufacturer: 'Company12',
    gnssReceiverSubModel: 'GNSS024',
    gnssReceiverSubFirmwareVersion: 'GNSS_FW24',
    gnssSettingId: '340f8028-fa16-67aa-e8ff-35b8debc66b3',
    machineInfoCompanyName: 'Kabelc Ltd.',
    machineInfoMachineType: 'Excavator',
    machineInfoMachineName: 'KE400-12-000004',
    machineInfoMachineId: 'EX64',
    rentalStatus: 'Returned',
    lastUpdated: '2020-03-06T02:03:01.000Z',
    hasLicense: false,
    inSendingBackSupported: false,
    needsNtripDataReration: false,
  },
  {
    id: 85,
    uuid: '1bda5dc2-a199-4814-ac57-c8ced0ef0321',
    retrofitDistributorId: 2,
    corporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    ownerCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    siteId: '14b2149c-9d45-f3cb-bc93-7916ecc3778d',
    corporationName: '',
    description: '',
    macAddress: '',
    latitude: 35.696227,
    longitude: 139.691062,
    lastOnlineTime: '2020-03-06T02:04:01.000Z',
    isError: true,
    isOnline: true,
    basicInfoManufacturer: 'Kabelc Ltd.',
    basicInfoModel: 'RF003-03',
    basicInfoProductNumber: 'PROD065',
    basicInfoSerialNumber: 'SERIAL065',
    controllerManufacturer: 'Company 3',
    controllerModel: 'CONT023',
    controllerFirmwareVersion: 'CONT_FW23',
    gnssReceiverMainManufacturer: 'Company 7',
    gnssReceiverMainModel: 'GNSS023',
    gnssReceiverMainFirmwareVersion: 'GNSS_FW23',
    gnssReceiverSubManufacturer: 'Company12',
    gnssReceiverSubModel: 'GNSS024',
    gnssReceiverSubFirmwareVersion: 'GNSS_FW24',
    gnssSettingId: 'cea905e2-b920-c7aa-528e-7031c532c811',
    machineInfoCompanyName: 'Kabelc Ltd.',
    machineInfoMachineType: 'Excavator',
    machineInfoMachineName: 'KE400-12-000005',
    machineInfoMachineId: 'EX65',
    rentalStatus: 'Returned',
    lastUpdated: '2020-03-06T02:04:01.000Z',
    hasLicense: false,
    inSendingBackSupported: false,
    needsNtripDataReration: false,
  },
  {
    id: 86,
    uuid: '1bda5dc2-a199-4814-ac57-c8ced0ef0322',
    retrofitDistributorId: 2,
    corporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    ownerCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    siteId: '14b2149c-9d45-f3cb-bc93-7916ecc3778d',
    corporationName: '',
    description: '',
    macAddress: '',
    latitude: 35.696227,
    longitude: 139.691062,
    lastOnlineTime: '2020-03-06T02:05:01.000Z',
    isError: false,
    isOnline: true,
    basicInfoManufacturer: 'Kabelc Ltd.',
    basicInfoModel: 'RF003-04',
    basicInfoProductNumber: 'PROD066',
    basicInfoSerialNumber: 'SERIAL066',
    controllerManufacturer: 'Company 3',
    controllerModel: 'CONT024',
    controllerFirmwareVersion: 'CONT_FW24',
    gnssReceiverMainManufacturer: 'Company 7',
    gnssReceiverMainModel: 'GNSS024',
    gnssReceiverMainFirmwareVersion: 'GNSS_FW24',
    gnssReceiverSubManufacturer: 'Company12',
    gnssReceiverSubModel: 'GNSS025',
    gnssReceiverSubFirmwareVersion: 'GNSS_FW25',
    gnssSettingId: '95c1f193-e9f6-4c59-2620-ed4e29b55413',
    machineInfoCompanyName: 'Kabelc Ltd.',
    machineInfoMachineType: 'Excavator',
    machineInfoMachineName: 'KE400-13-000001',
    machineInfoMachineId: 'EX66',
    rentalStatus: 'Returned',
    lastUpdated: '2020-03-06T02:05:01.000Z',
    hasLicense: false,
    inSendingBackSupported: false,
    needsNtripDataReration: false,
  },
  {
    id: 87,
    uuid: '1bda5dc2-a199-4814-ac57-c8ced0ef0323',
    retrofitDistributorId: 2,
    corporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    ownerCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    siteId: '14b2149c-9d45-f3cb-bc93-7916ecc3778d',
    corporationName: '',
    description: '',
    macAddress: '',
    latitude: 35.696227,
    longitude: 139.691062,
    lastOnlineTime: '2020-03-06T02:06:01.000Z',
    isError: false,
    isOnline: true,
    basicInfoManufacturer: 'Kabelc Ltd.',
    basicInfoModel: 'RF003-04',
    basicInfoProductNumber: 'PROD067',
    basicInfoSerialNumber: 'SERIAL067',
    controllerManufacturer: 'Company 3',
    controllerModel: 'CONT024',
    controllerFirmwareVersion: 'CONT_FW24',
    gnssReceiverMainManufacturer: 'Company 7',
    gnssReceiverMainModel: 'GNSS024',
    gnssReceiverMainFirmwareVersion: 'GNSS_FW24',
    gnssReceiverSubManufacturer: 'Company12',
    gnssReceiverSubModel: 'GNSS025',
    gnssReceiverSubFirmwareVersion: 'GNSS_FW25',
    gnssSettingId: '09e00b3b-6ed2-37e8-75ae-0e78f81f8345',
    machineInfoCompanyName: 'Kabelc Ltd.',
    machineInfoMachineType: 'Excavator',
    machineInfoMachineName: 'KE400-13-000002',
    machineInfoMachineId: 'EX67',
    rentalStatus: 'Returned',
    lastUpdated: '2020-03-06T02:06:01.000Z',
    hasLicense: false,
    inSendingBackSupported: false,
    needsNtripDataReration: false,
  },
  {
    id: 88,
    uuid: '1bda5dc2-a199-4814-ac57-c8ced0ef0324',
    retrofitDistributorId: 2,
    corporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    ownerCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    siteId: '14b2149c-9d45-f3cb-bc93-7916ecc3778d',
    corporationName: '',
    description: '',
    macAddress: '',
    latitude: 35.696227,
    longitude: 139.691062,
    lastOnlineTime: '2020-03-06T02:07:01.000Z',
    isError: false,
    isOnline: false,
    basicInfoManufacturer: 'Kabelc Ltd.',
    basicInfoModel: 'RF003-04',
    basicInfoProductNumber: 'PROD068',
    basicInfoSerialNumber: 'SERIAL068',
    controllerManufacturer: 'Company 3',
    controllerModel: 'CONT024',
    controllerFirmwareVersion: 'CONT_FW24',
    gnssReceiverMainManufacturer: 'Company 7',
    gnssReceiverMainModel: 'GNSS024',
    gnssReceiverMainFirmwareVersion: 'GNSS_FW24',
    gnssReceiverSubManufacturer: 'Company12',
    gnssReceiverSubModel: 'GNSS025',
    gnssReceiverSubFirmwareVersion: 'GNSS_FW25',
    gnssSettingId: 'd9b8a515-a5a1-b6f5-16b7-92ca7be5fafd',
    machineInfoCompanyName: 'Kabelc Ltd.',
    machineInfoMachineType: 'Excavator',
    machineInfoMachineName: 'KE400-13-000003',
    machineInfoMachineId: 'EX68',
    rentalStatus: 'Returned',
    lastUpdated: '2020-03-06T02:07:01.000Z',
    hasLicense: false,
    inSendingBackSupported: false,
    needsNtripDataReration: false,
  },
  {
    id: 89,
    uuid: '1bda5dc2-a199-4814-ac57-c8ced0ef0325',
    retrofitDistributorId: 2,
    corporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    ownerCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    siteId: '14b2149c-9d45-f3cb-bc93-7916ecc3778d',
    corporationName: '',
    description: '',
    macAddress: '',
    latitude: 35.696227,
    longitude: 139.691062,
    lastOnlineTime: '2020-03-06T02:08:01.000Z',
    isError: true,
    isOnline: false,
    basicInfoManufacturer: 'Kabelc Ltd.',
    basicInfoModel: 'RF003-04',
    basicInfoProductNumber: 'PROD069',
    basicInfoSerialNumber: 'SERIAL069',
    controllerManufacturer: 'Company 3',
    controllerModel: 'CONT024',
    controllerFirmwareVersion: 'CONT_FW24',
    gnssReceiverMainManufacturer: 'Company 7',
    gnssReceiverMainModel: 'GNSS024',
    gnssReceiverMainFirmwareVersion: 'GNSS_FW24',
    gnssReceiverSubManufacturer: 'Company12',
    gnssReceiverSubModel: 'GNSS025',
    gnssReceiverSubFirmwareVersion: 'GNSS_FW25',
    gnssSettingId: '85d6e797-8092-9e7a-32a0-dd09cf605cfe',
    machineInfoCompanyName: 'Kabelc Ltd.',
    machineInfoMachineType: 'Excavator',
    machineInfoMachineName: 'KE400-13-000004',
    machineInfoMachineId: 'EX69',
    rentalStatus: 'Returned',
    lastUpdated: '2020-03-06T02:08:01.000Z',
    hasLicense: false,
    inSendingBackSupported: false,
    needsNtripDataReration: false,
  },
  {
    id: 90,
    uuid: '1bda5dc2-a199-4814-ac57-c8ced0ef0326',
    retrofitDistributorId: 2,
    corporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    ownerCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    siteId: '14b2149c-9d45-f3cb-bc93-7916ecc3778d',
    corporationName: '',
    description: '',
    macAddress: '',
    latitude: 35.696227,
    longitude: 139.691062,
    lastOnlineTime: '2020-03-06T02:09:01.000Z',
    isError: true,
    isOnline: true,
    basicInfoManufacturer: 'Kabelc Ltd.',
    basicInfoModel: 'RF003-04',
    basicInfoProductNumber: 'PROD070',
    basicInfoSerialNumber: 'SERIAL070',
    controllerManufacturer: 'Company 3',
    controllerModel: 'CONT024',
    controllerFirmwareVersion: 'CONT_FW24',
    gnssReceiverMainManufacturer: 'Company 7',
    gnssReceiverMainModel: 'GNSS024',
    gnssReceiverMainFirmwareVersion: 'GNSS_FW24',
    gnssReceiverSubManufacturer: 'Company12',
    gnssReceiverSubModel: 'GNSS025',
    gnssReceiverSubFirmwareVersion: 'GNSS_FW25',
    gnssSettingId: '22ded954-beb6-094c-e53e-f3d0aec0664e',
    machineInfoCompanyName: 'Kabelc Ltd.',
    machineInfoMachineType: 'Excavator',
    machineInfoMachineName: 'KE400-13-000005',
    machineInfoMachineId: 'EX70',
    rentalStatus: 'Returned',
    lastUpdated: '2020-03-06T02:09:01.000Z',
    hasLicense: false,
    inSendingBackSupported: false,
    needsNtripDataReration: false,
  },
  {
    id: 91,
    uuid: '1bda5dc2-a199-4814-ac57-c8ced0ef0327',
    retrofitDistributorId: 2,
    corporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    ownerCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    siteId: '14b2149c-9d45-f3cb-bc93-7916ecc3778d',
    corporationName: '',
    description: '',
    macAddress: '',
    latitude: 35.696227,
    longitude: 139.691062,
    lastOnlineTime: '2020-03-06T02:10:01.000Z',
    isError: false,
    isOnline: true,
    basicInfoManufacturer: 'Kabelc Ltd.',
    basicInfoModel: 'RF003-05',
    basicInfoProductNumber: 'PROD071',
    basicInfoSerialNumber: 'SERIAL071',
    controllerManufacturer: 'Company 3',
    controllerModel: 'CONT025',
    controllerFirmwareVersion: 'CONT_FW25',
    gnssReceiverMainManufacturer: 'Company 7',
    gnssReceiverMainModel: 'GNSS025',
    gnssReceiverMainFirmwareVersion: 'GNSS_FW25',
    gnssReceiverSubManufacturer: 'Company12',
    gnssReceiverSubModel: 'GNSS026',
    gnssReceiverSubFirmwareVersion: 'GNSS_FW26',
    gnssSettingId: 'e153aa6a-2137-a7bb-fcf3-fae83568239e',
    machineInfoCompanyName: 'Kabelc Ltd.',
    machineInfoMachineType: 'ROADROLLER',
    machineInfoMachineName: 'KV25EW-6-000001',
    machineInfoMachineId: 'EX71',
    rentalStatus: 'Returned',
    lastUpdated: '2020-03-06T02:10:01.000Z',
    hasLicense: false,
    inSendingBackSupported: false,
    needsNtripDataReration: false,
  },
  {
    id: 92,
    uuid: '1bda5dc2-a199-4814-ac57-c8ced0ef0328',
    retrofitDistributorId: 2,
    corporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    ownerCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    siteId: '14b2149c-9d45-f3cb-bc93-7916ecc3778d',
    corporationName: '',
    description: '',
    macAddress: '',
    latitude: 35.696227,
    longitude: 139.691062,
    lastOnlineTime: '2020-03-06T02:11:01.000Z',
    isError: false,
    isOnline: true,
    basicInfoManufacturer: 'Kabelc Ltd.',
    basicInfoModel: 'RF003-05',
    basicInfoProductNumber: 'PROD072',
    basicInfoSerialNumber: 'SERIAL072',
    controllerManufacturer: 'Company 3',
    controllerModel: 'CONT025',
    controllerFirmwareVersion: 'CONT_FW25',
    gnssReceiverMainManufacturer: 'Company 7',
    gnssReceiverMainModel: 'GNSS025',
    gnssReceiverMainFirmwareVersion: 'GNSS_FW25',
    gnssReceiverSubManufacturer: 'Company12',
    gnssReceiverSubModel: 'GNSS026',
    gnssReceiverSubFirmwareVersion: 'GNSS_FW26',
    gnssSettingId: 'a583d773-0b90-9d83-54a7-6df0b4f683d8',
    machineInfoCompanyName: 'Kabelc Ltd.',
    machineInfoMachineType: 'ROADROLLER',
    machineInfoMachineName: 'KV25EW-6-000002',
    machineInfoMachineId: 'EX72',
    rentalStatus: 'Returned',
    lastUpdated: '2020-03-06T02:11:01.000Z',
    hasLicense: false,
    inSendingBackSupported: false,
    needsNtripDataReration: false,
  },
  {
    id: 93,
    uuid: '1bda5dc2-a199-4814-ac57-c8ced0ef0329',
    retrofitDistributorId: 2,
    corporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    ownerCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    siteId: '14b2149c-9d45-f3cb-bc93-7916ecc3778d',
    corporationName: '',
    description: '',
    macAddress: '',
    latitude: 35.696227,
    longitude: 139.691062,
    lastOnlineTime: '2020-03-06T02:12:01.000Z',
    isError: false,
    isOnline: false,
    basicInfoManufacturer: 'Kabelc Ltd.',
    basicInfoModel: 'RF003-05',
    basicInfoProductNumber: 'PROD073',
    basicInfoSerialNumber: 'SERIAL073',
    controllerManufacturer: 'Company 3',
    controllerModel: 'CONT025',
    controllerFirmwareVersion: 'CONT_FW25',
    gnssReceiverMainManufacturer: 'Company 7',
    gnssReceiverMainModel: 'GNSS025',
    gnssReceiverMainFirmwareVersion: 'GNSS_FW25',
    gnssReceiverSubManufacturer: 'Company12',
    gnssReceiverSubModel: 'GNSS026',
    gnssReceiverSubFirmwareVersion: 'GNSS_FW26',
    gnssSettingId: '9f0f6e42-d115-8ef6-e543-5a560f61c32a',
    machineInfoCompanyName: 'Kabelc Ltd.',
    machineInfoMachineType: 'ROADROLLER',
    machineInfoMachineName: 'KV25EW-6-000003',
    machineInfoMachineId: 'EX73',
    rentalStatus: 'Returned',
    lastUpdated: '2020-03-06T02:12:01.000Z',
    hasLicense: false,
    inSendingBackSupported: false,
    needsNtripDataReration: false,
  },
  {
    id: 94,
    uuid: '1bda5dc2-a199-4814-ac57-c8ced0ef0330',
    retrofitDistributorId: 2,
    corporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    ownerCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    siteId: '14b2149c-9d45-f3cb-bc93-7916ecc3778d',
    corporationName: '',
    description: '',
    macAddress: '',
    latitude: 35.696227,
    longitude: 139.691062,
    lastOnlineTime: '2020-03-06T02:13:01.000Z',
    isError: true,
    isOnline: false,
    basicInfoManufacturer: 'Kabelc Ltd.',
    basicInfoModel: 'RF003-05',
    basicInfoProductNumber: 'PROD074',
    basicInfoSerialNumber: 'SERIAL074',
    controllerManufacturer: 'Company 3',
    controllerModel: 'CONT025',
    controllerFirmwareVersion: 'CONT_FW25',
    gnssReceiverMainManufacturer: 'Company 7',
    gnssReceiverMainModel: 'GNSS025',
    gnssReceiverMainFirmwareVersion: 'GNSS_FW25',
    gnssReceiverSubManufacturer: 'Company12',
    gnssReceiverSubModel: 'GNSS026',
    gnssReceiverSubFirmwareVersion: 'GNSS_FW26',
    gnssSettingId: 'b24d4ba3-3317-a9a4-9dab-57e416480354',
    machineInfoCompanyName: 'Kabelc Ltd.',
    machineInfoMachineType: 'ROADROLLER',
    machineInfoMachineName: 'KV25EW-6-000004',
    machineInfoMachineId: 'EX74',
    rentalStatus: 'Returned',
    lastUpdated: '2020-03-06T02:13:01.000Z',
    hasLicense: false,
    inSendingBackSupported: false,
    needsNtripDataReration: false,
  },
  {
    id: 95,
    uuid: '1bda5dc2-a199-4814-ac57-c8ced0ef0331',
    retrofitDistributorId: 2,
    corporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    ownerCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    siteId: '14b2149c-9d45-f3cb-bc93-7916ecc3778d',
    corporationName: '',
    description: '',
    macAddress: '',
    latitude: 35.696227,
    longitude: 139.691062,
    lastOnlineTime: '2020-03-06T02:14:01.000Z',
    isError: true,
    isOnline: true,
    basicInfoManufacturer: 'Kabelc Ltd.',
    basicInfoModel: 'RF003-05',
    basicInfoProductNumber: 'PROD075',
    basicInfoSerialNumber: 'SERIAL075',
    controllerManufacturer: 'Company 3',
    controllerModel: 'CONT025',
    controllerFirmwareVersion: 'CONT_FW25',
    gnssReceiverMainManufacturer: 'Company 7',
    gnssReceiverMainModel: 'GNSS025',
    gnssReceiverMainFirmwareVersion: 'GNSS_FW25',
    gnssReceiverSubManufacturer: 'Company12',
    gnssReceiverSubModel: 'GNSS026',
    gnssReceiverSubFirmwareVersion: 'GNSS_FW26',
    gnssSettingId: '457c70c7-0f86-f562-3533-1b51c26730e3',
    machineInfoCompanyName: 'Kabelc Ltd.',
    machineInfoMachineType: 'ROADROLLER',
    machineInfoMachineName: 'KV25EW-6-000005',
    machineInfoMachineId: 'EX75',
    rentalStatus: 'Returned',
    lastUpdated: '2020-03-06T02:14:01.000Z',
    hasLicense: false,
    inSendingBackSupported: false,
    needsNtripDataReration: false,
  },
  {
    id: 96,
    uuid: '1bda5dc2-a199-4814-ac57-c8ced0ef0332',
    retrofitDistributorId: 2,
    corporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    ownerCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    siteId: '14b2149c-9d45-f3cb-bc93-7916ecc3778d',
    corporationName: '',
    description: '',
    macAddress: '',
    latitude: 35.696227,
    longitude: 139.691062,
    lastOnlineTime: '2020-03-06T02:15:01.000Z',
    isError: false,
    isOnline: true,
    basicInfoManufacturer: 'Tubota Ltd.',
    basicInfoModel: 'RF004-01',
    basicInfoProductNumber: 'PROD076',
    basicInfoSerialNumber: 'SERIAL076',
    controllerManufacturer: 'Company 4',
    controllerModel: 'CONT031',
    controllerFirmwareVersion: 'CONT_FW31',
    gnssReceiverMainManufacturer: 'Company 8',
    gnssReceiverMainModel: 'GNSS031',
    gnssReceiverMainFirmwareVersion: 'GNSS_FW31',
    gnssReceiverSubManufacturer: 'Company13',
    gnssReceiverSubModel: 'GNSS032',
    gnssReceiverSubFirmwareVersion: 'GNSS_FW32',
    gnssSettingId: '714cd2f9-09f4-18c2-b9e6-ebc0aa7c5d45',
    machineInfoCompanyName: 'Tubota Ltd.',
    machineInfoMachineType: 'Excavator',
    machineInfoMachineName: 'TF500-10-000001',
    machineInfoMachineId: 'EX76',
    rentalStatus: 'Returned',
    lastUpdated: '2020-03-06T02:15:01.000Z',
    hasLicense: false,
    inSendingBackSupported: false,
    needsNtripDataReration: false,
  },
  {
    id: 97,
    uuid: '1bda5dc2-a199-4814-ac57-c8ced0ef0333',
    retrofitDistributorId: 2,
    corporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    ownerCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    siteId: '14b2149c-9d45-f3cb-bc93-7916ecc3778d',
    corporationName: '',
    description: '',
    macAddress: '',
    latitude: 35.696227,
    longitude: 139.691062,
    lastOnlineTime: '2020-03-06T02:16:01.000Z',
    isError: false,
    isOnline: true,
    basicInfoManufacturer: 'Tubota Ltd.',
    basicInfoModel: 'RF004-01',
    basicInfoProductNumber: 'PROD077',
    basicInfoSerialNumber: 'SERIAL077',
    controllerManufacturer: 'Company 4',
    controllerModel: 'CONT031',
    controllerFirmwareVersion: 'CONT_FW31',
    gnssReceiverMainManufacturer: 'Company 8',
    gnssReceiverMainModel: 'GNSS031',
    gnssReceiverMainFirmwareVersion: 'GNSS_FW31',
    gnssReceiverSubManufacturer: 'Company13',
    gnssReceiverSubModel: 'GNSS032',
    gnssReceiverSubFirmwareVersion: 'GNSS_FW32',
    gnssSettingId: '3216f1d9-12dc-bedc-4857-9112c1b4d77d',
    machineInfoCompanyName: 'Tubota Ltd.',
    machineInfoMachineType: 'Excavator',
    machineInfoMachineName: 'TF500-10-000002',
    machineInfoMachineId: 'EX77',
    rentalStatus: 'Returned',
    lastUpdated: '2020-03-06T02:16:01.000Z',
    hasLicense: false,
    inSendingBackSupported: false,
    needsNtripDataReration: false,
  },
  {
    id: 98,
    uuid: '1bda5dc2-a199-4814-ac57-c8ced0ef0334',
    retrofitDistributorId: 2,
    corporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    ownerCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    siteId: '14b2149c-9d45-f3cb-bc93-7916ecc3778d',
    corporationName: '',
    description: '',
    macAddress: '',
    latitude: 35.696227,
    longitude: 139.691062,
    lastOnlineTime: '2020-03-06T02:17:01.000Z',
    isError: false,
    isOnline: false,
    basicInfoManufacturer: 'Tubota Ltd.',
    basicInfoModel: 'RF004-01',
    basicInfoProductNumber: 'PROD078',
    basicInfoSerialNumber: 'SERIAL078',
    controllerManufacturer: 'Company 4',
    controllerModel: 'CONT031',
    controllerFirmwareVersion: 'CONT_FW31',
    gnssReceiverMainManufacturer: 'Company 8',
    gnssReceiverMainModel: 'GNSS031',
    gnssReceiverMainFirmwareVersion: 'GNSS_FW31',
    gnssReceiverSubManufacturer: 'Company13',
    gnssReceiverSubModel: 'GNSS032',
    gnssReceiverSubFirmwareVersion: 'GNSS_FW32',
    gnssSettingId: '59b11818-a2fc-1074-219d-fdea49cd634e',
    machineInfoCompanyName: 'Tubota Ltd.',
    machineInfoMachineType: 'Excavator',
    machineInfoMachineName: 'TF500-10-000003',
    machineInfoMachineId: 'EX78',
    rentalStatus: 'Returned',
    lastUpdated: '2020-03-06T02:17:01.000Z',
    hasLicense: false,
    inSendingBackSupported: false,
    needsNtripDataReration: false,
  },
  {
    id: 99,
    uuid: '1bda5dc2-a199-4814-ac57-c8ced0ef0335',
    retrofitDistributorId: 2,
    corporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    ownerCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    siteId: '14b2149c-9d45-f3cb-bc93-7916ecc3778d',
    corporationName: '',
    description: '',
    macAddress: '',
    latitude: 35.696227,
    longitude: 139.691062,
    lastOnlineTime: '2020-03-06T02:18:01.000Z',
    isError: true,
    isOnline: false,
    basicInfoManufacturer: 'Tubota Ltd.',
    basicInfoModel: 'RF004-01',
    basicInfoProductNumber: 'PROD079',
    basicInfoSerialNumber: 'SERIAL079',
    controllerManufacturer: 'Company 4',
    controllerModel: 'CONT031',
    controllerFirmwareVersion: 'CONT_FW31',
    gnssReceiverMainManufacturer: 'Company 8',
    gnssReceiverMainModel: 'GNSS031',
    gnssReceiverMainFirmwareVersion: 'GNSS_FW31',
    gnssReceiverSubManufacturer: 'Company13',
    gnssReceiverSubModel: 'GNSS032',
    gnssReceiverSubFirmwareVersion: 'GNSS_FW32',
    gnssSettingId: '45b6e3d7-bd1b-a12e-edcd-04c7abc058c8',
    machineInfoCompanyName: 'Tubota Ltd.',
    machineInfoMachineType: 'Excavator',
    machineInfoMachineName: 'TF500-10-000004',
    machineInfoMachineId: 'EX79',
    rentalStatus: 'Returned',
    lastUpdated: '2020-03-06T02:18:01.000Z',
    hasLicense: false,
    inSendingBackSupported: false,
    needsNtripDataReration: false,
  },
  {
    id: 100,
    uuid: '1bda5dc2-a199-4814-ac57-c8ced0ef0336',
    retrofitDistributorId: 2,
    corporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    ownerCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    siteId: '14b2149c-9d45-f3cb-bc93-7916ecc3778d',
    corporationName: '',
    description: '',
    macAddress: '',
    latitude: 35.696227,
    longitude: 139.691062,
    lastOnlineTime: '2020-03-06T02:19:01.000Z',
    isError: true,
    isOnline: true,
    basicInfoManufacturer: 'Tubota Ltd.',
    basicInfoModel: 'RF004-01',
    basicInfoProductNumber: 'PROD080',
    basicInfoSerialNumber: 'SERIAL080',
    controllerManufacturer: 'Company 4',
    controllerModel: 'CONT031',
    controllerFirmwareVersion: 'CONT_FW31',
    gnssReceiverMainManufacturer: 'Company 8',
    gnssReceiverMainModel: 'GNSS031',
    gnssReceiverMainFirmwareVersion: 'GNSS_FW31',
    gnssReceiverSubManufacturer: 'Company13',
    gnssReceiverSubModel: 'GNSS032',
    gnssReceiverSubFirmwareVersion: 'GNSS_FW32',
    gnssSettingId: '414956d1-d744-eaa0-fd8b-c3d94774598f',
    machineInfoCompanyName: 'Tubota Ltd.',
    machineInfoMachineType: 'Excavator',
    machineInfoMachineName: 'TF500-10-000005',
    machineInfoMachineId: 'EX80',
    rentalStatus: 'Returned',
    lastUpdated: '2020-03-06T02:19:01.000Z',
    hasLicense: false,
    inSendingBackSupported: false,
    needsNtripDataReration: false,
  },
  {
    id: 101,
    uuid: '1bda5dc2-a199-4814-ac57-c8ced0ef0337',
    retrofitDistributorId: 2,
    corporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    ownerCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    siteId: '',
    corporationName: '',
    description: '',
    macAddress: '',
    latitude: 35.696227,
    longitude: 139.691062,
    lastOnlineTime: '2020-03-06T02:20:01.000Z',
    isError: false,
    isOnline: true,
    basicInfoManufacturer: 'Tubota Ltd.',
    basicInfoModel: 'RF004-02',
    basicInfoProductNumber: 'PROD081',
    basicInfoSerialNumber: 'SERIAL081',
    controllerManufacturer: 'Company 4',
    controllerModel: 'CONT032',
    controllerFirmwareVersion: 'CONT_FW32',
    gnssReceiverMainManufacturer: 'Company 8',
    gnssReceiverMainModel: 'GNSS032',
    gnssReceiverMainFirmwareVersion: 'GNSS_FW32',
    gnssReceiverSubManufacturer: 'Company13',
    gnssReceiverSubModel: 'GNSS033',
    gnssReceiverSubFirmwareVersion: 'GNSS_FW33',
    gnssSettingId: 'aba17ef3-dff6-58cd-e023-4d596262f821',
    machineInfoCompanyName: 'Tubota Ltd.',
    machineInfoMachineType: 'Excavator',
    machineInfoMachineName: 'TF500-11-000001',
    machineInfoMachineId: 'EX81',
    rentalStatus: 'Returned',
    lastUpdated: '2020-03-06T02:20:01.000Z',
    hasLicense: false,
    inSendingBackSupported: false,
    needsNtripDataReration: false,
  },
  {
    id: 102,
    uuid: '1bda5dc2-a199-4814-ac57-c8ced0ef0338',
    retrofitDistributorId: 2,
    corporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    ownerCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    siteId: '',
    corporationName: '',
    description: '',
    macAddress: '',
    latitude: 35.696227,
    longitude: 139.691062,
    lastOnlineTime: '2020-03-06T02:21:01.000Z',
    isError: false,
    isOnline: true,
    basicInfoManufacturer: 'Tubota Ltd.',
    basicInfoModel: 'RF004-02',
    basicInfoProductNumber: 'PROD082',
    basicInfoSerialNumber: 'SERIAL082',
    controllerManufacturer: 'Company 4',
    controllerModel: 'CONT032',
    controllerFirmwareVersion: 'CONT_FW32',
    gnssReceiverMainManufacturer: 'Company 8',
    gnssReceiverMainModel: 'GNSS032',
    gnssReceiverMainFirmwareVersion: 'GNSS_FW32',
    gnssReceiverSubManufacturer: 'Company13',
    gnssReceiverSubModel: 'GNSS033',
    gnssReceiverSubFirmwareVersion: 'GNSS_FW33',
    gnssSettingId: 'b67de8b0-c5c2-c1b8-1b60-840d6580a71e',
    machineInfoCompanyName: 'Tubota Ltd.',
    machineInfoMachineType: 'Excavator',
    machineInfoMachineName: 'TF500-11-000002',
    machineInfoMachineId: 'EX82',
    rentalStatus: 'Returned',
    lastUpdated: '2020-03-06T02:21:01.000Z',
    hasLicense: false,
    inSendingBackSupported: false,
    needsNtripDataReration: false,
  },
  {
    id: 103,
    uuid: '1bda5dc2-a199-4814-ac57-c8ced0ef0339',
    retrofitDistributorId: 2,
    corporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    ownerCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    siteId: '14b2149c-9d45-f3cb-bc93-7916ecc3778d',
    corporationName: '',
    description: '',
    macAddress: '',
    latitude: 35.696227,
    longitude: 139.691062,
    lastOnlineTime: '2020-03-06T02:22:01.000Z',
    isError: false,
    isOnline: false,
    basicInfoManufacturer: 'Tubota Ltd.',
    basicInfoModel: 'RF004-02',
    basicInfoProductNumber: 'PROD083',
    basicInfoSerialNumber: 'SERIAL083',
    controllerManufacturer: 'Company 4',
    controllerModel: 'CONT032',
    controllerFirmwareVersion: 'CONT_FW32',
    gnssReceiverMainManufacturer: 'Company 8',
    gnssReceiverMainModel: 'GNSS032',
    gnssReceiverMainFirmwareVersion: 'GNSS_FW32',
    gnssReceiverSubManufacturer: 'Company13',
    gnssReceiverSubModel: 'GNSS033',
    gnssReceiverSubFirmwareVersion: 'GNSS_FW33',
    gnssSettingId: 'df7f58c7-568b-ca0e-60d4-b560ea6899a9',
    machineInfoCompanyName: 'Tubota Ltd.',
    machineInfoMachineType: 'Excavator',
    machineInfoMachineName: 'TF500-11-000003',
    machineInfoMachineId: 'EX83',
    rentalStatus: 'Returned',
    lastUpdated: '2020-03-06T02:22:01.000Z',
    hasLicense: false,
    inSendingBackSupported: false,
    needsNtripDataReration: false,
  },
  {
    id: 104,
    uuid: '1bda5dc2-a199-4814-ac57-c8ced0ef0340',
    retrofitDistributorId: 2,
    corporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    ownerCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    siteId: '14b2149c-9d45-f3cb-bc93-7916ecc3778d',
    corporationName: '',
    description: '',
    macAddress: '',
    latitude: 35.696227,
    longitude: 139.691062,
    lastOnlineTime: '2020-03-06T02:23:01.000Z',
    isError: true,
    isOnline: false,
    basicInfoManufacturer: 'Tubota Ltd.',
    basicInfoModel: 'RF004-02',
    basicInfoProductNumber: 'PROD084',
    basicInfoSerialNumber: 'SERIAL084',
    controllerManufacturer: 'Company 4',
    controllerModel: 'CONT032',
    controllerFirmwareVersion: 'CONT_FW32',
    gnssReceiverMainManufacturer: 'Company 8',
    gnssReceiverMainModel: 'GNSS032',
    gnssReceiverMainFirmwareVersion: 'GNSS_FW32',
    gnssReceiverSubManufacturer: 'Company13',
    gnssReceiverSubModel: 'GNSS033',
    gnssReceiverSubFirmwareVersion: 'GNSS_FW33',
    gnssSettingId: 'c0f04dbf-7ea1-644f-f9c2-4206535fcc98',
    machineInfoCompanyName: 'Tubota Ltd.',
    machineInfoMachineType: 'Excavator',
    machineInfoMachineName: 'TF500-11-000004',
    machineInfoMachineId: 'EX84',
    rentalStatus: 'Returned',
    lastUpdated: '2020-03-06T02:23:01.000Z',
    hasLicense: false,
    inSendingBackSupported: false,
    needsNtripDataReration: false,
  },
  {
    id: 105,
    uuid: '1bda5dc2-a199-4814-ac57-c8ced0ef0341',
    retrofitDistributorId: 2,
    corporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    ownerCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    siteId: '14b2149c-9d45-f3cb-bc93-7916ecc3778d',
    corporationName: '',
    description: '',
    macAddress: '',
    latitude: 35.696227,
    longitude: 139.691062,
    lastOnlineTime: '2020-03-06T02:24:01.000Z',
    isError: true,
    isOnline: true,
    basicInfoManufacturer: 'Tubota Ltd.',
    basicInfoModel: 'RF004-02',
    basicInfoProductNumber: 'PROD085',
    basicInfoSerialNumber: 'SERIAL085',
    controllerManufacturer: 'Company 4',
    controllerModel: 'CONT032',
    controllerFirmwareVersion: 'CONT_FW32',
    gnssReceiverMainManufacturer: 'Company 8',
    gnssReceiverMainModel: 'GNSS032',
    gnssReceiverMainFirmwareVersion: 'GNSS_FW32',
    gnssReceiverSubManufacturer: 'Company13',
    gnssReceiverSubModel: 'GNSS033',
    gnssReceiverSubFirmwareVersion: 'GNSS_FW33',
    gnssSettingId: 'f67d2117-c73a-483d-e359-3debce898a4f',
    machineInfoCompanyName: 'Tubota Ltd.',
    machineInfoMachineType: 'Excavator',
    machineInfoMachineName: 'TF500-11-000005',
    machineInfoMachineId: 'EX85',
    rentalStatus: 'Returned',
    lastUpdated: '2020-03-06T02:24:01.000Z',
    hasLicense: false,
    inSendingBackSupported: false,
    needsNtripDataReration: false,
  },
  {
    id: 106,
    uuid: '1bda5dc2-a199-4814-ac57-c8ced0ef0342',
    retrofitDistributorId: 2,
    corporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    ownerCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    siteId: '14b2149c-9d45-f3cb-bc93-7916ecc3778d',
    corporationName: '',
    description: '',
    macAddress: '',
    latitude: 35.696227,
    longitude: 139.691062,
    lastOnlineTime: '2020-03-06T02:25:01.000Z',
    isError: false,
    isOnline: true,
    basicInfoManufacturer: 'Tubota Ltd.',
    basicInfoModel: 'RF004-03',
    basicInfoProductNumber: 'PROD086',
    basicInfoSerialNumber: 'SERIAL086',
    controllerManufacturer: 'Company 4',
    controllerModel: 'CONT033',
    controllerFirmwareVersion: 'CONT_FW33',
    gnssReceiverMainManufacturer: 'Company 8',
    gnssReceiverMainModel: 'GNSS033',
    gnssReceiverMainFirmwareVersion: 'GNSS_FW33',
    gnssReceiverSubManufacturer: 'Company13',
    gnssReceiverSubModel: 'GNSS034',
    gnssReceiverSubFirmwareVersion: 'GNSS_FW34',
    gnssSettingId: '0411c039-c219-d88c-d22a-7a77e3ac9058',
    machineInfoCompanyName: 'Tubota Ltd.',
    machineInfoMachineType: 'Excavator',
    machineInfoMachineName: 'TF500-12-000001',
    machineInfoMachineId: 'EX86',
    rentalStatus: 'Returned',
    lastUpdated: '2020-03-06T02:25:01.000Z',
    hasLicense: false,
    inSendingBackSupported: false,
    needsNtripDataReration: false,
  },
  {
    id: 107,
    uuid: '1bda5dc2-a199-4814-ac57-c8ced0ef0343',
    retrofitDistributorId: 2,
    corporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    ownerCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    siteId: '14b2149c-9d45-f3cb-bc93-7916ecc3778d',
    corporationName: '',
    description: '',
    macAddress: '',
    latitude: 35.696227,
    longitude: 139.691062,
    lastOnlineTime: '2020-03-06T02:26:01.000Z',
    isError: false,
    isOnline: true,
    basicInfoManufacturer: 'Tubota Ltd.',
    basicInfoModel: 'RF004-03',
    basicInfoProductNumber: 'PROD087',
    basicInfoSerialNumber: 'SERIAL087',
    controllerManufacturer: 'Company 4',
    controllerModel: 'CONT033',
    controllerFirmwareVersion: 'CONT_FW33',
    gnssReceiverMainManufacturer: 'Company 8',
    gnssReceiverMainModel: 'GNSS033',
    gnssReceiverMainFirmwareVersion: 'GNSS_FW33',
    gnssReceiverSubManufacturer: 'Company13',
    gnssReceiverSubModel: 'GNSS034',
    gnssReceiverSubFirmwareVersion: 'GNSS_FW34',
    gnssSettingId: '50b70e9a-92df-9ad9-3afe-b17925bcdf43',
    machineInfoCompanyName: 'Tubota Ltd.',
    machineInfoMachineType: 'Excavator',
    machineInfoMachineName: 'TF500-12-000002',
    machineInfoMachineId: 'EX87',
    rentalStatus: 'Returned',
    lastUpdated: '2020-03-06T02:26:01.000Z',
    hasLicense: false,
    inSendingBackSupported: false,
    needsNtripDataReration: false,
  },
  {
    id: 108,
    uuid: '1bda5dc2-a199-4814-ac57-c8ced0ef0344',
    retrofitDistributorId: 2,
    corporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    ownerCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    siteId: '14b2149c-9d45-f3cb-bc93-7916ecc3778d',
    corporationName: '',
    description: '',
    macAddress: '',
    latitude: 35.696227,
    longitude: 139.691062,
    lastOnlineTime: '2020-03-06T02:27:01.000Z',
    isError: false,
    isOnline: false,
    basicInfoManufacturer: 'Tubota Ltd.',
    basicInfoModel: 'RF004-03',
    basicInfoProductNumber: 'PROD088',
    basicInfoSerialNumber: 'SERIAL088',
    controllerManufacturer: 'Company 4',
    controllerModel: 'CONT033',
    controllerFirmwareVersion: 'CONT_FW33',
    gnssReceiverMainManufacturer: 'Company 8',
    gnssReceiverMainModel: 'GNSS033',
    gnssReceiverMainFirmwareVersion: 'GNSS_FW33',
    gnssReceiverSubManufacturer: 'Company13',
    gnssReceiverSubModel: 'GNSS034',
    gnssReceiverSubFirmwareVersion: 'GNSS_FW34',
    gnssSettingId: '149a0a99-05cd-2ab6-c38c-7753ff3bb7c7',
    machineInfoCompanyName: 'Tubota Ltd.',
    machineInfoMachineType: 'Excavator',
    machineInfoMachineName: 'TF500-12-000003',
    machineInfoMachineId: 'EX88',
    rentalStatus: 'Returned',
    lastUpdated: '2020-03-06T02:27:01.000Z',
    hasLicense: false,
    inSendingBackSupported: false,
    needsNtripDataReration: false,
  },
  {
    id: 109,
    uuid: '1bda5dc2-a199-4814-ac57-c8ced0ef0345',
    retrofitDistributorId: 2,
    corporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    ownerCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    siteId: '14b2149c-9d45-f3cb-bc93-7916ecc3778d',
    corporationName: '',
    description: '',
    macAddress: '',
    latitude: 35.696227,
    longitude: 139.691062,
    lastOnlineTime: '2020-03-06T02:28:01.000Z',
    isError: true,
    isOnline: false,
    basicInfoManufacturer: 'Tubota Ltd.',
    basicInfoModel: 'RF004-03',
    basicInfoProductNumber: 'PROD089',
    basicInfoSerialNumber: 'SERIAL089',
    controllerManufacturer: 'Company 4',
    controllerModel: 'CONT033',
    controllerFirmwareVersion: 'CONT_FW33',
    gnssReceiverMainManufacturer: 'Company 8',
    gnssReceiverMainModel: 'GNSS033',
    gnssReceiverMainFirmwareVersion: 'GNSS_FW33',
    gnssReceiverSubManufacturer: 'Company13',
    gnssReceiverSubModel: 'GNSS034',
    gnssReceiverSubFirmwareVersion: 'GNSS_FW34',
    gnssSettingId: 'fb62c010-d06e-9ccb-1f95-1f816c7e446c',
    machineInfoCompanyName: 'Tubota Ltd.',
    machineInfoMachineType: 'Excavator',
    machineInfoMachineName: 'TF500-12-000004',
    machineInfoMachineId: 'EX89',
    rentalStatus: 'Returned',
    lastUpdated: '2020-03-06T02:28:01.000Z',
    hasLicense: false,
    inSendingBackSupported: false,
    needsNtripDataReration: false,
  },
  {
    id: 110,
    uuid: '1bda5dc2-a199-4814-ac57-c8ced0ef0346',
    retrofitDistributorId: 2,
    corporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    ownerCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    siteId: '14b2149c-9d45-f3cb-bc93-7916ecc3778d',
    corporationName: '',
    description: '',
    macAddress: '',
    latitude: 35.696227,
    longitude: 139.691062,
    lastOnlineTime: '2020-03-06T02:29:01.000Z',
    isError: true,
    isOnline: true,
    basicInfoManufacturer: 'Tubota Ltd.',
    basicInfoModel: 'RF004-03',
    basicInfoProductNumber: 'PROD090',
    basicInfoSerialNumber: 'SERIAL090',
    controllerManufacturer: 'Company 4',
    controllerModel: 'CONT033',
    controllerFirmwareVersion: 'CONT_FW33',
    gnssReceiverMainManufacturer: 'Company 8',
    gnssReceiverMainModel: 'GNSS033',
    gnssReceiverMainFirmwareVersion: 'GNSS_FW33',
    gnssReceiverSubManufacturer: 'Company13',
    gnssReceiverSubModel: 'GNSS034',
    gnssReceiverSubFirmwareVersion: 'GNSS_FW34',
    gnssSettingId: 'f9f64656-16f6-fea3-bd83-5848bd21c450',
    machineInfoCompanyName: 'Tubota Ltd.',
    machineInfoMachineType: 'Excavator',
    machineInfoMachineName: 'TF500-12-000005',
    machineInfoMachineId: 'EX90',
    rentalStatus: 'Returned',
    lastUpdated: '2020-03-06T02:29:01.000Z',
    hasLicense: false,
    inSendingBackSupported: false,
    needsNtripDataReration: false,
  },
  {
    id: 111,
    uuid: '1bda5dc2-a199-4814-ac57-c8ced0ef0347',
    retrofitDistributorId: 2,
    corporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    ownerCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    siteId: '',
    corporationName: '',
    description: '',
    macAddress: '',
    latitude: 35.696227,
    longitude: 139.691062,
    lastOnlineTime: '2020-03-06T02:30:01.000Z',
    isError: false,
    isOnline: true,
    basicInfoManufacturer: 'Tubota Ltd.',
    basicInfoModel: 'RF004-04',
    basicInfoProductNumber: 'PROD091',
    basicInfoSerialNumber: 'SERIAL091',
    controllerManufacturer: 'Company 4',
    controllerModel: 'CONT034',
    controllerFirmwareVersion: 'CONT_FW34',
    gnssReceiverMainManufacturer: 'Company 8',
    gnssReceiverMainModel: 'GNSS034',
    gnssReceiverMainFirmwareVersion: 'GNSS_FW34',
    gnssReceiverSubManufacturer: 'Company13',
    gnssReceiverSubModel: 'GNSS035',
    gnssReceiverSubFirmwareVersion: 'GNSS_FW35',
    gnssSettingId: '6180b85a-9351-6ce7-01b6-805885f41122',
    machineInfoCompanyName: 'Tubota Ltd.',
    machineInfoMachineType: 'Excavator',
    machineInfoMachineName: 'TF500-13-000001',
    machineInfoMachineId: 'EX91',
    rentalStatus: 'Returned',
    lastUpdated: '2020-03-06T02:30:01.000Z',
    hasLicense: false,
    inSendingBackSupported: false,
    needsNtripDataReration: false,
  },
  {
    id: 112,
    uuid: '1bda5dc2-a199-4814-ac57-c8ced0ef0348',
    retrofitDistributorId: 2,
    corporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    ownerCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    siteId: '',
    corporationName: '',
    description: '',
    macAddress: '',
    latitude: 35.696227,
    longitude: 139.691062,
    lastOnlineTime: '2020-03-06T02:31:01.000Z',
    isError: false,
    isOnline: true,
    basicInfoManufacturer: 'Tubota Ltd.',
    basicInfoModel: 'RF004-04',
    basicInfoProductNumber: 'PROD092',
    basicInfoSerialNumber: 'SERIAL092',
    controllerManufacturer: 'Company 4',
    controllerModel: 'CONT034',
    controllerFirmwareVersion: 'CONT_FW34',
    gnssReceiverMainManufacturer: 'Company 8',
    gnssReceiverMainModel: 'GNSS034',
    gnssReceiverMainFirmwareVersion: 'GNSS_FW34',
    gnssReceiverSubManufacturer: 'Company13',
    gnssReceiverSubModel: 'GNSS035',
    gnssReceiverSubFirmwareVersion: 'GNSS_FW35',
    gnssSettingId: 'dd609f73-ab6a-c0e3-8009-7f757084456b',
    machineInfoCompanyName: 'Tubota Ltd.',
    machineInfoMachineType: 'Excavator',
    machineInfoMachineName: 'TF500-13-000002',
    machineInfoMachineId: 'EX92',
    rentalStatus: 'Returned',
    lastUpdated: '2020-03-06T02:31:01.000Z',
    hasLicense: false,
    inSendingBackSupported: false,
    needsNtripDataReration: false,
  },
  {
    id: 113,
    uuid: '1bda5dc2-a199-4814-ac57-c8ced0ef0349',
    retrofitDistributorId: 2,
    corporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    ownerCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    siteId: '',
    corporationName: '',
    description: '',
    macAddress: '',
    latitude: 35.696227,
    longitude: 139.691062,
    lastOnlineTime: '2020-03-06T02:32:01.000Z',
    isError: false,
    isOnline: false,
    basicInfoManufacturer: 'Tubota Ltd.',
    basicInfoModel: 'RF004-04',
    basicInfoProductNumber: 'PROD093',
    basicInfoSerialNumber: 'SERIAL093',
    controllerManufacturer: 'Company 4',
    controllerModel: 'CONT034',
    controllerFirmwareVersion: 'CONT_FW34',
    gnssReceiverMainManufacturer: 'Company 8',
    gnssReceiverMainModel: 'GNSS034',
    gnssReceiverMainFirmwareVersion: 'GNSS_FW34',
    gnssReceiverSubManufacturer: 'Company13',
    gnssReceiverSubModel: 'GNSS035',
    gnssReceiverSubFirmwareVersion: 'GNSS_FW35',
    gnssSettingId: 'f2d5df22-1bab-5a23-697b-ef70d7fe318e',
    machineInfoCompanyName: 'Tubota Ltd.',
    machineInfoMachineType: 'Excavator',
    machineInfoMachineName: 'TF500-13-000003',
    machineInfoMachineId: 'EX93',
    rentalStatus: 'Returned',
    lastUpdated: '2020-03-06T02:32:01.000Z',
    hasLicense: false,
    inSendingBackSupported: false,
    needsNtripDataReration: false,
  },
  {
    id: 114,
    uuid: '1bda5dc2-a199-4814-ac57-c8ced0ef0350',
    retrofitDistributorId: 2,
    corporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    ownerCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    siteId: '',
    corporationName: '',
    description: '',
    macAddress: '',
    latitude: 35.696227,
    longitude: 139.691062,
    lastOnlineTime: '2020-03-06T02:33:01.000Z',
    isError: true,
    isOnline: false,
    basicInfoManufacturer: 'Tubota Ltd.',
    basicInfoModel: 'RF004-04',
    basicInfoProductNumber: 'PROD094',
    basicInfoSerialNumber: 'SERIAL094',
    controllerManufacturer: 'Company 4',
    controllerModel: 'CONT034',
    controllerFirmwareVersion: 'CONT_FW34',
    gnssReceiverMainManufacturer: 'Company 8',
    gnssReceiverMainModel: 'GNSS034',
    gnssReceiverMainFirmwareVersion: 'GNSS_FW34',
    gnssReceiverSubManufacturer: 'Company13',
    gnssReceiverSubModel: 'GNSS035',
    gnssReceiverSubFirmwareVersion: 'GNSS_FW35',
    gnssSettingId: '23a005ab-29fc-83cf-0572-85c8c2d476db',
    machineInfoCompanyName: 'Tubota Ltd.',
    machineInfoMachineType: 'Excavator',
    machineInfoMachineName: 'TF500-13-000004',
    machineInfoMachineId: 'EX94',
    rentalStatus: 'Returned',
    lastUpdated: '2020-03-06T02:33:01.000Z',
    hasLicense: false,
    inSendingBackSupported: false,
    needsNtripDataReration: false,
  },
  {
    id: 115,
    uuid: '1bda5dc2-a199-4814-ac57-c8ced0ef0351',
    retrofitDistributorId: 2,
    corporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    ownerCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    siteId: '',
    corporationName: '',
    description: '',
    macAddress: '',
    latitude: 35.696227,
    longitude: 139.691062,
    lastOnlineTime: '2020-03-06T02:34:01.000Z',
    isError: true,
    isOnline: true,
    basicInfoManufacturer: 'Tubota Ltd.',
    basicInfoModel: 'RF004-04',
    basicInfoProductNumber: 'PROD095',
    basicInfoSerialNumber: 'SERIAL095',
    controllerManufacturer: 'Company 4',
    controllerModel: 'CONT034',
    controllerFirmwareVersion: 'CONT_FW34',
    gnssReceiverMainManufacturer: 'Company 8',
    gnssReceiverMainModel: 'GNSS034',
    gnssReceiverMainFirmwareVersion: 'GNSS_FW34',
    gnssReceiverSubManufacturer: 'Company13',
    gnssReceiverSubModel: 'GNSS035',
    gnssReceiverSubFirmwareVersion: 'GNSS_FW35',
    gnssSettingId: 'b1f8deb7-c232-8dac-96ee-e73f5e9e7f26',
    machineInfoCompanyName: 'Tubota Ltd.',
    machineInfoMachineType: 'Excavator',
    machineInfoMachineName: 'TF500-13-000005',
    machineInfoMachineId: 'EX95',
    rentalStatus: 'Returned',
    lastUpdated: '2020-03-06T02:34:01.000Z',
    hasLicense: false,
    inSendingBackSupported: false,
    needsNtripDataReration: false,
  },
  {
    id: 116,
    uuid: '1bda5dc2-a199-4814-ac57-c8ced0ef0352',
    retrofitDistributorId: 2,
    corporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    ownerCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    siteId: '',
    corporationName: '',
    description: '',
    macAddress: '',
    latitude: 35.696227,
    longitude: 139.691062,
    lastOnlineTime: '2020-03-06T02:35:01.000Z',
    isError: false,
    isOnline: true,
    basicInfoManufacturer: 'Tubota Ltd.',
    basicInfoModel: 'RF004-05',
    basicInfoProductNumber: 'PROD096',
    basicInfoSerialNumber: 'SERIAL096',
    controllerManufacturer: 'Company 4',
    controllerModel: 'CONT035',
    controllerFirmwareVersion: 'CONT_FW35',
    gnssReceiverMainManufacturer: 'Company 8',
    gnssReceiverMainModel: 'GNSS035',
    gnssReceiverMainFirmwareVersion: 'GNSS_FW35',
    gnssReceiverSubManufacturer: 'Company13',
    gnssReceiverSubModel: 'GNSS036',
    gnssReceiverSubFirmwareVersion: 'GNSS_FW36',
    gnssSettingId: '6f919fcb-b4a1-dc77-830f-74a3adf8f602',
    machineInfoCompanyName: 'Tubota Ltd.',
    machineInfoMachineType: 'ROADROLLER',
    machineInfoMachineName: 'TV25FW-6-000001',
    machineInfoMachineId: 'EX96',
    rentalStatus: 'Returned',
    lastUpdated: '2020-03-06T02:35:01.000Z',
    hasLicense: false,
    inSendingBackSupported: false,
    needsNtripDataReration: false,
  },
  {
    id: 117,
    uuid: '1bda5dc2-a199-4814-ac57-c8ced0ef0353',
    retrofitDistributorId: 2,
    corporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    ownerCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    siteId: '',
    corporationName: '',
    description: '',
    macAddress: '',
    latitude: 35.696227,
    longitude: 139.691062,
    lastOnlineTime: '2020-03-06T02:36:01.000Z',
    isError: false,
    isOnline: true,
    basicInfoManufacturer: 'Tubota Ltd.',
    basicInfoModel: 'RF004-05',
    basicInfoProductNumber: 'PROD097',
    basicInfoSerialNumber: 'SERIAL097',
    controllerManufacturer: 'Company 4',
    controllerModel: 'CONT035',
    controllerFirmwareVersion: 'CONT_FW35',
    gnssReceiverMainManufacturer: 'Company 8',
    gnssReceiverMainModel: 'GNSS035',
    gnssReceiverMainFirmwareVersion: 'GNSS_FW35',
    gnssReceiverSubManufacturer: 'Company13',
    gnssReceiverSubModel: 'GNSS036',
    gnssReceiverSubFirmwareVersion: 'GNSS_FW36',
    gnssSettingId: '33f0596e-6974-06ba-4276-8588ae74daaf',
    machineInfoCompanyName: 'Tubota Ltd.',
    machineInfoMachineType: 'ROADROLLER',
    machineInfoMachineName: 'TV25FW-6-000002',
    machineInfoMachineId: 'EX97',
    rentalStatus: 'Returned',
    lastUpdated: '2020-03-06T02:36:01.000Z',
    hasLicense: false,
    inSendingBackSupported: false,
    needsNtripDataReration: false,
  },
  {
    id: 118,
    uuid: '1bda5dc2-a199-4814-ac57-c8ced0ef0354',
    retrofitDistributorId: 2,
    corporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    ownerCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    siteId: '',
    corporationName: '',
    description: '',
    macAddress: '',
    latitude: 35.696227,
    longitude: 139.691062,
    lastOnlineTime: '2020-03-06T02:37:01.000Z',
    isError: false,
    isOnline: false,
    basicInfoManufacturer: 'Tubota Ltd.',
    basicInfoModel: 'RF004-05',
    basicInfoProductNumber: 'PROD098',
    basicInfoSerialNumber: 'SERIAL098',
    controllerManufacturer: 'Company 4',
    controllerModel: 'CONT035',
    controllerFirmwareVersion: 'CONT_FW35',
    gnssReceiverMainManufacturer: 'Company 8',
    gnssReceiverMainModel: 'GNSS035',
    gnssReceiverMainFirmwareVersion: 'GNSS_FW35',
    gnssReceiverSubManufacturer: 'Company13',
    gnssReceiverSubModel: 'GNSS036',
    gnssReceiverSubFirmwareVersion: 'GNSS_FW36',
    gnssSettingId: 'b55e53da-6024-90ab-8308-a844435e2de1',
    machineInfoCompanyName: 'Tubota Ltd.',
    machineInfoMachineType: 'ROADROLLER',
    machineInfoMachineName: 'TV25FW-6-000003',
    machineInfoMachineId: 'EX98',
    rentalStatus: 'Returned',
    lastUpdated: '2020-03-06T02:37:01.000Z',
    hasLicense: false,
    inSendingBackSupported: false,
    needsNtripDataReration: false,
  },
  {
    id: 119,
    uuid: '1bda5dc2-a199-4814-ac57-c8ced0ef0355',
    retrofitDistributorId: 2,
    corporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    ownerCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    siteId: '',
    corporationName: '',
    description: '',
    macAddress: '',
    latitude: 35.696227,
    longitude: 139.691062,
    lastOnlineTime: '2020-03-06T02:38:01.000Z',
    isError: true,
    isOnline: false,
    basicInfoManufacturer: 'Tubota Ltd.',
    basicInfoModel: 'RF004-05',
    basicInfoProductNumber: 'PROD099',
    basicInfoSerialNumber: 'SERIAL099',
    controllerManufacturer: 'Company 4',
    controllerModel: 'CONT035',
    controllerFirmwareVersion: 'CONT_FW35',
    gnssReceiverMainManufacturer: 'Company 8',
    gnssReceiverMainModel: 'GNSS035',
    gnssReceiverMainFirmwareVersion: 'GNSS_FW35',
    gnssReceiverSubManufacturer: 'Company13',
    gnssReceiverSubModel: 'GNSS036',
    gnssReceiverSubFirmwareVersion: 'GNSS_FW36',
    gnssSettingId: '3d5c3b5b-3cda-370a-d1eb-26752e7363f3',
    machineInfoCompanyName: 'Tubota Ltd.',
    machineInfoMachineType: 'ROADROLLER',
    machineInfoMachineName: 'TV25FW-6-000004',
    machineInfoMachineId: 'EX99',
    rentalStatus: 'Returned',
    lastUpdated: '2020-03-06T02:38:01.000Z',
    hasLicense: false,
    inSendingBackSupported: false,
    needsNtripDataReration: false,
  },
  {
    id: 120,
    uuid: '1bda5dc2-a199-4814-ac57-c8ced0ef0356',
    retrofitDistributorId: 2,
    corporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    ownerCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    siteId: '',
    corporationName: '',
    description: '',
    macAddress: '',
    latitude: 35.696227,
    longitude: 139.691062,
    lastOnlineTime: '2020-03-06T02:39:01.000Z',
    isError: true,
    isOnline: true,
    basicInfoManufacturer: 'Tubota Ltd.',
    basicInfoModel: 'RF004-05',
    basicInfoProductNumber: 'PROD100',
    basicInfoSerialNumber: 'SERIAL100',
    controllerManufacturer: 'Company 4',
    controllerModel: 'CONT035',
    controllerFirmwareVersion: 'CONT_FW35',
    gnssReceiverMainManufacturer: 'Company 8',
    gnssReceiverMainModel: 'GNSS035',
    gnssReceiverMainFirmwareVersion: 'GNSS_FW35',
    gnssReceiverSubManufacturer: 'Company13',
    gnssReceiverSubModel: 'GNSS036',
    gnssReceiverSubFirmwareVersion: 'GNSS_FW36',
    gnssSettingId: '2026f7aa-829c-3c91-092e-9421a9475f5a',
    machineInfoCompanyName: 'Tubota Ltd.',
    machineInfoMachineType: 'ROADROLLER',
    machineInfoMachineName: 'TV25FW-6-000005',
    machineInfoMachineId: 'EX100',
    rentalStatus: 'Returned',
    lastUpdated: '2020-03-06T02:39:01.000Z',
    hasLicense: false,
    inSendingBackSupported: false,
    needsNtripDataReration: false,
  },
  {
    id: 121,
    uuid: 'ce191370-5ef9-e59c-484f-05fdba7971da',
    retrofitDistributorId: 10,
    corporationId: '8715c009-aada-9c22-20e3-a63305b46db9',
    ownerCorporationId: '8715c009-aada-9c22-20e3-a63305b46db9',
    siteId: '87bab37d-6121-8712-b46f-db62854ffb7c',
    corporationName:
      '企業名５０６７８９０１２３４５６７８９０１２３４５６７８９０１２３４５６７８９０１２３４５６７８９０',
    description:
      '説明５０５６７８９０１２３４５６７８９０１２３４５６７８９０１２３４５６７８９０１２３４５６７８９０',
    macAddress: '',
    latitude: 35.659186,
    longitude: 139.705696,
    lastOnlineTime: '2020-05-11T00:01:01.000Z',
    isError: false,
    isOnline: false,
    basicInfoManufacturer: 'basicInfoManufacturer50456789012345678901234567890',
    basicInfoModel: 'basicInfoModel507890123456789012345678901234567890',
    basicInfoProductNumber:
      'basicInfoProductNumber5056789012345678901234567890',
    basicInfoSerialNumber: 'basicInfoSerialNumber50456789012345678901234567890',
    controllerManufacturer:
      'controllerManufacturer5056789012345678901234567890',
    controllerModel: 'controllerModel50890123456789012345678901234567890',
    controllerFirmwareVersion:
      'controllerFirmwareVersion5089012345678901234567890',
    gnssReceiverMainManufacturer:
      'gnssReceiverMainManufacturer5012345678901234567890',
    gnssReceiverMainModel: 'gnssReceiverMainModel50456789012345678901234567890',
    gnssReceiverMainFirmwareVersion:
      'gnssReceiverMainFirmwareVersion5045678901234567890',
    gnssReceiverSubManufacturer:
      'gnssReceiverSubManufacturer50012345678901234567890',
    gnssReceiverSubModel: 'gnssReceiverSubModel503456789012345678901234567890',
    gnssReceiverSubFirmwareVersion:
      'gnssReceiverSubFirmwareVersion50345678901234567890',
    gnssSettingId: '49362511-22ed-7661-70b1-20d9d2841954',
    machineInfoCompanyName:
      'machineInfoCompanyName5056789012345678901234567890',
    machineInfoMachineType: 'Excavator',
    machineInfoMachineName:
      '建機５０５６７８９０１２３４５６７８９０１２３４５６７８９０１２３４５６７８９０１２３４５６７８９０',
    machineInfoMachineId: 'machineInfoMachineId503456789012345678901234567890',
    rentalStatus: 'Returned',
    lastUpdated: '2020-05-11T00:01:01.000Z',
    hasLicense: false,
    inSendingBackSupported: false,
    needsNtripDataReration: false,
  },
  {
    id: 122,
    uuid: '32ae6919-9fcd-a9d2-3c7e-a92685c76a73',
    retrofitDistributorId: 10,
    corporationId: '8715c009-aada-9c22-20e3-a63305b46db9',
    ownerCorporationId: '8715c009-aada-9c22-20e3-a63305b46db9',
    siteId: '46fb0806-b4fe-6dc5-3127-d6fdc814c2f7',
    corporationName:
      '企業名１００７８９０１２３４５６７８９０１２３４５６７８９０１２３４５６７８９０１２３４５６７８９０１２３４５６７８９０１２３４５６７８９０１２３４５６７８９０１２３４５６７８９０１２３４５６７８９０',
    description:
      '説明１００６７８９０１２３４５６７８９０１２３４５６７８９０１２３４５６７８９０１２３４５６７８９０',
    macAddress: '',
    latitude: 35.658837,
    longitude: 139.705224,
    lastOnlineTime: '2020-05-11T00:01:02.001Z',
    isError: false,
    isOnline: false,
    basicInfoManufacturer:
      'basicInfoManufacturer1005678901234567890123456789012345678901234567890123456789012345678901234567890',
    basicInfoModel:
      'basicInfoManufacturer1005678901234567890123456789012345678901234567890123456789012345678901234567890',
    basicInfoProductNumber:
      'basicInfoProductNumber100678901234567890123456789012345678901234567890123456789012345678901234567890',
    basicInfoSerialNumber:
      'basicInfoSerialNumber1005678901234567890123456789012345678901234567890123456789012345678901234567890',
    controllerManufacturer:
      'controllerManufacturer100678901234567890123456789012345678901234567890123456789012345678901234567890',
    controllerModel:
      'controllerModel1009012345678901234567890123456789012345678901234567890123456789012345678901234567890',
    controllerFirmwareVersion:
      'controllerFirmwareVersion100901234567890123456789012345678901234567890123456789012345678901234567890',
    gnssReceiverMainManufacturer:
      'gnssReceiverMainManufacturer100234567890123456789012345678901234567890123456789012345678901234567890',
    gnssReceiverMainModel:
      'gnssReceiverMainModel1005678901234567890123456789012345678901234567890123456789012345678901234567890',
    gnssReceiverMainFirmwareVersion:
      'gnssReceiverMainFirmwareVersion100567890123456789012345678901234567890123456789012345678901234567890',
    gnssReceiverSubManufacturer:
      'gnssReceiverSubManufacturer1001234567890123456789012345678901234567890123456789012345678901234567890',
    gnssReceiverSubModel:
      'gnssReceiverSubModel10045678901234567890123456789012345678901234567890123456789012345678901234567890',
    gnssReceiverSubFirmwareVersion:
      'gnssReceiverSubFirmwareVersion1004567890123456789012345678901234567890123456789012345678901234567890',
    gnssSettingId: '5b18983c-8cb8-380e-6944-e7cc5b580789',
    machineInfoCompanyName:
      'machineInfoCompanyName100678901234567890123456789012345678901234567890123456789012345678901234567890',
    machineInfoMachineType: 'ROADROLLER',
    machineInfoMachineName:
      '建機１００６７８９０１２３４５６７８９０１２３４５６７８９０１２３４５６７８９０１２３４５６７８９０１２３４５６７８９０１２３４５６７８９０１２３４５６７８９０１２３４５６７８９０１２３４５６７８９０',
    machineInfoMachineId:
      'machineInfoMachineId10045678901234567890123456789012345678901234567890123456789012345678901234567890',
    rentalStatus: 'Returned',
    lastUpdated: '2020-05-11T00:01:02.001Z',
    hasLicense: false,
    inSendingBackSupported: false,
    needsNtripDataReration: false,
  },
  {
    id: 123,
    uuid: 'abeda495396d267f752af888fa083a1e061ba27f56075bb9b9520de864181f1b',
    retrofitDistributorId: 1,
    corporationId: 'd087e444-ded2-de5e-087b-76f3ce864ae3',
    ownerCorporationId: 'd087e444-ded2-de5e-087b-76f3ce864ae3',
    siteId: '',
    corporationName: 'LANDLOG',
    description: '０１号機',
    macAddress: '',
    latitude: 0,
    longitude: 0,
    lastOnlineTime: '',
    isError: false,
    isOnline: false,
    basicInfoManufacturer: 'LANDLOG',
    basicInfoModel: 'SCRF00AT01',
    basicInfoProductNumber: 'LL-1001-00-00-0101',
    basicInfoSerialNumber: 'SERIAL_LA',
    controllerManufacturer: '',
    controllerModel: '',
    controllerFirmwareVersion: '',
    gnssReceiverMainManufacturer: '',
    gnssReceiverMainModel: '',
    gnssReceiverMainFirmwareVersion: '',
    gnssReceiverSubManufacturer: '',
    gnssReceiverSubModel: '',
    gnssReceiverSubFirmwareVersion: '',
    gnssSettingId: 'd940daca-e3ea-5bac-c58a-4a3771490f26',
    machineInfoCompanyName: '',
    machineInfoMachineType: '',
    machineInfoMachineName: '未登録０１',
    machineInfoMachineId: '',
    rentalStatus: 'Returned',
    lastUpdated: '2020-05-11T00:01:01.000Z',
    hasLicense: false,
    inSendingBackSupported: false,
    needsNtripDataReration: false,
  },
  {
    id: 124,
    uuid: '298f95939e4d43891b9b87e372ce0fe32b9907900bf5c84be3b0193a288907f7',
    retrofitDistributorId: 1,
    corporationId: 'd087e444-ded2-de5e-087b-76f3ce864ae3',
    ownerCorporationId: 'd087e444-ded2-de5e-087b-76f3ce864ae3',
    siteId: '',
    corporationName: 'LANDLOG',
    description: '０２号機',
    macAddress: '',
    latitude: 0,
    longitude: 0,
    lastOnlineTime: '',
    isError: false,
    isOnline: false,
    basicInfoManufacturer: 'LANDLOG',
    basicInfoModel: 'SCRF00AT01',
    basicInfoProductNumber: 'LL-1001-00-00-0101',
    basicInfoSerialNumber: 'Retro-48A49349116A',
    controllerManufacturer: '',
    controllerModel: '',
    controllerFirmwareVersion: '',
    gnssReceiverMainManufacturer: '',
    gnssReceiverMainModel: '',
    gnssReceiverMainFirmwareVersion: '',
    gnssReceiverSubManufacturer: '',
    gnssReceiverSubModel: '',
    gnssReceiverSubFirmwareVersion: '',
    gnssSettingId: '9b648044-25e8-e8d9-1941-582dff445038',
    machineInfoCompanyName: '',
    machineInfoMachineType: '',
    machineInfoMachineName: '未登録０２',
    machineInfoMachineId: '',
    rentalStatus: 'Returned',
    lastUpdated: '2020-05-11T00:01:02.001Z',
    hasLicense: false,
    inSendingBackSupported: false,
    needsNtripDataReration: false,
  },
  {
    id: 125,
    uuid: '81f8726d84dbe053c0ff17e0c68ce62dcc7a0a8af6f8d8862a78bee092d6f4ee',
    retrofitDistributorId: 1,
    corporationId: 'd087e444-ded2-de5e-087b-76f3ce864ae3',
    ownerCorporationId: 'd087e444-ded2-de5e-087b-76f3ce864ae3',
    siteId: '',
    corporationName: 'LANDLOG',
    description: '０３号機',
    macAddress: '',
    latitude: 0,
    longitude: 0,
    lastOnlineTime: '',
    isError: false,
    isOnline: false,
    basicInfoManufacturer: 'LANDLOG',
    basicInfoModel: 'SCRF00AT01',
    basicInfoProductNumber: 'LL-1001-00-00-0101',
    basicInfoSerialNumber: 'Retro-48A49349119E',
    controllerManufacturer: '',
    controllerModel: '',
    controllerFirmwareVersion: '',
    gnssReceiverMainManufacturer: '',
    gnssReceiverMainModel: '',
    gnssReceiverMainFirmwareVersion: '',
    gnssReceiverSubManufacturer: '',
    gnssReceiverSubModel: '',
    gnssReceiverSubFirmwareVersion: '',
    gnssSettingId: 'a96a4a6b-76ed-b829-4190-edcd6699e9ee',
    machineInfoCompanyName: '',
    machineInfoMachineType: '',
    machineInfoMachineName: '未登録０３',
    machineInfoMachineId: '',
    rentalStatus: 'Returned',
    lastUpdated: '2020-05-11T00:01:03.001Z',
    hasLicense: false,
    inSendingBackSupported: false,
    needsNtripDataReration: false,
  },
  {
    id: 126,
    uuid: '879b3f0ac5cd201210e7bf0dc2698f70e37e154181190e1c40aed797782e702d',
    retrofitDistributorId: 1,
    corporationId: 'd087e444-ded2-de5e-087b-76f3ce864ae3',
    ownerCorporationId: 'd087e444-ded2-de5e-087b-76f3ce864ae3',
    siteId: '',
    corporationName: 'LANDLOG',
    description: '０４号機',
    macAddress: '',
    latitude: 0,
    longitude: 0,
    lastOnlineTime: '',
    isError: false,
    isOnline: false,
    basicInfoManufacturer: 'LANDLOG',
    basicInfoModel: 'SCRF00AT01',
    basicInfoProductNumber: 'LL-1001-00-00-0101',
    basicInfoSerialNumber: 'Retro-48A49349119C',
    controllerManufacturer: '',
    controllerModel: '',
    controllerFirmwareVersion: '',
    gnssReceiverMainManufacturer: '',
    gnssReceiverMainModel: '',
    gnssReceiverMainFirmwareVersion: '',
    gnssReceiverSubManufacturer: '',
    gnssReceiverSubModel: '',
    gnssReceiverSubFirmwareVersion: '',
    gnssSettingId: '245a4261-9a57-7499-f29a-9a518649286a',
    machineInfoCompanyName: '',
    machineInfoMachineType: '',
    machineInfoMachineName: '未登録０４',
    machineInfoMachineId: '',
    rentalStatus: 'Returned',
    lastUpdated: '2020-05-11T00:01:04.001Z',
    hasLicense: false,
    inSendingBackSupported: false,
    needsNtripDataReration: false,
  },
  {
    id: 127,
    uuid: '42c86e787bc47817dc74c50dafa85b63bca847ef23708da1f26dab504ffcca75',
    retrofitDistributorId: 1,
    corporationId: 'd087e444-ded2-de5e-087b-76f3ce864ae3',
    ownerCorporationId: 'd087e444-ded2-de5e-087b-76f3ce864ae3',
    siteId: '',
    corporationName: 'LANDLOG',
    description: '０５号機',
    macAddress: '',
    latitude: 0,
    longitude: 0,
    lastOnlineTime: '',
    isError: false,
    isOnline: false,
    basicInfoManufacturer: 'LANDLOG',
    basicInfoModel: 'SCRF00AT01',
    basicInfoProductNumber: 'LL-1001-00-00-0101',
    basicInfoSerialNumber: 'Retro-48A493491192',
    controllerManufacturer: '',
    controllerModel: '',
    controllerFirmwareVersion: '',
    gnssReceiverMainManufacturer: '',
    gnssReceiverMainModel: '',
    gnssReceiverMainFirmwareVersion: '',
    gnssReceiverSubManufacturer: '',
    gnssReceiverSubModel: '',
    gnssReceiverSubFirmwareVersion: '',
    gnssSettingId: 'd4a7f663-4244-0da2-6a1b-cbadbb053674',
    machineInfoCompanyName: '',
    machineInfoMachineType: '',
    machineInfoMachineName: '未登録０５',
    machineInfoMachineId: '',
    rentalStatus: 'Returned',
    lastUpdated: '2020-05-11T00:01:05.002Z',
    hasLicense: false,
    inSendingBackSupported: false,
    needsNtripDataReration: false,
  },
  {
    id: 128,
    uuid: 'ee191eacc111534c8098fce0f00a02c6cf91adff11b08e8ccf26bb2ec11c10ef',
    retrofitDistributorId: 1,
    corporationId: 'd087e444-ded2-de5e-087b-76f3ce864ae3',
    ownerCorporationId: 'd087e444-ded2-de5e-087b-76f3ce864ae3',
    siteId: '',
    corporationName: 'LANDLOG',
    description: '０６号機',
    macAddress: '',
    latitude: 0,
    longitude: 0,
    lastOnlineTime: '',
    isError: false,
    isOnline: false,
    basicInfoManufacturer: 'LANDLOG',
    basicInfoModel: 'SCRF00AT01',
    basicInfoProductNumber: 'LL-1001-00-00-0101',
    basicInfoSerialNumber: 'Retro-48A493491162',
    controllerManufacturer: '',
    controllerModel: '',
    controllerFirmwareVersion: '',
    gnssReceiverMainManufacturer: '',
    gnssReceiverMainModel: '',
    gnssReceiverMainFirmwareVersion: '',
    gnssReceiverSubManufacturer: '',
    gnssReceiverSubModel: '',
    gnssReceiverSubFirmwareVersion: '',
    gnssSettingId: 'f1dcabaf-064c-23d0-ac2e-8bb4b4416e55',
    machineInfoCompanyName: '',
    machineInfoMachineType: '',
    machineInfoMachineName: '未登録０６',
    machineInfoMachineId: '',
    rentalStatus: 'Returned',
    lastUpdated: '2020-05-11T00:01:06.002Z',
    hasLicense: false,
    inSendingBackSupported: false,
    needsNtripDataReration: false,
  },
  {
    id: 129,
    uuid: '5ea0fbb055a475daa05f3d489dbe39f9881b1fce7cf8ffdd6a467948f9e398e9',
    retrofitDistributorId: 1,
    corporationId: 'd087e444-ded2-de5e-087b-76f3ce864ae3',
    ownerCorporationId: 'd087e444-ded2-de5e-087b-76f3ce864ae3',
    siteId: '',
    corporationName: 'LANDLOG',
    description: '０７号機',
    macAddress: '',
    latitude: 0,
    longitude: 0,
    lastOnlineTime: '',
    isError: false,
    isOnline: false,
    basicInfoManufacturer: 'LANDLOG',
    basicInfoModel: 'SCRF00AT01',
    basicInfoProductNumber: 'LL-1001-00-00-0101',
    basicInfoSerialNumber: 'Retro-48A493491182',
    controllerManufacturer: '',
    controllerModel: '',
    controllerFirmwareVersion: '',
    gnssReceiverMainManufacturer: '',
    gnssReceiverMainModel: '',
    gnssReceiverMainFirmwareVersion: '',
    gnssReceiverSubManufacturer: '',
    gnssReceiverSubModel: '',
    gnssReceiverSubFirmwareVersion: '',
    gnssSettingId: 'efa13ee2-92f4-11e3-015e-4ffe70e8479f',
    machineInfoCompanyName: '',
    machineInfoMachineType: '',
    machineInfoMachineName: '未登録０７',
    machineInfoMachineId: '',
    rentalStatus: 'Returned',
    lastUpdated: '2020-05-11T00:01:07.002Z',
    hasLicense: false,
    inSendingBackSupported: false,
    needsNtripDataReration: false,
  },
  {
    id: 130,
    uuid: 'e131b477772276f513fcfd01704d5e63f76d2700a84b774de4592e96914ff3f4',
    retrofitDistributorId: 1,
    corporationId: 'd087e444-ded2-de5e-087b-76f3ce864ae3',
    ownerCorporationId: 'd087e444-ded2-de5e-087b-76f3ce864ae3',
    siteId: '',
    corporationName: 'LANDLOG',
    description: '０８号機',
    macAddress: '',
    latitude: 0,
    longitude: 0,
    lastOnlineTime: '',
    isError: false,
    isOnline: false,
    basicInfoManufacturer: 'LANDLOG',
    basicInfoModel: 'SCRF00AT01',
    basicInfoProductNumber: 'LL-1001-00-00-0101',
    basicInfoSerialNumber: 'Retro-48A4934916CC',
    controllerManufacturer: '',
    controllerModel: '',
    controllerFirmwareVersion: '',
    gnssReceiverMainManufacturer: '',
    gnssReceiverMainModel: '',
    gnssReceiverMainFirmwareVersion: '',
    gnssReceiverSubManufacturer: '',
    gnssReceiverSubModel: '',
    gnssReceiverSubFirmwareVersion: '',
    gnssSettingId: '1a134bc0-956e-fda2-3ca6-f5ed8bf50807',
    machineInfoCompanyName: '',
    machineInfoMachineType: '',
    machineInfoMachineName: '未登録０８',
    machineInfoMachineId: '',
    rentalStatus: 'Returned',
    lastUpdated: '2020-05-11T00:01:08.003Z',
    hasLicense: false,
    inSendingBackSupported: false,
    needsNtripDataReration: false,
  },
  {
    id: 131,
    uuid: '8f27a6cfc0f9584be73531a005250dc71ea707e7973082dab1bbf678db32a104',
    retrofitDistributorId: 1,
    corporationId: 'd087e444-ded2-de5e-087b-76f3ce864ae3',
    ownerCorporationId: 'd087e444-ded2-de5e-087b-76f3ce864ae3',
    siteId: '',
    corporationName: 'LANDLOG',
    description: '０９号機',
    macAddress: '',
    latitude: 0,
    longitude: 0,
    lastOnlineTime: '',
    isError: false,
    isOnline: false,
    basicInfoManufacturer: 'LANDLOG',
    basicInfoModel: 'SCRF00AT01',
    basicInfoProductNumber: 'LL-1001-00-00-0101',
    basicInfoSerialNumber: 'Retro-48A4934916CA',
    controllerManufacturer: '',
    controllerModel: '',
    controllerFirmwareVersion: '',
    gnssReceiverMainManufacturer: '',
    gnssReceiverMainModel: '',
    gnssReceiverMainFirmwareVersion: '',
    gnssReceiverSubManufacturer: '',
    gnssReceiverSubModel: '',
    gnssReceiverSubFirmwareVersion: '',
    gnssSettingId: 'd5c4ad2f-cd3a-fc91-e380-4a0b79e92fcd',
    machineInfoCompanyName: '',
    machineInfoMachineType: '',
    machineInfoMachineName: '未登録０９',
    machineInfoMachineId: '',
    rentalStatus: 'Returned',
    lastUpdated: '2020-05-11T00:01:09.003Z',
    hasLicense: false,
    inSendingBackSupported: false,
    needsNtripDataReration: false,
  },
  {
    id: 132,
    uuid: 'd802d9f670f8b844f561abd5b33ad66e4ac0a231408b100a0c9ed1dab4d066b3',
    retrofitDistributorId: 1,
    corporationId: 'd087e444-ded2-de5e-087b-76f3ce864ae3',
    ownerCorporationId: 'd087e444-ded2-de5e-087b-76f3ce864ae3',
    siteId: '',
    corporationName: 'LANDLOG',
    description: '１０号機',
    macAddress: '',
    latitude: 0,
    longitude: 0,
    lastOnlineTime: '',
    isError: false,
    isOnline: false,
    basicInfoManufacturer: 'LANDLOG',
    basicInfoModel: 'SCRF00AT01',
    basicInfoProductNumber: 'LL-1001-00-00-0101',
    basicInfoSerialNumber: 'Retro-48A4934916C6',
    controllerManufacturer: '',
    controllerModel: '',
    controllerFirmwareVersion: '',
    gnssReceiverMainManufacturer: '',
    gnssReceiverMainModel: '',
    gnssReceiverMainFirmwareVersion: '',
    gnssReceiverSubManufacturer: '',
    gnssReceiverSubModel: '',
    gnssReceiverSubFirmwareVersion: '',
    gnssSettingId: '88d68069-bfd1-ba98-fc8a-4d6a8087a319',
    machineInfoCompanyName: '',
    machineInfoMachineType: '',
    machineInfoMachineName: '未登録１０',
    machineInfoMachineId: '',
    rentalStatus: 'Returned',
    lastUpdated: '2020-05-11T00:01:10.003Z',
    hasLicense: false,
    inSendingBackSupported: false,
    needsNtripDataReration: false,
  },
  {
    id: 133,
    uuid: 'd406d96ce7046538556732c17bb9715423f4674881ad85e5d8f5ba6a8298a434',
    retrofitDistributorId: 1,
    corporationId: 'd087e444-ded2-de5e-087b-76f3ce864ae3',
    ownerCorporationId: 'd087e444-ded2-de5e-087b-76f3ce864ae3',
    siteId: '',
    corporationName: 'LANDLOG',
    description: '１１号機',
    macAddress: '',
    latitude: 0,
    longitude: 0,
    lastOnlineTime: '',
    isError: false,
    isOnline: false,
    basicInfoManufacturer: 'LANDLOG',
    basicInfoModel: 'SCRF00AT01',
    basicInfoProductNumber: 'LL-1001-00-00-0101',
    basicInfoSerialNumber: 'Retro-48A4934916C0',
    controllerManufacturer: '',
    controllerModel: '',
    controllerFirmwareVersion: '',
    gnssReceiverMainManufacturer: '',
    gnssReceiverMainModel: '',
    gnssReceiverMainFirmwareVersion: '',
    gnssReceiverSubManufacturer: '',
    gnssReceiverSubModel: '',
    gnssReceiverSubFirmwareVersion: '',
    gnssSettingId: '5d8bf094-1c29-16e5-ab71-c55daaca8555',
    machineInfoCompanyName: '',
    machineInfoMachineType: '',
    machineInfoMachineName: '未登録１１',
    machineInfoMachineId: '',
    rentalStatus: 'Returned',
    lastUpdated: '2020-05-11T00:01:01.000Z',
    hasLicense: false,
    inSendingBackSupported: false,
    needsNtripDataReration: false,
  },
  {
    id: 134,
    uuid: 'a6808281f2302b0744da392cb1f69b85a01cd67a0bf678e00e1c747f581a3776',
    retrofitDistributorId: 1,
    corporationId: 'd087e444-ded2-de5e-087b-76f3ce864ae3',
    ownerCorporationId: 'd087e444-ded2-de5e-087b-76f3ce864ae3',
    siteId: '',
    corporationName: 'LANDLOG',
    description: '１２号機',
    macAddress: '',
    latitude: 0,
    longitude: 0,
    lastOnlineTime: '',
    isError: false,
    isOnline: false,
    basicInfoManufacturer: 'LANDLOG',
    basicInfoModel: 'SCRF00AT01',
    basicInfoProductNumber: 'LL-1001-00-00-0101',
    basicInfoSerialNumber: 'Retro-48A493491180',
    controllerManufacturer: '',
    controllerModel: '',
    controllerFirmwareVersion: '',
    gnssReceiverMainManufacturer: '',
    gnssReceiverMainModel: '',
    gnssReceiverMainFirmwareVersion: '',
    gnssReceiverSubManufacturer: '',
    gnssReceiverSubModel: '',
    gnssReceiverSubFirmwareVersion: '',
    gnssSettingId: '8c8f2f59-dc1c-90f8-8fd5-43211b453517',
    machineInfoCompanyName: '',
    machineInfoMachineType: '',
    machineInfoMachineName: '未登録１２',
    machineInfoMachineId: '',
    rentalStatus: 'Returned',
    lastUpdated: '2020-05-11T00:01:02.001Z',
    hasLicense: false,
    inSendingBackSupported: false,
    needsNtripDataReration: false,
  },
  {
    id: 135,
    uuid: '842b068aaadf540d19144086b9270f2e165ad804cd70e8eaee247fd5e008a3ba',
    retrofitDistributorId: 1,
    corporationId: 'd087e444-ded2-de5e-087b-76f3ce864ae3',
    ownerCorporationId: 'd087e444-ded2-de5e-087b-76f3ce864ae3',
    siteId: '',
    corporationName: 'LANDLOG',
    description: '１３号機',
    macAddress: '',
    latitude: 0,
    longitude: 0,
    lastOnlineTime: '',
    isError: false,
    isOnline: false,
    basicInfoManufacturer: 'LANDLOG',
    basicInfoModel: 'SCRF00AT01',
    basicInfoProductNumber: 'LL-1001-00-00-0101',
    basicInfoSerialNumber: 'Retro-48A493491164',
    controllerManufacturer: '',
    controllerModel: '',
    controllerFirmwareVersion: '',
    gnssReceiverMainManufacturer: '',
    gnssReceiverMainModel: '',
    gnssReceiverMainFirmwareVersion: '',
    gnssReceiverSubManufacturer: '',
    gnssReceiverSubModel: '',
    gnssReceiverSubFirmwareVersion: '',
    gnssSettingId: 'ad34e155-9791-dc19-ceed-07cfc79cd522',
    machineInfoCompanyName: '',
    machineInfoMachineType: '',
    machineInfoMachineName: '未登録１３',
    machineInfoMachineId: '',
    rentalStatus: 'Returned',
    lastUpdated: '2020-05-11T00:01:03.001Z',
    hasLicense: false,
    inSendingBackSupported: false,
    needsNtripDataReration: false,
  },
  {
    id: 136,
    uuid: 'f533369ca4e2150d64e4496e8a45784bb21094e0bd908de9b1fef64558d6ff7c',
    retrofitDistributorId: 1,
    corporationId: 'd087e444-ded2-de5e-087b-76f3ce864ae3',
    ownerCorporationId: 'd087e444-ded2-de5e-087b-76f3ce864ae3',
    siteId: '',
    corporationName: 'LANDLOG',
    description: '１４号機',
    macAddress: '',
    latitude: 0,
    longitude: 0,
    lastOnlineTime: '',
    isError: false,
    isOnline: false,
    basicInfoManufacturer: 'LANDLOG',
    basicInfoModel: 'SCRF00AT01',
    basicInfoProductNumber: 'LL-1001-00-00-0101',
    basicInfoSerialNumber: 'Retro-48A493492A3E',
    controllerManufacturer: '',
    controllerModel: '',
    controllerFirmwareVersion: '',
    gnssReceiverMainManufacturer: '',
    gnssReceiverMainModel: '',
    gnssReceiverMainFirmwareVersion: '',
    gnssReceiverSubManufacturer: '',
    gnssReceiverSubModel: '',
    gnssReceiverSubFirmwareVersion: '',
    gnssSettingId: 'c97a77d2-e4a1-875b-d1f7-18cccca7760b',
    machineInfoCompanyName: '',
    machineInfoMachineType: '',
    machineInfoMachineName: '未登録１４',
    machineInfoMachineId: '',
    rentalStatus: 'Returned',
    lastUpdated: '2020-05-11T00:01:04.001Z',
    hasLicense: false,
    inSendingBackSupported: false,
    needsNtripDataReration: false,
  },
  {
    id: 137,
    uuid: '80d2395ec773083a084b996c0142e4ad69c2258139f109d7ab92816d8756c4f9',
    retrofitDistributorId: 1,
    corporationId: 'd087e444-ded2-de5e-087b-76f3ce864ae3',
    ownerCorporationId: 'd087e444-ded2-de5e-087b-76f3ce864ae3',
    siteId: '',
    corporationName: 'LANDLOG',
    description: '１５号機',
    macAddress: '',
    latitude: 0,
    longitude: 0,
    lastOnlineTime: '',
    isError: false,
    isOnline: false,
    basicInfoManufacturer: 'LANDLOG',
    basicInfoModel: 'SCRF00AT01',
    basicInfoProductNumber: 'LL-1001-00-00-0101',
    basicInfoSerialNumber: 'Retro-48A493491176',
    controllerManufacturer: '',
    controllerModel: '',
    controllerFirmwareVersion: '',
    gnssReceiverMainManufacturer: '',
    gnssReceiverMainModel: '',
    gnssReceiverMainFirmwareVersion: '',
    gnssReceiverSubManufacturer: '',
    gnssReceiverSubModel: '',
    gnssReceiverSubFirmwareVersion: '',
    gnssSettingId: '9de4169f-f5fd-e037-8e07-81b2690e85e8',
    machineInfoCompanyName: '',
    machineInfoMachineType: '',
    machineInfoMachineName: '未登録１５',
    machineInfoMachineId: '',
    rentalStatus: 'Returned',
    lastUpdated: '2020-05-11T00:01:05.002Z',
    hasLicense: false,
    inSendingBackSupported: false,
    needsNtripDataReration: false,
  },
  {
    id: 138,
    uuid: '9ea337c9819a76731bd4715638ea00e8e07317e50e85126bae5cc33123b62f9e',
    retrofitDistributorId: 1,
    corporationId: 'd087e444-ded2-de5e-087b-76f3ce864ae3',
    ownerCorporationId: 'd087e444-ded2-de5e-087b-76f3ce864ae3',
    siteId: '',
    corporationName: 'LANDLOG',
    description: '１６号機',
    macAddress: '',
    latitude: 0,
    longitude: 0,
    lastOnlineTime: '',
    isError: false,
    isOnline: false,
    basicInfoManufacturer: 'LANDLOG',
    basicInfoModel: 'SCRF00AT01',
    basicInfoProductNumber: 'LL-1001-00-00-0101',
    basicInfoSerialNumber: 'Retro-48A493491178',
    controllerManufacturer: '',
    controllerModel: '',
    controllerFirmwareVersion: '',
    gnssReceiverMainManufacturer: '',
    gnssReceiverMainModel: '',
    gnssReceiverMainFirmwareVersion: '',
    gnssReceiverSubManufacturer: '',
    gnssReceiverSubModel: '',
    gnssReceiverSubFirmwareVersion: '',
    gnssSettingId: 'f29f0e91-01a8-7707-c07c-c25b75a25f0f',
    machineInfoCompanyName: '',
    machineInfoMachineType: '',
    machineInfoMachineName: '未登録１６',
    machineInfoMachineId: '',
    rentalStatus: 'Returned',
    lastUpdated: '2020-05-11T00:01:06.002Z',
    hasLicense: false,
    inSendingBackSupported: false,
    needsNtripDataReration: false,
  },
  {
    id: 139,
    uuid: 'aeaf2d7fdd57f46a827745d1c5a1a480f3ae713a678d226956ae436f10f8d4b4',
    retrofitDistributorId: 1,
    corporationId: 'd087e444-ded2-de5e-087b-76f3ce864ae3',
    ownerCorporationId: 'd087e444-ded2-de5e-087b-76f3ce864ae3',
    siteId: '',
    corporationName: 'LANDLOG',
    description: '１７号機',
    macAddress: '',
    latitude: 0,
    longitude: 0,
    lastOnlineTime: '',
    isError: false,
    isOnline: false,
    basicInfoManufacturer: 'LANDLOG',
    basicInfoModel: 'SCRF00AT01',
    basicInfoProductNumber: 'LL-1001-00-00-0101',
    basicInfoSerialNumber: 'Retro-48A49349117E',
    controllerManufacturer: '',
    controllerModel: '',
    controllerFirmwareVersion: '',
    gnssReceiverMainManufacturer: '',
    gnssReceiverMainModel: '',
    gnssReceiverMainFirmwareVersion: '',
    gnssReceiverSubManufacturer: '',
    gnssReceiverSubModel: '',
    gnssReceiverSubFirmwareVersion: '',
    gnssSettingId: '5002d76f-3ddf-ac72-8665-c647f2e0f9bd',
    machineInfoCompanyName: '',
    machineInfoMachineType: '',
    machineInfoMachineName: '未登録１７',
    machineInfoMachineId: '',
    rentalStatus: 'Returned',
    lastUpdated: '2020-05-11T00:01:07.002Z',
    hasLicense: false,
    inSendingBackSupported: false,
    needsNtripDataReration: false,
  },
  {
    id: 140,
    uuid: '37352c6bbdec0103130cafc2dcbb146d5ed39b7e78237f4b648bddac66e8ba74',
    retrofitDistributorId: 1,
    corporationId: 'd087e444-ded2-de5e-087b-76f3ce864ae3',
    ownerCorporationId: 'd087e444-ded2-de5e-087b-76f3ce864ae3',
    siteId: '',
    corporationName: 'LANDLOG',
    description: '１８号機',
    macAddress: '',
    latitude: 0,
    longitude: 0,
    lastOnlineTime: '',
    isError: false,
    isOnline: false,
    basicInfoManufacturer: 'LANDLOG',
    basicInfoModel: 'SCRF00AT01',
    basicInfoProductNumber: 'LL-1001-00-00-0101',
    basicInfoSerialNumber: 'Retro-48A49349118A',
    controllerManufacturer: '',
    controllerModel: '',
    controllerFirmwareVersion: '',
    gnssReceiverMainManufacturer: '',
    gnssReceiverMainModel: '',
    gnssReceiverMainFirmwareVersion: '',
    gnssReceiverSubManufacturer: '',
    gnssReceiverSubModel: '',
    gnssReceiverSubFirmwareVersion: '',
    gnssSettingId: '61c623cf-fb07-f671-75cc-f5c92649b9c7',
    machineInfoCompanyName: '',
    machineInfoMachineType: '',
    machineInfoMachineName: '未登録１８',
    machineInfoMachineId: '',
    rentalStatus: 'Returned',
    lastUpdated: '2020-05-11T00:01:08.003Z',
    hasLicense: false,
    inSendingBackSupported: false,
    needsNtripDataReration: false,
  },
  {
    id: 141,
    uuid: '955c25346730dd849e01e25efe7ffdda2991fbc76ed72b4f8057103a2c00de4d',
    retrofitDistributorId: 1,
    corporationId: 'd087e444-ded2-de5e-087b-76f3ce864ae3',
    ownerCorporationId: 'd087e444-ded2-de5e-087b-76f3ce864ae3',
    siteId: '',
    corporationName: 'LANDLOG',
    description: '１９号機',
    macAddress: '',
    latitude: 0,
    longitude: 0,
    lastOnlineTime: '',
    isError: false,
    isOnline: false,
    basicInfoManufacturer: 'LANDLOG',
    basicInfoModel: 'SCRF00AT01',
    basicInfoProductNumber: 'LL-1001-00-00-0101',
    basicInfoSerialNumber: 'Retro-48A493491188',
    controllerManufacturer: '',
    controllerModel: '',
    controllerFirmwareVersion: '',
    gnssReceiverMainManufacturer: '',
    gnssReceiverMainModel: '',
    gnssReceiverMainFirmwareVersion: '',
    gnssReceiverSubManufacturer: '',
    gnssReceiverSubModel: '',
    gnssReceiverSubFirmwareVersion: '',
    gnssSettingId: '4928d275-508f-2c43-9c82-b22e09e73658',
    machineInfoCompanyName: '',
    machineInfoMachineType: '',
    machineInfoMachineName: '未登録１９',
    machineInfoMachineId: '',
    rentalStatus: 'Returned',
    lastUpdated: '2020-05-11T00:01:09.003Z',
    hasLicense: false,
    inSendingBackSupported: false,
    needsNtripDataReration: false,
  },
  {
    id: 142,
    uuid: '8bb9c475b2998cdb839e1e0ebeaf5d6b03ebc463125204f1bedb3e2df170aeba',
    retrofitDistributorId: 1,
    corporationId: 'd087e444-ded2-de5e-087b-76f3ce864ae3',
    ownerCorporationId: 'd087e444-ded2-de5e-087b-76f3ce864ae3',
    siteId: '',
    corporationName: 'LANDLOG',
    description: '２０号機',
    macAddress: '',
    latitude: 0,
    longitude: 0,
    lastOnlineTime: '',
    isError: false,
    isOnline: false,
    basicInfoManufacturer: 'LANDLOG',
    basicInfoModel: 'SCRF00AT01',
    basicInfoProductNumber: 'LL-1001-00-00-0101',
    basicInfoSerialNumber: 'Retro-48A49349117C',
    controllerManufacturer: '',
    controllerModel: '',
    controllerFirmwareVersion: '',
    gnssReceiverMainManufacturer: '',
    gnssReceiverMainModel: '',
    gnssReceiverMainFirmwareVersion: '',
    gnssReceiverSubManufacturer: '',
    gnssReceiverSubModel: '',
    gnssReceiverSubFirmwareVersion: '',
    gnssSettingId: 'b658b49c-2a05-5242-f7cc-be65f9542200',
    machineInfoCompanyName: '',
    machineInfoMachineType: '',
    machineInfoMachineName: '未登録２０',
    machineInfoMachineId: '',
    rentalStatus: 'Returned',
    lastUpdated: '2020-05-11T00:01:10.003Z',
    hasLicense: false,
    inSendingBackSupported: false,
    needsNtripDataReration: false,
  },
  {
    id: 143,
    uuid: 'e300e4cb166d22687dc577712c728bfa193fcae5c8fdb97b067826a6f8f1d61c',
    retrofitDistributorId: 1,
    corporationId: 'd087e444-ded2-de5e-087b-76f3ce864ae3',
    ownerCorporationId: 'd087e444-ded2-de5e-087b-76f3ce864ae3',
    siteId: 'test___',
    corporationName: 'LANDLOG',
    description: '２１号機',
    macAddress: '',
    latitude: 0,
    longitude: 0,
    lastOnlineTime: '',
    isError: false,
    isOnline: false,
    basicInfoManufacturer: 'LANDLOG',
    basicInfoModel: 'SCRF00AT01',
    basicInfoProductNumber: 'LL-1001-00-00-0101',
    basicInfoSerialNumber: 'Retro-48A493491166',
    controllerManufacturer: '',
    controllerModel: '',
    controllerFirmwareVersion: '',
    gnssReceiverMainManufacturer: '',
    gnssReceiverMainModel: '',
    gnssReceiverMainFirmwareVersion: '',
    gnssReceiverSubManufacturer: '',
    gnssReceiverSubModel: '',
    gnssReceiverSubFirmwareVersion: '',
    gnssSettingId: '506284f2-04c5-4186-26b4-979c999f29b2',
    machineInfoCompanyName: '',
    machineInfoMachineType: '',
    machineInfoMachineName: '未登録２１',
    machineInfoMachineId: '',
    rentalStatus: 'Returned',
    lastUpdated: '2020-05-11T00:01:01.000Z',
    hasLicense: false,
    inSendingBackSupported: false,
    needsNtripDataReration: false,
  },
  {
    id: 144,
    uuid: 'e300e4cb166d22687dc577712c728bfa193fcae5c8fdb97b067826a6f8f1d61c',
    retrofitDistributorId: 1,
    corporationId: 'd087e444-ded2-de5e-087b-76f3ce864ae3',
    ownerCorporationId: 'd087e444-ded2-de5e-087b-76f3ce864ae3',
    siteId: '',
    corporationName: 'LANDLOG',
    description: '２１号機',
    macAddress: '',
    latitude: 0,
    longitude: 0,
    lastOnlineTime: '',
    isError: false,
    isOnline: false,
    basicInfoManufacturer: 'LANDLOG',
    basicInfoModel: 'SCRF00AT01',
    basicInfoProductNumber: 'LL-1001-00-00-0101',
    basicInfoSerialNumber: 'Retro-48A493491166',
    controllerManufacturer: '',
    controllerModel: '',
    controllerFirmwareVersion: '',
    gnssReceiverMainManufacturer: '',
    gnssReceiverMainModel: '',
    gnssReceiverMainFirmwareVersion: '',
    gnssReceiverSubManufacturer: '',
    gnssReceiverSubModel: '',
    gnssReceiverSubFirmwareVersion: '',
    gnssSettingId: '506284f2-04c5-4186-26b4-979c999f29b2',
    machineInfoCompanyName: '',
    machineInfoMachineType: '',
    machineInfoMachineName: '未登録２１',
    machineInfoMachineId: '',
    rentalStatus: 'Returned',
    lastUpdated: '2020-05-11T00:01:01.000Z',
    hasLicense: false,
    inSendingBackSupported: false,
    needsNtripDataReration: false,
  },
  {
    id: 145,
    uuid: 'e300e4cb166d22687dc577712c728bfa193fcae5c8fdb97b067826a6f8f1d61c',
    retrofitDistributorId: 1,
    corporationId: 'd087e444-ded2-de5e-087b-76f3ce864ae3',
    ownerCorporationId: 'd087e444-ded2-de5e-087b-76f3ce864ae3',
    siteId: '',
    corporationName: 'LANDLOG',
    description: '２１号機',
    macAddress: '',
    latitude: 0,
    longitude: 0,
    lastOnlineTime: '',
    isError: false,
    isOnline: false,
    basicInfoManufacturer: 'LANDLOG',
    basicInfoModel: 'SCRF00AT01',
    basicInfoProductNumber: 'LL-1001-00-00-0101',
    basicInfoSerialNumber: 'Retro-48A493491166',
    controllerManufacturer: '',
    controllerModel: '',
    controllerFirmwareVersion: '',
    gnssReceiverMainManufacturer: '',
    gnssReceiverMainModel: '',
    gnssReceiverMainFirmwareVersion: '',
    gnssReceiverSubManufacturer: '',
    gnssReceiverSubModel: '',
    gnssReceiverSubFirmwareVersion: '',
    gnssSettingId: '506284f2-04c5-4186-26b4-979c999f29b2',
    machineInfoCompanyName: '',
    machineInfoMachineType: '',
    machineInfoMachineName: '未登録２１',
    machineInfoMachineId: '',
    rentalStatus: 'Returned',
    lastUpdated: '2020-05-11T00:01:01.000Z',
    hasLicense: false,
    inSendingBackSupported: false,
    needsNtripDataReration: false,
  },
];
export default retrofits;
