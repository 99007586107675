import React, { FC, useMemo } from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { Box } from '@material-ui/core';

import { Labeled, useGetManyReference } from 'react-admin';
import { CustomDateField, LicenseIconField, SimpleShowLayout } from '..';
import { objectToList, licenseToConvertElement } from '../../utils';
import {
  RetrofitCorporationLicenseData,
  RetrofitData,
} from '../../dataProvider';

const useStyles = makeStyles(() =>
  createStyles({
    labeled: {
      alignItems: 'start',
      '& > div > label': {
        marginTop: 12,
      },
    },
    licenseRow: {
      display: 'grid',
      gridTemplateColumns: 'auto 1fr',
    },
    colIcon: {
      gridColumn: '1 / 2',
    },
    colDate: {
      gridColumn: '2 / 3',
      marginTop: 8,
      marginLeft: 5,
      '& .MuiFormLabel-root': {
        display: 'none',
      },
    },
    component: {
      display: 'flex',
      alignItems: 'center',
    },
  }),
);

interface Props {
  resource: string;
  record: RetrofitData;
  addLabel: boolean;
  label: string;
}

const RetrofitLicenseShowLayout: FC<Partial<Props>> = props => {
  const classes = useStyles();
  const { resource, record } = props;
  if (!record) throw Error('Invalid record');

  const { id } = record;

  const { data } = useGetManyReference(
    'retrofitCorporationLicenses',
    'retrofitId',
    id,
    {
      page: 1,
      perPage: 3, // 現状の個別ライセンス種別最大3種類
    },
    { field: 'id', order: 'ASC' },
    { enable: true },
    'retrofits',
  );

  const elements = useMemo(() => {
    const licenseList = objectToList(data) as RetrofitCorporationLicenseData[];
    return licenseList.map(license => {
      return licenseToConvertElement(license);
    });
  }, [data]);

  return (
    <SimpleShowLayout {...props}>
      {elements && elements.length > 0 && elements[0] ? (
        <Labeled
          className={classes.labeled}
          label="admin.label.retrofitEdit.license"
        >
          <>
            {elements.map((element, index) => {
              const styleGridRow = `${index + 1} / ${index + 2}`;
              return (
                <Box
                  key={`license${index}`}
                  className={classes.licenseRow}
                  style={{ gridRow: styleGridRow }}
                >
                  <Box className={classes.colIcon}>
                    <LicenseIconField element={element} />
                  </Box>
                  <Box className={classes.colDate}>
                    <div className={classes.component}>
                      <CustomDateField
                        resource={resource}
                        record={{ id: 1, expiresAt: element?.expiresAt }}
                        source="expiresAt"
                        showTime
                      />
                    </div>
                  </Box>
                </Box>
              );
            })}
          </>
        </Labeled>
      ) : null}
    </SimpleShowLayout>
  );
};

RetrofitLicenseShowLayout.defaultProps = {
  addLabel: true,
};
RetrofitLicenseShowLayout.displayName = 'RetrofitLicenseShowLayout';
export default RetrofitLicenseShowLayout;
