import React from 'react';
import { AutocompleteInput } from '../../..';
import { RetrofitData, SiteData } from '../../../../dataProvider';
import { useResource } from '../../../../hooks';
import { getValidSiteStatus } from '../../../../utils';

interface SiteReferenceInputProps {
  record?: RetrofitData;
  userCorporationId?: string;
  rented?: boolean;
  unapproved?: boolean;
  adminDisabled?: boolean;
  transferd?: boolean;
}

const SiteReferenceInput: React.FC<SiteReferenceInputProps> = ({
  record,
  userCorporationId = '',
  rented = false,
  unapproved = false,
  adminDisabled = false,
  transferd = false,
}) => {
  const resource = useResource('retrofits');
  if (!record) return <></>;
  const { corporationId } = record;
  const unMatchCorporation = corporationId !== userCorporationId;
  // ＜非活性になる条件＞
  //   条件1: 貸与未承認のデータが1件でも存在する場合
  //   条件2: 貸与中のデータが1件でも存在する場合でかつ、
  //         レトロフィットの企業IDが所属している企業IDと一致しない場合
  //   条件3: 所属している企業が該当レトロフィットの管理企業でかつ、
  //         レトロフィットの企業IDが所属している企業IDと一致しない場合
  //   条件4: 譲渡未承認のデータが１件でも存在する場合
  const disabled =
    unapproved || (rented && unMatchCorporation) || adminDisabled || transferd;

  return (
    <AutocompleteInput
      className="sites"
      resource={resource}
      source="siteId"
      choiceResources="sites"
      choicesCreate={({ id, name }: SiteData) => ({ id, name })}
      filter={{ corporationId, status: getValidSiteStatus }}
      sort={{ field: 'name', order: 'ASC' }}
      autoCompleteOptions={{
        disabled,
      }}
      resettable={true}
      allowEmpty={false}
    />
  );
};

SiteReferenceInput.displayName = 'SiteReferenceInput';
export default SiteReferenceInput;
