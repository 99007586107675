import React, {
  useCallback,
  useState,
  cloneElement,
  isValidElement,
  useEffect,
} from 'react';
import { createStyles, Divider, makeStyles } from '@material-ui/core';
import { Link } from 'react-router-dom';
import MuiTabs from '@material-ui/core/Tabs';
import MuiTab from '@material-ui/core/Tab';
import {
  Loading,
  useDataProvider,
  useRedirect,
  useTranslate,
} from 'react-admin';
import {
  CustomDateField,
  FunctionField,
  GridLayout,
  Show,
  SimpleShowLayout,
  SiteBucketList,
  SiteRetrofitList,
  SiteProjectList,
  SiteAsbuiltList,
  SiteExtensionarmList,
  SiteGeofenceList,
  TextField,
  IconField,
  Button,
  TabData,
  TabContent,
} from '../..';
import { SiteData, UserConfigureData } from '../../../dataProvider/types';
import { config } from '../../../utils';
import { colors } from '../../../theme';
import { useCustomLocation, useGetSupportMode } from '../../../hooks';
import { buttonHeight, buttonDisabledOpacity } from '../../../theme/define';
import SiteConfiguresEdit from './configures/SiteConfiguresEdit';
import { SiteCompactionMasterIcon, SiteConfigureIcon } from '../../../assets';
import { useGetSiteConfigures, useGetSiteExtensionarms } from './hooks';

const optionalButtonWidth = 80;
const settingMargin = optionalButtonWidth + 8;

const useCompactionButtonStyles = makeStyles(() =>
  createStyles({
    root: {
      height: 0,
      textAlign: 'right',
      '& > a': {
        position: 'relative',
        marginRight: settingMargin,
      },
    },
    button: {
      fontSize: 14,
      backgroundColor: colors.button.cancel,
      minWidth: optionalButtonWidth,
      minHeight: buttonHeight,
      textTransform: 'none',
      paddingLeft: 0,
      '&:hover': {
        backgroundColor: colors.button.cancelHover,
      },
    },
  }),
);

const useConfigureEditButtonStyles = makeStyles(() =>
  createStyles({
    root: {
      height: 0,
      textAlign: 'right',
      '& > button': {
        position: 'relative',
      },
    },
    button: {
      backgroundColor: colors.button.cancel,
      minWidth: optionalButtonWidth,
      minHeight: buttonHeight,
      color: colors.text.primaryLight,
      fontSize: 14,
      textTransform: 'none',
      '&:hover': {
        backgroundColor: colors.button.cancelHover,
      },
      '&.Mui-disabled': {
        backgroundColor: colors.button.cancelDisabled,
        '& svg': {
          opacity: buttonDisabledOpacity,
        },
      },
      '& span': {
        padding: 0,
      },
    },
  }),
);

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      '& .MuiTab-root': {
        width: 160,
      },
      '& .MuiTab-wrapper': {
        fontSize: 14,
      },
    },
    topDivier: {
      marginBottom: 12,
    },
    bottomDivier: {
      marginTop: 12,
    },
    siteName: {
      marginBottom: 8,
      fontSize: '18px',
      fontWeight: 'bold',
      width: 780,
    },
    corporationName: {
      marginBottom: 8,
    },
    statusIcon: {
      display: 'flex',
      alignItems: 'center',
      paddingLeft: '-10px',
      verticalAlign: 'middle',
    },
  }),
);

const useCreateSiteShowTabs = (props: any): TabData[] => {
  const translate = useTranslate();
  const tabData: TabData[] = [];
  // Machinesタブ
  tabData.push({
    name: 'retrofits',
    tab: (
      <MuiTab
        label={translate('resources.retrofits.name')}
        key="tab_retrofits"
        value="retrofits"
      />
    ),
    content: <SiteRetrofitList {...props} />,
    order: 10,
  });
  // Projectsタブ
  tabData.push({
    name: 'projects',
    tab: (
      <MuiTab
        label={translate('resources.projects.name')}
        key="tab_projects"
        value="projects"
      />
    ),
    content: <SiteProjectList {...props} />,
    order: 20,
  });
  // Bucketsタブ
  tabData.push({
    name: 'buckets',
    tab: (
      <MuiTab
        label={translate('resources.buckets.name')}
        key="tab_buckets"
        value="buckets"
      />
    ),
    content: <SiteBucketList {...props} />,
    order: 30,
  });
  // Extension Armsタブ
  const { siteId } = props;
  const hasExtensionarmData = useGetSiteExtensionarms(siteId);
  if (hasExtensionarmData) {
    tabData.push({
      name: 'extensionarms',
      tab: (
        <MuiTab
          label={translate('resources.extensionarms.name')}
          key="tab_extensionarms"
          value="extensionarms"
        />
      ),
      content: <SiteExtensionarmList {...props} />,
      order: 40,
    });
  }
  // geofenceタブ
  const siteConfigure = useGetSiteConfigures({ siteId });
  if (config.feature.geofence && siteConfigure.data.enableGeofence) {
    tabData.push({
      name: 'geofences',
      tab: (
        <MuiTab
          label={translate('resources.geofences.name')}
          key="tab_geofences"
          value="geofences"
        />
      ),
      content: <SiteGeofenceList {...props} />,
      order: 50,
    });
  }
  // Constraction Resultタブ
  tabData.push({
    name: 'asbuilts',
    tab: (
      <MuiTab
        label={translate('resources.asbuilts.name')}
        key="tab_asbuilts"
        value="asbuilts"
      />
    ),
    content: <SiteAsbuiltList {...props} />,
    order: 60,
  });
  return tabData.sort((lhs, rhs) => (lhs.order > rhs.order ? 1 : -1));
};

const MasterButton: React.FC<{
  basePath?: string;
  record?: SiteData;
  label?: string;
}> = ({ label, ...props }) => {
  const compactionClasses = useCompactionButtonStyles();
  const { basePath = '', record } = props;
  const { id: siteId } = record || { id: '' };
  const path = `${basePath}/${siteId}/show/master`;
  return (
    <Button
      className={compactionClasses.button}
      component={Link}
      path={path}
      to={path}
      data-testid={label}
    >
      <SiteCompactionMasterIcon />
    </Button>
  );
};

const MasterButtonField: React.FC = props => {
  const compactionClasses = useCompactionButtonStyles();
  return (
    <div className={compactionClasses.root}>
      <MasterButton label="admin.actions.compactionItems" {...props} />
    </div>
  );
};

const SiteShow: React.FC = (props: any) => {
  const classes = useStyles();
  const { enable: supportMode } = useGetSupportMode();
  const redirectTo = useRedirect();
  const { basePath, location, match, id: siteId } = props;
  const { pathname } = location;
  const { url } = match;
  const { getValue } = useCustomLocation(url);
  const dataProvider = useDataProvider();
  const [apiState, setApiState] = useState<{
    fetched: boolean;
    loading: boolean;
    data?: { userConfigure: UserConfigureData | undefined };
  }>({
    fetched: false,
    loading: true,
    data: undefined,
  });

  const handleChange = (_event: any, newValue: string) => {
    redirectTo(`${basePath}/${siteId}/show/${newValue}`);
  };

  const [open, setOpen] = useState(false);
  const handleEdit = useCallback(() => {
    setOpen(true);
  }, [setOpen]);
  const handleClose = useCallback(() => setOpen(false), [setOpen]);

  const configClasses = useConfigureEditButtonStyles();

  // ユーザ設定情報取得
  useEffect(() => {
    if (apiState.fetched) return;
    setApiState({ fetched: true, loading: true });

    // サポートモード使用中はGoogle Analyticsにデータを送信しない
    if (!supportMode) {
      // GoogleAnalytics イベント送信
      window.gtag('event', 'sc_jobsite_open', {
        event: 'sc_jobsite_open',
        sc_site_id: siteId,
      });
    }

    const api = async () => {
      // ログイン中ユーザーアカウント
      const { data: accountData } = await dataProvider.getOne('accounts', {
        id: 1,
      });
      const { email } = accountData;

      // ユーザ設定
      const { data: userConfigureData } =
        await dataProvider.getList<UserConfigureData>('userConfigures', {
          pagination: {
            page: 1,
            perPage: 1,
          },
          sort: { field: 'id', order: 'DESC' },
          filter: { email },
        });
      const userConfigure = userConfigureData?.[0]
        ? userConfigureData[0]
        : undefined;
      return { userConfigure };
    };
    api().then(data => {
      setApiState({ fetched: true, loading: false, data });
    });
  }, [supportMode, dataProvider, apiState.fetched, siteId]);

  // ダイアログの保存ボタン押下後、変更後の色を保持する
  const handleChangeMapColor = useCallback(
    (userConfigure: UserConfigureData) => {
      setApiState({ fetched: true, loading: false, data: { userConfigure } });
    },
    [],
  );

  const userConfigure = apiState.data ? apiState.data.userConfigure : undefined;
  const tabProps = {
    basePath: pathname,
    siteId,
    userConfigure,
  };
  const tabs = useCreateSiteShowTabs(tabProps);
  const [firstTab] = tabs;
  const currentTab = getValue() || firstTab.name;

  // userConfigure 取得中は画面を表示しない
  if (apiState.loading) return <Loading />;

  return (
    <>
      <Show
        {...props}
        className={config.feature.geofence ? classes.root : undefined}
      >
        <SimpleShowLayout>
          <SiteConfiguresEdit
            open={open}
            userConfigure={userConfigure}
            onClose={handleClose}
            onChangeMapColor={handleChangeMapColor}
          />
          <div className={configClasses.root}>
            <Button
              className={configClasses.button}
              data-testid="ra.action.edit"
              onClick={handleEdit}
            >
              <SiteConfigureIcon />
            </Button>
          </div>
          {/* ↓ REACT_APP_FEATURE_COMPACTION が消えたら <MasterButtonField /> のみにする */}
          {config.feature.compaction ? <MasterButtonField /> : null}
          <TextField
            className={classes.siteName}
            addLabel={false}
            resource="sites"
            source="name"
          />
          <TextField
            className={classes.corporationName}
            addLabel={false}
            resource="sites"
            source="corporationName"
          />
          <GridLayout container direction="row" className={classes.statusIcon}>
            <GridLayout item>
              <CustomDateField resource="sites" source="workPeriodStartDate" />
              &nbsp;-&nbsp;
              <CustomDateField resource="sites" source="workPeriodEndDate" />
            </GridLayout>
            <GridLayout item className={classes.statusIcon}>
              <IconField resource="sites" source="status" />
            </GridLayout>
          </GridLayout>
          <FunctionField
            className={classes.bottomDivier}
            addLabel={false}
            render={() => <Divider />}
          />
          <MuiTabs value={currentTab} onChange={handleChange}>
            {tabs.map(({ tab }) => tab)};
          </MuiTabs>
          <Divider />
          <FunctionField
            addLabel={false}
            render={siteData =>
              tabs.map(({ name, content }) => {
                return (
                  <TabContent name={name} key={name} value={currentTab}>
                    {isValidElement(content) &&
                      cloneElement(content, { record: siteData } as any)}
                  </TabContent>
                );
              })
            }
          />
        </SimpleShowLayout>
      </Show>
    </>
  );
};

SiteShow.displayName = 'SiteShow';

export default SiteShow;
