import React, { FC, useEffect, useState } from 'react';
import { useRedirect } from 'react-admin';
import { useLocation, useParams } from 'react-router-dom';
import {
  Edit,
  CustomForm,
  BreadcrumbsFiled,
  TextField,
  ReferenceField,
  AutocompleteInput,
  FunctionField,
  IconFileField,
  CustomDateField,
  NormalButton,
  Loading,
  CustomDeleteButton,
} from '../..';
import { getParentPath, getValidSiteStatus } from '../../../utils';
import { ExtensionarmData } from '../../../dataProvider/types';
import { useAccount, useGetSelefDetailData } from '../../../hooks';
import { DeleteIcon, ShareIcon } from '../../../assets';

const ExtensionarmEdit: FC = () => {
  const redirect = useRedirect();
  const { pathname } = useLocation();
  const basePath = getParentPath(pathname);
  const id = pathname.replace(`${basePath}/`, '');
  const props = {
    id,
    basePath,
    resource: 'extensionarms',
  };
  const { id: extensionarmId } = useParams<{ id: string }>();
  const getSelefDetailData = useGetSelefDetailData();
  const redirectTo = useRedirect();
  const getAccount = useAccount();
  const { resource } = props;

  const [apiState, setApiState] = useState<{
    fetched: boolean;
    loading: boolean;
    extensionarmId: string | undefined;
    data?: { isOwnData?: boolean; corporationId: string };
  }>({
    fetched: false,
    loading: true,
    extensionarmId: undefined,
    data: undefined,
  });

  useEffect(() => {
    if (apiState.fetched && apiState.extensionarmId === extensionarmId) return;
    setApiState({ fetched: true, loading: true, extensionarmId });

    const api = async () => {
      const {
        data: { corporationId },
      } = await getAccount();
      const filter = { corporationId };
      const extensionarm = await getSelefDetailData(
        resource,
        filter,
        extensionarmId,
      );
      return {
        isOwnData: !!extensionarm,
        corporationId,
      };
    };
    api().then(data => {
      setApiState({ fetched: true, loading: false, extensionarmId, data });
    });
  }, [
    apiState.extensionarmId,
    apiState.fetched,
    getSelefDetailData,
    extensionarmId,
    getAccount,
    redirectTo,
    resource,
  ]);

  const { loading } = apiState;
  if (loading || !apiState.data) return <Loading />;

  const { corporationId, isOwnData } = apiState.data;
  if (!isOwnData) redirectTo('/');

  const deleteButton = (
    <CustomDeleteButton messageTargetItem="admin.dialog.extensionarmEdit.deleteConfirm.messageTargetItem">
      <DeleteIcon />
    </CustomDeleteButton>
  );
  return (
    <Edit {...props}>
      <CustomForm
        title="admin.pages.extensionarmEdit"
        redirect={false}
        deleteButton={deleteButton}
        actionButton={
          <NormalButton
            label="admin.actions.lendBucket"
            onClick={() => {
              redirect(`${basePath}/${id}/create`);
            }}
          >
            <ShareIcon />
          </NormalButton>
        }
      >
        <BreadcrumbsFiled
          breadcrumbs={[
            'resources.buckets.name',
            'resources.extensionarms.name',
          ]}
          customPaths={['/buckets', '/extensionarms']}
          label="ra.action.edit"
        />
        <ReferenceField
          resource="extensionarms"
          source="corporationId"
          reference="corporations"
          link=""
        >
          <TextField resource="corporations" source="name" />
        </ReferenceField>
        <AutocompleteInput
          className="sites"
          resource="extensionarms"
          source="siteId"
          choiceResources="sites"
          choicesCreate={({ id: siteId, name }: ExtensionarmData) => ({
            id: siteId,
            name,
          })}
          filter={{
            corporationId,
            status: getValidSiteStatus,
          }}
          sort={{ field: 'name', order: 'ASC' }}
          autoCompleteOptions={{
            suggestionsContainerProps: {
              modifiers: { keepTogether: { enabled: true } },
            },
          }}
          resettable={true}
          allowEmpty={false}
        />
        <TextField resource="extensionarms" source="name" />
        <TextField resource="extensionarms" source="extensionarmType" />
        <FunctionField
          resource="extensionarms"
          source="extensionarmFile"
          render={record => (
            <IconFileField record={record} source="extensionarmFile" />
          )}
        />
        <CustomDateField
          resource="extensionarms"
          source="lastUpdated"
          showTime
        />
      </CustomForm>
    </Edit>
  );
};

ExtensionarmEdit.displayName = 'ExtensionarmEdit';
export default ExtensionarmEdit;
