import { colors } from '.';
import {
  inputWidthRight,
  alignRightPadding,
  buttonWidth,
  buttonHeight,
  buttonDisabledOpacity,
} from './define';

export default {
  multilineText: {
    width: '100%',
    '& > div': {
      width: '100%',
    },
  },
  longWidthText: {
    '& > div': {
      width: 470,
    },
  },
  zeroPaddingHorizontal: {
    paddingRight: 0,
    paddingLeft: 0,
  },
  errorInfo: {
    width: 600,
    height: 96,
    paddingLeft: 24,
    paddingRight: 24,
  },
  multiLineErrorInfo: {
    width: 750,
    minHeight: 96,
    maxHeight: 250,
    paddingLeft: 24,
    paddingRight: 24,
  },
  lincensePurchaceNoticeDialog: {
    width: 730,
    minHeight: 96,
    maxHeight: 250,
    paddingLeft: 24,
    paddingRight: 24,
  },
  buttonArrayInputDelete: {
    '& span': {
      padding: 0,
    },
    width: 44,
    minWidth: 44,
    height: 34,
    minHeight: 34,
    borderRadius: 3,
    boxShadow: '0px 0px 4px #00000040',
    background: colors.button.delete,
    '&:hover': {
      background: colors.button.deleteHover,
    },
    '&.Mui-disabled': {
      backgroundColor: colors.button.deleteDisabled,
    },
  },
  // 右寄せのSelectInputを実現する共通スタイル
  selectInputRightAligned: {
    '& .MuiSelect-filled.MuiSelect-filled': {
      display: 'grid',
      textAlign: 'right',
      minWidth: inputWidthRight,
      paddingRight: alignRightPadding,
    },
  },
  // ライトグレーボタン (アイコン用)
  iconLightGrayButton: {
    minWidth: buttonWidth,
    minHeight: buttonHeight,
    backgroundColor: colors.button.cancel,
    '&:hover': {
      backgroundColor: colors.button.cancelHover,
    },
    '&.Mui-disabled': {
      backgroundColor: colors.button.cancelDisabled,
      '& svg': {
        opacity: buttonDisabledOpacity,
      },
    },
  },
  buttonIteratorDelete: {
    background: colors.button.delete,
    width: 44,
    minWidth: 44,
    height: 34,
    minHeight: 34,
    '&:hover': {
      background: colors.button.deleteHover,
    },
    '&.Mui-disabled': {
      backgroundColor: colors.button.deleteDisabled,
    },
  },
  buttonIteratorAdd: {
    backgroundColor: colors.button.cancel,
    minWidth: 80,
    minHeight: 34,
    width: 80,
    height: 34,
    '&:hover': {
      backgroundColor: colors.button.cancelHover,
    },
    '&.Mui-disabled': {
      backgroundColor: colors.button.cancelDisabled,
    },
  },
};
