/* eslint-disable no-nested-ternary */
import {
  DataProvider,
  CreateParams,
  UpdateParams,
  UpdateManyParams,
  GetListResult,
} from 'react-admin';
import fakerestDataProvider from 'ra-data-fakerest';
import { omit } from 'lodash';
import fakeData from './data';
import {
  ProjectsData,
  RetrofitRentalData,
  AsbuiltData,
  RetrofitBulkActionData,
  GnssSettingData,
  RetrofitDistributorData,
  RetrofitNamePlateFileApiData,
  RetrofitAdminCorporationBulkActionData,
} from '..';
import { getCustomers } from '../customers';
import { getCoordinateFake } from '../coordinate';
import { getGeofenceCoordinateFake } from '../geofenceCordinate';
import { getAsbuiltDownloadFilesFake } from '../asbuiltDownloadFiles';

const nowUtcString = () => new Date().toISOString();

const retrofitGetList = (params: any) => {
  const filterJson = params.filter;
  // adminCorporationId指定時のみor検索が必要なため独自処理を実施する
  // eslint-disable-next-line no-prototype-builtins
  if (filterJson.hasOwnProperty('adminCorporationId')) {
    const paramCorporationId = params.filter.adminCorporationId;
    delete filterJson.adminCorporationId;
    delete filterJson.ownerCorporationId;
    const newParams = params;
    newParams.filter = filterJson;

    return fakerest.getList('retrofits', newParams).then(({ data }) => {
      const newData: any = data
        .filter(
          it =>
            it.adminCorporationId === paramCorporationId ||
            it.ownerCorporationId === paramCorporationId,
        )
        .map(record => ({
          ...record,
          licenseMachineType: record.hasLicense
            ? `${record.machineInfoMachineType}_License`
            : record.machineInfoMachineType,
        }));
      return Promise.resolve({
        data: newData,
        total: newData.length,
      });
    });
  }
  return fakerest.getList('retrofits', params).then(({ data }) => {
    const newData: any = data.map(record => ({
      ...record,
      licenseMachineType: record.hasLicense
        ? `${record.machineInfoMachineType}_License`
        : record.machineInfoMachineType,
    }));
    return Promise.resolve({
      data: newData,
      total: newData.length,
    });
  });
};

const fakerest = fakerestDataProvider(fakeData);
const fakeDataProvider: DataProvider = {
  getList: (resource, params) => {
    switch (resource) {
      case 'bucketNames':
        return fakerest.getList('buckets', params);

      case 'projectNames':
        return fakerest.getList('projects', params);

      case 'customers':
        return getCustomers(fakerest, fakerest, params) as Promise<
          GetListResult<any>
        >;
      case 'coordinates':
        return getCoordinateFake(params);

      case 'geofenceCoordinates':
        return getGeofenceCoordinateFake(params);

      case 'retrofits':
        return retrofitGetList(params);

      case 'termsConditionDocuments': {
        return fakerest.getList(resource, {
          ...params,
          filter: { ...omit(params.filter, ['language']) }, // Fakeなのでここはブラウザ言語に依存せずに返すようにする
        });
      }

      default:
        return fakerest.getList(resource, params);
    }
  },
  getOne: (resource, params) => fakerest.getOne(resource, params),
  getMany: (resource, params) => fakerest.getMany(resource, params),
  getManyReference: (resource, params) =>
    fakerest.getManyReference(resource, params),
  update: (resource, params) => {
    switch (resource) {
      case 'projects': {
        const projectData: ProjectsData = params.data;
        const data: ProjectsData = {
          ...projectData,
          version: projectData.version + 1,
          lastUpdated: nowUtcString(),
          projectSourceLayers: projectData.projectSourceLayers.map(layer => {
            return { ...layer, id: layer.mapServerProjectSourceLayerId };
          }),
        };
        const newParams: UpdateParams = {
          ...params,
          data,
        };
        return fakerest.update(resource, newParams);
      }

      case 'retrofitRentals': {
        const retrofitRentalData: RetrofitRentalData = params.data;
        const data: RetrofitRentalData = {
          ...retrofitRentalData,
          rentalStatus: retrofitRentalData.isReturned
            ? 'Returned'
            : retrofitRentalData.isApproved
            ? 'Approved'
            : 'Unapproved',
        };
        const newParams: UpdateParams = {
          ...params,
          data,
        };
        return fakerest.update(resource, newParams);
      }

      case 'retrofits': {
        const data = {
          ...params.data,
          lastUpdated: nowUtcString(),
        };
        const newParams: UpdateParams = { ...params, data };
        return fakerest.update(resource, newParams);
      }

      default:
        return fakerest.update(resource, params);
    }
  },
  updateMany: (resource, params) => {
    switch (resource) {
      case 'retrofits': {
        const data = {
          ...params.data,
          lastUpdated: nowUtcString(),
        };
        const newParams: UpdateManyParams = { ...params, data };
        return fakerest.updateMany(resource, newParams);
      }

      default:
        return fakerest.updateMany(resource, params);
    }
  },
  create: (resource, params) => {
    switch (resource) {
      case 'projects': {
        const projectData: ProjectsData = params.data;
        const data: ProjectsData = {
          ...projectData,
          version: 1,
          status: 'WaitForConvert',
          lastUpdated: nowUtcString(),
        };
        const newParams: CreateParams = { data };
        return fakerest.create(resource, newParams);
      }

      case 'retrofitRentals': {
        const retrofitRentalData: RetrofitRentalData = params.data;
        const data: RetrofitRentalData = {
          ...retrofitRentalData,
          rentalStatus: 'Unapproved',
        };
        const newParams: CreateParams = { data };
        return fakerest.create(resource, newParams);
      }

      case 'asbuilts': {
        const asbuiltsData: AsbuiltData = params.data;
        const addTimestamp = new Date().getTime().toString();
        const dummyId = asbuiltsData.siteId + addTimestamp;
        const data: AsbuiltData = {
          ...asbuiltsData,
          id: dummyId,
          execStatus: 'InProgress',
          createDate: nowUtcString(),
        };
        const newParams: CreateParams = { data };
        return fakerest.create(resource, newParams);
      }

      case 'asbuiltDownloadFiles':
        return getAsbuiltDownloadFilesFake(params);

      case 'retrofitBulkActions': {
        const bulkActionData: RetrofitBulkActionData = params.data;
        const addTimestamp = new Date().getTime().toString();
        const dummyId = addTimestamp;
        const data: RetrofitBulkActionData = {
          ...bulkActionData,
          id: dummyId,
          execStatus: 'InProgress',
          execStartDate: nowUtcString(),
          execEndDate: undefined,
        };
        const newParams: CreateParams = { data };
        return fakerest.create(resource, newParams);
      }

      case 'retrofitAdminCorporationBulkActions': {
        const bulkActionData: RetrofitAdminCorporationBulkActionData =
          params.data;
        const addTimestamp = new Date().getTime().toString();
        const dummyId = addTimestamp;
        const data: RetrofitAdminCorporationBulkActionData = {
          ...bulkActionData,
          id: dummyId,
          execStatus: 'InProgress',
          execStartDate: nowUtcString(),
          execEndDate: undefined,
        };
        const newParams: CreateParams = { data };
        return fakerest.create(resource, newParams);
      }

      case 'retrofits': {
        const data = {
          ...params.data,
          lastUpdated: nowUtcString(),
        };
        const newParams: CreateParams = { ...params, data };
        return fakerest.create(resource, newParams);
      }

      case 'gnssSettings': {
        const gnssSettingData: GnssSettingData = params.data;
        const addTimestamp = new Date().getTime().toString();
        const dummyId = gnssSettingData.id + addTimestamp;
        const data: GnssSettingData = {
          ...gnssSettingData,
          id: dummyId,
          status: 'Sending',
          updateDate: nowUtcString(),
        };
        const newParams: CreateParams = { data };
        return fakerest.create(resource, newParams);
      }

      case 'retrofitDistributors': {
        const retrofitDistributorData: RetrofitDistributorData = params.data;
        const data: RetrofitDistributorData = {
          ...retrofitDistributorData,
        };
        const newParams: CreateParams = { data };
        return fakerest.create(resource, newParams);
      }

      case 'retrofitNamePlateFiles': {
        const { data } = params;
        const newParams: CreateParams = { data };
        // create 時の付与される ID 値を利用したいので、以下でデータ加工を行う
        return fakerest.create(resource, newParams).then((result: any) => {
          const { data: oldData } = result as {
            data: RetrofitNamePlateFileApiData;
          };
          const { rawFile } = oldData;
          const { name } = rawFile;
          const index = oldData.id + 1;
          const serialNumber = `SERIAL_${index}`;
          const extractStatus = name.includes('error') ? 'Error' : 'Complete'; // 'error' 文字列が含まれているファイル名の場合、Errorステータスを返す
          const newData = { data: { ...oldData, serialNumber, extractStatus } };
          return Promise.resolve(newData) as any;
        });
      }

      default:
        return fakerest.create(resource, params);
    }
  },
  delete: (resource, params) => fakerest.delete(resource, params),
  deleteMany: (resource, params) => fakerest.deleteMany(resource, params),
};
export { fakeData, fakeDataProvider };
