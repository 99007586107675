import { BucketData } from '../../types';

const buckets: BucketData[] = [
  {
    id: 1,
    corporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    name: '標準バケット０１９０１２３４５６７８９０１２３４５６７８９０',
    bucketType: 'Standard',
    bk3File: {
      src: 'http://example.com/dummy.bk3',
      title: 'Standard01.bk3',
    },
    siteId: '65a5a0fc-ae01-4fae-8f8a-406faf1fb0e4',
    uploadedBy: 'Admin',
    fileStatus: 'Converting',
    lastUpdated: '2019-11-01T09:00:00.000Z',
    updatedBy: 'テストユーザＡ',
  },
  {
    id: 2,
    corporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    name: '標準バケット０２',
    bucketType: 'Standard',
    bk3File: {
      src: 'http://example.com/dummy.bk3',
      title: 'Standard02.bk3',
    },
    siteId: '65a5a0fc-ae01-4fae-8f8a-406faf1fb0e4',
    uploadedBy: 'Admin',
    fileStatus: 'Converting',
    lastUpdated: '2019-11-02T09:00:00.000Z',
    updatedBy: 'テストユーザＡ',
  },
  {
    id: 3,
    corporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    name: '標準バケット０３',
    bucketType: 'Standard',
    bk3File: {
      src: 'http://example.com/dummy.bk3',
      title: 'Standard03.bk3',
    },
    siteId: '14b2149c-9d45-f3cb-bc93-7916ecc3778d',
    uploadedBy: 'Admin',
    fileStatus: 'Error',
    lastUpdated: '2019-11-03T09:00:00.000Z',
    updatedBy: 'テストユーザＡ',
  },
  {
    id: 4,
    corporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    name: '標準バケット０４',
    bucketType: 'Standard',
    bk3File: {
      src: 'http://example.com/dummy.bk3',
      title: 'Standard04.bk3',
    },
    siteId: '14b2149c-9d45-f3cb-bc93-7916ecc3778d',
    uploadedBy: 'Admin',
    fileStatus: 'Completed',
    lastUpdated: '2019-11-04T09:00:00.000Z',
    updatedBy: 'テストユーザＡ',
    gltfFile: {
      src: 'http://example.com/dummy.gltf',
      title: 'Standard04.gltf',
    },
  },
  {
    id: 5,
    corporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    name: 'バケット１',
    bucketType: 'Standard',
    bk3File: {
      src: 'http://example.com/dummy.bk3',
      title: 'Standard01.bk3',
    },
    siteId: '6b7741a1-c2cf-4ba3-bc2d-7d19bac78922',
    uploadedBy: 'Admin',
    fileStatus: 'Completed',
    lastUpdated: '2020-01-29T01:00:00.000Z',
    updatedBy: 'テストユーザＡ',
  },
  {
    id: 6,
    corporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    name: 'バケット３（Tablet）',
    bucketType: 'Slope',
    bk3File: {
      src: 'http://example.com/dummy.bk3',
      title: 'Slope03.bk3',
    },
    siteId: '6b7741a1-c2cf-4ba3-bc2d-7d19bac78922',
    uploadedBy: 'Tablet',
    fileStatus: 'Completed',
    lastUpdated: '2020-01-29T02:00:00.000Z',
    updatedBy: 'テストユーザＡ',
  },
  {
    id: 7,
    corporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    name: 'Tiltバケット',
    bucketType: 'Tilt',
    bk3File: {
      src: 'http://example.com/dummy.bk3',
      title: 'Tilt01.bk3',
    },
    siteId: '6b7741a1-c2cf-4ba3-bc2d-7d19bac78922',
    uploadedBy: 'Tablet',
    fileStatus: 'Completed',
    lastUpdated: '2019-11-05T09:00:00.000Z',
    updatedBy: 'テストユーザＢ',
  },
  {
    id: 8,
    corporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    name: '標準バケット０６',
    bucketType: 'Standard',
    bk3File: {
      src: '',
      title: '',
    },
    siteId: '',
    uploadedBy: 'Admin',
    fileStatus: 'Converting',
    lastUpdated: '2019-11-06T09:00:00.000Z',
    updatedBy: 'テストユーザＡ',
  },
  {
    id: 9,
    corporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    name: '標準バケット０７',
    bucketType: 'Standard',
    bk3File: {
      src: '',
      title: '',
    },
    siteId: '',
    uploadedBy: 'Admin',
    fileStatus: 'Converting',
    lastUpdated: '2019-12-02T09:00:00.000Z',
    updatedBy: 'テストユーザＡ',
  },
  {
    id: 10,
    corporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    name: '標準バケット０８',
    bucketType: 'Standard',
    bk3File: {
      src: '',
      title: '',
    },
    siteId: '',
    uploadedBy: 'Admin',
    fileStatus: 'Converting',
    lastUpdated: '2019-12-02T10:00:00.000Z',
    updatedBy: 'テストユーザＡ',
  },
  {
    id: 11,
    corporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    name: '標準バケット０９',
    bucketType: 'Standard',
    bk3File: {
      src: '',
      title: '',
    },
    siteId: '',
    uploadedBy: 'Admin',
    fileStatus: 'Converting',
    lastUpdated: '2019-12-02T11:00:00.000Z',
    updatedBy: 'テストユーザＡ',
  },
  {
    id: 12,
    corporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    name: '標準バケット１０',
    bucketType: 'Standard',
    bk3File: {
      src: '',
      title: '',
    },
    siteId: '',
    uploadedBy: 'Admin',
    fileStatus: 'Converting',
    lastUpdated: '2019-12-02T12:00:00.000Z',
    updatedBy: 'テストユーザＡ',
  },
  {
    id: 13,
    corporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    name: '標準バケット１１',
    bucketType: 'Standard',
    bk3File: {
      src: '',
      title: '',
    },
    siteId: '',
    uploadedBy: 'Admin',
    fileStatus: 'Converting',
    lastUpdated: '2019-12-02T09:00:00.000Z',
    updatedBy: 'テストユーザＡ',
  },
  {
    id: 14,
    corporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    name: '標準バケット１２',
    bucketType: 'Standard',
    bk3File: {
      src: '',
      title: '',
    },
    siteId: '',
    uploadedBy: 'Admin',
    fileStatus: 'Converting',
    lastUpdated: '2019-12-02T09:00:00.000Z',
    updatedBy: 'テストユーザＡ',
  },
  {
    id: 15,
    corporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    name: '標準バケット１３',
    bucketType: 'Standard',
    bk3File: {
      src: '',
      title: '',
    },
    siteId: '',
    uploadedBy: 'Admin',
    fileStatus: 'Converting',
    lastUpdated: '2019-12-02T09:00:00.000Z',
    updatedBy: 'テストユーザＡ',
  },
  {
    id: 16,
    corporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    name: '標準バケット１４',
    bucketType: 'Standard',
    bk3File: {
      src: '',
      title: '',
    },
    siteId: '',
    uploadedBy: 'Admin',
    fileStatus: 'Converting',
    lastUpdated: '2019-12-02T09:00:00.000Z',
    updatedBy: 'テストユーザＡ',
  },
  {
    id: 17,
    corporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    name: '標準バケット１５',
    bucketType: 'Standard',
    bk3File: {
      src: '',
      title: '',
    },
    siteId: '',
    uploadedBy: 'Admin',
    fileStatus: 'Converting',
    lastUpdated: '2019-12-02T09:00:00.000Z',
    updatedBy: 'テストユーザＡ',
  },
  {
    id: 18,
    corporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    name: '標準バケット１６',
    bucketType: 'Standard',
    bk3File: {
      src: '',
      title: '',
    },
    siteId: '',
    uploadedBy: 'Admin',
    fileStatus: 'Converting',
    lastUpdated: '2019-12-02T09:00:00.000Z',
    updatedBy: 'テストユーザＡ',
  },
  {
    id: 19,
    corporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    name: '標準バケット１７',
    bucketType: 'Standard',
    bk3File: {
      src: '',
      title: '',
    },
    siteId: '',
    uploadedBy: 'Admin',
    fileStatus: 'Converting',
    lastUpdated: '2019-12-02T09:00:00.000Z',
    updatedBy: 'テストユーザＡ',
  },
  {
    id: 20,
    corporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    name: '標準バケット１８',
    bucketType: 'Standard',
    bk3File: {
      src: '',
      title: '',
    },
    siteId: '',
    uploadedBy: 'Admin',
    fileStatus: 'Converting',
    lastUpdated: '2019-12-02T09:00:00.000Z',
    updatedBy: 'テストユーザＡ',
  },
  {
    id: 21,
    corporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    name: '標準バケット１９',
    bucketType: 'Standard',
    bk3File: {
      src: '',
      title: '',
    },
    siteId: '',
    uploadedBy: 'Admin',
    fileStatus: 'Converting',
    lastUpdated: '2019-12-02T09:00:00.000Z',
    updatedBy: 'テストユーザＡ',
  },
  {
    id: 22,
    corporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    name: '標準バケット２０',
    bucketType: 'Standard',
    bk3File: {
      src: '',
      title: '',
    },
    siteId: '',
    uploadedBy: 'Admin',
    fileStatus: 'Converting',
    lastUpdated: '2019-12-02T09:00:00.000Z',
    updatedBy: 'テストユーザＡ',
  },
  {
    id: 23,
    corporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    name: '標準バケット２１',
    bucketType: 'Standard',
    bk3File: {
      src: '',
      title: '',
    },
    siteId: '',
    uploadedBy: 'Admin',
    fileStatus: 'Converting',
    lastUpdated: '2019-12-02T09:00:00.000Z',
    updatedBy: 'テストユーザＡ',
  },
  {
    id: 24,
    corporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    name: '標準バケット２２',
    bucketType: 'Standard',
    bk3File: {
      src: '',
      title: '',
    },
    siteId: '',
    uploadedBy: 'Admin',
    fileStatus: 'Converting',
    lastUpdated: '2019-12-02T09:00:00.000Z',
    updatedBy: 'テストユーザＡ',
  },
  {
    id: 25,
    corporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    name: '標準バケット２３',
    bucketType: 'Standard',
    bk3File: {
      src: '',
      title: '',
    },
    siteId: '',
    uploadedBy: 'Admin',
    fileStatus: 'Converting',
    lastUpdated: '2019-12-02T09:00:00.000Z',
    updatedBy: 'テストユーザＡ',
  },
  {
    id: 26,
    corporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    name: '標準バケット２４',
    bucketType: 'Standard',
    bk3File: {
      src: '',
      title: '',
    },
    siteId: '',
    uploadedBy: 'Admin',
    fileStatus: 'Converting',
    lastUpdated: '2019-12-02T09:00:00.000Z',
    updatedBy: 'テストユーザＡ',
  },
  {
    id: 27,
    corporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    name: '標準バケット２５',
    bucketType: 'Standard',
    bk3File: {
      src: '',
      title: '',
    },
    siteId: '',
    uploadedBy: 'Admin',
    fileStatus: 'Converting',
    lastUpdated: '2019-12-02T09:00:00.000Z',
    updatedBy: 'テストユーザＡ',
  },
  {
    id: 28,
    corporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    name: '標準バケット２６',
    bucketType: 'Standard',
    bk3File: {
      src: '',
      title: '',
    },
    siteId: '',
    uploadedBy: 'Admin',
    fileStatus: 'Converting',
    lastUpdated: '2019-12-02T09:00:00.000Z',
    updatedBy: 'テストユーザＡ',
  },
  {
    id: 29,
    corporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    name: '標準バケット２７',
    bucketType: 'Standard',
    bk3File: {
      src: '',
      title: '',
    },
    siteId: '',
    uploadedBy: 'Admin',
    fileStatus: 'Converting',
    lastUpdated: '2019-12-02T09:00:00.000Z',
    updatedBy: 'テストユーザＡ',
  },
  {
    id: 30,
    corporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    name: '標準バケット２８',
    bucketType: 'Standard',
    bk3File: {
      src: '',
      title: '',
    },
    siteId: '',
    uploadedBy: 'Admin',
    fileStatus: 'Converting',
    lastUpdated: '2019-12-02T09:00:00.000Z',
    updatedBy: 'テストユーザＡ',
  },
  {
    id: 31,
    corporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    name: '標準バケット２９',
    bucketType: 'Standard',
    bk3File: {
      src: '',
      title: '',
    },
    siteId: '',
    uploadedBy: 'Admin',
    fileStatus: 'Converting',
    lastUpdated: '2019-12-02T09:00:00.000Z',
    updatedBy: 'テストユーザＡ',
  },
  {
    id: 32,
    corporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    name: '標準バケット３０',
    bucketType: 'Standard',
    bk3File: {
      src: '',
      title: '',
    },
    siteId: '',
    uploadedBy: 'Admin',
    fileStatus: 'Converting',
    lastUpdated: '2019-12-02T09:00:00.000Z',
    updatedBy: 'テストユーザＡ',
  },
  {
    id: 33,
    corporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    name: '標準バケット３１',
    bucketType: 'Standard',
    bk3File: {
      src: '',
      title: '',
    },
    siteId: '',
    uploadedBy: 'Admin',
    fileStatus: 'Converting',
    lastUpdated: '2019-12-02T09:00:00.000Z',
    updatedBy: 'テストユーザＡ',
  },
  {
    id: 34,
    corporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    name: '標準バケット３２',
    bucketType: 'Standard',
    bk3File: {
      src: '',
      title: '',
    },
    siteId: '',
    uploadedBy: 'Admin',
    fileStatus: 'Converting',
    lastUpdated: '2019-12-02T09:00:00.000Z',
    updatedBy: 'テストユーザＡ',
  },
  {
    id: 35,
    corporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    name: '標準バケット３３',
    bucketType: 'Standard',
    bk3File: {
      src: '',
      title: '',
    },
    siteId: '',
    uploadedBy: 'Admin',
    fileStatus: 'Converting',
    lastUpdated: '2019-12-02T09:00:00.000Z',
    updatedBy: 'テストユーザＡ',
  },
  {
    id: 36,
    corporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    name: '標準バケット３４',
    bucketType: 'Standard',
    bk3File: {
      src: '',
      title: '',
    },
    siteId: '',
    uploadedBy: 'Admin',
    fileStatus: 'Converting',
    lastUpdated: '2019-12-02T09:00:00.000Z',
    updatedBy: 'テストユーザＡ',
  },
  {
    id: 37,
    corporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    name: '標準バケット３５',
    bucketType: 'Standard',
    bk3File: {
      src: '',
      title: '',
    },
    siteId: '',
    uploadedBy: 'Admin',
    fileStatus: 'Converting',
    lastUpdated: '2019-12-02T09:00:00.000Z',
    updatedBy: 'テストユーザＡ',
  },
  {
    id: 38,
    corporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    name: '標準バケット３６',
    bucketType: 'Standard',
    bk3File: {
      src: '',
      title: '',
    },
    siteId: '',
    uploadedBy: 'Admin',
    fileStatus: 'Converting',
    lastUpdated: '2019-12-02T09:00:00.000Z',
    updatedBy: 'テストユーザＡ',
  },
  {
    id: 39,
    corporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    name: '標準バケット３７',
    bucketType: 'Standard',
    bk3File: {
      src: '',
      title: '',
    },
    siteId: '',
    uploadedBy: 'Admin',
    fileStatus: 'Converting',
    lastUpdated: '2019-12-02T09:00:00.000Z',
    updatedBy: 'テストユーザＡ',
  },
  {
    id: 40,
    corporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    name: '標準バケット３８',
    bucketType: 'Standard',
    bk3File: {
      src: '',
      title: '',
    },
    siteId: '',
    uploadedBy: 'Admin',
    fileStatus: 'Converting',
    lastUpdated: '2019-12-02T09:00:00.000Z',
    updatedBy: 'テストユーザＡ',
  },
  {
    id: 41,
    corporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    name: '標準バケット３９',
    bucketType: 'Standard',
    bk3File: {
      src: '',
      title: '',
    },
    siteId: '',
    uploadedBy: 'Admin',
    fileStatus: 'Converting',
    lastUpdated: '2019-12-02T09:00:00.000Z',
    updatedBy: 'テストユーザＡ',
  },
  {
    id: 42,
    corporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    name: '標準バケット４０',
    bucketType: 'Standard',
    bk3File: {
      src: '',
      title: '',
    },
    siteId: '',
    uploadedBy: 'Admin',
    fileStatus: 'Converting',
    lastUpdated: '2019-12-02T09:00:00.000Z',
    updatedBy: 'テストユーザＡ',
  },
  {
    id: 43,
    corporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    name: 'スロープバケット０１０１２３４５６７８９０１２３４５６７８９０',
    bucketType: 'Slope',
    bk3File: {
      src: 'http://example.com/dummy.bk3',
      title: 'Slope01.bk3',
    },
    siteId: '65a5a0fc-ae01-4fae-8f8a-406faf1fb0e4',
    uploadedBy: 'Admin',
    fileStatus: 'Converting',
    lastUpdated: '2019-12-02T09:00:00.000Z',
    updatedBy: 'テストユーザＡ',
  },
  {
    id: 44,
    corporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    name: 'スロープバケット０２',
    bucketType: 'Slope',
    bk3File: {
      src: 'http://example.com/dummy.bk3',
      title: 'Slope02.bk3',
    },
    siteId: '65a5a0fc-ae01-4fae-8f8a-406faf1fb0e4',
    uploadedBy: 'Admin',
    fileStatus: 'Converting',
    lastUpdated: '2019-12-02T09:00:00.000Z',
    updatedBy: 'テストユーザＡ',
  },
  {
    id: 45,
    corporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    name: 'スロープバケット０３',
    bucketType: 'Slope',
    bk3File: {
      src: 'http://example.com/dummy.bk3',
      title: 'Slope03.bk3',
    },
    siteId: '14b2149c-9d45-f3cb-bc93-7916ecc3778d',
    uploadedBy: 'Admin',
    fileStatus: 'Error',
    lastUpdated: '2019-12-02T09:00:00.000Z',
    updatedBy: 'テストユーザＡ',
  },
  {
    id: 46,
    corporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    name: 'スロープバケット０４',
    bucketType: 'Slope',
    bk3File: {
      src: 'http://example.com/dummy.bk3',
      title: 'Slope04.bk3',
    },
    siteId: '14b2149c-9d45-f3cb-bc93-7916ecc3778d',
    uploadedBy: 'Admin',
    fileStatus: 'Completed',
    lastUpdated: '2019-12-02T09:00:00.000Z',
    updatedBy: 'テストユーザＡ',
  },
  {
    id: 47,
    corporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    name: 'スロープバケット０５',
    bucketType: 'Slope',
    bk3File: {
      src: '',
      title: '',
    },
    siteId: '',
    uploadedBy: 'Admin',
    fileStatus: 'Converting',
    lastUpdated: '2019-12-02T09:00:00.000Z',
    updatedBy: 'テストユーザＡ',
  },
  {
    id: 48,
    corporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    name: 'スロープバケット０６',
    bucketType: 'Slope',
    bk3File: {
      src: '',
      title: '',
    },
    siteId: '',
    uploadedBy: 'Admin',
    fileStatus: 'Converting',
    lastUpdated: '2019-12-02T09:00:00.000Z',
    updatedBy: 'テストユーザＡ',
  },
  {
    id: 49,
    corporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    name: 'スロープバケット０７',
    bucketType: 'Slope',
    bk3File: {
      src: '',
      title: '',
    },
    siteId: '',
    uploadedBy: 'Admin',
    fileStatus: 'Converting',
    lastUpdated: '2019-12-02T09:00:00.000Z',
    updatedBy: 'テストユーザＡ',
  },
  {
    id: 50,
    corporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    name: 'スロープバケット０８',
    bucketType: 'Slope',
    bk3File: {
      src: '',
      title: '',
    },
    siteId: '',
    uploadedBy: 'Admin',
    fileStatus: 'Converting',
    lastUpdated: '2019-12-02T09:00:00.000Z',
    updatedBy: 'テストユーザＡ',
  },
  {
    id: 51,
    corporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    name: 'スロープバケット０９',
    bucketType: 'Slope',
    bk3File: {
      src: '',
      title: '',
    },
    siteId: '',
    uploadedBy: 'Admin',
    fileStatus: 'Converting',
    lastUpdated: '2019-12-02T09:00:00.000Z',
    updatedBy: 'テストユーザＡ',
  },
  {
    id: 52,
    corporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    name: 'スロープバケット１０',
    bucketType: 'Slope',
    bk3File: {
      src: '',
      title: '',
    },
    siteId: '',
    uploadedBy: 'Admin',
    fileStatus: 'Converting',
    lastUpdated: '2019-12-02T09:00:00.000Z',
    updatedBy: 'テストユーザＡ',
  },
  {
    id: 53,
    corporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    name: 'スロープバケット１１',
    bucketType: 'Slope',
    bk3File: {
      src: '',
      title: '',
    },
    siteId: '',
    uploadedBy: 'Admin',
    fileStatus: 'Converting',
    lastUpdated: '2019-12-02T09:00:00.000Z',
    updatedBy: 'テストユーザＡ',
  },
  {
    id: 54,
    corporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    name: 'スロープバケット１２',
    bucketType: 'Slope',
    bk3File: {
      src: '',
      title: '',
    },
    siteId: '',
    uploadedBy: 'Admin',
    fileStatus: 'Converting',
    lastUpdated: '2019-12-02T09:00:00.000Z',
    updatedBy: 'テストユーザＡ',
  },
  {
    id: 55,
    corporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    name: 'スロープバケット１３',
    bucketType: 'Slope',
    bk3File: {
      src: '',
      title: '',
    },
    siteId: '',
    uploadedBy: 'Admin',
    fileStatus: 'Converting',
    lastUpdated: '2019-12-02T09:00:00.000Z',
    updatedBy: 'テストユーザＡ',
  },
  {
    id: 56,
    corporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    name: 'スロープバケット１４',
    bucketType: 'Slope',
    bk3File: {
      src: '',
      title: '',
    },
    siteId: '',
    uploadedBy: 'Admin',
    fileStatus: 'Converting',
    lastUpdated: '2019-12-02T09:00:00.000Z',
    updatedBy: 'テストユーザＡ',
  },
  {
    id: 57,
    corporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    name: 'スロープバケット１５',
    bucketType: 'Slope',
    bk3File: {
      src: '',
      title: '',
    },
    siteId: '',
    uploadedBy: 'Admin',
    fileStatus: 'Converting',
    lastUpdated: '2019-12-02T09:00:00.000Z',
    updatedBy: 'テストユーザＡ',
  },
  {
    id: 58,
    corporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    name: 'スロープバケット１６',
    bucketType: 'Slope',
    bk3File: {
      src: '',
      title: '',
    },
    siteId: '',
    uploadedBy: 'Admin',
    fileStatus: 'Converting',
    lastUpdated: '2019-12-02T09:00:00.000Z',
    updatedBy: 'テストユーザＡ',
  },
  {
    id: 59,
    corporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    name: 'スロープバケット１７',
    bucketType: 'Slope',
    bk3File: {
      src: '',
      title: '',
    },
    siteId: '',
    uploadedBy: 'Admin',
    fileStatus: 'Converting',
    lastUpdated: '2019-12-02T09:00:00.000Z',
    updatedBy: 'テストユーザＡ',
  },
  {
    id: 60,
    corporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    name: 'スロープバケット１８',
    bucketType: 'Slope',
    bk3File: {
      src: '',
      title: '',
    },
    siteId: '',
    uploadedBy: 'Admin',
    fileStatus: 'Converting',
    lastUpdated: '2019-12-02T09:00:00.000Z',
    updatedBy: 'テストユーザＡ',
  },
  {
    id: 61,
    corporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    name: 'スロープバケット１９',
    bucketType: 'Slope',
    bk3File: {
      src: '',
      title: '',
    },
    siteId: '',
    uploadedBy: 'Admin',
    fileStatus: 'Converting',
    lastUpdated: '2019-12-02T09:00:00.000Z',
    updatedBy: 'テストユーザＡ',
  },
  {
    id: 62,
    corporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    name: 'スロープバケット２０',
    bucketType: 'Slope',
    bk3File: {
      src: '',
      title: '',
    },
    siteId: '',
    uploadedBy: 'Admin',
    fileStatus: 'Converting',
    lastUpdated: '2019-12-02T09:00:00.000Z',
    updatedBy: 'テストユーザＡ',
  },
  {
    id: 63,
    corporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    name: 'スロープバケット２１',
    bucketType: 'Slope',
    bk3File: {
      src: '',
      title: '',
    },
    siteId: '',
    uploadedBy: 'Admin',
    fileStatus: 'Converting',
    lastUpdated: '2019-12-02T09:00:00.000Z',
    updatedBy: 'テストユーザＡ',
  },
  {
    id: 64,
    corporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    name: 'スロープバケット２２',
    bucketType: 'Slope',
    bk3File: {
      src: '',
      title: '',
    },
    siteId: '',
    uploadedBy: 'Admin',
    fileStatus: 'Converting',
    lastUpdated: '2019-12-02T09:00:00.000Z',
    updatedBy: 'テストユーザＡ',
  },
  {
    id: 65,
    corporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    name: 'スロープバケット２３',
    bucketType: 'Slope',
    bk3File: {
      src: '',
      title: '',
    },
    siteId: '',
    uploadedBy: 'Admin',
    fileStatus: 'Converting',
    lastUpdated: '2019-12-02T09:00:00.000Z',
    updatedBy: 'テストユーザＡ',
  },
  {
    id: 66,
    corporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    name: 'スロープバケット２４',
    bucketType: 'Slope',
    bk3File: {
      src: '',
      title: '',
    },
    siteId: '',
    uploadedBy: 'Admin',
    fileStatus: 'Converting',
    lastUpdated: '2019-12-02T09:00:00.000Z',
    updatedBy: 'テストユーザＡ',
  },
  {
    id: 67,
    corporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    name: 'スロープバケット２５',
    bucketType: 'Slope',
    bk3File: {
      src: '',
      title: '',
    },
    siteId: '',
    uploadedBy: 'Admin',
    fileStatus: 'Converting',
    lastUpdated: '2019-12-02T09:00:00.000Z',
    updatedBy: 'テストユーザＡ',
  },
  {
    id: 68,
    corporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    name: 'スロープバケット２６',
    bucketType: 'Slope',
    bk3File: {
      src: '',
      title: '',
    },
    siteId: '',
    uploadedBy: 'Admin',
    fileStatus: 'Converting',
    lastUpdated: '2019-12-02T09:00:00.000Z',
    updatedBy: 'テストユーザＡ',
  },
  {
    id: 69,
    corporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    name: 'スロープバケット２７',
    bucketType: 'Slope',
    bk3File: {
      src: '',
      title: '',
    },
    siteId: '',
    uploadedBy: 'Admin',
    fileStatus: 'Converting',
    lastUpdated: '2019-12-02T09:00:00.000Z',
    updatedBy: 'テストユーザＡ',
  },
  {
    id: 70,
    corporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    name: 'スロープバケット２８',
    bucketType: 'Slope',
    bk3File: {
      src: '',
      title: '',
    },
    siteId: '',
    uploadedBy: 'Admin',
    fileStatus: 'Converting',
    lastUpdated: '2019-12-02T09:00:00.000Z',
    updatedBy: 'テストユーザＡ',
  },
  {
    id: 71,
    corporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    name: 'スロープバケット２９',
    bucketType: 'Slope',
    bk3File: {
      src: '',
      title: '',
    },
    siteId: '',
    uploadedBy: 'Admin',
    fileStatus: 'Converting',
    lastUpdated: '2019-12-02T09:00:00.000Z',
    updatedBy: 'テストユーザＡ',
  },
  {
    id: 72,
    corporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    name: 'スロープバケット３０',
    bucketType: 'Slope',
    bk3File: {
      src: '',
      title: '',
    },
    siteId: '',
    uploadedBy: 'Admin',
    fileStatus: 'Converting',
    lastUpdated: '2019-12-02T09:00:00.000Z',
    updatedBy: 'テストユーザＡ',
  },
  {
    id: 73,
    corporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    name: 'スロープバケット３１',
    bucketType: 'Slope',
    bk3File: {
      src: '',
      title: '',
    },
    siteId: '',
    uploadedBy: 'Admin',
    fileStatus: 'Converting',
    lastUpdated: '2019-12-02T09:00:00.000Z',
    updatedBy: 'テストユーザＡ',
  },
  {
    id: 74,
    corporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    name: 'スロープバケット３２',
    bucketType: 'Slope',
    bk3File: {
      src: '',
      title: '',
    },
    siteId: '',
    uploadedBy: 'Admin',
    fileStatus: 'Converting',
    lastUpdated: '2019-12-02T09:00:00.000Z',
    updatedBy: 'テストユーザＡ',
  },
  {
    id: 75,
    corporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    name: 'スロープバケット３３',
    bucketType: 'Slope',
    bk3File: {
      src: '',
      title: '',
    },
    siteId: '',
    uploadedBy: 'Admin',
    fileStatus: 'Converting',
    lastUpdated: '2019-12-02T09:00:00.000Z',
    updatedBy: 'テストユーザＡ',
  },
  {
    id: 76,
    corporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    name: 'スロープバケット３４',
    bucketType: 'Slope',
    bk3File: {
      src: '',
      title: '',
    },
    siteId: '',
    uploadedBy: 'Admin',
    fileStatus: 'Converting',
    lastUpdated: '2019-12-02T09:00:00.000Z',
    updatedBy: 'テストユーザＡ',
  },
  {
    id: 77,
    corporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    name: 'スロープバケット３５',
    bucketType: 'Slope',
    bk3File: {
      src: '',
      title: '',
    },
    siteId: '',
    uploadedBy: 'Admin',
    fileStatus: 'Converting',
    lastUpdated: '2019-12-02T09:00:00.000Z',
    updatedBy: 'テストユーザＡ',
  },
  {
    id: 78,
    corporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    name: 'スロープバケット３６',
    bucketType: 'Slope',
    bk3File: {
      src: '',
      title: '',
    },
    siteId: '',
    uploadedBy: 'Admin',
    fileStatus: 'Converting',
    lastUpdated: '2019-12-02T09:00:00.000Z',
    updatedBy: 'テストユーザＡ',
  },
  {
    id: 79,
    corporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    name: 'スロープバケット３７',
    bucketType: 'Slope',
    bk3File: {
      src: '',
      title: '',
    },
    siteId: '',
    uploadedBy: 'Admin',
    fileStatus: 'Converting',
    lastUpdated: '2019-12-02T09:00:00.000Z',
    updatedBy: 'テストユーザＡ',
  },
  {
    id: 80,
    corporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    name: 'スロープバケット３８',
    bucketType: 'Slope',
    bk3File: {
      src: '',
      title: '',
    },
    siteId: '',
    uploadedBy: 'Admin',
    fileStatus: 'Converting',
    lastUpdated: '2019-12-02T09:00:00.000Z',
    updatedBy: 'テストユーザＡ',
  },
  {
    id: 81,
    corporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    name: 'スロープバケット３９',
    bucketType: 'Slope',
    bk3File: {
      src: '',
      title: '',
    },
    siteId: '',
    uploadedBy: 'Admin',
    fileStatus: 'Converting',
    lastUpdated: '2019-12-02T09:00:00.000Z',
    updatedBy: 'テストユーザＡ',
  },
  {
    id: 82,
    corporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    name: 'スロープバケット４０',
    bucketType: 'Slope',
    bk3File: {
      src: '',
      title: '',
    },
    siteId: '',
    uploadedBy: 'Admin',
    fileStatus: 'Converting',
    lastUpdated: '2019-12-02T09:00:00.000Z',
    updatedBy: 'テストユーザＡ',
  },
  {
    id: 83,
    corporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    name: 'スロープバケット４１',
    bucketType: 'Slope',
    bk3File: {
      src: '',
      title: '',
    },
    siteId: '',
    uploadedBy: 'Admin',
    fileStatus: 'Converting',
    lastUpdated: '2019-12-02T09:00:00.000Z',
    updatedBy: 'テストユーザＡ',
  },
  {
    id: 84,
    corporationId: '',
    name: 'Standard Bucket 1',
    bucketType: 'Standard',
    bk3File: {
      src: '',
      title: '',
    },
    siteId: '',
    uploadedBy: 'Admin',
    fileStatus: 'Converting',
    lastUpdated: '2019-12-02T09:00:00.000Z',
    updatedBy: 'テストユーザＡ',
  },
  {
    id: 85,
    corporationId: '8715c009-aada-9c22-20e3-a63305b46db9',
    name: '標準バケット名５００１２３４５６７８９０１２３４５６７８９０１２３４５６７８９０１２３４５６７８９０',
    bucketType: 'Standard',
    bk3File: {
      src: 'Standard50123456789012345678901234567890123456.bk3',
      title: 'Standard50123456789012345678901234567890123456.bk3',
    },
    siteId: '87bab37d-6121-8712-b46f-db62854ffb7c',
    uploadedBy: 'Admin',
    fileStatus: 'Completed',
    lastUpdated: '2019-12-02T09:00:00.000Z',
    updatedBy:
      '更新者５０６７８９０１２３４５６７８９０１２３４５６７８９０１２３４５６７８９０１２３４５６７８９０',
  },
  {
    id: 86,
    corporationId: '8715c009-aada-9c22-20e3-a63305b46db9',
    name: 'スロープバケット名１００３４５６７８９０１２３４５６７８９０１２３４５６７８９０１２３４５６７８９０１２３４５６７８９０１２３４５６７８９０１２３４５６７８９０１２３４５６７８９０１２３４５６７８９０',
    bucketType: 'Slope',
    bk3File: {
      src: 'Slope1009012345678901234567890123456789012345678901234567890123456789012345678901234567890123456.bk3',
      title:
        'Slope1009012345678901234567890123456789012345678901234567890123456789012345678901234567890123456.bk3',
    },
    siteId: '46fb0806-b4fe-6dc5-3127-d6fdc814c2f7',
    uploadedBy: 'Admin',
    fileStatus: 'Completed',
    lastUpdated: '2019-12-02T09:00:00.000Z',
    updatedBy:
      '更新者５０６７８９０１２３４５６７８９０１２３４５６７８９０１２３４５６７８９０１２３４５６７８９０１２３４５６７８９０１２３４５６７８９０１２３４５６７８９０１２３４５６７８９０１２３４５６７８９０',
  },
];

export default buckets;
