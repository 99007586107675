import { DataProvider } from 'react-admin';
import { SupportModeData, SiteData, CorporationData, AccountData } from '..';

const getSupportModeData = async (
  landlog: DataProvider,
  supportMode: DataProvider,
) => {
  const {
    data: { email },
  } = await landlog.getOne('accounts', { id: 1 });
  const { data } = await supportMode.getOne('supportMode', { id: email });
  const {
    enabled,
    siteId,
    siteName,
    corporationId,
    corporationName,
    latitude,
    longitude,
    photoUrl,
    workPeriodEndDate,
    workPeriodStartDate,
  } = data as Required<SupportModeData>;
  const site: SiteData = {
    id: siteId || ' ',
    name: siteName || ' ',
    corporationId: corporationId || ' ',
    corporationName: corporationName || ' ',
    latitude: latitude || 35.670873,
    longitude: longitude || 139.74211,
    photoUrl,
    workPeriodEndDate: workPeriodEndDate || new Date().toISOString(),
    workPeriodStartDate: workPeriodStartDate || new Date().toISOString(),
    status: 'Working',
    unitLength: 'M',
  };
  const corporation: CorporationData = {
    id: corporationId,
    name: corporationName,
    countryCode: '',
  };
  const account: AccountData = {
    id: 1,
    email,
    name: 'Support Mode',
    picture: '',
    corporationId,
    corporationName,
  };
  return { enabled, site, corporation, account };
};

export default (
  landlog: DataProvider,
  supportMode: DataProvider,
): DataProvider => ({
  getList: async (resource, params): Promise<any> => {
    const { enabled, corporation, site } = await getSupportModeData(
      landlog,
      supportMode,
    );
    if (enabled) {
      if (resource === 'sites') {
        if (site.id !== ' ') return { total: 1, data: [site] };
        return { total: 0, data: [] };
      }
      if (resource === 'corporations') return { total: 1, data: [corporation] };
    }
    return landlog.getList(resource, params);
  },
  getOne: async (resource, params): Promise<any> => {
    const { enabled, corporation, site, account } = await getSupportModeData(
      landlog,
      supportMode,
    );
    if (enabled) {
      if (resource === 'sites') {
        if (params.id !== site.id) throw new Error('Not Found');
        return { data: site };
      }
      if (resource === 'corporations') {
        if (params.id !== corporation.id) throw new Error('Not Found');
        return { data: corporation };
      }
      if (resource === 'accounts') {
        if (params.id !== 1) throw new Error('Not Found');
        return { data: account };
      }
    }
    return landlog.getOne(resource, params);
  },
  getMany: async (resource, params): Promise<any> => {
    const { enabled, corporation, site } = await getSupportModeData(
      landlog,
      supportMode,
    );
    if (enabled) {
      if (resource === 'sites') {
        if (params.ids.includes(site.id)) return { total: 1, data: [site] };
        return { total: 0, data: [] };
      }
      if (resource === 'corporations') {
        if (params.ids.includes(corporation.id))
          return { total: 1, data: [corporation] };
        return { total: 0, data: [] };
      }
    }
    return landlog.getMany(resource, params);
  },
  getManyReference: () => Promise.reject(new Error('not supported')),
  update: () => Promise.reject(new Error('not supported')),
  updateMany: () => Promise.reject(new Error('not supported')),
  create: () => Promise.reject(new Error('not supported')),
  delete: () => Promise.reject(new Error('not supported')),
  deleteMany: () => Promise.reject(new Error('not supported')),
});
