import { GetListParams, GetListResult } from 'react-admin';
import {
  BasicSettingDownloadInfo,
  CalibrationDownloadInfo,
  ConfigDownloadInfo,
  getHttpClient,
  RetrofitKitInfoDataObject,
} from '.';
import {
  RetrofitBasicSettingsData,
  RetrofitCalibsData,
  RetrofitConfigData,
  RetrofitKitInfoData,
} from '..';
import { config } from '../../utils';

const makeId = (retrofitId: number, timeStamp: string) =>
  `${retrofitId}_${timeStamp}`;

const convertWithUrl = (
  data: {
    retrofitId: number;
    timeStamp: string;
    url?: string;
    userCorporationId?: string;
  }[],
) =>
  data.map(({ retrofitId, timeStamp, url, userCorporationId }) => ({
    id: makeId(retrofitId, timeStamp),
    retrofitId,
    timeStamp,
    url: url || '',
    userCorporationId: userCorporationId || '',
  }));

const resourceMap = {
  retrofitConfigs: {
    apiResource: 'retrofitconfig',
    convert: (data: ConfigDownloadInfo[]): RetrofitConfigData[] =>
      convertWithUrl(data),
  },
  retrofitKitInfos: {
    apiResource: 'retrofitkitinfo',
    convert: (data: RetrofitKitInfoDataObject[]): RetrofitKitInfoData[] =>
      convertWithUrl(data),
  },
  retrofitCalibs: {
    apiResource: 'retrofitcalibs',
    convert: (data: CalibrationDownloadInfo[]): RetrofitCalibsData[] =>
      convertWithUrl(data),
  },
  retrofitBasicSettings: {
    apiResource: 'retrofitbasicsetting',
    convert: (data: BasicSettingDownloadInfo[]): RetrofitBasicSettingsData[] =>
      convertWithUrl(data),
  },
};

export default async (
  resource: keyof typeof resourceMap,
  { filter, sort, pagination: { page, perPage } }: GetListParams,
): Promise<GetListResult> => {
  if (!('retrofitId' in filter))
    throw new Error('retrofitId shoud be in filter');
  const { retrofitId, userCorporationId } = filter;
  const apiUrl = config.api.admin.apiUri;
  const { apiResource, convert } = resourceMap[resource];
  const jsonFilter = userCorporationId
    ? JSON.stringify({
        userCorporationId,
      })
    : undefined;
  const encodeUrl = jsonFilter
    ? `${apiUrl}/${apiResource}?retrofitId=${retrofitId}&filter=${encodeURIComponent(
        jsonFilter,
      )}`
    : `${apiUrl}/${apiResource}?retrofitId=${retrofitId}`;
  const httpClient = getHttpClient();
  const { json } = await httpClient(encodeUrl);
  const order = sort.field === 'id' ? 'DESC' : sort.order;
  const allData = [...convert(json)].sort((a, b) => {
    if (order === 'ASC') return a.timeStamp > b.timeStamp ? 1 : -1;
    return a.timeStamp < b.timeStamp ? 1 : -1;
  });
  const total = allData.length;
  const data = allData.slice((page - 1) * perPage, page * perPage - 1);
  return { data, total };
};
