// for it
// イタリア語
import raMessage from 'ra-language-italian';
import en from './en';

const { ra } = raMessage;
const message: typeof en = {
  ra: {
    ...ra,
    input: {
      ...ra.input,
      password: {
        toggle_visible: '',
        toggle_hidden: '',
      },
    },
    navigation: {
      ...ra.navigation,
      next: '',
      prev: '',
      no_results: 'Non trovato',
    },
    action: {
      ...ra.action,
      add_filter: '',
      confirm: 'OK',
      move_up: '',
      move_down: '',
      select_all: '',
      select_row: '',
    },
    message: {
      ...ra.message,
      bulk_delete_title: '',
      bulk_delete_content: 'Vuoi eliminare %{name}?',
    },
  },
  admin: {
    title: {
      normal: 'Smart Cconstruction Pilot',
      split1: 'Smart Cconstruction',
      split2: 'Pilot',
    },
    copyright: 'Copyright © EARTHBRAIN Ltd. All rights reserved.',
    approval: 'In attesa di approvazione',
    usermenu: {
      userNotice: 'Notifica',
      terms: 'TERMINI DI SERVIZIO',
      manual: 'Manuale',
      downloads: 'Scarica',
    },
    breadcrumbs: {
      details: 'Dettagli',
      retrofitEdit: 'Dettagli',
      taskCreate: 'Creare attività',
      taskEdit: 'Dettagli del compito',
      geofenceCreate: 'Creare',
      geofenceEdit: 'Modifica',
      geofenceAlertList: 'Allarme Geofence',
      geofenceAlertShow: 'Dettagli',
      compactionWorkAreaCreate: 'Aggiungi aree di lavoro di compattazione',
      compactionWorkAreaShow: 'Aree di lavoro di compattazione',
      compactionWorkAreaEdit: 'Modifica aree di lavoro di compattazione',
      compactionLayerCreate: 'Creare strati di compattazione',
      compactionLayerEdit: 'Modifica strati di compattazione',
      supportMode: 'Modalità di supporto',
      retrofitManage: 'Registrazione controller',
      retrofitManageAdmin: 'Registrazione controller (Admin)',
      retrofitNamePlateFiles: 'Registrazione controller',
      retrofitAlternateRegistCreate: 'Registrazione proxy controller',
      retrofitAlternateRegistEdit: 'Dettagli',
      retrofitManageAdminBulkActionCreate: 'Registrazione',
      terms: 'TERMINI DI SERVIZIO',
      downloads: 'Scarica',
    },
    pages: {
      siteList: 'Elenco cantieri',
      siteRetrofitCreate: 'Aggiungi macchina al cantiere',
      siteRetrofitEdit: '',
      siteBucketCreate: 'Aggiungi un secchio al cantiere',
      siteBucketEdit: 'Dettagli del secchio',
      siteProjectEdit: 'Dettagli progetto',
      siteProjectCreate: 'Registrazione file progetto',
      siteExtensionarmCreate: 'Aggiungi un braccio di estensione al cantiere',
      taskCreate: 'Creare attività',
      taskEdit: 'Dettagli del compito',
      geofenceAlertList: 'Allarme Geofence',
      geofenceAlertShow: 'Dettagli avviso Geofence',
      geofenceCreate: 'Creare geofence',
      geofenceEdit: 'Modifica geofence',
      compactionWorkAreaCreate: 'Aggiungi aree di lavoro di compattazione',
      compactionLayerCreate: 'Creare strati di compattazione',
      compactionLayerEdit: 'Modifica strati di compattazione',
      siteFileEdit: 'Dettagli',
      siteFileCreate: 'Aggiungi un file al cantiere',
      retrofitList: 'Elenco macchine',
      retrofitEdit: '',
      retrofitRentalCreate: "Registrazione dell'assegnazione",
      retrofitTransferCreate: 'Registrazione del trasferimento',
      retrofitSendBack: 'Invia nuovamente la registrazione',
      bucketList: 'Elenco secchi',
      bucketEdit: 'Dettagli del secchio',
      bucketCreate: 'Registrazione secchio',
      bucketRentalCreate: 'Condivisione secchio',
      extensionarmList: 'Elenco del braccio di estensione',
      extensionarmEdit: 'Dettagli del braccio di estensione',
      extensionarmShare: 'Condivisione braccio di estensione',
      retrofitBulkActionList: 'Elenco registrazione bulk',
      retrofitBulkActionCreate: 'Registrazione bulk',
      retrofitBasicInfoList: 'Elenco controller',
      retrofitBasicInfoCreate: 'Registrazione controller',
      retrofitBasicInfoEdit: 'Aggiornamento controller',
      gnssSettingList: 'Elenco delle impostazioni Ntrip',
      gnssSettingCreate: 'Creare impostazione Ntrip',
      gnssSettingAdminCreate: 'Creare impostazione Ntrip',
      gnssSettingOwnerCreate: 'Creare impostazione Ntrip',
      gnssSettingEdit: 'Modifica impostazione Ntrip',
      gnssSettingAdminEdit: 'Modifica impostazione Ntrip',
      gnssSettingOwnerEdit: 'Modifica impostazione Ntrip',
      approvalList: 'Elenco macchine',
      retrofitRentalEdit: "Dettagli dell'assegnazione",
      retrofitTransferEdit: 'Dettagli del trasferimento',
      asbuiltsCreate: 'Acquisire il risultato della costruzione',
      asbuiltsShow: 'Cronologia Asbuild',
      retrofitConfigList: 'Informazioni di configurazione',
      retrofitKitInfoList: 'Info controller',
      retrofitAccuraciesList: 'Risultato del controllo di precisione',
      retrofitAccuraciesShow: 'Dettagli',
      retrofitCalibaList: 'Info calibrazione',
      retrofitCalibInfo: 'Dettagli sulla calibrazione della macchina',
      retrofitBasicSettingList: 'Impostazioni di base',
      retrofitErrorList: "Informazioni sull'errore",
      retrofitErrorShow: 'Dettagli',
      retrofitTopographicSurveyList: 'Lista rilievi topografici',
      retrofitTopographicSurveyShow: 'Dettagli del rilevamento topografico',
      retrofitShareFileList: 'Registro applicazioni',
      retrofitCorporationLicenseList: 'Elenco licenze',
      retrofitCorporationLicenseEdit: 'Dettagli licenza',
      CompactionLayerList: 'Strati di compattazione',
      userNoticeList: 'Elenco notifiche',
      userNoticeCreate: 'Aggiungi notifica',
      userNoticeEdit: 'Aggiorna notifica',
      userNoticeDrawer: 'Notifica',
      retrofitAlternateRegists: 'Elenco registrazioni proxy controller',
      firmwareList: 'Elenco firmware',
      firmwareShow: 'Dettagli del firmware',
      firmwareCreate: 'Aggiungi firmware',
      retrofitManage: 'Testo',
      retrofitNamePlateFiles: 'Foto',
      retrofitManageAdminBulkAction: 'Registrazione bulk',
      retrofitManageAdminBulkActionCreate: 'Registrazione bulk',
      retrofitAlternateRegistCreate: 'Registrazione proxy controller',
      retrofitAlternateRegistEdit:
        'Dettagli di registrazione del proxy controller',
      retrofitAlternateRegistCreateText: 'Testo',
      retrofitAlternateRegistCreatePhoto: 'Foto',
      ntripList: 'Informazioni Ntrip',
      ntripEdit: 'Gestione informazioni Ntrip',
    },
    label: {
      login: 'Accesso',
      selected: 'Selezionati (%{length} casi)',
      listNoResults: 'Dati non trovati',
      maintenance: {
        title: 'Attualmente in manutenzione.',
        message: `Attualmente abbiamo sospeso i servizi per manutenzione.
      Ci scusiamo per l inconveniente, ma attendi ancora un momento.`,
      },
      fileInput: {
        project: 'xml, tp3, tn3, gc3, dxf, ttm, svd, dsz, cal',
        bucket: 'bk3',
        csv: 'csv',
        pdf: 'pdf',
        namePlate: 'jpg, png',
        plaseholder:
          'Rilascia o seleziona il/i file (%{fileTypes}) da caricare.',
        maxFileNum:
          'Il numero massimo di controller che possono essere registrati contemporaneamente è cinque.',
      },
      nosetting: 'Nessuna impostazione',
      siteRetrofitList: {
        remoteSupport: 'Supporto remoto',
      },
      retrofitEdit: {
        breadcrumbs: 'Dettagli',
        belongsDetails: '',
        ntripSettingInfo: 'Informazioni sulle impostazioni Ntrip',
        machineryDetails: 'Macchina',
        retrofitDetails: 'Controller',
        basicInfo: 'Info base',
        controller: 'Controller',
        reciverMain: 'Ricevitore princ GNSS',
        reciverSub: 'Ricevitore sec GNSS',
        retrofitstatus: 'Stato controller',
        license: 'Licenza',
      },
      retrofitRental: {
        rentalDetails: "Dettagli dell'assegnazione",
        rentalInfo: "Dettagli dell'assegnazione",
        lending: 'Assegnazione',
        return: 'Ritorno',
        lendingDestination: 'Azienda assegnata',
        lendingCompany: 'Nome azienda',
        lendingEmail: 'E-mail',
        companyName: 'Nome azienda',
      },
      retorfitTransfer: {
        tab: 'Trasferimento',
        transferInfo: 'Dettagli del trasferimento',
        transferButton: 'Trasferimento',
        transferEditButton: 'Modifica trasferimento',
      },
      siteConfigureEdit: {
        onButton: 'ON',
        offButton: 'OFF',
      },
      approvals: {
        rentalTab: 'Assegnazione',
        transferTab: 'Trasferimento',
      },
      buckets: {
        bucketTab: 'Benna',
        extensionarmTab: 'Prolunga braccio di scavo',
        extensionarmFile: 'File del braccio di estensione',
        shareTo: 'Condividi a',
      },
      bucketRental: {
        corporation: 'Condividi a',
        bucketType: 'Tipo',
        bk3File: 'File BK3',
      },
      retrofitBasic: {
        basicInfoTab: 'Info base',
        bulkActionTab: 'Registrazione bulk',
        ntripTab: 'Gestione informazioni Ntrip',
      },
      retrofitSendbacks: {
        retrofitSendbackInfo: 'Invia indietro',
        initialize: 'Inizializzazione',
      },
      retrofitBasicInfos: {
        bulkRegistrationTitle: 'Aggiornamento bulk',
        retrofitDistributor: 'Distributore',
        retrofitRegion: 'Regione',
        corporationId: 'ID azienda',
        corporationName: 'Nome azienda',
      },
      retrofitBasicInfoEdit: {
        corporationId: 'ID azienda in uso',
        corporationName: 'Azienda in uso',
      },
      gnssSettingsEdit: {
        ownerCorporationName: 'Nome azienda',
      },
      terms: {
        title: 'TERMINI DI SERVIZIO',
        message:
          'Si prega di fare clic sul link sottostante per revisionare i Termini di utilizzo.',
        confirmMessage1:
          'Per utilizzare Smart Construction Pilot, è necessario accettare il seguente documento o documenti.',
        confirmMessage2:
          "Se non sei d'accordo, non potrai utilizzare Smart Construction Pilot.",
        select: 'Si prega di selezionare la propria regione.',
      },
      downloads: {
        title: 'Convertitore di file di progetto',
        message:
          "Si tratta di un'applicazione autonoma che crea un file di progetto dai file di progetto.",
        button: 'Scarica',
      },
      retrofitBasicInfoCreate: {
        uuid: 'Numerazione automatica',
      },
      retrofitBulkActions: {
        uploadFileDownload: 'Scarica',
        resultFileDownload: 'Scarica',
      },
      tasks: {
        coordinate: 'Coordinate (unit:%{unit})',
        coordinateX: 'X',
        coordinateY: 'Y',
      },
      compactionWorkAreas: {
        blockSize: 'Dimensione del blocco (%{unit})',
      },
      compactionLayers: {
        thickness: 'Spessore (%{unit})',
      },
      pendingRentals: {
        breadcrumbs: 'Dettagli',
        rentalDetail: "Dettagli dell'assegnazione",
      },
      pendingTransfers: {
        breadcrumbs: 'Dettagli',
        transferDetail: 'Dettagli del trasferimento',
      },
      pendingAlternateRegists: {
        breadcrumbs: 'Dettagli',
        alternateRegistDetail: 'Dettagli di registrazione del proxy controller',
      },
      supportMode: {
        retrofit: 'Macchina',
        optional: 'Opzionale',
      },
      retrofitList: {
        assignment: 'Assegnazione',
        license: 'Licenza',
      },
      userNotices: {
        languageDetails: 'Contenuti della notifica',
        language: 'Lingua',
        languageEn: 'English',
        languageJa: 'Giapponese',
        languageFr: 'Francese',
        languageDe: 'Tedesco',
        languageEs: 'Spagnolo',
        Information: 'Notifica',
        Maintenance: 'Manutenzione',
        emergency: 'Emergenza',
        important: 'Importante',
      },
      userNoticeDrawer: {
        more: '',
        close: '',
      },
      retrofitsManage: {
        menuTitle: 'Registrazione controller',
        corporationName: 'Nome azienda',
        basicInfoSerialNumber: 'Numero di serie',
        confirmInput: 'Reinserisci il numero di serie',
      },
      retrofitNamePlateFiles: {
        serialNumber: 'Numero di serie',
        status: 'Stato',
        imageFile: 'Immagine',
        message1:
          'Si prega di scattare una foto del numero di serie del controller o del codice QR che sia chiara e non sfocata.',
        message2: 'Le immagini devono essere inferiori a %{mbyte} MB.',
        errormessage:
          'Il numero massimo di controller che possono essere registrati contemporaneamente è cinque.',
      },
      retrofitCorporationLicenses: {
        licenseStatusChoices: {
          enable: 'Valido',
          disable: 'Non valido',
          unLicense: 'Non assegnato',
        },
        machine: 'Macchina',
        before: 'Prima',
        after: 'Dopo',
      },
      geofences: {
        project: 'Progetto',
        projectSourceLayer: 'Layer',
        rgb: 'Colore(RGB)',
        select: 'Geofence di selezione',
        coordinates: 'Coordinate',
        coordinatesLabel: 'Coordinate (N,E)',
        unitLength: 'Unità:%{unit}',
      },
      geofenceDialog: {
        coordinate: 'Coordinate (unit:%{unit})',
        coordinateX: 'X',
        coordinateY: 'Y',
        radius: 'Raggio',
      },
      retrofitAlternateRegists: {
        menuTitle: 'Registrazione proxy controller',
        corporationName: 'Nome azienda',
        email: 'E-mail',
        basicInfoSerialNumber: 'Numero di serie',
        confirmInput: 'Reinserisci il numero di serie',
      },
      ntripList: {
        unusedTotal: 'Totale non utilizzato',
      },
      calibrationDetail: {
        breadcrumbs: 'Dettagli',
        machineInfo: 'Info macchina',
        machineGeometryInfo: 'Info geometria macchina',
        bodyCenterToBoomFootPin: '',
        imuMountInfo: 'Info montaggio IMU',
        gnssMountInfo: '',
        bodyCenterToGnssMain: 'Info montaggio GNSS princ',
        bodyCenterToGnssSub: 'Info montaggio GNSS sec',
        extendedModel: 'Modello esteso',
        measuredValues: 'Info calibrazione',
      },
    },
    errorInfo: {
      projects: {
        system_scapi_error: 'Errore di conversione dati',
        system_localization_quantity_error: `Non è possibile registrare più file contenenti informazioni di localizzazione.
      Si prega di registrare un solo file contenente informazioni di localizzazione.`,
        system_detect_0byte_file_error: `La registrazione del file sul server non è riuscita a causa di un errore di comunicazione.
      Si prega di registrare nuovamente il file.`,
        system_batch_error: 'Errore di sistema (P001)',
        system_parameter_error: 'Errore di sistema (P002)',
        system_parameterfile_notfound: 'Errore di sistema (P003)',
        system_exception: 'Errore di sistema (P004)',
        system_logfile_error: 'Errore di sistema (P005)',
        system_parameterfile_parameter_error: 'Errore di sistema (P006)',
        desing_localization_error: `Non ci sono informazioni di localizzazione disponibili.
      Si prega di registrare uno dei seguenti file:

      ・File GC3
      ・File TP3 contenente punti di controllo
      ・File CAL`,
        desing_no_support_dxf_error:
          'Il/i file(s) DXF non può/non possono essere letto/i perché la sua/loro versione è più vecchia di AutoCAD2000.',
        desing_tile_error: 'Impossibile creare il file di progetto.',
        desing_outline_error:
          'La creazione della linea di confine esterna è fallita.',
        desing_area_length_error:
          "Il processo è stato interrotto perché l'area di progetto è troppo grande. Verificare che i dati di progetto e la scala dei dati CAD siano corretti.",
        desing_layer_name_duplication_error: `I dati del progetto con nomi di layer duplicati non possono essere registrati.
      Si prega di cambiare il nome del layer di progettazione o registrarlo in modo che ci sia solo un file con lo stesso nome del layer di progettazione.`,
        targetFiles: '[Nome file]',
        targetLayers: '[Layer]',
        errorFiles: '・%{errorFile}',
      },
      tasks: {
        none: 'Nessuno',
        system_batch_error: 'Errore di sistema (T001)',
        system_parameter_error: 'Errore di sistema (T002)',
        system_parameterfile_notfound: 'Errore di sistema (T003)',
        system_exception: 'Errore di sistema (T004)',
        system_logfile_error: 'Errore di sistema (T005)',
        system_basefile_notfound: 'Errore di sistema (T006)',
        system_prjfile_multi: 'Errore di sistema (T007)',
        system_basefile_read_error: 'Errore di sistema (T008)',
        system_target_layer_notfound: 'Errore di sistema (T009)',
        system_target_layer_duplication:
          'Lo stesso nome del livello esiste già.',
        system_taskarea_error: 'Area di attività non valida',
        system_taskarea_design_notfound:
          "Non ci sono dati per il file di progetto all'interno dell'area di lavoro (TIN, linework, punti).",
        system_tile_error: 'Impossibile creare il file di progetto.',
        system_outline_error:
          'La creazione della linea di confine esterna è fallita.',
        system_parameterfile_error: 'Errore di sistema',
      },
      retrofitError: {
        1000: 'Dati UDP da controller non rilevati',
        2000: 'IMU cassone non rilevato',
        2001: 'Errore hardware IMU cassone',
        2002: 'Errore algoritmo IMU cassone',
        2003: 'Errore dati IMU cassone',
        2004: 'Errore inizializzazione IMU cassone',
        2100: 'IMU braccio di sollevamento non rilevato',
        2101: 'Errore hardware IMU braccio di sollevamento',
        2102: 'Errore algoritmo IMU braccio di sollevamento',
        2103: 'Errore dati IMU braccio di sollevamento',
        2104: 'Errore inizializzazione IMU braccio di sollevamento',
        2200: 'IMU braccio di scavo non rilevato',
        2201: 'Errore hardware IMU braccio di scavo',
        2202: 'Errore algoritmo IMU braccio di scavo',
        2203: 'Errore dati IMU braccio di scavo',
        2204: 'Errore inizializzazione IMU braccio di scavo',
        2300: 'IMU benna non rilevato',
        2301: 'Errore hardware IMU benna',
        2302: 'Errore algoritmo IMU benna',
        2303: 'Errore dati IMU benna',
        2304: 'Errore inizializzazione IMU benna',
        2400: 'IMU benna inclinabile non rilevato',
        2401: 'Errore hardware IMU benna inclinabile',
        2402: 'Errore algoritmo IMU benna inclinabile',
        2403: 'Errore dati IMU benna inclinabile',
        2404: 'Errore inizializzazione IMU benna inclinabile',
        2500: 'IMU 2° braccio di sollevamento non rilevata',
        2501: 'Errore hardware IMU 2° braccio di sollevamento',
        2502: 'Errore algoritmo IMU 2° braccio di sollevamento',
        2503: 'Errore dati IMU 2° braccio di sollevamento',
        2504: 'Errore inizializzazione IMU 2° braccio di sollevamento',
        2600: 'Sensore angolo di rotazione non rilevato',
        2601: "Errore di sistema del sensore dell'angolo di oscillazione",
        2602: 'Errore hardware del sensore angolo di oscillazione',
        2700: 'Sensore di offset non rilevato',
        2701: 'Errore di sistema del sensore di offset',
        2702: 'Errore hardware del sensore di offset',
        3000: 'Sensore pressione lato superiore non rilevato',
        3100: 'Sensore pressione lato inferiore non rilevato',
        4000: 'Antenna GNSS non rilevata',
        5000: 'Dati di correzione non rilevati',
        5001: 'Segnale radioricevitore non rilevato',
      },
    },
    dialog: {
      menu: {
        confirmManualLink:
          'Verrete reindirizzati a un altro sito web. Va bene così?',
      },
      bucketEdit: {
        deleteConfirm: {
          messageTargetItem: 'Benna',
        },
      },
      retrofitBasicInfoList: {
        saveValidation: {
          message: 'ID azienda e nome azienda sono richiesti.',
        },
        save: {
          message1: 'ID/nome azienda non può essere cambiato perché include',
          message2: "macchina(e) assegnata(e) a un'altra società.",
          message3: '',
          message4: 'Si prega di controllare i seguenti numeri di serie',
        },
      },
      retrofitBasicInfoEdit: {
        deleteConfirm: {
          messageTargetItem: 'Controller',
        },
        undeletable: {
          message: 'Il controller è in uso e non può essere eliminato.',
        },
      },
      Ntrip: {
        save: {
          stop: 'Vuoi fermare questo impostazione Ntrip?',
          delete: "Vuoi eliminare l'impostazione Ntrip?",
        },
      },
      siteRetrofitList: {
        title: 'Informazioni supplementari',
        message: 'Il periodo di costruzione è terminato.',
      },
      siteConfigureEdit: {
        title: {
          commonSetting: 'Impostazione comune',
          siteSetting: 'Impostazioni cantiere',
        },
        mapBackgroundColor: 'Colore di sfondo',
        message:
          "La funzione di notifica del rilevamento contatto/allarme geofence potrebbe non funzionare correttamente a seconda dell'ambiente e delle condizioni in cui si utilizza. Non utilizzare con eccessiva fiducia la funzione; comprenderne il funzionamento e le condizioni d’uso.",
      },
      geofenceList: {
        confirmDelete: {
          message: 'Vuoi eliminare questo geofence?',
        },
      },
      retrofitRentalCreate: {
        unableTieUpJobSite: {
          message1:
            'La macchina è collegata a un cantiere e non può essere assegnata. Scollegare la macchina dal cantiere.',
          message2: 'Si prega di disconnettere la macchina dal cantiere.',
        },
        sameOwner: {
          message:
            "La macchina non può essere assegnata perché l'azienda è proprietaria.",
        },
        unableTransfer: {
          message:
            "La macchina è in attesa di approvazione del trasferimento e non può essere registrata per l'assegnazione.",
        },
        unableAlternateRegist: {
          message:
            "La macchina è in attesa dell'approvazione della registrazione del controller proxy e non può essere registrata per l'assegnazione.",
        },
      },
      retrofitTransferCreate: {
        messageSiteId:
          'La macchina è collegata a un cantiere e non può essere trasferita. Scollegare la macchina dal cantiere.',
        messageRentaled: 'La macchina è assegnata e non può essere trasferita.',
        messageLicense:
          'La macchina ha una licenza e non può essere trasferita.',
        pendingApprovals: {
          message1:
            "La richiesta di trasferimento è stata ricevuta. Attendere l'approvazione.",
          message2: "Se hai domande, contattaci dall'URL sottostante.",
        },
      },
      retrofitSendBacks: {
        submit:
          'Vuoi rispedire il controller e ereditare le impostazioni sul controller di destinazione?',
        deleteNtrip: 'Vuoi inizializzare il controller restituito?',
        unableRegion:
          'Questo controller è stato spedito all\'estero. Se questo controller è quello corretto, reimposta la "Regione" a null.',
        unableCorporation: {
          message1: 'Il controller non può essere restituito.',
          message2:
            'Se ritieni che si tratti di un errore, contatta il tuo rivenditore.',
        },
        retrofitRentaled:
          'Il controller è assegnato e non può essere restituito.',
        tiedToSite:
          'La macchina è collegata a un cantiere e non può essere rispedita.',
      },
      retrofitAddToSiteAction: {
        title: 'Informazioni supplementari',
        message:
          "C'è una macchina in attesa di approvazione, quindi non può essere collegata al cantiere.",
      },
      retrofitAddToSiteActionTransfer: {
        title: 'Informazioni supplementari',
        message:
          'La macchina è in fase di trasferimento e non può essere collegata al cantiere.',
      },
      retrofitEdit: {
        unableSettingGnessInfo: {
          message:
            "L'impostazione Ntrip selezionata viene inviata a un'altra macchina.",
        },
        unableTransferInfo: {
          message:
            'Per trasferire, si prega di annullare le seguenti impostazioni.',
          reasonSiteId: 'Cantiere',
          reasonRentaled: 'Assegnazione',
          reasonLicense: 'Licenza',
        },
      },
      retrofitRentalEdit: {
        overDoubleRentalStartDate: {
          message1:
            "L'azienda assegnata sta attualmente assegnando la macchina a un'altra azienda. La data di inizio dell'assegnazione a un'altra azienda è il %{date}.",
          message2: 'Si prega di selezionare una data di fine dopo %{date}.',
        },
        confirmTieUpJobsite: {
          message:
            "La macchina è collegata a un cantiere dell'azienda assegnata. Si desidera terminare l'assegnazione?",
        },
        confirmDoubleRental: {
          message:
            "\"L'azienda assegnata sta attualmente assegnando la macchina a un'altra azienda. L'azienda assegnata sta attualmente assegnando la macchina a un'altra azienda. Impostare una data di fine termina anche l'assegnazione a un'altra azienda. Vuoi impostare la data di fine?\"",
        },
        unapprovedSave: {
          message:
            'Questo incarico non è stato approvato e non può essere salvato.',
        },
        unapprovedDelete: {
          title: '',
          message: 'Vuoi eliminare questo compito?',
        },
        approvedDelete: {
          title: '',
          message:
            'Questo incarico è stato approvato e non può essere cancellato.',
        },
      },
      retrofitTransferEdit: {
        unapprovedSave: {
          title: '',
          message:
            'La macchina è attualmente in assegnazione e non può essere trasferita.',
        },
        approvedSave: {
          message: 'La macchina ha una licenza e non può essere trasferita.',
        },
        unapprovedDelete: {
          title: '',
          message: 'Vuoi eliminare questo trasferimento?',
        },
        approvedDelete: {
          title: '',
          message:
            'Questo trasferimento è stato approvato e non può essere cancellato.',
        },
      },
      extensionarmEdit: {
        deleteConfirm: {
          messageTargetItem: 'Prolunga braccio di scavo',
        },
      },
      projectFileUpload: {
        message:
          'Non è possibile registrare più file di punti di controllo (.gc3/.cal).',
      },
      projectEdit: {
        confirm: {
          message:
            'Una volta aggiornato il progetto, verranno eliminati i compiti esistenti. Vuoi aggiornare?',
        },
      },
      projectConversionStatus: {
        title: 'Stato di conversione',
        button: 'OK',
      },
      taskConversionStatus: {
        title: 'Stato di conversione',
        button: 'OK',
      },
      taskList: {
        undeletable: {
          message:
            "L'ordine selezionato non può essere cancellato perché è in uso.",
        },
        unableToCreate: {
          message: 'Nessun dato di progetto è stato registrato.',
        },
        unableToDelete: {
          message1:
            "L'attività non può essere eliminata perché lo stato del progetto e lo stato dell'attività sono i seguenti.",
          message2: 'Progetto: Converting',
          message3: 'Compito: Errore',
        },
        unableToUpdate: {
          message1:
            "L'attività non può essere modificata perché lo stato del progetto e lo stato dell'attività non sono i seguenti.",
          message2: 'Progetto: [Convertito]',
          message3:
            'Compito: [Trasferimento][Trasferito][Trasferimento non riuscito]',
        },
      },
      taskEdit: {
        deleteConfirm: {
          messageTargetItem: 'Compito',
        },
        unableToDelete: {
          message:
            "L'ordine selezionato non può essere cancellato perché è in uso.",
        },
      },
      taskMap: {
        crossingMessage: "L'area del compito è intersecata.",
        sameValueMessage: 'Sono state inserite le stesse coordinate.',
        areaZeroMessage: "Non c'è un'area per l'area di lavoro.",
        noPolygonMessage:
          "L'attività non può essere registrata perché non è ancora stata registrata.",
      },
      compactionMaterials: {
        add: {
          title: 'Aggiungi un materiale',
        },
        update: {
          title: 'Aggiorna un materiale',
        },
      },
      weathers: {
        add: {
          title: 'Aggiungi un meteo',
        },
        update: {
          title: 'Aggiorna il meteo',
        },
      },
      operators: {
        add: {
          title: 'Aggiungi un operatore',
        },
        update: {
          title: 'Aggiorna un operatore',
        },
      },
      compactionWorkAreas: {
        update: {
          message: 'Modifica aree di lavoro di compattazione',
        },
        undeletable: {
          message:
            "Lo strato di compattazione incluso nell'area di costruzione è in uso e non può essere eliminato.",
        },
      },
      compactionLayers: {
        undeletable: {
          message:
            'Lo strato di compattazione è in uso e non può essere eliminato.',
        },
        deleteConfirm: {
          messageTargetItem: 'Strato di compattazione',
        },
      },
      gnssSettings: {
        unableSettingGnessInfo: {
          message:
            "Un'altra impostazione Ntrip viene inviata alla macchina selezionata.",
        },
        confirmDelete: {
          message: "Vuoi eliminare l'impostazione Ntrip?",
        },
        confirmDeleteWithMachine: {
          message:
            "La macchina è attualmente collegata. Si desidera eliminare l'impostazione Ntrip?",
        },
      },
      userNoticeEdit: {
        deleteConfirm: {
          messageTargetItem: 'Notifica',
        },
      },
      retrofitCorporationLicenses: {
        confirm: {
          message: 'Imposta la/e licenza/e sulla/e seguente/i macchina/e.',
        },
        changeDisable: {
          message:
            "Si prega di cambiare l'ID della licenza della seguente macchinario edile in valido.",
        },
        changeTemporary: {
          message:
            'Imposta la/e licenza/e sulla/e seguente/i macchina/e. La licenza temporanea attualmente impostata sarà invalidata.',
        },
        errorAdd: {
          message:
            'La licenza della macchina non può essere modificata perché è già stata impostata la licenza Tutti.',
        },
        errorSameType: {
          message:
            'La licenza della macchina non può essere modificata perché è già stato impostato lo stesso tipo di licenza.',
        },
        errorUnlimited: {
          message:
            'La macchina ha già impostato la licenza Unlimited, non è possibile impostare altre licenze.',
        },
        addAll: {
          message:
            'Sulla macchina seguente sono già state impostate le licenze. Si desidera impostare tutte le licenze?',
        },
        compare: {
          message: 'Cambia la/e licenza/e per la/e seguente/i macchina/e.',
        },
        warning: {
          message:
            '%{retrofit} ha la licenza di %{id}. Vuoi disconnettere la licenza?',
        },
        unableTransfer: {
          message:
            'La macchina è stata trasferita e la licenza non può essere collegata.',
        },
      },
      retrofitNamePlateFiles: {
        error: {
          serial:
            "Ci sono dati che non sono stati in grado di analizzare l'immagine.",
        },
      },
      retrofitManageTab: {
        pendingApprovals: {
          message1:
            "È stata ricevuta una richiesta di registrazione. Attendere l'approvazione.",
          message2: "Se hai domande, contattaci dall'URL sottostante.",
        },
        corporationIdCheck: {
          message1:
            'Il controller è già stato registrato. Controlla cercando nelle macchine.',
          message2:
            "Se non riesci a trovare il controller di destinazione nell'elenco, contattaci dall'URL sottostante.",
        },
        earthbrainIdCheck: {
          message1: "Ci scusiamo per l'inconveniente",
          message2:
            "ale vi preghiamo di contattarci dall'URL sottostante con le seguenti informazioni.",
          message3: '-Numero di serie controller',
          message4: '-Nome della macchina da registrare (opzionale)',
          message5: '-ID azienda da registrare',
        },
        invalidSerialNumber: {
          message1: "Il controller è già in uso da un'altra azienda.",
          message2: 'Se vuoi cambiarlo per altri motivi,',
          message3:
            "Si prega di contattarci all'URL sottostante con le seguenti informazioni.",
          message4: '-Numero di serie controller',
          message5: '-Nome della macchina da registrare (opzionale)',
          message6: '-ID azienda da registrare',
          message7: '-Motivo del cambio',
        },
        lendingRetrofits: {
          message1: 'Il controller è assegnato.',
          message2:
            "Si prega di ripresentare la domanda dopo la fine dell'assegnazione presso l'azienda assegnatrice.",
          message3: "Se hai domande, contattaci dall'URL sottostante.",
        },
        siteAttached: {
          message1: 'Il controller è collegato a un cantiere.',
          message2:
            'Si prega di ripresentare la domanda dopo aver disconnesso la macchina dal cantiere.',
          message3: "Se hai domande, contattaci dall'URL sottostante.",
        },
        serialNumberExistError: {
          message1:
            'Questo è il numero di serie di un controllore che non esiste. Assicurarsi che il numero di serie del controllore inserito sia corretto.',
          message2: "Se il problema persiste, contattaci dall'URL sottostante.",
        },
        ntripShortage: {
          message1:
            'Non siamo riusciti a emettere il tuo ID utente e la tua password Ntrip.',
          message2: "Si prega di contattarci all'URL sottostante.",
        },
        serialnumberCheck: {
          message:
            'Si prega di controllare i prodotti con i seguenti numeri di serie',
        },
      },
      retrofitAlternateRegists: {
        deleteConfirm: {
          messageTargetItem: 'retrofitAlternateRegists',
        },
        saveValidation: {
          message: "L'e-mail è richiesto",
        },
        pendingApprovals: {
          message1:
            'È stata ricevuta la richiesta di registrazione. Attendere fino a quando non sarà approvata.',
          message2: "Se hai domande, contattaci dall'URL sottostante.",
        },
        corporationIdCheck: {
          message1:
            'Il controller è già stato registrato. Controlla cercando nelle macchine.',
          message2:
            "Se non riesci a trovare il controller di destinazione nell'elenco, contattaci dall'URL sottostante.",
        },
        earthbrainIdCheck: {
          message1: "Ci scusiamo per l'inconveniente",
          message2:
            "ale vi preghiamo di contattarci dall'URL sottostante con le seguenti informazioni.",
          message3: '-Numero di serie controller',
          message4: '-Nome della macchina da registrare (opzionale)',
          message5: '-ID azienda da registrare',
        },
        invalidSerialNumber: {
          message1: "Il controller è già in uso da un'altra azienda.",
          message2: 'Se vuoi cambiarlo per altri motivi,',
          message3:
            "Si prega di contattarci all'URL sottostante con le seguenti informazioni.",
          message4: '-Numero di serie controller',
          message5: '-Nome della macchina da registrare (opzionale)',
          message6: '-ID azienda da registrare',
          message7: '-Motivo del cambio',
        },
        lendingRetrofits: {
          message1: 'Il controller è assegnato.',
          message2:
            "Si prega di ripresentare la domanda dopo la fine dell'assegnazione presso l'azienda assegnatrice.",
          message3: "Se hai domande, contattaci dall'URL sottostante.",
        },
        siteAttached: {
          message1: 'Il controller è collegato a un cantiere.',
          message2:
            'Si prega di ripresentare la domanda dopo aver disconnesso la macchina dal cantiere.',
          message3: "Se hai domande, contattaci dall'URL sottostante.",
        },
        serialNumberExistError: {
          message1:
            'Questo è il numero di serie di un controllore che non esiste. Assicurarsi che il numero di serie del controllore inserito sia corretto.',
          message2: "Se il problema persiste, contattaci dall'URL sottostante.",
        },
        ntripShortage: {
          message1:
            'Non siamo riusciti a emettere il tuo ID utente e la tua password Ntrip.',
          message2: "Si prega di contattarci all'URL sottostante.",
        },
        serialnumberCheck: {
          message:
            'Si prega di controllare i prodotti con i seguenti numeri di serie',
        },
      },
      pendingApprovals: {
        ownerCorporation: {
          message1:
            'Questo controllore è già di proprietà della vostra azienda.',
          message2: "Si prega di contattare l'azienda richiedente.",
        },
        userCorporation: {
          message1:
            'Non è possibile approvare questa richiesta perché si sta già prestando questo controller.',
          message2: "Si prega di contattare l'azienda richiedente.",
        },
      },
      licensePurchaseNotice: {
        button: 'OK',
        message1: `C'è una macchina in uso sul posto senza licenza. 
        Per l'utilizzo in loco è necessaria una licenza. 
        Se non è stata acquistata una licenza, acquistarla dal Marketplace Smart Construction.`,
        message2: `
        Se la macchina è stata noleggiata da un'altra azienda, si prega di richiedere una licenza per la macchina alla fonte del noleggio. 
        
        In caso di domande, consultare il seguente sito di supporto.`,
      },
    },
    tootip: {
      retrofitShareFileList: 'Registro applicazioni',
      retrofitConfigList: 'Informazioni di configurazione',
      retrofitKitInfoList: 'Info controller',
      retrofitAccuraciesList: 'Risultato del controllo di precisione',
      retrofitCalibaList: 'Info calibrazione',
      retrofitBasicSettingList: 'Parametri base',
      retrofitErrorList: 'Dettagli errore',
      retrofitTopographicSurveyList: 'Rilievo topografico',
    },
    actions: {
      ok: 'OK',
      add: 'Aggiungi',
      create: 'Registrazione',
      yes: 'Sì',
      no: 'No',
      confirmation: 'Conferma',
      save: 'SALVA',
      cancel: 'Annulla',
      required: 'Richiesto',
      addMachineToSite: 'Aggiungi macchina al cantiere',
      addBucketToSite: 'Aggiungi',
      deleteFromSite: 'Disconnetti dal Jobsite',
      removeLicense: 'Disconnetti dalla macchina',
      lendMachine: 'Assegnazione',
      transferMachine: 'Trasferimento',
      remoteSupport: 'Supporto remoto',
      lendBucket: 'Condividi',
      approval: 'Approvazione',
      connect: 'Connetti',
      creationInstructions: 'Acquisire il risultato della costruzione',
      csvDownload: 'Scarica',
      retrofitBundleCreate: 'Registrazione bulk',
      termsAccept: 'Accetta',
      termsDeny: 'Non concordare',
      bulkRegistration: 'Aggiornamento bulk',
      bulkRegistrationOK: 'OK',
      compactionItems: 'Elementi di compattazione',
      addLanguage: 'Aggiungi lingua',
    },
    message: {
      error: 'Si è verificato un errore',
      confirm: 'Va bene?',
      fileUpload: 'Trascina e rilascia i file qui',
      success: 'Registrazione completata',
      approved: "L'approvazione è stata completata",
      deleteConfirmTitle: '',
      deleteConfirmContent: 'Vuoi eliminare %{title}?',
      bulkDeleteConfirmContent: 'Vuoi eliminare %{title}?',
      disconnectFromSite: 'Vuoi disconnetterti dal Jobsite?',
      saveConfirmContent: 'Conferma',
      removeLicense: 'Vuoi disconnetterti dalla macchina?',
      task: {
        crossingMessage: "L'area del compito è intersecata.",
        sameValueMessage: 'Sono state inserite le stesse coordinate.',
        areaZeroMessage: "Non c'è un'area per l'area di lavoro.",
        invalidPolygonMessage: 'Sono state inserite le stesse coordinate.',
      },
      geofence: {
        crossingMessage: "L'area di geofence è intersecata.",
        sameValueMessage: 'Sono state inserite le stesse coordinate.',
        areaZeroMessage: "Non c'è un'area per la geofence.",
      },
      licenseError:
        'Non si dispone di una licenza per Smart Construction Pilot (Web App).',
      retrofitsManage: {
        serialNumberMatchError: 'Il numero di serie non corrisponde.',
        serialNumberInputError: 'Il numero di serie non esiste.',
        invalidAccess:
          'Non siete autorizzati a visualizzare questa pagina, tornate alla pagina iniziale.',
      },
      retrofitsAlternateRegistText: {
        serialNumberMatchError: 'Il numero di serie non corrisponde.',
        serialNumberInputError: 'Il numero di serie non esiste.',
        retrofitRentaled:
          'Le informazioni aziendali non possono essere aggiornate mentre il controller è in missione.',
        invalidCorporation:
          'Poiché la tua azienda non è un distributore, non puoi effettuare la registrazione proxy del controller.',
      },
    },
    validation: {
      startDate: 'Data di inizio',
      endDate: 'Data di fine',
      invalidDateFormat: 'Il formato data non è valido.',
      invalidDateTimeFormat: 'Il formato data e ora non è valido.',
      duplicateProject: 'Nome progetto duplicato',
      incorrectBeforeDate:
        '%{target} deve essere impostato prima di %{before}.',
      incorrectAfterDate: '%{target} deve essere impostato dopo %{after}.',
      incorrectPlanStartDate:
        'La data di inizio prevista deve essere prima della data di fine prevista.',
      incorrectPlanEndDate:
        'La data di fine prevista deve essere una data successiva alla data di inizio prevista.',
      incorrectActualStartDate:
        'La data di inizio deve essere una data precedente alla data di fine.',
      incorrectActualEndDate:
        'La data di fine deve essere impostata una data dopo la data di inizio.',
      withActualStartDate: 'Si prega di inserire anche la data di inizio.',
      inputAvailableDate: 'La data che può essere inserita è dal %{date}.',
      registrationRequired:
        "Si prega di selezionare l'azienda assegnata o l'indirizzo e-mail.",
      bothInput:
        "È possibile inserire solo l'azienda assegnata o l'indirizzo e-mail.",
      supportMode: 'Si prega di inserire un ID aziendale o un ID del cantiere.',
      invalidBkFile: 'Non è un file BK3.',
      invalidPdfFile: 'Non è un file PDF.',
      duplicatedBucketName: 'Il nome secchio è duplicato',
      duplicatedTaskName: 'Il nome del compito è duplicato.',
      duplicateGeofenceName: 'Il nome Geofence è duplicato',
      duplicatedCompactionWorkAreaName:
        'Nome area di lavoro di compattazione duplicato',
      duplicatedCompactionLayerName: 'Nome strato di compattazione duplicato',
      duplicated: {
        compactionMaterials: {
          message: 'Nome materiale duplicato',
        },
        weathers: {
          message: 'Nome meteo duplicato',
        },
        operators: {
          message: 'Nome operatore duplicato',
        },
      },
      containInvalidString:
        'Sono inclusi caratteri non validi. (%{msg} non è consentito)',
      validHalfWidthAlphanumeric:
        'Sono consentiti solo caratteri alfanumerici a metà larghezza.',
      validNumberFormat: 'Non è un numero.',
      validIntegerFormat: 'Non è un intero.',
      validIntegerRangeFormat: "L'input è consentito da 0 a 2147483647.",
      validDuplicateLanguage: 'È stata selezionata la stessa lingua.',
      validBeforeDate:
        '%{target} deve essere impostato a una data e ora prima di %{before}.',
      validAfterDate:
        '%{target} deve essere impostato a una data e ora dopo %{after}.',
    },
  },
  resources: {
    sites: {
      name: 'Cantiere',
      fields: {
        name: 'Cantiere',
        corporationName: 'Nome azienda',
        status: 'Stato',
        workPeriodStartDate: 'Data di inizio',
        workPeriodEndDate: 'Data di fine',
      },
      status: {
        completed: 'Completato',
        waiting: "Prima dell'inizio della costruzione",
        working: 'In costruzione',
      },
    },
    retrofits: {
      name: 'Macchina',
      fields: {
        information: 'Info macchina',
        rental: 'Assegnazione',
        sendBack: 'Invia indietro',
        corporationId: 'Azienda in uso',
        siteId: 'Cantiere',
        uuid: 'UUID',
        isError: 'Stato',
        isOnline: 'Online',
        lastOnlineTime: 'Ultima connessione',
        basicInfoManufacturer: 'Produttore',
        basicInfoModel: 'Modello',
        basicInfoProductNumber: 'N. prodotto',
        basicInfoSerialNumber: 'N. di serie',
        controllerManufacturer: 'Produttore',
        controllerModel: 'Modello',
        controllerFirmwareVersion: 'Ver. firmware',
        gnssReceiverMainManufacturer: 'Produttore',
        gnssReceiverMainModel: 'Modello',
        gnssReceiverMainFirmwareVersion: 'Ver. firmware',
        gnssReceiverSubManufacturer: 'Produttore',
        gnssReceiverSubModel: 'Modello',
        gnssReceiverSubFirmwareVersion: 'Ver. firmware',
        machineInfoCompanyName: 'Produttore',
        machineInfoMachineType: 'Tipo macchina',
        machineInfoMachineName: 'Nome macchina',
        machineInfoMachineId: 'ID macchina',
        rentalStatus: 'Assegnazione',
        lastUpdated: 'Ultimo aggiornamento',
      },
      isError: {
        true: 'Errore',
        false: 'Normale',
      },
      isOnline: {
        true: 'Online',
        false: 'Offline',
      },
      machineInfoMachineType: {
        excavator: 'Escavatore',
        roadroller: 'Rullo stradale',
        dozer: 'Bulldozer',
        rigiddump: 'Cassone fisso',
        wheelloader: 'Pala gommata',
        grader: 'Gradatore',
        automobile: 'Automobile',
      },
      licenseMachineType: {
        excavator: 'Escavatore',
        roadroller: 'Rullo stradale',
        dozer: 'Bulldozer',
        rigiddump: 'Cassone fisso',
        wheelloader: 'Pala gommata',
        grader: 'Gradatore',
        automobile: 'Automobile',
        excavator_license: 'Escavatore : %{licenseGrantInfo}',
        roadroller_license: 'Rullo stradale : %{licenseGrantInfo}',
        dozer_license: 'Bulldozer : %{licenseGrantInfo}',
        rigiddump_license: 'Cassone fisso : %{licenseGrantInfo}',
        wheelloader_license: 'Pala gommata: %{licenseGrantInfo}',
        grader_license: 'Gradatore : %{licenseGrantInfo}',
        automobile_license: 'Automobile: %{licenseGrantInfo}',
      },
      gnssSettingStatus: {
        sending: 'Trasferimento',
        sent: 'Trasferito',
        failtosend: 'Trasferimento fallito',
      },
      rentalStatus: {
        approved: 'Approvato',
        unapproved: 'Non approvato',
        returned: 'Restituito',
      },
    },
    retrofitRentals: {
      name: 'Assegnazione',
      fields: {
        id: 'ID',
        retrofitId: 'ID macchina',
        fromCorporationId: 'ID azienda assegnatrice',
        fromCorporationName: 'Azienda assegnatrice',
        toCorporationId: 'Azienda assegnata',
        toCorporationName: 'Azienda in uso',
        retrofitRentalId: "ID dell'assegnazione",
        isApproved: 'Stato',
        isReturned: 'Restituito',
        planStartDate: 'Data di inizio prevista',
        planEndDate: 'Data di fine prevista',
        actualStartDate: 'Data di inizio',
        actualEndDate: 'Data di fine',
        rentalUsers: 'E-mail',
        email: 'E-mail',
        rentalStatus: 'Stato',
      },
      isApproved: {
        true: 'Approvato',
        false: 'Non approvato',
      },
      rentalStatus: {
        approved: 'Approvato',
        unapproved: 'Non approvato',
        returned: 'Restituito',
      },
    },
    pendingRentals: {
      name: 'In attesa di approvazione',
      fields: {
        fromCorporationName: 'Azienda assegnatrice',
        planStartDate: 'Data di inizio prevista',
        planEndDate: 'Data di fine prevista',
      },
    },
    pendingTransfers: {
      name: 'In attesa di approvazione',
      fields: {
        fromCorporationName: 'Azienda che trasferisce',
        planDate: 'Data di trasferimento prevista',
        lastUpdated: 'Ultimo aggiornamento',
      },
    },
    pendingAlternateRegists: {
      name: 'In attesa di approvazione',
      fields: {
        fromCorporationName: 'Proprietario corrente',
        lastUpdated: 'Ultimo aggiornamento',
      },
    },
    assignments: {
      name: 'Trasferimento',
      fields: {
        id: 'ID',
        retrofitId: 'ID macchina',
        fromCorporationId: 'ID azienda trasferente',
        fromCorporationName: 'Azienda che trasferisce',
        toCorporationId: 'Azienda trasferita',
        toCorporationName: 'Azienda',
        isApproved: 'Stato',
        planDate: 'Data di trasferimento prevista',
        actualDate: 'Data di trasferimento',
        transferUsers: 'E-mail',
        email: 'E-mail',
        lastUpdated: 'Ultimo aggiornamento',
      },
      isApproved: {
        true: 'Approvato',
        false: 'Non approvato',
      },
    },
    buckets: {
      name: 'Benna',
      fields: {
        companyName: 'Nome azienda',
        siteId: 'Cantiere',
        name: 'Nome',
        corporationId: 'Azienda in uso',
        bucketType: 'Tipo',
        bk3File: 'File BK3',
        'bk3File.title': 'File BK3',
        gltfFile: 'File benna',
        uploadedBy: 'Registrato da',
        updatedBy: 'Aggiornato da',
        fileStatus: 'Stato',
        lastUpdated: 'Ultimo aggiornamento',
        uploadBk: 'Carica BK3',
      },
      bucketType: {
        slope: 'Pendenza',
        standard: 'Standard',
        tilt: 'Inclinazione',
      },
      uploadedBy: {
        admin: 'Amministratore',
        tablet: 'Tablet',
      },
      fileStatus: {
        converting: 'Conversione',
        completed: 'Completato',
        error: 'Errore',
      },
    },
    projects: {
      name: 'Progetto',
      fields: {
        name: 'Nome progetto',
        coordinate: 'Sist coordinate',
        version: 'Versione',
        status: 'Stato',
        lastUpdated: 'Ultimo aggiornamento',
        regionId: 'Regione',
        projectionId: 'Proiezione',
        geoidId: 'Geoide',
        datumId: 'Dato',
        file: 'File/i',
        retrofitId: 'Macchina di destinazione',
      },
      status: {
        converted: 'Completato',
        waitforconvert: 'Conversione',
        error: 'Errore',
      },
    },
    extensionarms: {
      name: 'Prolunga braccio di scavo',
      fields: {
        extensionarmType: 'Tipo',
        name: 'Nome',
        corporationId: 'Azienda in uso',
        corporationName: 'Azienda in uso',
        siteId: 'Cantiere',
        siteName: 'Cantiere',
        lastUpdated: 'Ultimo aggiornamento',
        extensionarmFile: 'File del braccio di estensione',
      },
      extensionarmType: {
        basic: 'Prolunga braccio di scavo',
        a: 'Tipo A',
        b: 'Tipo B',
      },
    },
    extensionarmShares: {
      fields: {
        corporationId: 'Condividi a',
        name: 'Nome',
        extensionarmType: 'Tipo',
        extensionarmFile: {
          title: 'File del braccio di estensione',
        },
      },
    },
    corporations: {
      name: 'Azienda',
      fields: {
        name: 'Azienda in uso',
      },
    },
    retrofitShareFiles: {
      name: 'Registro applicazioni',
      fields: {
        name: 'Nome file',
        fileTime: 'Data e ora di ricezione',
        url: 'Scarica',
      },
    },
    retrofitConfigs: {
      name: 'Informazioni di configurazione',
      fields: {
        timeStamp: 'Data e ora di ricezione',
        url: 'Scarica',
      },
    },
    retrofitKitInfos: {
      name: 'Info controller',
      fields: {
        timeStamp: 'Data e ora di ricezione',
        url: 'Scarica',
      },
    },
    retrofitCalibs: {
      name: 'Info calibrazione',
      fields: {
        timeStamp: 'Data e ora di ricezione',
        url: 'Scarica',
        detailInfo: {
          createDateTime: 'Data e ora di creazione',
          updateDateTime: 'Data e ora di aggiornamento',
          machineInfo: {
            companyName: 'Produttore',
            machineType: 'Tipo macchina',
            machineName: 'Nome macchina',
            machineId: 'ID macchina',
            drumConfiguration: 'Configurazione della macchina',
            guidanceMode: 'Modalità di guida',
          },
          machineGeometryInfo: {
            boomLength: 'Lung braccio sollevamento',
            secondBoomLength: 'Lunghezza 2° braccio di sollevamento',
            armLength: 'Lung braccio scavo',
            armTopPinToBucketSideLinkPinLength:
              'Dist tra perno sup br scavo e perno art lat benna',
            boomTopPinToBucketSideLinkPinLength:
              'Dist tra perno sup br soll e perno art lat benna',
            bucketLink1Length: 'Dist tra perno lat benna e perno cil benna',
            bucketLink2Length: 'Dist tra perno cil benna e perno lat benna',
            bodyCenterToBoomFootPin: {
              x: 'X perno base br soll vs. centro cass',
              y: 'Y perno base br soll vs. centro cass',
              z: 'Z perno base br soll vs. centro cass',
            },
            drumWidth: 'Larghezza della lama',
            length: 'Lunghezza tra i pattini',
            shoeInterval: `Lunghezza dell'intervallo tra le scarpe`,
            bladeWidthForDozer: 'Larghezza della lama per apripista',
            distanceFromBodyToBlade: 'Distanza dal corpo alla lama',
            frontToMainGnssAntenna: `Lunghezza dal centro di una linea retta che collega l'estremità anteriore a terra all'antenna gnss principale X`,
            frontToSubGnssAntenna: `Lunghezza dal centro della linea retta che collega l'estremità anteriore con messa a terra all'antenna secondaria GNSS X`,
            centerToMainGnssAntenna:
              'Y distanza dal centro al centro della fase elettrica GNSS principale',
            centerToSubGnssAntenna:
              'Distanza Y dal centro al centro di fase elettrico GNSS secondario',
            groundToMainGnssAntenna:
              'Z Distanza dal suolo al centro di fase elettrico GNSS principale',
            groundToSubGnssAntenna:
              'Z distanza da terra al centro di fase elettrico GNSS secondario',
          },
          imuMountInfo: {
            boomImuOffsetAngle: 'Ang offset IMU br sollev',
            secondBoomImuOffsetAngle:
              'Angolo di offset IMU 2° braccio di sollev',
            armImuOffsetAngle: 'Ang offset IMU br scavo',
            bucketImuOffsetAngle: 'Ang offset IMU benna',
            bodyImuOffsetRollAngle: 'Ang off rollio IMU cass ',
            bodyImuOffsetPitchAngle: 'Ang off becch IMU cass',
          },
          gnssMountInfo: {
            main: {
              bodyCenterToGnss: {
                x: 'X GNSS pr vs. centro cass',
                y: 'Y GNSS pr vs. centro cass',
                z: 'Z GNSS pr vs. centro cass',
              },
            },
            sub: {
              bodyCenterToGnss: {
                x: 'X GNSS sc vs. centro cass',
                y: 'Y GNSS sc vs. centro cass',
                z: 'Z GNSS sc vs. centro cass',
              },
            },
          },
          extendedModel: {
            swingBoom: 'Modello braccio rotazione',
            twoPieceBoom: 'Modello braccio di sollevamento a 2 elementi',
            minimalSwingRadius: 'Modello braccio decentrato',
          },
          measuredValues: {
            otherLength: {
              machineHeight: 'Distanza contrappeso',
            },
            bodyImu1: {
              roll: `Rollio del valore misurato dall'IMU`,
              pitch: `Pitch del valore misurato dall'IMU`,
              yaw: `Valore misurato dell'imbardata dell'IMU`,
            },
            bodyImu2: {
              roll: `Valore misurato del rollio dell'IMU`,
              pitch: `Valore misurato del passo dell'IMU`,
              yaw: `Valore misurato dell'imbardata dell'IMU`,
            },
            frontLeft: {
              x: 'Valore rilevato della parte anteriore sinistra X',
              y: 'Valore rilevato della parte anteriore sinistra Y',
              z: 'Valore rilevato della parte anteriore sinistra Z',
            },
            frontRight: {
              x: 'Valore rilevato della parte anteriore destra X',
              y: 'Valore rilevato della parte anteriore destra Y',
              z: 'Valore rilevato della parte anteriore destra Z',
            },
            frontGroundingEnd: {
              x: `Valore rilevato dell'estremità anteriore X`,
              y: `Valore rilevato dell'estremità frontale Y`,
              z: `Valore rilevato dell'estremità anteriore Z`,
            },
            rearGroundingEnd: {
              x: `Valore rilevato dell'estremità posteriore X`,
              y: `Valore rilevato dell'estremità posteriore Y`,
              z: `Valore rilevato dell'estremità posteriore Z`,
            },
            mainGnssAntenna: {
              x: `Valore rilevato dell'antenna GNSS principale X`,
              y: `Valore rilevato dell'antenna GNSS principale Y`,
              z: `Valore rilevato dell'antenna GNSS principale Z`,
            },
            subGnssAntenna: {
              x: `Valore rilevato dell'antenna GNSS secondaria X`,
              y: `Valore rilevato dell'antenna GNSS secondaria Y`,
              z: `Valore rilevato dell'antenna GNSS secondaria Z`,
            },
            prismPoleOffset: 'Altezza del palo del prisma',
            antennaElectricalCenterHeight: `Altezza dal centro elettrico dell'antenna`,
          },
        },
      },
    },
    retrofitAccuracies: {
      name: 'Risultato del controllo di precisione',
      fields: {
        checkDateTime: 'Data e ora di conferma',
        surveyPoint: 'Punto misurato',
        positionN: 'Valore misurato : N',
        positionE: 'E',
        positionZ: 'Z',
        machineOffsetN: 'Valore di compensazione : N',
        machineOffsetE: 'E',
        machineOffsetZ: 'Z',
        referencePointName: 'Nome del punto di riferimento',
        referencePointN: 'Punto di riferimento : N',
        referencePointE: 'E',
        referencePointZ: 'Z',
        unitLength: 'Unità di lunghezza',
      },
    },
    retrofitBasicSettings: {
      name: 'Parametri base',
      fields: {
        timeStamp: 'Data e ora di ricezione',
        url: 'Scarica',
      },
    },
    retrofitErrors: {
      name: "Informazioni sull'errore",
      fields: {
        notifiedTime: 'Data e ora di occorrenza',
        errorCodes: 'Codice di errore: Dettagli',
      },
    },
    retrofitToposurveys: {
      name: 'Lista rilievi topografici',
      fields: {
        surveyDate: 'Data e ora del sondaggio',
        projectName: 'Nome progetto',
        layerName: 'Layer',
        pointName: 'Nome punto',
        surveyPoint: 'Punto misurato',
        n: 'Valore misurato : N',
        e: 'E',
        z: 'Z',
        unitLength: 'Unità di lunghezza',
      },
    },
    asbuilts: {
      name: 'Risultati costruzione',
      fields: {
        execStartDate: 'Inizio esecuzione',
        execEndDate: 'Fine esecuzione',
        startPeriod: 'Periodo di acquisizione da',
        endPeriod: 'Periodo di acquisizione a',
        execStatus: 'Stato',
        url: '',
        csvType: 'Formato dati',
        createUser: 'Creare utente',
      },
      execStatus: {
        complete: 'Completato',
        inprogress: 'Elaborazione',
        error: 'Errore',
        completewithoutdata: 'Completato (Nessun dato)',
      },
    },
    retrofitBasicInfos: {
      name: 'Info base',
      fields: {
        uuid: 'UUID',
        retrofitDistributorId: 'Distributore',
        retrofitRegionId: 'Regione',
        corporationId: 'ID azienda',
        corporationName: 'Nome azienda',
        basicInfoManufacturer: 'Produttore',
        basicInfoModel: 'Modello',
        basicInfoProductNumber: 'N. prodotto',
        basicInfoSerialNumber: 'N. di serie',
        lastUpdated: 'Ultimo aggiornamento',
        description: 'Descrizione',
        ownerId: 'ID azienda',
        ownerName: 'Nome azienda',
      },
      tag: {
        retrofitBasicInfosDetail: 'Info base',
        sendBack: 'Invia indietro',
      },
      sendBack: {
        fields: {
          site: 'Cantiere',
          corporation: 'Azienda in uso',
          ntripSettingInfo: 'Ntrip ID',
          toRetrofit: 'Controller alternativo',
          notificationEmailAddress: 'E-mail',
        },
      },
    },
    retrofitBulkActions: {
      name: 'Registrazione bulk',
      fields: {
        uploadFileName: 'Nome file',
        execStartDate: 'Data e ora di inizio esecuzione',
        execEndDate: 'Data e ora di fine esecuzione',
        execStatus: 'Stato',
        uploadFile: 'File registrato',
        resultFile: 'Risultati della registrazione',
      },
      execStatus: {
        complete: 'Completato',
        inprogress: 'Elaborazione',
        error: 'Errore',
      },
    },
    retrofitAdminCorporationBulkActions: {
      name: 'Azione di massa amministratore aziendale',
      fields: {
        uploadFileName: 'Nome file',
        execStartDate: 'Data e ora di inizio esecuzione',
        execEndDate: 'Data e ora di fine esecuzione',
        execStatus: 'Stato',
        uploadFile: 'File registrato',
        resultFile: 'Risultati della registrazione',
      },
      execStatus: {
        complete: 'Completato',
        inprogress: 'Elaborazione',
        error: 'Errore',
      },
    },
    tasks: {
      name: 'Compito',
      fields: {
        name: 'Nome',
        status: 'Stato',
        description: 'Descrizione',
        projectSourceLayerId: 'Layer',
        retrofitIds: 'Macchina di destinazione',
        expiredDate: 'Data di scadenza',
        createDate: 'Data di scadenza',
        rectangle: 'Gamma supportata',
        lastUpdated: 'Ultimo aggiornamento',
      },
      status: {
        error: 'Errore',
        converting: 'Conversione',
        sending: 'Trasferimento',
        sent: 'Trasferito',
        failtosend: 'Trasferimento fallito',
      },
    },
    compactionMaterials: {
      name: 'Materiali',
      fields: {
        name: 'Nome',
      },
    },
    weathers: {
      name: 'Condizioni meteorologiche',
      fields: {
        name: 'Nome',
      },
    },
    operators: {
      name: 'Operatori',
      fields: {
        name: 'Nome',
      },
    },
    compactionWorkAreas: {
      name: 'Aree di lavoro di compattazione',
      fields: {
        name: 'Nomi aree di lavoro',
        blockSize: 'Dimensione del blocco',
        description: 'Descrizione',
      },
    },
    compactionLayers: {
      name: 'Strati di compattazione',
      fields: {
        name: 'Nome',
        thickness: 'Spessore',
        passCount: 'Numero di passaggi',
        compactionMaterialId: 'Materiale',
        inUse: 'Stato',
        description: 'Descrizione',
      },
      inUse: {
        true: 'in uso',
        false: 'Inutilizzato',
      },
    },
    gnssSettings: {
      name: 'Impostazioni Ntrip',
      fields: {
        registCorporationName: 'Registrato da',
        ownerCorporationId: 'ID azienda',
        ownerCorporationName: 'Nome azienda',
        name: 'Nome',
        url: 'URL server',
        portnum: 'Porta',
        userId: 'ID',
        password: 'Password',
        retrofitId: 'Macchina di destinazione',
        status: 'Stato',
        updateDate: 'Ultimo aggiornamento',
      },
      status: {
        waitforsend: 'Conversione',
        sending: 'Trasferimento',
        sent: 'Trasferito',
        failtosend: 'Trasferimento fallito',
      },
    },
    supportMode: {
      name: 'Modalità di supporto',
      fields: {
        enabled: 'Abilita la modalità di supporto',
        corporationId: 'ID azienda',
        corporationName: 'Nome azienda',
        siteId: 'ID cantiere',
        siteName: 'Cantiere',
        latitude: 'Latitudine',
        longitude: 'Longitudine',
        workPeriodStartDate:
          'Periodo di costruzione effettivo (data di inizio)',
        workPeriodEndDate: 'Periodo di costruzione effettivo (data di fine)',
        photoUrl: 'URL immagine sito',
        description: 'Descrizione',
      },
    },
    userNotices: {
      name: 'Notifica',
      noticeType: {
        emergencyimportant: 'Emergenza/Importante',
        emergency: 'Emergenza',
        important: 'Importante',
      },
      fields: {
        noticeType: 'Importanza',
        noticeTypes: 'Importanza',
        noticeCategory: 'Tipo di notifica',
        title: 'Titolo',
        content: 'Contenuti',
        publicationStartDate: 'Data e ora di inizio (UTC)',
        publicationEndDate: 'Data e ora di fine(UTC)',
        file: 'Allegati',
        language: 'Lingua',
        emergency: 'Emergenza',
        important: 'Importante',
      },
    },
    firmwares: {
      name: 'Firmware',
      fields: {
        version: 'Versione',
        compatibilityLevel: 'Livello di compatibilità',
        firmwareFile: 'File firmware',
        firmwareFileCheckSum: 'Firmware Check Sum',
        firstInitializationFile: 'File di inizializzazione 1',
        firstInitializationFileCheckSum: 'Checksum file di inizializzazione 1',
        secondInitializationFile: 'File di inizializzazione 2',
        secondInitializationFileCheckSum: 'Checksum file di inizializzazione 2',
      },
    },
    bucketShares: {
      name: 'Condivisione secchio',
      fields: {
        fromBucketId: 'Da Bucket Id',
        corporationId: 'ID azienda',
        name: 'Nome',
      },
    },
    extensionarmShare: {
      name: 'Condivisione braccio di estensione',
      fields: {
        name: 'Nome',
      },
    },
    retrofitCorporationLicenses: {
      name: 'LICENZA',
      fields: {
        licenseId: 'ID licenza',
        licenseType: 'Tipo',
        issuedAt: 'Data di emissione',
        expiresAt: 'Data di scadenza',
        retrofitId: 'Macchina',
        comment: 'Descrizione',
        licenseStatus: 'Stato',
        licensePermission: 'LICENZA',
        productType: 'Nome prodotto',
      },
      licenseStatus: {
        unlicense: 'Non assegnato',
        disable: 'Non valido',
        enable: 'Valido',
      },
    },
    retrofitNamePlateFiles: {
      fields: {
        corporationName: 'Nome azienda',
        namePlates: 'Foto',
      },
    },
    siteConfigures: {
      fields: {
        unitLength: 'Unità di lunghezza',
      },
    },
    geofences: {
      name: 'Geofence',
      fields: {
        type: 'Tipo',
        name: 'Nome',
        dimension: 'Dimensioni',
        trigger: 'Innesco',
        valid: 'Valido',
        alertType: 'Tipo di allarme',
        startDate: 'Data di inizio',
        endDate: 'Data di fine',
        transparency: 'Trasparente',
        elevation: 'Quota',
        height: 'Altezza',
        radius: 'Raggio',
        lastUpdated: 'Ultimo aggiornamento',
        points: 'Gamma supportata',
        thickness: 'Spessore',
      },
      type: {
        line: 'Linea',
        polygon: 'Poligono',
        circle: 'Cerchio',
        point: 'Punto',
        wall: 'Parete',
      },
      dimension: {
        twodimension: '2D',
        threedimension: '3D',
      },
      valid: {
        true: 'Valido',
        false: 'Non valido',
      },
      alertType: {
        attention: 'Attenzione',
        notice: 'Avviso',
        warning: 'Avvertenza',
        caution: 'Attenzione',
        danger: 'Pericolo',
      },
      transparency: {
        true: 'Trasparente',
        false: 'Opaco',
      },
    },
    geofenceAlerts: {
      name: 'Avvisi',
      fields: {
        timeStamp: 'Data e ora di rilevamento',
        geofenceName: 'Nome Geofence',
        alertType: 'Tipo di allarme',
        collisionPoint: 'Punto di collisione',
        retrofit: {
          machineInfoMachineName: 'Macchina',
        },
      },
      alertType: {
        attention: 'Attenzione',
        notice: 'Avviso',
        warning: 'Avvertenza',
        caution: 'Attenzione',
        danger: 'Pericolo',
      },
    },
    ntrips: {
      name: 'Gestione informazioni Ntrip',
      fields: {
        name: 'ID utente',
        ntripPass: 'Password',
        serial: 'N. di serie',
        customerCorporationId: 'ID azienda',
        customerCorporationName: 'Nome azienda',
        ntrip: 'Stato',
        numberOfExchanges: 'Numero di scambi',
        id: 'ID',
        licenceId: 'ID licenza',
        dateOfRegistration: 'Data di registrazione',
        billingToCustomers: 'Fatturazione ai clienti',
        paymentToKomatsu: 'Pagamento a Komatsu',
        applicantId: 'ID richiedente',
        applicant: 'Richiedente',
        requestCorporationId: "Richiedi l'ID aziendale",
        requestCorporationName: 'Richiedi il nome aziendale',
        isUsed: 'È usato',
      },
      ntrip: {
        publish: 'Pagato',
        stop: 'Stop',
        delete: 'Cancellato',
      },
    },
    retrofitAlternateRegists: {
      name: 'Registrazione proxy controller',
      fields: {
        id: 'ID',
        retrofitId: 'retrofitId',
        fromCorporationId: 'da virksomheds-id',
        fromCorporationName: 'Registrazione proxy controller',
        toCorporationId: 'Utente',
        toCorporationName: 'Nome azienda',
        isApproved: 'Stato',
        createDate: 'Data di richiesta',
        actualDate: 'Data di approvazione',
        transferUsers: 'E-mail',
        email: 'E-mail',
        lastUpdated: 'Ultimo aggiornamento',
      },
      isApproved: {
        true: 'Approvato',
        false: 'Non approvato',
      },
    },
    pendingApprovals: {
      name: 'In attesa di approvazione',
      fields: {
        approvalType: 'Tipo',
        requestedBy: 'Azienda richiedente',
        lastUpdated: 'Ultimo aggiornamento',
      },
      approvalType: {
        assignment: 'Assegnazione',
        transfer: 'Trasferimento',
        agent: 'Registrazione proxy controller',
      },
    },
    retrofitSendbacks: {
      name: 'Invia indietro',
      fields: {
        fromRetrofitId: 'Da Retrofit',
        toRetrofitId: 'Controller alternativo',
        notificationEmailAddress: 'E-mail',
        siteId: 'Cantiere',
        corporationId: 'ID azienda',
        corporationName: 'Azienda in uso',
        ntripId: 'Informazioni Ntrip',
        isComplete: 'Invio completato',
      },
    },
  },
};

export default message;
