import polyglotI18nProvider from 'ra-i18n-polyglot';
import { resolveBrowserLocale } from 'react-admin';
import {
  en,
  ja,
  cs,
  da,
  de,
  es,
  et,
  fr,
  it,
  lt,
  lv,
  nl,
  pl,
  pt,
  th,
  id,
} from '.';
import { AnyObject } from '../utils';

export default () =>
  polyglotI18nProvider(locale => {
    // NOTE: 対応する言語が増えた場合は、messagesオブジェクトに言語を追加する
    const messages: AnyObject = {
      en,
      ja,
      cs,
      da,
      de,
      es,
      et,
      fr,
      it,
      lt,
      lv,
      nl,
      pl,
      pt,
      th,
      id,
    };
    const defaultLang = 'en';
    const lang = locale in messages ? locale : defaultLang;
    return messages[lang];
    // TODO: APIで取得したLandologログインユーザの言語をセットする必要がある
  }, resolveBrowserLocale());
