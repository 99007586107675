import React from 'react';
import { useLocation } from 'react-router-dom';
import { makeStyles, createStyles } from '@material-ui/core';
import {
  CustomList,
  Datagrid,
  CustomDateField,
  DeleteFromSiteButton,
  FunctionField,
  IconField,
  TextField,
  RetrofitLocationMap,
  ToggleAddSiteButton,
  CreateButton,
} from '../../..';
import RetrofitRemoteSupportField from '../../../organisms/RetrofitRemoteSupportField'; // テスト側でモックするため直接 import している
import { useResource } from '../../../../hooks';
import { RetrofitData, SiteData } from '../../../../dataProvider';
import { AddIcon } from '../../../../assets';
import { config } from '../../../../utils';

const useStyle = makeStyles(() =>
  createStyles({
    root: { marginTop: 12 },
  }),
);

const BulkActionButtons: React.FC = props => (
  <DeleteFromSiteButton {...props} />
);

interface Props {
  site: SiteData;
}
const Map: React.FC<Props> = props => {
  const classes = useStyle();
  const { data, ids, site }: any = props;
  const sitePosition = {
    longitude: site.longitude,
    latitude: site.latitude,
  };
  const retrofitData: RetrofitData[] = ids.map((id: number) => {
    return data[id];
  });

  return (
    <RetrofitLocationMap
      // NOTE: 初期表示時の暫定zoom値(18) ※基本的には上書きされる
      defaultView={{ zoom: 18 }}
      classes={classes}
      retrofitData={retrofitData}
      sitePosition={sitePosition}
    />
  );
};

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      tableLayout: 'fixed',
      '& .MuiTableHead-root': {
        '& .MuiTableRow-root': {
          '& .MuiTableCell-root': {
            paddingTop: '6px',
            paddingBottom: '6px',
            boxSizing: 'content-box',
            whiteSpace: 'pre-line',
          },
          '& .MuiTableCell-root:nth-child(1)': {
            paddingRight: '15px',
            paddingLeft: '16px',
            width: 16,
          },
          '& .MuiTableCell-root:nth-child(2)': {
            paddingRight: '15px',
            paddingLeft: '15px',
            width: 76,
            textAlign: 'center',
          },
          '& .MuiTableCell-root:nth-child(3)': {
            paddingRight: '15px',
            paddingLeft: '15px',
            width: 166,
          },
          '& .MuiTableCell-root:nth-child(4)': {
            paddingRight: '15px',
            paddingLeft: '15px',
            width: 76,
            textAlign: 'center',
          },
          '& .MuiTableCell-root:nth-child(5)': {
            paddingRight: '15px',
            paddingLeft: '15px',
            width: 76,
            textAlign: 'center',
          },
          '& .MuiTableCell-root:nth-child(6)': {
            paddingRight: '15px',
            paddingLeft: '15px',
            width: 80,
            textAlign: 'center',
          },
          '& .MuiTableCell-root:nth-child(7)': {
            paddingRight: '15px',
            paddingLeft: '15px',
            width: 112,
          },
          '& .MuiTableCell-root:nth-child(8)': {
            paddingRight: '16px',
            paddingLeft: '15px',
            width: 120,
          },
        },
      },
    },
    assignment: {
      paddingLeft: '15px',
    },
  }),
);

const trancelateLicensePermissionName = (permission: string | undefined) => {
  return permission?.replace('MachineGuidance2D', '2D MachineGuidance');
};

const SiteRetrofitList: React.FC = props => {
  const location = useLocation();
  const resource = useResource('retrofits');
  const classes = useStyles();
  return (
    <FunctionField
      {...props}
      resource="sites"
      addLabel={false}
      render={record =>
        record ? (
          <CustomList
            breadcrumbs={['resources.sites.name']}
            basePath={`${location.pathname}/retrofits`}
            location={location}
            resource={resource}
            controllerResource={`${resource}_${record.id}`}
            filter={{
              siteId: record.id,
              ownerCorporationId: record.corporationId,
            }}
            actions={
              <ToggleAddSiteButton
                {...props}
                label="admin.actions.addMachineToSite"
              >
                <CreateButton>
                  <AddIcon />
                </CreateButton>
              </ToggleAddSiteButton>
            }
            bulkActionButtons={<BulkActionButtons />}
            hasCreate
            aside={<Map site={record} />}
          >
            <Datagrid rowClick="edit" className={classes.root}>
              {config.feature.useRetrofitLicense ? (
                <IconField
                  resource={resource}
                  source="licenseMachineType"
                  label="resources.retrofits.fields.machineInfoMachineType"
                  sortable={false}
                  onTooltip={({ res, record: retrofit, translate }) => {
                    const { hasLicense, licenseGrantInfo } =
                      retrofit as RetrofitData;
                    if (
                      hasLicense &&
                      licenseGrantInfo !== 'Unlimited' &&
                      licenseGrantInfo !== 'FlexUnlimited'
                    ) {
                      return translate(res, {
                        licenseGrantInfo:
                          trancelateLicensePermissionName(licenseGrantInfo),
                      });
                    }
                    return translate(res);
                  }}
                />
              ) : (
                <IconField
                  resource={resource}
                  source="machineInfoMachineType"
                />
              )}
              <TextField resource={resource} source="machineInfoMachineName" />
              <IconField
                resource={resource}
                source="isOnline"
                sortable={false}
              />
              <IconField
                resource={resource}
                source="isError"
                sortable={false}
              />
              <IconField
                resource={resource}
                source="rentalStatus"
                sortable={false}
              />
              <CustomDateField
                resource={resource}
                source="lastOnlineTime"
                showTime
                sortable={false}
              />
              <RetrofitRemoteSupportField
                label="admin.label.siteRetrofitList.remoteSupport"
                sortable={true}
              />
            </Datagrid>
          </CustomList>
        ) : null
      }
    />
  );
};

SiteRetrofitList.displayName = 'SiteRetrofitList';
export default SiteRetrofitList;
