import React from 'react';
import { Record, useTranslate } from 'react-admin';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { Tooltip } from '@material-ui/core';
import { iconMap } from '../../../utils';
import { ResourceFieldFC } from '../..';

interface IconFieldProps {
  resource: string;
  source: string;
  record: Record;
  addLabel: boolean;
  button?: React.ReactElement<any> | boolean;
  validButton?: (record: Record) => boolean;
  positionLastColumn?: boolean;
  onTooltip: (params: { res: string; record: any; translate: any }) => string;
}

export const IconFieldPaddingZeroStyle = {
  '& .icon-field': {
    '& > .svg-icon': {
      paddingLeft: '0px',
    },
  },
};

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      '& > .svg-icon': {
        textAlign: 'center',
        paddingLeft: '10px',
      },
      '& > .last-column': {
        textAlign: 'center',
      },
    },
  }),
);

const IconField: React.FC<Partial<IconFieldProps>> = ({
  resource,
  source,
  record,
  button,
  validButton,
  positionLastColumn = false, // Listの右端のカラムの場合、paddingの調整が必要
  onTooltip,
}) => {
  const translate = useTranslate();
  const classes = useStyles();

  if (!resource || !source || !record) return null;
  if (record[source] !== false && !record[source]) return null;
  const value: string = record[source].toString().toLowerCase();
  const Icon = iconMap[resource][source][value];
  if (!Icon) return null;

  const res = `resources.${resource}.${source}.${value}`;
  const tooltipText = onTooltip
    ? onTooltip({
        res,
        record,
        translate,
      })
    : translate(res);

  const buttonEnable = button && validButton && validButton(record);
  const Element = buttonEnable ? (
    React.isValidElement(button) &&
    React.cloneElement(button, { ...{ record } }, <Icon />)
  ) : (
    <Icon />
  );

  return (
    <div className={[classes.root, 'icon-field'].join(' ')}>
      <Tooltip
        className={positionLastColumn ? 'last-column' : 'svg-icon'}
        title={tooltipText}
      >
        <div className="icon-root">{Element}</div>
      </Tooltip>
    </div>
  );
};

IconField.displayName = 'IconField';
IconField.defaultProps = {
  addLabel: true,
};
export default IconField as ResourceFieldFC<Partial<IconFieldProps>>;
