import React, { useCallback, useEffect, useState } from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { useTranslate, Loading, useRedirect } from 'react-admin';
import {
  Edit,
  CustomForm,
  TextField,
  IconField,
  IconFieldPaddingZeroStyle,
  CustomDateField,
  BreadcrumbsFiled,
  Typography,
  CustomParentBox,
  CustomChildBox,
  FunctionField,
  RetrofitRemoteSupportField,
  ReferenceField,
  CorporationNameField,
  SimpleShowLayout,
  NormalButton,
  DialogOKOnly,
} from '../../..';
import {
  useAccount,
  useGetSelefDetailData,
  useGetUserData,
  useResource,
} from '../../../../hooks';
import {
  config,
  hasRoleFromUserDataByConfigInfo,
  countryCodeStore,
} from '../../../../utils';
import {
  useGetRentalInfo,
  useGetNtripInfoDisable,
  SaveButton,
  SiteReferenceInput,
  GnssReferenceInput,
  useGetTransferInfo,
} from '.';
import {
  fakeDataProvider,
  AssignmentData,
  RetrofitData,
  AccountData,
  landlogDataProvider,
} from '../../../../dataProvider';
import RetrofitLicenseShowLayout from '../../../organisms/RetrofitLicenseShowLayout';
import { useLicense, useRentaled, useTransferred } from '../transfers/hooks';
import { TransferEditIcon, TransferIcon } from '../../../../assets';
import { useGetRetrofitsSiteId } from '../info/hooks';

const useStyles = makeStyles(() =>
  createStyles({
    titleFirst: {
      padding: '12px 0px',
    },
    title: {
      padding: '12px 0px',
      borderTop: '1px solid #F5F5FF33',
    },
    form: {
      ...IconFieldPaddingZeroStyle,
    },
    space: {
      marginBottom: 8,
    },
  }),
);

const useRemoteSupportStyles = makeStyles(() =>
  createStyles({
    root: {
      width: 160,
    },
  }),
);

const useNtripStyles = makeStyles(() =>
  createStyles({
    statusIcon: {
      minHeight: 36,
      marginTop: 8,
      marginBottom: 8,
      '& > div > div': {
        marginTop: 0,
        marginBottom: 0,
        marginLeft: -10,
      },
    },
  }),
);

const TransferButton: React.FC<{
  redirectIcon: any;
  redirectPath: string;
  retrofitId: number;
}> = ({ redirectIcon, redirectPath, retrofitId }) => {
  const redirectTo = useRedirect();
  const getSiteId = useGetRetrofitsSiteId();
  const getRentaled = useRentaled();
  const getLicense = useLicense();

  const [dialogState, setDialogState] = useState<{
    open: boolean;
    messages?: string[];
  }>({ open: false });

  const handleTransferClick = useCallback(() => {
    const errorItem = new Array<string>();

    const api = async () => {
      const { data } = await getSiteId({ retrofitId });
      const isRentaled = await getRentaled(retrofitId);
      const isLicense = await getLicense(retrofitId);
      return { siteId: data.siteId, isRentaled, isLicense };
    };
    api().then(result => {
      // 建機に現場が紐付いてる場合
      if (result.siteId) {
        errorItem.push(
          'admin.dialog.retrofitEdit.unableTransferInfo.reasonSiteId',
        );
      }
      // 建機が貸与中の場合
      if (result.isRentaled) {
        errorItem.push(
          'admin.dialog.retrofitEdit.unableTransferInfo.reasonRentaled',
        );
      }
      // 建機にライセンスが紐づいている場合
      if (result.isLicense) {
        errorItem.push(
          'admin.dialog.retrofitEdit.unableTransferInfo.reasonLicense',
        );
      }

      if (errorItem.length > 0) {
        errorItem.unshift(
          'admin.dialog.retrofitEdit.unableTransferInfo.message',
        );
        setDialogState({
          open: true,
          messages: errorItem,
        });
        return;
      }

      redirectTo(redirectPath);
    });
  }, [
    getSiteId,
    getRentaled,
    getLicense,
    redirectTo,
    redirectPath,
    retrofitId,
  ]);

  return (
    <>
      <DialogOKOnly
        open={dialogState.open}
        onClose={() => setDialogState({ open: false })}
        title=""
        messages={dialogState?.messages}
      />
      <NormalButton
        label={'admin.label.retorfitTransfer.transferButton'}
        onClick={handleTransferClick}
      >
        {redirectIcon}
      </NormalButton>
    </>
  );
};

interface RetrofitInfoEditProp {
  id?: number;
  basePath?: string;
  record?: RetrofitData;
  corporationid?: string;
  admindisabled?: boolean;
  disabled?: boolean;
}

interface ApiResult {
  isOwnData: boolean;
  rentalInfo: {
    unapproved: boolean;
    rented: boolean;
  };
  ntripDisable: boolean;
  transfer: {
    unapproved: boolean;
    editableId?: number;
    transferdData?: AssignmentData;
    hasTransferRole: boolean;
  };
  isTransferred: boolean;
}

// REACT_APP_FEATURE_TRANSFERが削除されたら削除(letにしたために必要となった型定義であるため)
interface TransferResult {
  unapproved: boolean;
  editableId?: number;
  transferdData?: AssignmentData;
  hasTransferRole: boolean;
}

const RetrofitInfoEdit: React.FC<RetrofitInfoEditProp> = (props: any) => {
  const resource = useResource('retrofits');
  const gnssSettings = useResource('gnssSettings');
  const classes = useStyles();
  const remoteSupportClasses = useRemoteSupportStyles();
  const ntripClasses = useNtripStyles();
  const translate = useTranslate();
  const redirectTo = useRedirect();

  const {
    id: retrofitId = 0,
    corporationid: userCorporationId,
    admindisabled: adminDisabled,
    basePath,
  } = props;

  const getRentalInfo = useGetRentalInfo(retrofitId);
  const getNtripInfoDisable = useGetNtripInfoDisable(userCorporationId);
  const getAccount = useAccount();
  const getUserData = useGetUserData();
  const getTransferInfo = useGetTransferInfo();
  const getTransferred = useTransferred();
  const getSelefDetailData = useGetSelefDetailData();

  const [apiState, setApiState] = useState<{
    loading: boolean;
    fetched: boolean;
    data?: ApiResult;
  }>({
    loading: true,
    fetched: false,
    data: undefined,
  });

  useEffect(() => {
    if (apiState.fetched) return;

    const api = async (): Promise<{
      data: {
        apiResult: ApiResult;
      };
    }> => {
      // retrofits
      const listFileter = {
        ownerCorporationId: userCorporationId,
        adminCorporationId: userCorporationId,
      };
      const retrofit = await getSelefDetailData(
        resource,
        listFileter,
        retrofitId,
      );

      // rentalInfo
      const rentalInfo = await getRentalInfo();
      // ntripDisable
      const ntripDisable = await getNtripInfoDisable();

      // assignments
      // REACT_APP_FEATURE_TRANSFERが削除されたらlet変数を削除、getTransferInfo()の結果dataをtransferとする
      let transfer: TransferResult = {
        unapproved: false,
        editableId: undefined,
        transferdData: undefined,
        hasTransferRole: false,
      };
      let isTransferred = false;
      let hasTransferRole = false;
      if (config.feature.transfer) {
        // accounts
        const accountDataProvider = config.api.admin.useFake
          ? fakeDataProvider
          : landlogDataProvider;
        const { data: account } = await accountDataProvider.getOne<AccountData>(
          'accounts',
          {
            id: 1,
          },
        );
        const { corporationId, email } = account;

        // users
        const userData = await getUserData(corporationId, email);
        hasTransferRole = hasRoleFromUserDataByConfigInfo(
          userData,
          config.feature.roleTransferCreate,
        );

        if (retrofit && hasTransferRole) {
          // assignments * 2
          const { data } = await getTransferInfo({
            retrofitId,
            userCorporationId: userCorporationId || '',
            retrofitCorporationId: retrofit.corporationId,
          });
          transfer = { ...data, hasTransferRole };

          // assignments
          isTransferred = await getTransferred(retrofitId);
        }
      }
      return {
        data: {
          apiResult: {
            isOwnData: !!retrofit,
            rentalInfo,
            ntripDisable,
            transfer,
            isTransferred,
          },
        },
      };
    };
    api().then(({ data: { apiResult } }) => {
      setApiState({ loading: false, fetched: true, data: apiResult });
    });
  }, [
    getSelefDetailData,
    resource,
    getRentalInfo,
    getNtripInfoDisable,
    getAccount,
    getUserData,
    getTransferInfo,
    getTransferred,
    retrofitId,
    userCorporationId,
    apiState,
  ]);

  if (apiState.loading || !apiState.data) return <Loading />;

  const { isOwnData, rentalInfo, ntripDisable, transfer, isTransferred } =
    apiState.data;
  // 一覧に存在しないretoriftのデータは表示させない
  if (!isOwnData) redirectTo('/');

  // ログインユーザーの国情報を取得し、日本企業ではないことを判定
  const isOverseas = countryCodeStore.get() !== 'JP';

  const redirectUrl = transfer.editableId ? transfer.editableId : 'create';
  const redirectPath = `${basePath}/${retrofitId}/transfer/${redirectUrl}`;
  const transferButtonIcon = transfer.unapproved ? (
    <TransferEditIcon />
  ) : (
    <TransferIcon />
  );

  // 譲渡ボタンを表示の条件
  // 本番未公開機能OFF、ログイン中ユーザーのロール権限がある場合、かつログイン中ユーザーが日本企業ではない場合
  const hasTransferAuth =
    config.feature.transfer && transfer.hasTransferRole && isOverseas;

  return (
    <Edit {...props}>
      <CustomForm
        title="admin.pages.retrofitEdit"
        redirect={false}
        saveButton={<SaveButton />}
        deleteButton={false}
        actionButton={
          <RetrofitRemoteSupportField
            buttonLabel="admin.actions.remoteSupport"
            classes={remoteSupportClasses}
          />
        }
        className={classes.form}
      >
        <BreadcrumbsFiled
          breadcrumbs={['resources.retrofits.name']}
          label="admin.label.retrofitEdit.breadcrumbs"
        />
        <Typography className={classes.titleFirst}>
          {translate('admin.label.retrofitEdit.belongsDetails')}
        </Typography>
        <CorporationNameField label="resources.retrofits.fields.corporationId" />
        <SiteReferenceInput
          unapproved={rentalInfo.unapproved}
          rented={rentalInfo.rented}
          userCorporationId={userCorporationId}
          adminDisabled={adminDisabled}
          transferd={isTransferred}
        />
        <GnssReferenceInput
          unapproved={rentalInfo.unapproved}
          rented={rentalInfo.rented}
          userCorporationId={userCorporationId}
          selectNtripInfoDisable={ntripDisable}
          adminDisabled={adminDisabled}
        />
        <ReferenceField
          resource={resource}
          source="gnssSettingId"
          reference={gnssSettings}
          label="resources.gnssSettings.fields.status"
          link={false}
          className={ntripClasses.statusIcon}
        >
          <IconField resource={gnssSettings} source="status" />
        </ReferenceField>
        <Typography className={classes.title}>
          {translate('admin.label.retrofitEdit.machineryDetails')}
        </Typography>
        <CustomParentBox>
          <SimpleShowLayout className={classes.space}>
            <FunctionField
              resource={resource}
              source="machineInfoMachineType"
              render={record => {
                const res = 'resources.retrofits.machineInfoMachineType';
                const type = record?.machineInfoMachineType.toLowerCase();
                const trans = translate(`${res}.${type}`);
                // 翻訳できなければ、もとの内容をそのまま返す
                if (trans.startsWith(res)) return type;
                return trans;
              }}
            />
            <TextField resource={resource} source="machineInfoMachineId" />
            <TextField resource={resource} source="machineInfoMachineName" />
            <TextField resource={resource} source="machineInfoCompanyName" />
          </SimpleShowLayout>
          {config.feature.useRetrofitLicense ? (
            <RetrofitLicenseShowLayout addLabel={false} />
          ) : null}
        </CustomParentBox>
        <Typography className={classes.title}>
          {translate('admin.label.retrofitEdit.retrofitDetails')}
        </Typography>
        <CustomParentBox>
          <CustomChildBox
            title={translate('admin.label.retrofitEdit.basicInfo')}
          >
            <TextField resource={resource} source="basicInfoManufacturer" />
            <TextField resource={resource} source="basicInfoModel" />
            <TextField resource={resource} source="basicInfoProductNumber" />
            <TextField resource={resource} source="basicInfoSerialNumber" />
          </CustomChildBox>

          <CustomChildBox
            title={translate('admin.label.retrofitEdit.controller')}
          >
            <TextField resource={resource} source="controllerManufacturer" />
            <TextField resource={resource} source="controllerModel" />
            <TextField resource={resource} source="controllerFirmwareVersion" />
          </CustomChildBox>
        </CustomParentBox>
        <CustomParentBox>
          <CustomChildBox
            title={translate('admin.label.retrofitEdit.reciverMain')}
          >
            <TextField
              resource={resource}
              source="gnssReceiverMainManufacturer"
            />
            <TextField resource={resource} source="gnssReceiverMainModel" />
            <TextField
              resource={resource}
              source="gnssReceiverMainFirmwareVersion"
            />
          </CustomChildBox>

          <CustomChildBox
            title={translate('admin.label.retrofitEdit.reciverSub')}
          >
            <TextField
              resource={resource}
              source="gnssReceiverSubManufacturer"
            />
            <TextField resource={resource} source="gnssReceiverSubModel" />
            <TextField
              resource={resource}
              source="gnssReceiverSubFirmwareVersion"
            />
          </CustomChildBox>
        </CustomParentBox>
        <Typography className={classes.title}>
          {translate('admin.label.retrofitEdit.retrofitstatus')}
        </Typography>
        <IconField resource={resource} source="isOnline" />
        <IconField resource={resource} source="isError" />
        <CustomDateField resource={resource} source="lastOnlineTime" showTime />
        {hasTransferAuth ? (
          <>
            <Typography className={classes.title}>
              {translate('admin.label.retorfitTransfer.transferInfo')}
            </Typography>
            <TransferButton
              redirectIcon={transferButtonIcon}
              redirectPath={redirectPath}
              retrofitId={retrofitId}
            />
          </>
        ) : null}
      </CustomForm>
    </Edit>
  );
};

RetrofitInfoEdit.displayName = 'RetrofitInfoEdit';
export default RetrofitInfoEdit;
