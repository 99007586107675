import { AdminApiConverter, ApiFilter } from '.';
import { convertFillDate } from '../../../utils';

const isOnline = (time: string, online: boolean) => {
  const last = new Date(time).getTime();
  const diff = Date.now() - last;
  return online && diff < 1000 * 60 * 3; // isOnline=true かつ lastOnlineTimeが現在日時から3分未満ならオンライン
};

const isError = (code: number) => {
  const status = code.toString();
  if (status.length !== 11 && status.length !== 14) return true;
  const [
    version,
    ahrsEstimateStatus,
    positionStatus,
    baseStationConnectionStatus,
    boomImuStatus,
    armImuStatus,
    bucketImuStatus,
    bodyImuStatus,
    boomHeadPressureSensorStatus,
    boomBottomPressureSensorStatus,
    tiltBucketImuStatus,
    secondBoomImuStatus,
    rotarySensorStatus,
    boomOffsetSensorStatus,
  ] = [
    status[0],
    status[1],
    status[2],
    status[3],
    status[4],
    status[5],
    status[6],
    status[7],
    status[8],
    status[9],
    status[10],
    status[11],
    status[12],
    status[13],
  ];

  // Versionの数値を除き，各数値が「0」または[]内の数値の場合にはエラーとして扱わない
  // ()内の数値は，各値がとりうる範囲を示している

  // Version (1) Version (2) ※今後，Reserveの利用に併せて更新
  if (version !== '1' && version !== '2') return true;

  // AHRS estimate status (0~6) [5]
  if (!['0', '5'].includes(ahrsEstimateStatus)) return true;

  // Position status (0~4) [1~3]
  if (!['0', '1', '2', '3'].includes(positionStatus)) return true;

  // Base station connection status (0~2) [1~2]
  if (!['0', '1', '2'].includes(baseStationConnectionStatus)) return true;

  // Boom IMU status (0~3)
  if (boomImuStatus !== '0') return true;

  // Arm IMU status (0~3)
  if (armImuStatus !== '0') return true;

  // Bucket IMU status (0~3)
  if (bucketImuStatus !== '0') return true;

  // Body IMU status (0~3)
  if (bodyImuStatus !== '0') return true;

  // Boom head pressure sensor status (0~3)
  if (boomHeadPressureSensorStatus !== '0') return true;

  // Boom bottom pressure sensor status (0~3)
  if (boomBottomPressureSensorStatus !== '0') return true;

  // Tilt bucket IMU status (0~3)
  if (tiltBucketImuStatus !== '0') return true;

  if (version === '2' && status.length === 14) {
    // Second Boom IMU status (0~3)
    if (secondBoomImuStatus !== '0') return true;

    // Rotary sensor status (0~6)
    if (rotarySensorStatus !== '0') return true;

    // Boom offset sensor status (0~6)
    if (boomOffsetSensorStatus !== '0') return true;
  }
  return false;
};

const converter: AdminApiConverter<'retrofits', 'retrofits'> = {
  resource: 'retrofits',
  defaultSort: { field: 'machineInfoMachineName', order: 'ASC' },
  filterToApi: filter => {
    const apiFilter: ApiFilter<'retrofits'> = {};
    if ('q' in filter) apiFilter.q = filter.q;
    if ('id' in filter) apiFilter.id = filter.id;
    if ('uuid' in filter) apiFilter.uuid = filter.uuid;
    if ('corporationId' in filter)
      apiFilter.corporationId = filter.corporationId;
    if ('ownerId' in filter) apiFilter.ownerId = filter.ownerId;
    if ('ownerCorporationId' in filter)
      apiFilter.ownerCorporationId = filter.ownerCorporationId;
    if ('adminCorporationId' in filter)
      apiFilter.adminCorporationId = filter.adminCorporationId;
    if ('siteId' in filter) apiFilter.siteId = filter.siteId;
    if ('isSiteAttached' in filter)
      apiFilter.isSiteAttached = filter.isSiteAttached;
    if ('macAddress' in filter) apiFilter.macAddress = filter.macAddress;
    if ('longitude' in filter) apiFilter.longitude = filter.longitude;
    if ('latitude' in filter) apiFilter.latitude = filter.latitude;
    if ('basicInfoManufacturer' in filter)
      apiFilter.basicInfoManufacturer = filter.basicInfoManufacturer;
    if ('basicInfoModel' in filter)
      apiFilter.basicInfoModel = filter.basicInfoModel;
    if ('basicInfoProductNumber' in filter)
      apiFilter.basicInfoProductNumber = filter.basicInfoProductNumber;
    if ('basicInfoSerialNumber' in filter)
      apiFilter.basicInfoSerialNumber = filter.basicInfoSerialNumber;
    if ('controllerManufacturer' in filter)
      apiFilter.controllerManufacturer = filter.controllerManufacturer;
    if ('controllerModel' in filter)
      apiFilter.controllerModel = filter.controllerModel;
    if ('controllerFirmwareVersion' in filter)
      apiFilter.controllerFirmwareVersion = filter.controllerFirmwareVersion;
    if ('gnssReceiverMainManufacturer' in filter)
      apiFilter.gnssReceiverMainManufacturer =
        filter.gnssReceiverMainManufacturer;
    if ('gnssReceiverMainModel' in filter)
      apiFilter.gnssReceiverMainModel = filter.gnssReceiverMainModel;
    if ('gnssReceiverMainFirmwareVersion' in filter)
      apiFilter.gnssReceiverMainFirmwareVersion =
        filter.gnssReceiverMainFirmwareVersion;
    if ('gnssReceiverSubManufacturer' in filter)
      apiFilter.gnssReceiverSubManufacturer =
        filter.gnssReceiverSubManufacturer;
    if ('gnssReceiverSubModel' in filter)
      apiFilter.gnssReceiverSubModel = filter.gnssReceiverSubModel;
    if ('gnssReceiverSubFirmwareVersion' in filter)
      apiFilter.gnssReceiverSubFirmwareVersion =
        filter.gnssReceiverSubFirmwareVersion;
    if ('machineInfoCompanyName' in filter)
      apiFilter.machineInfoCompanyName = filter.machineInfoCompanyName;
    if ('machineInfoMachineType' in filter)
      apiFilter.machineInfoMachineType = filter.machineInfoMachineType;
    if ('machineInfoMachineName' in filter)
      apiFilter.machineInfoMachineName = filter.machineInfoMachineName;
    if ('machineInfoMachineId' in filter)
      apiFilter.machineInfoMachineId = filter.machineInfoMachineId;
    if ('isError' in filter)
      throw new Error('Unsupported filter field: isError');
    if ('isOnline' in filter)
      throw new Error('Unsupported filter field: isOnline');
    if ('lastOnlineTime' in filter)
      throw new Error('Unsupported filter field: lastOnlineTime');
    if ('lastUpdated' in filter) apiFilter.updateDate = filter.lastUpdated;
    if ('corporationName' in filter)
      apiFilter.corporationName = filter.corporationName;
    if ('description' in filter) apiFilter.description = filter.description;
    if ('rentalStatus' in filter) {
      const definedValue =
        filter.rentalStatus === 'Approved' ? 'Approved' : 'Unapproved';
      const filterStatus =
        filter.rentalStatus === 'Returned' ? undefined : definedValue;
      apiFilter.rentalStatus = filterStatus;
    }
    if ('registrationApplicantId' in filter)
      throw new Error('Unsupported filter field: registrationApplicantId');
    if ('registrationApplicantName' in filter)
      throw new Error('Unsupported filter field: registrationApplicantName');
    apiFilter.hasLicense = filter.hasLicense;
    apiFilter.licenseGrantInfo = filter.licenseGrantInfo;
    if ('inSendingBackSupported' in filter)
      apiFilter.inSendingBackSupported = filter.inSendingBackSupported;
    if ('needsNtripDataReration' in filter)
      apiFilter.needsNtripDataReration = filter.needsNtripDataReration;
    return apiFilter;
  },
  sortFieldToApi: field => {
    if (field === 'isError') throw new Error('Unsupported sort field: isError');
    if (field === 'isOnline')
      throw new Error('Unsupported sort field: isOnline');
    if (field === 'lastOnlineTime')
      throw new Error('Unsupported sort field: lastOnlineTime');
    if (field === 'lastUpdated') return 'updateDate';
    if (field === 'ownerCorporationId')
      throw new Error('Unsupported sort field: ownerCorporationId');
    if (field === 'registrationApplicantId')
      throw new Error('Unsupported sort field: registrationApplicantId');
    if (field === 'registrationApplicantName')
      throw new Error('Unsupported sort field: registrationApplicantName');
    return field;
  },
  recordsFromApi: records =>
    Promise.resolve(
      records.map(record => {
        if (!record.uuid)
          throw new Error("Can't get UUID of Retrofit from the server.");
        /*
        建機名が設定：建機名
        建機名が未設定：BasicInfoの情報
        以下のretrofitsのBasicInfoの4項目を "/"で連結して編集する。   
          basicInfoManufacturer: string;
          basicInfoModel: string;
          basicInfoProductNumber: string;
          basicInfoSerialNumber: string;
        BasicInfoの情報が未設定”Unregistered Machine"
         */
        const basicInfo = [
          record.basicInfoManufacturer,
          record.basicInfoModel,
          record.basicInfoProductNumber,
          record.basicInfoSerialNumber,
        ];
        // 各種BasicInfoの値が１つでも設定されている場合は、「/」区切りで文字列連結
        const basicInfoMachineName =
          basicInfo.filter(Boolean).length > 0 ? basicInfo.join('/') : null;
        const settingMachineName =
          record.machineInfoMachineName ||
          basicInfoMachineName ||
          'Unregistered Machine';
        return {
          id: record.id || 0,
          retrofitDistributorId:
            record.retrofitDistributorId === null
              ? undefined
              : record.retrofitDistributorId,
          retrofitRegionId:
            record.retrofitRegionId === null
              ? undefined
              : record.retrofitRegionId,
          adminCorporations: record.adminCorporations,
          corporationId: record.corporationId || '',
          ownerCorporationId: record.ownerCorporationId || '',
          adminCorporationId: record.adminCorporationId || '',
          siteId: record.siteId === '' ? undefined : record.siteId,
          isSiteAttached: record.isSiteAttached ? record.isSiteAttached : false,
          corporationName: record.corporationName || '',
          ownerId: record.ownerId || '',
          ownerName: record.ownerName || '',
          description: record.description || '',
          macAddress: record.macAddress || '',
          longitude: record.longitude || 0,
          latitude: record.latitude || 0,
          uuid: record.uuid,
          isError: record.controllerStatus
            ? isError(record.controllerStatus)
            : false,
          isOnline: record.lastOnlineTime
            ? isOnline(
                record.lastOnlineTime,
                record.isOnline ? record.isOnline : false,
              )
            : false,
          lastOnlineTime: record.lastOnlineTime || '',
          basicInfoManufacturer: record.basicInfoManufacturer || '',
          basicInfoModel: record.basicInfoModel || '',
          basicInfoProductNumber: record.basicInfoProductNumber || '',
          basicInfoSerialNumber: record.basicInfoSerialNumber || '',
          controllerManufacturer: record.controllerManufacturer || '',
          controllerModel: record.controllerModel || '',
          controllerFirmwareVersion: record.controllerFirmwareVersion || '',
          gnssReceiverMainManufacturer:
            record.gnssReceiverMainManufacturer || '',
          gnssReceiverMainModel: record.gnssReceiverMainModel || '',
          gnssReceiverMainFirmwareVersion:
            record.gnssReceiverMainFirmwareVersion || '',
          gnssReceiverSubManufacturer: record.gnssReceiverSubManufacturer || '',
          gnssReceiverSubModel: record.gnssReceiverSubModel || '',
          gnssReceiverSubFirmwareVersion:
            record.gnssReceiverSubFirmwareVersion || '',
          gnssSettingId:
            record.gnssSettingId === null ? undefined : record.gnssSettingId,
          machineInfoCompanyName: record.machineInfoCompanyName || '',
          machineInfoMachineType: record.machineInfoMachineType || '',
          machineInfoMachineName: settingMachineName,
          machineInfoMachineNameParameter: record.machineInfoMachineName || '',
          machineInfoMachineId: record.machineInfoMachineId || '',
          rentalStatus: record.rentalStatus || 'Returned',
          lastUpdated: record.updateDate || '',
          licenseMachineType:
            record.hasLicense &&
            record.licenseGrantInfo !== 'Unlimited' &&
            record.licenseGrantInfo !== 'FlexUnlimited'
              ? `${record.machineInfoMachineType}_License`
              : record.machineInfoMachineType,
          hasLicense: record.hasLicense,
          licenseGrantInfo: record.licenseGrantInfo,
          inSendingBackSupported: record.inSendingBackSupported
            ? record.inSendingBackSupported
            : false,
          needsNtripDataReration: record.needsNtripDataReration
            ? record.needsNtripDataReration
            : false,
        };
      }),
    ),
  recordToApi: record => ({
    retrofitDistributorId: record.retrofitDistributorId,
    retrofitRegionId: record.retrofitRegionId,
    adminCorporations: record.adminCorporations,
    uuid: record.uuid,
    corporationId: record.corporationId,
    corporationName: record.corporationName,
    requestLocalDate: convertFillDate(new Date().toString(), {
      hour: 0,
      min: 0,
      sec: 0,
    }),
    description: record.description,
    basicInfoManufacturer: record.basicInfoManufacturer,
    basicInfoModel: record.basicInfoModel,
    basicInfoProductNumber: record.basicInfoProductNumber,
    basicInfoSerialNumber: record.basicInfoSerialNumber,
    siteId: record.siteId,
    gnssSettingId: record.gnssSettingId,
    registrationApplicantId: record.registrationApplicantId,
    registrationApplicantName: record.registrationApplicantName,
    needsNtripDataReration: record.needsNtripDataReration,
  }),
};

export default converter;
