import React from 'react';
import {
  MachineGuidanceIcon,
  PayloadMeterIcon,
  AsbuiltIcon,
  LicenseAllIcon,
} from '../assets';
import { RetrofitCorporationLicenseData } from '../dataProvider';

export interface LicenseElement {
  iconList: React.FC[];
  expiresAt: string;
  permission: string;
}

const licenseToConvertElement = (
  license: RetrofitCorporationLicenseData | undefined,
): LicenseElement | undefined => {
  if (!license) return undefined;

  const { licensePermission, expiresAt } = license;
  switch (licensePermission) {
    case 'All':
    case 'FlexAll':
    case 'Temporary':
      return {
        iconList: [LicenseAllIcon],
        expiresAt,
        permission: licensePermission,
      };

    case 'MachineGuidance':
      return {
        iconList: [MachineGuidanceIcon],
        expiresAt,
        permission: licensePermission,
      };
    case 'MachineGuidance2D':
      return {
        iconList: [MachineGuidanceIcon],
        expiresAt,
        permission: '2D MachineGuidance',
      };
    case 'PayloadMeter':
      return {
        iconList: [PayloadMeterIcon],
        expiresAt,
        permission: licensePermission,
      };
    case 'Asbuilt':
      return {
        iconList: [AsbuiltIcon],
        expiresAt,
        permission: licensePermission,
      };
    case 'Unlimited':
    case 'FlexUnlimited':
      return undefined;
    default:
  }
  console.error(`Illegal Permission: '${licensePermission}'`);
  return undefined;
};

export default licenseToConvertElement;
