import React, { useState, useCallback, useEffect } from 'react';
import { useRedirect } from 'react-admin';
import { useParams } from 'react-router';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import {
  Edit,
  CustomForm,
  BreadcrumbsFiled,
  TextField,
  AutocompleteInput,
  ReferenceField,
  CustomDateField,
  NormalButton,
  LoadingView,
  BucketStateView,
  Loading,
  FunctionField,
  IconFileField,
  CustomDeleteButton,
  IconField,
  IconFieldPaddingZeroStyle,
} from '../..';
import { useResource, useGetSelefDetailData, useAccount } from '../../../hooks';
import { getValidSiteStatus } from '../../../utils';
import { BucketData } from '../../../dataProvider/types';
import { ShareIcon } from '../../../assets';

const useStyles = makeStyles(() =>
  createStyles({
    form: {
      ...IconFieldPaddingZeroStyle,
    },
  }),
);

const BucketEdit: React.FC = () => {
  const classes = useStyles();
  const redirect = useRedirect();
  const resource = useResource('buckets');
  const { id: bucketId } = useParams<{ id: string }>();
  const props = {
    id: Number(bucketId),
    basePath: '/buckets',
    resource,
  };
  const getAccount = useAccount();
  const getSelefDetailData = useGetSelefDetailData();
  const redirectTo = useRedirect();

  const [apiState, setApiState] = useState<{
    fetched: boolean;
    loading: boolean;
    bucketId: string | undefined;
    data?: { isOwnData?: boolean; corporationId: string };
  }>({
    fetched: false,
    loading: true,
    bucketId: undefined,
    data: undefined,
  });
  const [status, setStatus] = useState('');
  const onStateHandle = useCallback(
    newStatus => {
      setStatus(newStatus);
    },
    [setStatus],
  );

  // 自社のバケットIdか判定
  useEffect(() => {
    if (apiState.fetched && apiState.bucketId === bucketId) return;
    setApiState({ fetched: true, loading: true, bucketId });

    const api = async () => {
      // accounts
      const {
        data: { corporationId },
      } = await getAccount();
      const filter = { corporationId };
      const bucket = await getSelefDetailData(resource, filter, bucketId);
      return {
        isOwnData: !!bucket,
        corporationId,
      };
    };
    api().then(data => {
      setApiState({ fetched: true, loading: false, bucketId, data });
    });
  }, [
    apiState.fetched,
    apiState.bucketId,
    getAccount,
    getSelefDetailData,
    bucketId,
    redirectTo,
    resource,
  ]);

  if (apiState.loading || !apiState.data) return <Loading />;

  const { isOwnData, corporationId } = apiState.data;
  if (!isOwnData) redirectTo('/');

  return (
    <LoadingView isForceBlocking={status === 'Converting'}>
      <Edit {...props}>
        <CustomForm
          title="admin.pages.bucketEdit"
          redirect={false}
          deleteButton={
            <CustomDeleteButton
              messageTargetItem="admin.dialog.bucketEdit.deleteConfirm.messageTargetItem"
              disabled={!(status === 'Completed' || status === 'Error')} // Completed, Error 以外は非活性
            />
          }
          actionButton={
            <NormalButton
              label="admin.actions.lendBucket"
              disabled={status !== 'Completed'} // Completed 以外は非活性
              onClick={() => {
                redirect(`${props.basePath}/${props.id}/create`);
              }}
            >
              <ShareIcon />
            </NormalButton>
          }
          className={classes.form}
        >
          <BucketStateView onStateChange={onStateHandle} />
          <BreadcrumbsFiled
            breadcrumbs={['resources.buckets.name']}
            label="ra.action.edit"
          />
          <ReferenceField
            resource={resource}
            source="corporationId"
            reference="corporations"
            link=""
          >
            <TextField resource="corporations" source="name" />
          </ReferenceField>
          <AutocompleteInput
            className="sites"
            resource={resource}
            source="siteId"
            choiceResources="sites"
            choicesCreate={({ id, name }: BucketData) => ({ id, name })}
            filter={{ corporationId, status: getValidSiteStatus }}
            sort={{ field: 'name', order: 'ASC' }}
            autoCompleteOptions={{
              suggestionsContainerProps: {
                modifiers: { keepTogether: { enabled: true } },
              },
            }}
            resettable={true}
            allowEmpty={false}
          />
          <TextField resource={resource} source="name" />
          <TextField resource={resource} source="bucketType" />
          <FunctionField
            resource={resource}
            source="bk3File"
            render={record => (
              <IconFileField record={record} source="bk3File" />
            )}
          />
          <FunctionField
            resource={resource}
            source="gltfFile"
            render={record => (
              <IconFileField record={record} source="gltfFile" />
            )}
          />
          <TextField resource={resource} source="uploadedBy" />
          <IconField resource={resource} source="fileStatus" />
          <CustomDateField resource={resource} source="lastUpdated" showTime />
          <TextField resource={resource} source="updatedBy" />
        </CustomForm>
      </Edit>
    </LoadingView>
  );
};

BucketEdit.displayName = 'BucketEdit';
export default BucketEdit;
