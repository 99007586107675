// for lt
// リトアニア語
import raMessage from 'ra-language-lithuanian';
import en from './en';

const { ra } = raMessage;
const message: typeof en = {
  ra: {
    ...ra,
    input: {
      ...ra.input,
      password: {
        toggle_visible: '',
        toggle_hidden: '',
      },
    },
    navigation: {
      ...ra.navigation,
      next: '',
      prev: '',
      no_results: 'Nerasta',
    },
    action: {
      ...ra.action,
      add_filter: '',
      confirm: 'OK',
      move_up: '',
      move_down: '',
      select_all: '',
      select_row: '',
    },
    message: {
      ...ra.message,
      bulk_delete_title: '',
      bulk_delete_content: 'Ar norite ištrinti %{name}?',
    },
  },
  admin: {
    title: {
      normal: 'Smart Cconstruction Pilot',
      split1: 'Smart Cconstruction',
      split2: 'Pilot',
    },
    copyright: 'Copyright © EARTHBRAIN Ltd. All rights reserved.',
    approval: 'Laukiama patvirtinimo',
    usermenu: {
      userNotice: 'Pranešimas',
      terms: 'NAUDOJIMO SĄLYGOS',
      manual: 'Vadovas',
      downloads: 'Parsisiųsti',
    },
    breadcrumbs: {
      details: 'Informacija',
      retrofitEdit: 'Informacija',
      taskCreate: 'Sukurti užduotį',
      taskEdit: 'Užduoties informacija',
      geofenceCreate: 'Sukurti',
      geofenceEdit: 'Redaguoti',
      geofenceAlertList: 'Geofencijos įspėjimas',
      geofenceAlertShow: 'Informacija',
      compactionWorkAreaCreate: 'Pridėti suspaudimo darbo plotus',
      compactionWorkAreaShow: 'Suspaudimo darbo sritys',
      compactionWorkAreaEdit: 'Redaguoti suspaudimo darbo sritis',
      compactionLayerCreate: 'Sukurti suspaudimo sluoksnius',
      compactionLayerEdit: 'Redaguoti suspaudimo sluoksnius',
      supportMode: 'Palaikymo režimas',
      retrofitManage: 'Valdiklio registracija',
      retrofitManageAdmin: 'Valdiklio registracija (Administratorius)',
      retrofitNamePlateFiles: 'Valdiklio registracija',
      retrofitAlternateRegistCreate: 'Valdiklio įgaliojimo registracija',
      retrofitAlternateRegistEdit: 'Informacija',
      retrofitManageAdminBulkActionCreate: 'Registracija',
      terms: 'NAUDOJIMO SĄLYGOS',
      downloads: 'Parsisiųsti',
    },
    pages: {
      siteList: 'Objektų sąrašas',
      siteRetrofitCreate: 'Pridėti mašiną darbui vietoje',
      siteRetrofitEdit: '',
      siteBucketCreate: 'Pridėti kibirą darbui vietoje',
      siteBucketEdit: 'Kubilo informacija',
      siteProjectEdit: 'Projekto informacija',
      siteProjectCreate: 'Projektų failų registravimas',
      siteExtensionarmCreate: 'Pridėti pratęsimo ranką darbui vietoje',
      taskCreate: 'Sukurti užduotį',
      taskEdit: 'Užduoties informacija',
      geofenceAlertList: 'Geofencijos įspėjimas',
      geofenceAlertShow: 'Geofencijos įspėjimo informacija',
      geofenceCreate: 'Sukurti geoinfekciją',
      geofenceEdit: 'Redaguoti geoinfekciją',
      compactionWorkAreaCreate: 'Pridėti suspaudimo darbo plotus',
      compactionLayerCreate: 'Sukurti suspaudimo sluoksnius',
      compactionLayerEdit: 'Redaguoti suspaudimo sluoksnius',
      siteFileEdit: 'Informacija',
      siteFileCreate: 'Pridėti failą darbui vietoje',
      retrofitList: 'Mašinų sąrašas',
      retrofitEdit: '',
      retrofitRentalCreate: 'Užduoties registracija',
      retrofitTransferCreate: 'Prijungimo registracija',
      retrofitSendBack: 'Siųsti registraciją atgal',
      bucketList: 'Kubilų sąrašas',
      bucketEdit: 'Kubilo informacija',
      bucketCreate: 'Kibilo registracija',
      bucketRentalCreate: 'Kibilo bendradarbiavimas',
      extensionarmList: 'Išplėtimo rankenos sąrašas',
      extensionarmEdit: 'Išplėtimo rankenos informacija',
      extensionarmShare: 'Išplėtimo rankenos bendrinimas',
      retrofitBulkActionList: 'Masinė registracijos sąrašas',
      retrofitBulkActionCreate: 'Masinė registracija',
      retrofitBasicInfoList: 'Valdiklio sąrašas',
      retrofitBasicInfoCreate: 'Valdiklio registracija',
      retrofitBasicInfoEdit: 'Valdiklio atnaujinimas',
      gnssSettingList: 'Ntrip nustatymų sąrašas',
      gnssSettingCreate: 'Sukurti Ntrip nustatymą',
      gnssSettingAdminCreate: 'Sukurti Ntrip nustatymą',
      gnssSettingOwnerCreate: 'Sukurti Ntrip nustatymą',
      gnssSettingEdit: 'Redaguoti Ntrip nustatymą',
      gnssSettingAdminEdit: 'Redaguoti Ntrip nustatymą',
      gnssSettingOwnerEdit: 'Redaguoti Ntrip nustatymą',
      approvalList: 'Mašinų sąrašas',
      retrofitRentalEdit: 'Užduoties informacija',
      retrofitTransferEdit: 'Perdavimo informacija',
      asbuiltsCreate: 'Gauti statybos rezultatą',
      asbuiltsShow: 'Asbuild istorija',
      retrofitConfigList: 'Konfigūracijos informacija',
      retrofitKitInfoList: 'Valdiklio informacija',
      retrofitAccuraciesList: 'Tikslumo patikros rezultatas',
      retrofitAccuraciesShow: 'Informacija',
      retrofitCalibaList: 'Kalibravimo informacija',
      retrofitCalibInfo: 'Mašinų kalibravimo informacija Išsami informacija',
      retrofitBasicSettingList: 'Pagrindinės nustatymai',
      retrofitErrorList: 'Klaidos informacija',
      retrofitErrorShow: 'Informacija',
      retrofitTopographicSurveyList: 'Topografinių ištyrimų sąrašas',
      retrofitTopographicSurveyShow: 'Topografinio matavimo informacija',
      retrofitShareFileList: 'Programos žurnalas',
      retrofitCorporationLicenseList: 'Licencijų sąrašas',
      retrofitCorporationLicenseEdit: 'Licencijos informacija',
      CompactionLayerList: 'Suspaudimo sluoksniai',
      userNoticeList: 'Pranešimų sąrašas',
      userNoticeCreate: 'Pridėti pranešimą',
      userNoticeEdit: 'Atnaujinti pranešimą',
      userNoticeDrawer: 'Pranešimas',
      retrofitAlternateRegists: 'Valdiklio Proxy registracijos sąrašas',
      firmwareList: 'Firmware sąrašas',
      firmwareShow: 'Firmware Details',
      firmwareCreate: 'Pridėti programinę įrangą',
      retrofitManage: 'Tekstas',
      retrofitNamePlateFiles: 'Foto',
      retrofitManageAdminBulkAction: 'Masinė registracija',
      retrofitManageAdminBulkActionCreate: 'Masinė registracija',
      retrofitAlternateRegistCreate: 'Valdiklio įgaliojimo registracija',
      retrofitAlternateRegistEdit: 'Valdiklio Proxy registracijos informacija',
      retrofitAlternateRegistCreateText: 'Tekstas',
      retrofitAlternateRegistCreatePhoto: 'Foto',
      ntripList: 'Ntrip Info',
      ntripEdit: 'Ntrip informacijos valdymas',
    },
    label: {
      login: 'Prisijungti',
      selected: 'Pasirinktas (%{length} atvejai)',
      listNoResults: 'Duomenų nerasta',
      maintenance: {
        title: 'Šiuo metu vyksta techninė priežiūra.',
        message: `Laikinai sustabdėme paslaugas dėl techninės priežiūros darbų.
      Atsiprašome už nepatogumus, prašome palaukti dar šiek tiek.`,
      },
      fileInput: {
        project: 'xml, tp3, tn3, gc3, dxf, ttm, svd, dsz, cal',
        bucket: 'bk3',
        csv: 'csv',
        pdf: 'pdf',
        namePlate: 'jpg, png',
        plaseholder:
          'Nometiet vai atlasiet failus (%{fileTypes}), lai augšupielādētu.',
        maxFileNum:
          'Didžiausias kontrolierų skaičius, kurį galima vienu metu registruoti, yra penki.',
      },
      nosetting: 'Nėra nustatymų',
      siteRetrofitList: {
        remoteSupport: 'Nuotolinė palaikymo',
      },
      retrofitEdit: {
        breadcrumbs: 'Informacija',
        belongsDetails: '',
        ntripSettingInfo: 'Ntrip nustatymo informacija',
        machineryDetails: 'Mašinos',
        retrofitDetails: 'Valdiklis',
        basicInfo: 'Bazinė informacija',
        controller: 'Valdiklis',
        reciverMain: 'GNSS pagrindinis imtuvas',
        reciverSub: 'GNSS antrinis imtuvas',
        retrofitstatus: 'Valdiklio būsena',
        license: 'Licencija',
      },
      retrofitRental: {
        rentalDetails: 'Užduoties informacija',
        rentalInfo: 'Užduoties informacija',
        lending: 'Užduotis',
        return: 'Sugrįžimas',
        lendingDestination: 'Priskirta įmonė',
        lendingCompany: 'Įmonės pavadinimas',
        lendingEmail: 'El.paštas',
        companyName: 'Įmonės pavadinimas',
      },
      retorfitTransfer: {
        tab: 'Perdavimas',
        transferInfo: 'Perdavimo informacija',
        transferButton: 'Perdavimas',
        transferEditButton: 'Redaguoti perdavimą',
      },
      siteConfigureEdit: {
        onButton: 'ĮJUNGTA',
        offButton: 'IŠJUNGTA',
      },
      approvals: {
        rentalTab: 'Užduotis',
        transferTab: 'Perdavimas',
      },
      buckets: {
        bucketTab: 'Kaušas',
        extensionarmTab: 'Prailginta tolimesnė strėlė',
        extensionarmFile: 'Išplėtimo rankenos failas',
        shareTo: 'Bendrinti su',
      },
      bucketRental: {
        corporation: 'Bendrinti su',
        bucketType: 'Tipas',
        bk3File: 'BK3 failas',
      },
      retrofitBasic: {
        basicInfoTab: 'Bazinė informacija',
        bulkActionTab: 'Masinė registracija',
        ntripTab: 'Ntrip informacijos valdymas',
      },
      retrofitSendbacks: {
        retrofitSendbackInfo: 'Siųsti atgal',
        initialize: 'Inicializacija',
      },
      retrofitBasicInfos: {
        bulkRegistrationTitle: 'Masinė atnaujinimas',
        retrofitDistributor: 'Platintojas',
        retrofitRegion: 'Regionas',
        corporationId: 'Įmonės ID',
        corporationName: 'Įmonės pavadinimas',
      },
      retrofitBasicInfoEdit: {
        corporationId: 'Įmonės ID naudojamas',
        corporationName: 'Įmonė naudojama',
      },
      gnssSettingsEdit: {
        ownerCorporationName: 'Įmonės pavadinimas',
      },
      terms: {
        title: 'NAUDOJIMO SĄLYGOS',
        message:
          'Prašome paspausti žemiau esantį nuorodą, kad galėtumėte peržiūrėti naudojimosi sąlygas',
        confirmMessage1:
          'Norėdami naudoti „Smart Construction Pilot“, turite sutikti su šiais dokumentais.',
        confirmMessage2:
          'Jeigu nesutinkate, negalėsite naudoti „Smart Construction Pilot“.',
        select: 'Prašome pasirinkti savo regioną.',
      },
      downloads: {
        title: 'Projektų failų konverteris',
        message:
          'Tai savarankiška programa, kuri sukuria projekto failą iš projektavimo failų.',
        button: 'Parsisiųsti',
      },
      retrofitBasicInfoCreate: {
        uuid: 'Automatinis numeravimas',
      },
      retrofitBulkActions: {
        uploadFileDownload: 'Parsisiųsti',
        resultFileDownload: 'Parsisiųsti',
      },
      tasks: {
        coordinate: 'Koordinatės (unit:%{unit})',
        coordinateX: 'X',
        coordinateY: 'Y',
      },
      compactionWorkAreas: {
        blockSize: 'Bloko dydis (%{unit})',
      },
      compactionLayers: {
        thickness: 'Storis (%{unit})',
      },
      pendingRentals: {
        breadcrumbs: 'Informacija',
        rentalDetail: 'Užduoties informacija',
      },
      pendingTransfers: {
        breadcrumbs: 'Informacija',
        transferDetail: 'Perdavimo informacija',
      },
      pendingAlternateRegists: {
        breadcrumbs: 'Informacija',
        alternateRegistDetail: 'Valdiklio Proxy registracijos informacija',
      },
      supportMode: {
        retrofit: 'Mašinos',
        optional: 'Neprivaloma',
      },
      retrofitList: {
        assignment: 'Užduotis',
        license: 'Licencija',
      },
      userNotices: {
        languageDetails: 'Pranešimo turinys',
        language: 'Kalba',
        languageEn: 'English',
        languageJa: 'Japonų',
        languageFr: 'Prancūzų',
        languageDe: 'Vokiečių',
        languageEs: 'Ispanų kalba',
        Information: 'Pranešimas',
        Maintenance: 'Priežiūra',
        emergency: 'Skubus',
        important: 'Svarbu',
      },
      userNoticeDrawer: {
        more: '',
        close: '',
      },
      retrofitsManage: {
        menuTitle: 'Valdiklio registracija',
        corporationName: 'Įmonės pavadinimas',
        basicInfoSerialNumber: 'Serijinis numeris',
        confirmInput: 'Įveskite serijos numerį iš naujo',
      },
      retrofitNamePlateFiles: {
        serialNumber: 'Serijinis numeris',
        status: 'Būsena',
        imageFile: 'Paveikslelis',
        message1:
          'Prašome padaryti aiškią ir nesumarškintą nuotrauką su valdiklio serijos numeriu arba QR kodu.',
        message2: 'Paveikslai turi būti mažesni nei %{mbyte} MB.',
        errormessage:
          'Didžiausias kontrolierų skaičius, kurį galima vienu metu registruoti, yra penki.',
      },
      retrofitCorporationLicenses: {
        licenseStatusChoices: {
          enable: 'Galiojantis',
          disable: 'Neteisingas',
          unLicense: 'Neužskirta',
        },
        machine: 'Mašinos',
        before: 'Prieš',
        after: 'Po',
      },
      geofences: {
        project: 'Projektas',
        projectSourceLayer: 'Sluoksnis',
        rgb: 'Spalva(RGB)',
        select: 'Pasirinkimo geofencas',
        coordinates: 'Koordinatės',
        coordinatesLabel: 'Koordinatės (N,E)',
        unitLength: 'Vienetas:%{unit}',
      },
      geofenceDialog: {
        coordinate: 'Koordinatės (unit:%{unit})',
        coordinateX: 'X',
        coordinateY: 'Y',
        radius: 'Spindulys',
      },
      retrofitAlternateRegists: {
        menuTitle: 'Valdiklio įgaliojimo registracija',
        corporationName: 'Įmonės pavadinimas',
        email: 'El.paštas',
        basicInfoSerialNumber: 'Serijinis numeris',
        confirmInput: 'Įveskite serijos numerį iš naujo',
      },
      ntripList: {
        unusedTotal: 'Nenaudojama suma',
      },
      calibrationDetail: {
        breadcrumbs: 'Informacija',
        machineInfo: 'Mašinos informacija',
        machineGeometryInfo: 'Mašinos geometrijos informacija',
        bodyCenterToBoomFootPin: '',
        imuMountInfo: 'Pajungimo informacija',
        gnssMountInfo: '',
        bodyCenterToGnssMain: 'Pagrindinio GNS tvirtinimo informacija',
        bodyCenterToGnssSub: 'Antrinio GNS tvirtinimo informacija',
        extendedModel: 'Išplėstinis modelis',
        measuredValues: 'Kalibravimo informacija',
      },
    },
    errorInfo: {
      projects: {
        system_scapi_error: 'Duomenų konvertavimo klaida',
        system_localization_quantity_error: `Negalima registruoti kelių failų su lokalizacijos informacija.
      Užregistruokite tik vieną failą, kuriame yra lokalizacijos informacijos.`,
        system_detect_0byte_file_error: `Failo registracija serveryje nepavyko dėl ryšio klaidos.
      Prašome dar kartą užregistruoti failą.`,
        system_batch_error: 'Sistemos klaida (P001)',
        system_parameter_error: 'Sistemos klaida (P002)',
        system_parameterfile_notfound: 'Sistemos klaida (P003)',
        system_exception: 'Sistemos klaida (P004)',
        system_logfile_error: 'Sistemos klaida (P005)',
        system_parameterfile_parameter_error: 'Sistemos klaida (P006)',
        desing_localization_error: `Nėra lokalizacijos informacijos.
      Prašome užregistruoti vieną iš šių failų:
      
      ・GC3 failas
      ・TP3 failas su kontroliniais taškais
      ・CAL failas`,
        desing_no_support_dxf_error:
          'DXF failas (-ai) negalėjo būti perskaitytas (-i), nes jo/jų versija senesnė nei AutoCAD2000.',
        desing_tile_error: 'Nepavyko sukurti projekto failo.',
        desing_outline_error: 'Nepavyko sukurti išorinės ribinės linijos.',
        desing_area_length_error:
          'Procesas buvo nutrauktas, nes projekto sritis yra per didelė. Patikrinkite, ar projekto duomenys ir CAD duomenų mastelis yra teisingi.',
        desing_layer_name_duplication_error: `Projektiniai duomenys su pasikartojančiais sluoksnio pavadinimais negali būti registruojami.
      Prašome pakeisti dizaino sluoksnio pavadinimą arba registruoti, kad būtų tik vienas failas su tuo pačiu dizaino sluoksnio pavadinimu.`,
        targetFiles: '[Failo pavadinimas]',
        targetLayers: '[Sluoksnis]',
        errorFiles: '・%{errorFile}',
      },
      tasks: {
        none: '„None“ (Nėra)',
        system_batch_error: 'Sistemos klaida (T001)',
        system_parameter_error: 'Sistemos klaida (T002)',
        system_parameterfile_notfound: 'Sistemos klaida (T003)',
        system_exception: 'Sistemos klaida (T004)',
        system_logfile_error: 'Sistemos klaida (T005)',
        system_basefile_notfound: 'Sistemos klaida (T006)',
        system_prjfile_multi: 'Sistemos klaida (T007)',
        system_basefile_read_error: 'Sistemos klaida (T008)',
        system_target_layer_notfound: 'Sistemos klaida (T009)',
        system_target_layer_duplication:
          'Toks pats lygio pavadinimas jau egzistuoja',
        system_taskarea_error: 'Neleistina užduoties sritis',
        system_taskarea_design_notfound:
          'Užduoties srityje nėra projekto failo duomenų (TIN, linijų, taškų).',
        system_tile_error: 'Nepavyko sukurti projekto failo.',
        system_outline_error: 'Nepavyko sukurti išorinės ribinės linijos.',
        system_parameterfile_error: 'Sistemos klaida',
      },
      retrofitError: {
        1000: 'UDP duomenys iš valdiklio neaptikti',
        2000: 'Kėbulo IMU neaptiktas',
        2001: 'Kėbulo IMU techninės įrangos klaida',
        2002: 'Kėbulo IMU algoritmo klaida',
        2003: 'Kėbulo IMU duomenų klaida',
        2004: 'Kėbulo IMU inicijavimo klaida',
        2100: 'Strėlės IMU neaptiktas',
        2101: 'Strėlės IMU techninės įrangos klaida',
        2102: 'Strėlės IMU algoritmo klaida',
        2103: 'Strėlės IMU duomenų klaida',
        2104: 'Strėlės IMU inicijavimo klaida',
        2200: 'Tolimesnės strėlės IMU neaptiktas',
        2201: 'Tolimesnės strėlės techninės įrangos klaida',
        2202: 'Tolimesnės strėlės algoritmo klaida',
        2203: 'Tolimesnės strėlės IMU duomenų klaida',
        2204: 'Tolimesnės strėlės IMU inicijavimo klaida',
        2300: 'Kaušo IMU neaptiktas',
        2301: 'Kaušo IMU techninės įrangos klaida',
        2302: 'Kaušo IMU algoritmo klaida',
        2303: 'Kaušo IMU duomenų klaida',
        2304: 'Kaušo IMU inicijavimo klaida',
        2400: 'Kaušo pakreipimo IMU neaptiktas',
        2401: 'Kaušo pakreipimo IMU techninės įrangos klaida',
        2402: 'Kaušo pakreipimo IMU algoritmo klaida',
        2403: 'Kaušo pakreipimo IMU duomenų klaida',
        2404: 'Kaušo pakreipimo IMU inicijavimo klaida',
        2500: '2 dalių strėlės IMU neaptiktas',
        2501: '2 dalių strėlės IMU techninės įrangos klaida',
        2502: '2 dalių strėlės IMU algoritmo klaida',
        2503: '2 dalių strėlės IMU duomenų klaida',
        2504: '2 dalių strėlės IMU inicijavimo klaida',
        2600: 'Neaptiktas svyravimo kampo jutiklis',
        2601: 'Svyravimo kampo jutiklio sistemos klaida',
        2602: 'Svyravimo kampo jutiklio aparatūros klaida',
        2700: 'Neaptiktas Poslinkio jutiklis',
        2701: 'Poslinkio jutiklis sistemos klaida',
        2702: 'Poslinkio jutiklis aparatūros klaida',
        3000: 'Viršutinės pusės slėgio jutiklis neaptiktas',
        3100: 'Apatinės pusės slėgio jutiklis neaptiktas',
        4000: 'GNSS antena neaptikta',
        5000: 'Taisymo informacija neaptikta',
        5001: 'Radijo imtuvo signalas neaptiktas',
      },
    },
    dialog: {
      menu: {
        confirmManualLink:
          'Jūs būsite nukreipti į kitą svetainę. Ar viskas gerai?',
      },
      bucketEdit: {
        deleteConfirm: {
          messageTargetItem: 'Kaušas',
        },
      },
      retrofitBasicInfoList: {
        saveValidation: {
          message: 'Įmonės ID ir įmonės pavadinimas yra būtini.',
        },
        save: {
          message1: 'Įmonės ID/pavadinimas negali būti pakeistas, nes jame yra',
          message2: 'mašina(s) priskirta(-os) kitiems įmonėms.',
          message3: '',
          message4: 'Prašome patikrinkite žemiau esančius serijos numerius',
        },
      },
      retrofitBasicInfoEdit: {
        deleteConfirm: {
          messageTargetItem: 'Valdiklis',
        },
        undeletable: {
          message: 'Valdiklis yra naudojamas ir negali būti pašalintas.',
        },
      },
      Ntrip: {
        save: {
          stop: 'Ar norite sustabdyti šį Ntrip nustatymą?',
          delete: 'Ar norite ištrinti Ntrip nustatymą?',
        },
      },
      siteRetrofitList: {
        title: 'Papildoma informacija',
        message: 'Statybos laikotarpis baigtas.',
      },
      siteConfigureEdit: {
        title: {
          commonSetting: 'Bendri nustatymai',
          siteSetting: 'Objekto nustatymai',
        },
        mapBackgroundColor: 'Žemėlapio fono spalva',
        message:
          'Priklausomai nuo aplinkos ir sąlygų, kuriomis ji naudojama, geografinės zonos kontakto aptikimo / įspėjimo pranešimo funkcija gali veikti netinkamai. Nesinaudokite funkcija pernelyg pasitikėdami savimi ir įsitikinkite, kad suprantate jos paskirtį ir naudojimo sąlygas.',
      },
      geofenceList: {
        confirmDelete: {
          message: 'Ar norite ištrinti šią geoinfekciją?',
        },
      },
      retrofitRentalCreate: {
        unableTieUpJobSite: {
          message1:
            'Mašina yra susieta su darbo vieta ir negali būti priskirta. Atjunkite mašiną nuo darbo vietos.',
          message2: 'Prašome atjungti mašiną nuo darbo vietos.',
        },
        sameOwner: {
          message:
            'Mašina negali būti priskirta, nes jūsų įmonė yra savininkė.',
        },
        unableTransfer: {
          message:
            'Mašina laukia perdavimo patvirtinimo ir negali būti registruojama priskyrimui.',
        },
        unableAlternateRegist: {
          message:
            'Mašina laukia Controller Proxy registracijos patvirtinimo ir negali būti registruojama priskyrimui.',
        },
      },
      retrofitTransferCreate: {
        messageSiteId:
          'Mašina yra susieta su darbo vieta, jos negalima perduoti. Atjunkite mašiną nuo darbo vietos.',
        messageRentaled: 'Mašina yra priskirta ir jos negalima perkelti.',
        messageLicense: 'Mašina turi licenciją ir jos negalima perduoti.',
        pendingApprovals: {
          message1: 'Pervedimo užklausa gauta. Laukite patvirtinimo.',
          message2:
            'Jei turite kokių nors klausimų, prašome susisiekti su mumis naudodami žemiau esantį URL.',
        },
      },
      retrofitSendBacks: {
        submit:
          'Ar norite atgauti valdiklį ir paveldėti nustatymus į tikslinį valdiklį?',
        deleteNtrip: 'Ar norite inicializuoti grąžintą valdiklį?',
        unableRegion:
          'Šis valdiklis išsiųstas užsienyje. Jei tai teisingas valdiklis, nustatykite "Region" į null.',
        unableCorporation: {
          message1: 'Valdiklis negali būti išsiųstas atgal.',
          message2:
            'Jei manote, kad tai klaida, prašome susisiekti su savo platintoju.',
        },
        retrofitRentaled:
          'Valdiklis yra priskirtas ir negali būti išsiųstas atgal.',
        tiedToSite:
          'Mašina yra susieta su darbo vieta ir jos negalima išsiųsti atgal.',
      },
      retrofitAddToSiteAction: {
        title: 'Papildoma informacija',
        message:
          'Yra mašina, laukianti patvirtinimo, todėl jos negalima susieti su darbo vieta.',
      },
      retrofitAddToSiteActionTransfer: {
        title: 'Papildoma informacija',
        message: 'Mašina perkeliama ir jos negalima susieti su darbo vieta.',
      },
      retrofitEdit: {
        unableSettingGnessInfo: {
          message: 'Pasirinktas "Ntrip Setting" siunčiamas į kitą mašiną.',
        },
        unableTransferInfo: {
          message: 'Norėdami perduoti, atšaukite šiuos nustatymus.',
          reasonSiteId: 'Objektas',
          reasonRentaled: 'Užduotis',
          reasonLicense: 'Licencija',
        },
      },
      retrofitRentalEdit: {
        overDoubleRentalStartDate: {
          message1:
            'Priskirta įmonė šiuo metu priskiria mašiną kitai įmonei. Priskyrimo pradžios data kitai įmonei yra %{date}.',
          message2: 'Prašome pasirinkti pabaigos datą po %{date}.',
        },
        confirmTieUpJobsite: {
          message:
            'Mašina yra susieta su priskirtoje įmonėje esančia darbo vieta. Ar norite nutraukti šį priskyrimą?',
        },
        confirmDoubleRental: {
          message:
            '"Priskirta įmonė šiuo metu priskiria mašiną kitai įmonei. Priskirta įmonė šiuo metu priskiria mašiną kitai įmonei. Nustatant pabaigos datą, taip pat baigiasi priskyrimas kitai įmonei. Ar norite nustatyti pabaigos datą?"',
        },
        unapprovedSave: {
          message: 'Ši užduotis nebuvo patvirtinta ir jos negalima išsaugoti.',
        },
        unapprovedDelete: {
          title: '',
          message: 'Ar norite ištrinti šį užduotį?',
        },
        approvedDelete: {
          title: '',
          message: 'Ši užduotis patvirtinta ir jos negalima ištrinti.',
        },
      },
      retrofitTransferEdit: {
        unapprovedSave: {
          title: '',
          message: 'Mašina šiuo metu yra priskirta ir negali būti perkelta.',
        },
        approvedSave: {
          message: 'Mašina turi licenciją ir jos negalima perduoti.',
        },
        unapprovedDelete: {
          title: '',
          message: 'Ar norite ištrinti šį perdavimą?',
        },
        approvedDelete: {
          title: '',
          message: 'Šis perdavimas patvirtintas ir negali būti ištrintas.',
        },
      },
      extensionarmEdit: {
        deleteConfirm: {
          messageTargetItem: 'Prailginta tolimesnė strėlė',
        },
      },
      projectFileUpload: {
        message:
          'Negalima registruoti kelių kontrolės taškų failų (.gc3/.cal).',
      },
      projectEdit: {
        confirm: {
          message:
            'Kai atnaujinsite projektą, bus ištrintos esamos užduotys. Ar norite atnaujinti?',
        },
      },
      projectConversionStatus: {
        title: 'Konversijos būsena',
        button: 'OK',
      },
      taskConversionStatus: {
        title: 'Konversijos būsena',
        button: 'OK',
      },
      taskList: {
        undeletable: {
          message: 'Pasirinktos užduoties negalima ištrinti, nes ji naudojama.',
        },
        unableToCreate: {
          message: 'Projektas nav reģistrēti dizaina dati.',
        },
        unableToDelete: {
          message1:
            'Užduoties negalima ištrinti, nes projekto būsena ir užduoties būsena yra tokios.',
          message2: 'Projektas: Konvertuojama',
          message3: 'Užduotis: Klaida',
        },
        unableToUpdate: {
          message1:
            'Užduoties negalima redaguoti, nes projekto būsena ir užduoties būsena nėra tokios, kaip nurodyta toliau.',
          message2: 'Projektas: [Konvertuotas]',
          message3: 'Užduotis: [Pervedimas][Pervedimas][Nepavyko pervesti]',
        },
      },
      taskEdit: {
        deleteConfirm: {
          messageTargetItem: 'Užduotis',
        },
        unableToDelete: {
          message: 'Pasirinktos užduoties negalima ištrinti, nes ji naudojama.',
        },
      },
      taskMap: {
        crossingMessage: 'Užduoties sritis susikerta.',
        sameValueMessage: 'Buvo įvestos tos pačios koordinatės.',
        areaZeroMessage: 'Nėra užduoties srities ploto.',
        noPolygonMessage:
          'Užduoties negalima užregistruoti, nes ji dar nėra užregistruota.',
      },
      compactionMaterials: {
        add: {
          title: 'Pridėti medžiagą',
        },
        update: {
          title: 'Atnaujinti medžiagą',
        },
      },
      weathers: {
        add: {
          title: 'Pridėti oro sąlygas',
        },
        update: {
          title: 'Atnaujinti orą',
        },
      },
      operators: {
        add: {
          title: 'Pridėti operatorių',
        },
        update: {
          title: 'Atnaujinti operatorių',
        },
      },
      compactionWorkAreas: {
        update: {
          message: 'Redaguoti suspaudimo darbo sritis',
        },
        undeletable: {
          message:
            'Suspaudimo sluoksnis, įtrauktas į statybos plotą, yra naudojamas ir negali būti pašalintas.',
        },
      },
      compactionLayers: {
        undeletable: {
          message:
            'Suspaudimo sluoksnis yra naudojamas ir negali būti pašalintas.',
        },
        deleteConfirm: {
          messageTargetItem: 'Suspaudimo sluoksnis',
        },
      },
      gnssSettings: {
        unableSettingGnessInfo: {
          message: 'Pasirinktai mašinai siunčiamas kitas Ntrip nustatymas.',
        },
        confirmDelete: {
          message: 'Ar norite ištrinti Ntrip nustatymą?',
        },
        confirmDeleteWithMachine: {
          message:
            'Mašina šiuo metu yra susieta. Ar norite ištrinti "Ntrip Setting" (Nusistatymo nustatymas)?',
        },
      },
      userNoticeEdit: {
        deleteConfirm: {
          messageTargetItem: 'Pranešimas',
        },
      },
      retrofitCorporationLicenses: {
        confirm: {
          message: 'Nustatykite licenciją(-as) šiai mašinai.',
        },
        changeDisable: {
          message:
            'Prašome pakeisti šių statybinės technikos licencijos ID į galiojantį.',
        },
        changeTemporary: {
          message:
            'Nustatykite licenciją(-as) šiai mašinai. Dabartinė laikina licencija bus negaliojanti.',
        },
        errorAdd: {
          message:
            'Mašinos licencija negali būti pakeista, nes jau nustatyta All license (Visos licencijos).',
        },
        errorSameType: {
          message:
            'Mašinos licencija negali būti pakeista, nes jau nustatyta tokio paties tipo licencija.',
        },
        errorUnlimited: {
          message:
            'Mašina jau nustatyta Neribota licencija, kitų licencijų nustatyti negalima.',
        },
        addAll: {
          message:
            'Šiai mašinai jau nustatyta licencija (-os). Ar norite nustatyti Visas licencijas?',
        },
        compare: {
          message: 'Pakeiskite licenciją(-as) šiai mašinai.',
        },
        warning: {
          message:
            '%{retrofit} turi licenciją su %{id}. Ar norite atjungti licenciją?',
        },
        unableTransfer: {
          message: 'Mašina perkeliama taip, kad licencijos negalima susieti.',
        },
      },
      retrofitNamePlateFiles: {
        error: {
          serial: 'Yra duomenų, kuriuos nepavyko išanalizuoti.',
        },
      },
      retrofitManageTab: {
        pendingApprovals: {
          message1:
            'Registracijos užklausa buvo gauta. Palaukite, kol jis bus patvirtintas.',
          message2:
            'Jei turite kokių nors klausimų, prašome susisiekti su mumis naudodami žemiau esantį URL.',
        },
        corporationIdCheck: {
          message1:
            'Valdiklis jau yra užregistruotas. Prašome patikrinti, ieškodami mašinose.',
          message2:
            'Jei negalite rasti tikslinio valdiklio sąraše, prašome susisiekti su mumis naudodami žemiau esantį URL.',
        },
        earthbrainIdCheck: {
          message1: 'Atsiprašome už nepatogumus',
          message2:
            'bet prašome susisiekite su mumis iš žemiau esančio URL su šia informacija.',
          message3: '-Valdiklio serijos numeris',
          message4: '-Pavadinimas mašinos, kuri bus registruota (neprivaloma)',
          message5: '-Įmonės ID, kurį reikia užregistruoti',
        },
        invalidSerialNumber: {
          message1: 'Valdiklis jau yra naudojamas kitos įmonės.',
          message2: 'Jei norėtumėte pakeisti dėl kitų priežasčių,',
          message3:
            'Prašome susisiekti su mumis naudojant žemiau pateiktą URL ir pateikti šią informaciją.',
          message4: '-Valdiklio serijos numeris',
          message5: '-Pavadinimas mašinos, kuri bus registruota (neprivaloma)',
          message6: '-Įmonės ID, kurį reikia užregistruoti',
          message7: '-Priežastis keitimui',
        },
        lendingRetrofits: {
          message1: 'Valdiklis yra priskirtas.',
          message2:
            'Prašome iš naujo pateikti paraišką po priskyrimo įmonės pabaigos.',
          message3:
            'Jei turite kokių nors klausimų, prašome susisiekti su mumis naudodami žemiau esantį URL.',
        },
        siteAttached: {
          message1: 'Valdiklis yra susietas su darbaviete.',
          message2:
            'Prašome iš naujo pateikti paraišką, atjungus mašiną nuo darbo vietos.',
          message3:
            'Jei turite kokių nors klausimų, prašome susisiekti su mumis naudodami žemiau esantį URL.',
        },
        serialNumberExistError: {
          message1:
            'Tai yra neegzistuojančio valdiklio serijos numeris. Įsitikinkite, kad įvestas valdiklio serijos numeris yra teisingas.',
          message2:
            'Jei problema tęsiasi, susisiekite su mumis naudodami žemiau esantį URL.',
        },
        ntripShortage: {
          message1:
            'Negalėjome išduoti jūsų Ntrip vartotojo ID ir slaptažodžio.',
          message2:
            'Prašome susisiekti su mumis naudojant žemiau pateiktą URL.',
        },
        serialnumberCheck: {
          message: 'Prašome patikrinti produktus su šiais serijos numeriais',
        },
      },
      retrofitAlternateRegists: {
        deleteConfirm: {
          messageTargetItem: 'retrofitAlternateRegists',
        },
        saveValidation: {
          message: 'El.paštas yra privalomas',
        },
        pendingApprovals: {
          message1:
            'Gavote registracijos užklausą. Prašome palaukti, kol ji bus patvirtinta.',
          message2:
            'Jei turite kokių nors klausimų, prašome susisiekti su mumis naudodami žemiau esantį URL.',
        },
        corporationIdCheck: {
          message1:
            'Valdiklis jau yra užregistruotas. Prašome patikrinti, ieškodami mašinose.',
          message2:
            'Jei negalite rasti tikslinio valdiklio sąraše, prašome susisiekti su mumis naudodami žemiau esantį URL.',
        },
        earthbrainIdCheck: {
          message1: 'Atsiprašome už nepatogumus',
          message2:
            'bet prašome susisiekite su mumis iš žemiau esančio URL su šia informacija.',
          message3: '-Valdiklio serijos numeris',
          message4: '-Pavadinimas mašinos, kuri bus registruota (neprivaloma)',
          message5: '-Įmonės ID, kurį reikia užregistruoti',
        },
        invalidSerialNumber: {
          message1: 'Valdiklis jau yra naudojamas kitos įmonės.',
          message2: 'Jei norėtumėte pakeisti dėl kitų priežasčių,',
          message3:
            'Prašome susisiekti su mumis naudojant žemiau pateiktą URL ir pateikti šią informaciją.',
          message4: '-Valdiklio serijos numeris',
          message5: '-Pavadinimas mašinos, kuri bus registruota (neprivaloma)',
          message6: '-Įmonės ID, kurį reikia užregistruoti',
          message7: '-Priežastis keitimui',
        },
        lendingRetrofits: {
          message1: 'Valdiklis yra priskirtas.',
          message2:
            'Prašome iš naujo pateikti paraišką po priskyrimo įmonės pabaigos.',
          message3:
            'Jei turite kokių nors klausimų, prašome susisiekti su mumis naudodami žemiau esantį URL.',
        },
        siteAttached: {
          message1: 'Valdiklis yra susietas su darbaviete.',
          message2:
            'Prašome iš naujo pateikti paraišką, atjungus mašiną nuo darbo vietos.',
          message3:
            'Jei turite kokių nors klausimų, prašome susisiekti su mumis naudodami žemiau esantį URL.',
        },
        serialNumberExistError: {
          message1:
            'Tai yra neegzistuojančio valdiklio serijos numeris. Įsitikinkite, kad įvestas valdiklio serijos numeris yra teisingas.',
          message2:
            'Jei problema tęsiasi, susisiekite su mumis naudodami žemiau esantį URL.',
        },
        ntripShortage: {
          message1:
            'Negalėjome išduoti jūsų Ntrip vartotojo ID ir slaptažodžio.',
          message2:
            'Prašome susisiekti su mumis naudojant žemiau pateiktą URL.',
        },
        serialnumberCheck: {
          message: 'Prašome patikrinti produktus su šiais serijos numeriais',
        },
      },
      pendingApprovals: {
        ownerCorporation: {
          message1: 'Šis valdiklis jau priklauso jūsų įmonei.',
          message2: 'Prašome susisiekti su prašančiąja įmonę.',
        },
        userCorporation: {
          message1:
            'Jūs negalite patvirtinti šios užklausos, nes jau skolinate šį valdiklį.',
          message2: 'Prašome susisiekti su prašančiąja įmonę.',
        },
      },
      licensePurchaseNotice: {
        button: 'GERAI',
        message1: `Įmonėje naudojama mašina be licencijos. 
        Norint naudoti mašiną darbo vietoje, reikia turėti licenciją. 
        Jei licencijos neįsigijote, įsigykite ją iš Smart Construction Marketplace.`,
        message2: `
        Jei mašiną nuomojatės iš kitos įmonės, paprašykite mašinos licencijos iš nuomos šaltinio. 
        
        Jei turite klausimų, apsilankykite šioje pagalbos svetainėje.`,
      },
    },
    tootip: {
      retrofitShareFileList: 'Programos žurnalas',
      retrofitConfigList: 'Konfigūracijos informacija',
      retrofitKitInfoList: 'Valdiklio informacija',
      retrofitAccuraciesList: 'Tikslumo patikros rezultatas',
      retrofitCalibaList: 'Kalibravimo informacija',
      retrofitBasicSettingList: 'Baziniai nustatymai',
      retrofitErrorList: 'Klaidos informacija',
      retrofitTopographicSurveyList: 'Topografinių ištyrimas',
    },
    actions: {
      ok: 'OK',
      add: 'Pridėti',
      create: 'Registracija',
      yes: 'Taip',
      no: 'Ne',
      confirmation: 'Patvirtinti',
      save: 'IŠSAUGOTI',
      cancel: 'Atšaukti',
      required: 'Būtinas',
      addMachineToSite: 'Pridėti mašiną darbui vietoje',
      addBucketToSite: 'Pridėti',
      deleteFromSite: 'Atjungti nuo darbo vietos',
      removeLicense: 'Atsijungti nuo mašinos',
      lendMachine: 'Užduotis',
      transferMachine: 'Perdavimas',
      remoteSupport: 'Nuotolinė palaikymo',
      lendBucket: 'Bendrinti',
      approval: 'Patvirtinimas',
      connect: 'Jungtis',
      creationInstructions: 'Gauti statybos rezultatą',
      csvDownload: 'Parsisiųsti',
      retrofitBundleCreate: 'Masinė registracija',
      termsAccept: 'Sutikti',
      termsDeny: 'Nesutikti',
      bulkRegistration: 'Masinė atnaujinimas',
      bulkRegistrationOK: 'OK',
      compactionItems: 'Suspaudimo elementai',
      addLanguage: 'Pridėti kalbą',
    },
    message: {
      error: 'Įvyko klaida',
      confirm: 'Ar viskas gerai?',
      fileUpload: 'Vilkt un nomest failus šeit',
      success: 'Registracija baigta',
      approved: 'Patvirtinimas baigtas',
      deleteConfirmTitle: '',
      deleteConfirmContent: 'Ar norite ištrinti %{title}?',
      bulkDeleteConfirmContent: 'Ar norite ištrinti %{title}?',
      disconnectFromSite: 'Ar vēlaties atvienoties no darbavietas?',
      saveConfirmContent: 'Patvirtinti',
      removeLicense: 'Ar vēlaties atvienoties no mašīnas?',
      task: {
        crossingMessage: 'Užduoties sritis susikerta.',
        sameValueMessage: 'Buvo įvestos tos pačios koordinatės.',
        areaZeroMessage: 'Nėra užduoties srities ploto.',
        invalidPolygonMessage: 'Buvo įvestos tos pačios koordinatės.',
      },
      geofence: {
        crossingMessage: 'Geografinės zonos plotas yra susikirtęs.',
        sameValueMessage: 'Buvo įvestos tos pačios koordinatės.',
        areaZeroMessage: 'Nėra geografinio aptvėrimo srities.',
      },
      licenseError:
        'Jūs neturite "Smart Construction Pilot" (Web App) licencijos.',
      retrofitsManage: {
        serialNumberMatchError: 'Serijinis numeris neatitinka',
        serialNumberInputError: 'Serijinis numeris neegzistuoja',
        invalidAccess:
          'Jūs neturite teisės peržiūrėti šio puslapio, grįžkite į pradinį puslapį.',
      },
      retrofitsAlternateRegistText: {
        serialNumberMatchError: 'Serijinis numeris neatitinka',
        serialNumberInputError: 'Serijinis numeris neegzistuoja',
        retrofitRentaled:
          'Įmonės informacijos negalima atnaujinti, kol kontroleris yra priskirtas.',
        invalidCorporation:
          'Kadangi jūsų įmonė nėra platintojas, negalite atlikti valdiklio įgaliojamojo registracijos.',
      },
    },
    validation: {
      startDate: 'Pradžios data',
      endDate: 'Pabaigos data',
      invalidDateFormat: 'Datums formatas neteisingas.',
      invalidDateTimeFormat: 'Datums ir laiko formatas neteisingas.',
      duplicateProject: 'Projekto pavadinimas yra dubliuotas',
      incorrectBeforeDate: '%{target} turi būti nustatytas prieš %{before}.',
      incorrectAfterDate: '%{target} turi būti nustatytas po %{after}.',
      incorrectPlanStartDate:
        'Planuojama pradžios data turi būti prieš planuojamą pabaigos datą.',
      incorrectPlanEndDate:
        'Planuojama pabaigos data turi būti po planuojamos pradžios datos.',
      incorrectActualStartDate: 'Pradžios data turi būti prieš pabaigos datą.',
      incorrectActualEndDate:
        'Pabaigos data turi būti nustatyta po pradžios datos.',
      withActualStartDate: 'Prašome taip pat įvesti pradžios datą.',
      inputAvailableDate: 'Galima įvesti data nuo %{date}.',
      registrationRequired:
        'Prašome pasirinkti priskirtą įmonę arba el. pašto adresą.',
      bothInput: 'Jūs galite įvesti tik priskirtą įmonę arba el. pašto adresą.',
      supportMode: 'Prašome įvesti įmonės ID arba statybos vietos ID.',
      invalidBkFile: 'Tai nėra BK3 failas.',
      invalidPdfFile: 'Tai nėra PDF failas.',
      duplicatedBucketName: 'Kibilo pavadinimas yra dubliuotas',
      duplicatedTaskName: 'Užduoties pavadinimas dubliuojamas.',
      duplicateGeofenceName: 'Geofencijos pavadinimas dubliuojamas',
      duplicatedCompactionWorkAreaName:
        'Pasikartojantis suspaudimo darbo srities pavadinimas',
      duplicatedCompactionLayerName:
        'Pasikartojantis suspaudimo sluoksnio pavadinimas',
      duplicated: {
        compactionMaterials: {
          message: 'Pasikartojantis medžiagos pavadinimas',
        },
        weathers: {
          message: 'Pasikartojantis oro pavadinimas',
        },
        operators: {
          message: 'Pasikartojantis operatoriaus pavadinimas',
        },
      },
      containInvalidString:
        'Įtraukiami neleidžiami simboliai. (%{msg} negalimas)',
      validHalfWidthAlphanumeric:
        'Leidžiamos tik pusiaukščio raidės ir skaičiai.',
      validNumberFormat: 'Nėra numeris',
      validIntegerFormat: 'Nėra sveikasis skaičius',
      validIntegerRangeFormat: 'Įvestis leidžiama nuo 0 iki 2147483647.',
      validDuplicateLanguage: 'Pasirinkta ta pati kalba.',
      validBeforeDate:
        '%{target} turi būti nustatytas datą ir laiką prieš %{before}.',
      validAfterDate:
        '%{target} turi būti nustatytas datą ir laiką po %{after}.',
    },
  },
  resources: {
    sites: {
      name: 'Objektas',
      fields: {
        name: 'Objektas',
        corporationName: 'Įmonės pavadinimas',
        status: 'Būsena',
        workPeriodStartDate: 'Pradžios data',
        workPeriodEndDate: 'Pabaigos data',
      },
      status: {
        completed: 'Užbaigtas',
        waiting: 'Prieš pradedant statyti',
        working: 'Statyba',
      },
    },
    retrofits: {
      name: 'Mašinos',
      fields: {
        information: 'Mašinos informacija',
        rental: 'Užduotis',
        sendBack: 'Siųsti atgal',
        corporationId: 'Įmonė naudojama',
        siteId: 'Objektas',
        uuid: 'UUID',
        isError: 'Būsena',
        isOnline: 'Prisijungęs',
        lastOnlineTime: 'Paskutinė prisijungimas',
        basicInfoManufacturer: 'Gamintojas',
        basicInfoModel: 'Modelis',
        basicInfoProductNumber: 'Gaminio Nr.',
        basicInfoSerialNumber: 'Serijos Nr.',
        controllerManufacturer: 'Gamintojas',
        controllerModel: 'Modelis',
        controllerFirmwareVersion: 'Programinės-aparatinės įrangos versija',
        gnssReceiverMainManufacturer: 'Gamintojas',
        gnssReceiverMainModel: 'Modelis',
        gnssReceiverMainFirmwareVersion:
          'Programinės-aparatinės įrangos versija',
        gnssReceiverSubManufacturer: 'Gamintojas',
        gnssReceiverSubModel: 'Modelis',
        gnssReceiverSubFirmwareVersion:
          'Programinės-aparatinės įrangos versija',
        machineInfoCompanyName: 'Gamintojas',
        machineInfoMachineType: 'Mašinos tipas',
        machineInfoMachineName: 'Mašinos pavadinimas',
        machineInfoMachineId: 'Mašinos ID',
        rentalStatus: 'Užduotis',
        lastUpdated: 'Paskutinė atnaujinimas',
      },
      isError: {
        true: 'Klaida',
        false: 'Normalus',
      },
      isOnline: {
        true: 'Prisijungęs',
        false: 'Neprisijungęs',
      },
      machineInfoMachineType: {
        excavator: 'Kasos ekskavatorius',
        roadroller: 'Kelio gręžtuvas',
        dozer: 'Buldozeris',
        rigiddump: 'Stiprus nusileidimas',
        wheelloader: 'Ratų krautuvas',
        grader: 'Graderis',
        automobile: 'Automobilis',
      },
      licenseMachineType: {
        excavator: 'Kasos ekskavatorius',
        roadroller: 'Kelio gręžtuvas',
        dozer: 'Buldozeris',
        rigiddump: 'Stiprus nusileidimas',
        wheelloader: 'Ratų krautuvas',
        grader: 'Graderis',
        automobile: 'Automobilis',
        excavator_license: 'Kasos ekskavatorius : %{licenseGrantInfo}',
        roadroller_license: 'Kelio gręžtuvas : %{licenseGrantInfo}',
        dozer_license: 'Buldozeris : %{licenseGrantInfo}',
        rigiddump_license: 'Stiprus nusileidimas : %{licenseGrantInfo}',
        wheelloader_license: 'Ratų krautuvas: %{licenseGrantInfo}',
        grader_license: 'Graderis : %{licenseGrantInfo}',
        automobile_license: 'Automobilis: %{licenseGrantInfo}',
      },
      gnssSettingStatus: {
        sending: 'Perdavimas',
        sent: 'Perdavimas',
        failtosend: 'Perdavimas nepavyko',
      },
      rentalStatus: {
        approved: 'Patvirtinta',
        unapproved: 'Nesutikta',
        returned: 'Sugrąžinta',
      },
    },
    retrofitRentals: {
      name: 'Užduotis',
      fields: {
        id: 'ID',
        retrofitId: 'Mašinos ID',
        fromCorporationId: 'Priskyrimo įmonės ID',
        fromCorporationName: 'Priskyrimo įmonė',
        toCorporationId: 'Priskirta įmonė',
        toCorporationName: 'Įmonė naudojama',
        retrofitRentalId: 'Užduoties ID',
        isApproved: 'Būsena',
        isReturned: 'Sugrąžinta',
        planStartDate: 'Planuojama pradžios data',
        planEndDate: 'Planuojama pabaigos data',
        actualStartDate: 'Pradžios data',
        actualEndDate: 'Pabaigos data',
        rentalUsers: 'El.paštas',
        email: 'El.paštas',
        rentalStatus: 'Būsena',
      },
      isApproved: {
        true: 'Patvirtinta',
        false: 'Nesutikta',
      },
      rentalStatus: {
        approved: 'Patvirtinta',
        unapproved: 'Nesutikta',
        returned: 'Sugrąžinta',
      },
    },
    pendingRentals: {
      name: 'Laukiama patvirtinimo',
      fields: {
        fromCorporationName: 'Priskyrimo įmonė',
        planStartDate: 'Planuojama pradžios data',
        planEndDate: 'Planuojama pabaigos data',
      },
    },
    pendingTransfers: {
      name: 'Laukiama patvirtinimo',
      fields: {
        fromCorporationName: 'Prijungianti įmonė',
        planDate: 'Planuojama perdavimo data',
        lastUpdated: 'Paskutinė atnaujinimas',
      },
    },
    pendingAlternateRegists: {
      name: 'Laukiama patvirtinimo',
      fields: {
        fromCorporationName: 'Dabartinis savininkas',
        lastUpdated: 'Paskutinė atnaujinimas',
      },
    },
    assignments: {
      name: 'Perdavimas',
      fields: {
        id: 'ID',
        retrofitId: 'Mašinos ID',
        fromCorporationId: 'Prijungiančios įmonės ID',
        fromCorporationName: 'Prijungianti įmonė',
        toCorporationId: 'Prijungta įmonė',
        toCorporationName: 'Įmonė',
        isApproved: 'Būsena',
        planDate: 'Planuojama perdavimo data',
        actualDate: 'Perdavimo data',
        transferUsers: 'El.paštas',
        email: 'El.paštas',
        lastUpdated: 'Paskutinė atnaujinimas',
      },
      isApproved: {
        true: 'Patvirtinta',
        false: 'Nesutikta',
      },
    },
    buckets: {
      name: 'Kaušas',
      fields: {
        companyName: 'Įmonės pavadinimas',
        siteId: 'Objektas',
        name: 'Pavadinimas',
        corporationId: 'Įmonė naudojama',
        bucketType: 'Tipas',
        bk3File: 'BK3 failas',
        'bk3File.title': 'BK3 failas',
        gltfFile: 'Kaušo failas',
        uploadedBy: 'Užregistruota',
        updatedBy: 'Atnaujinta',
        fileStatus: 'Būsena',
        lastUpdated: 'Paskutinė atnaujinimas',
        uploadBk: 'Įkelti BK3',
      },
      bucketType: {
        slope: 'Šlaitas',
        standard: 'Standartinis',
        tilt: 'Lenkimas',
      },
      uploadedBy: {
        admin: 'Administratorius',
        tablet: 'Tabletė',
      },
      fileStatus: {
        converting: 'Konvertavimas',
        completed: 'Užbaigtas',
        error: 'Klaida',
      },
    },
    projects: {
      name: 'Projektas',
      fields: {
        name: 'Projekto pavadinimas',
        coordinate: 'Koordinačių sistema',
        version: 'Versija',
        status: 'Būsena',
        lastUpdated: 'Paskutinė atnaujinimas',
        regionId: 'Regionas',
        projectionId: 'Projekcija',
        geoidId: 'Geoidas',
        datumId: 'Atskaitos taškas',
        file: 'Failai',
        retrofitId: 'Tikslinė mašina',
      },
      status: {
        converted: 'Užbaigtas',
        waitforconvert: 'Konvertavimas',
        error: 'Klaida',
      },
    },
    extensionarms: {
      name: 'Prailginta tolimesnė strėlė',
      fields: {
        extensionarmType: 'Tipas',
        name: 'Pavadinimas',
        corporationId: 'Įmonė naudojama',
        corporationName: 'Įmonė naudojama',
        siteId: 'Objektas',
        siteName: 'Objektas',
        lastUpdated: 'Paskutinė atnaujinimas',
        extensionarmFile: 'Išplėtimo rankenos failas',
      },
      extensionarmType: {
        basic: 'Prailginta tolimesnė strėlė',
        a: 'Tipas A',
        b: 'Tipas B',
      },
    },
    extensionarmShares: {
      fields: {
        corporationId: 'Bendrinti su',
        name: 'Pavadinimas',
        extensionarmType: 'Tipas',
        extensionarmFile: {
          title: 'Išplėtimo rankenos failas',
        },
      },
    },
    corporations: {
      name: 'Įmonė',
      fields: {
        name: 'Įmonė naudojama',
      },
    },
    retrofitShareFiles: {
      name: 'Programos žurnalas',
      fields: {
        name: 'Failo pavadinimas',
        fileTime: 'Gavimo data ir laikas',
        url: 'Parsisiųsti',
      },
    },
    retrofitConfigs: {
      name: 'Konfigūracijos informacija',
      fields: {
        timeStamp: 'Gavimo data ir laikas',
        url: 'Parsisiųsti',
      },
    },
    retrofitKitInfos: {
      name: 'Valdiklio informacija',
      fields: {
        timeStamp: 'Gavimo data ir laikas',
        url: 'Parsisiųsti',
      },
    },
    retrofitCalibs: {
      name: 'Kalibravimo informacija',
      fields: {
        timeStamp: 'Gavimo data ir laikas',
        url: 'Parsisiųsti',
        detailInfo: {
          createDateTime: 'Sukūrimo data ir laikas',
          updateDateTime: 'Atnaujinimo data ir laikas',
          machineInfo: {
            companyName: 'Gamintojas',
            machineType: 'Mašinos tipas',
            machineName: 'Mašinos pavadinimas',
            machineId: 'Mašinos ID',
            drumConfiguration: 'Mašinos konfigūracija',
            guidanceMode: 'Vedimo režimas',
          },
          machineGeometryInfo: {
            boomLength: 'Strėlės ilgis',
            secondBoomLength: '2 dalių strėlės ilgis',
            armLength: 'Tolimesnės strėlės ilgis',
            armTopPinToBucketSideLinkPinLength:
              'Atstumas tarp tolimesnės strėlės viršutinės ir kaušo šoninės jungties',
            boomTopPinToBucketSideLinkPinLength:
              'Atstumas tarp strėlės viršutinės ir kaušo šoninės jungtiesDistance b/w Boom Top and Bucket Side Link',
            bucketLink1Length:
              'Atstumas tarp kaušo šoninės jungties ir kaušo cilindro',
            bucketLink2Length: 'Atstumas tarp kaušo cilindro ir kaušo jungties',
            bodyCenterToBoomFootPin: {
              x: 'Strėlės pagrindo ašis X pagal kėbulo centrą',
              y: 'Strėlės pagrindo ašis Y pagal kėbulo centrą',
              z: 'Strėlės pagrindo ašis Z pagal kėbulo centrą',
            },
            drumWidth: 'Geležtės plotis',
            length: 'Ilgis tarp batų',
            shoeInterval: 'Intervalo tarp batų ilgis',
            bladeWidthForDozer: 'Buldozerio ašmenų plotis',
            distanceFromBodyToBlade: 'Atstumas nuo kėbulo iki ašmenų',
            frontToMainGnssAntenna:
              'Ilgis nuo tiesios linijos, jungiančios priekinį įžemintą galą priekyje su pagrindine gnss antena, centro X',
            frontToSubGnssAntenna:
              'Ilgis nuo tiesiosios linijos, jungiančios priekinį įžemintą priekinį galą su antenos antgaliu, centro X',
            centerToMainGnssAntenna:
              'Y Atstumas nuo centro iki pagrindinės GNSS elektrinės fazės centro',
            centerToSubGnssAntenna:
              'Y atstumas nuo centro iki antrinės GNSS elektrinės fazės centro',
            groundToMainGnssAntenna:
              'Z atstumas nuo žemės iki pagrindinio GNSS elektrinės fazės centro',
            groundToSubGnssAntenna:
              'Z atstumas nuo žemės iki sub GNSS elektrinės fazės centro',
          },
          imuMountInfo: {
            boomImuOffsetAngle: 'Strėlės IMU poslinkio kampas',
            secondBoomImuOffsetAngle: '2 dalių strėlės IMU poslinkio kampas',
            armImuOffsetAngle: 'Tolimesnės strėlės IMU poslinkio kampas',
            bucketImuOffsetAngle: 'Kaušo IMU poslinkio kampas',
            bodyImuOffsetRollAngle: 'Kėbulo IMU polinkio kampo poslinkis',
            bodyImuOffsetPitchAngle: 'Kėbulo IMU pakreipimo kampo poslinkis',
          },
          gnssMountInfo: {
            main: {
              bodyCenterToGnss: {
                x: 'Pagrindinio GNSS X pagal kėbulo centrą',
                y: 'Pagrindinio GNSS Y pagal kėbulo centrą',
                z: 'Pagrindinio GNSS Z pagal kėbulo centrą',
              },
            },
            sub: {
              bodyCenterToGnss: {
                x: 'Antrinio GNSS X pagal kėbulo centrą',
                y: 'Antrinio GNSS Y pagal kėbulo centrą',
                z: 'Antrinio GNSS Z pagal kėbulo centrą',
              },
            },
          },
          extendedModel: {
            swingBoom: 'Svyranti strėlė modelis',
            twoPieceBoom: '2 dalių strėlės modelis',
            minimalSwingRadius: 'Paslankioji strėlė modelis',
          },
          measuredValues: {
            otherLength: {
              machineHeight: 'Tarpas tarp apkabų',
            },
            bodyImu1: {
              roll: 'IMU išmatuotos vertės pasukimas',
              pitch: 'IMU išmatuota posvyrio vertė',
              yaw: 'IMU išmatuota posvyrio vertė',
            },
            bodyImu2: {
              roll: 'IMU išmatuota posvyrio vertė',
              pitch: 'Išmatuota IMU posvyrio vertė',
              yaw: 'Išmatuota IMU posvyrio vertė',
            },
            frontLeft: {
              x: 'Ištirta priekinės kairės pusės X',
              y: 'Tiriamoji priekinės kairės pusės vertė Y',
              z: 'Ištirta priekinės kairės pusės vertė Z',
            },
            frontRight: {
              x: 'Ištirta priekinės dešinės pusės vertė X',
              y: 'Ištirta priekinės dešinės pusės vertė Y',
              z: 'Apskaičiuota priekinės dešinės pusės vertė Z',
            },
            frontGroundingEnd: {
              x: 'Apskaičiuota priekinio goundingo galo vertė X',
              y: 'Apskaičiuota priekinio goundingo galo vertė Y',
              z: 'Apskaičiuota priekinio goundingo galo vertė Z',
            },
            rearGroundingEnd: {
              x: 'Apskaičiuota galinio įlenkimo galo vertė X',
              y: 'Apskaičiuota galinio įlenkimo galo vertė Y',
              z: 'Apskaičiuota galinio gondoliavimo galo vertė Z',
            },
            mainGnssAntenna: {
              x: 'Pagrindinės GNSS antenos išmatuota vertė X',
              y: 'Pagrindinės GNSS antenos tiriamoji vertė Y',
              z: 'Pagrindinės GNSS antenos apžiūrėta vertė Z',
            },
            subGnssAntenna: {
              x: 'Apžiūrėta antrinės GNSS antenos vertė X',
              y: 'Apskaičiuotoji sub GNSS antenos vertė Y',
              z: 'Apskaičiuota antenos po GNSS Z vertė',
            },
            prismPoleOffset: 'Prizminio stulpo aukštis',
            antennaElectricalCenterHeight:
              'Aukštis nuo antenos elektrinio centro',
          },
        },
      },
    },
    retrofitAccuracies: {
      name: 'Tikslumo patikros rezultatas',
      fields: {
        checkDateTime: 'Patvirtinimo datos ir laiko',
        surveyPoint: 'Matuotas taškas',
        positionN: 'Matuota vertė : N',
        positionE: 'E',
        positionZ: 'Z',
        machineOffsetN: 'Postūmio vertė : N',
        machineOffsetE: 'E',
        machineOffsetZ: 'Z',
        referencePointName: 'Atskaites taško pavadinimas',
        referencePointN: 'Atskaites taškas : N',
        referencePointE: 'E',
        referencePointZ: 'Z',
        unitLength: 'Ilgio mato vienetas',
      },
    },
    retrofitBasicSettings: {
      name: 'Baziniai nustatymai',
      fields: {
        timeStamp: 'Gavimo data ir laikas',
        url: 'Parsisiųsti',
      },
    },
    retrofitErrors: {
      name: 'Klaidos informacija',
      fields: {
        notifiedTime: 'Įvykio data ir laikas',
        errorCodes: 'Klaidos kodas: Išsami informacija',
      },
    },
    retrofitToposurveys: {
      name: 'Topografinių ištyrimų sąrašas',
      fields: {
        surveyDate: 'Apklausos data ir laikas',
        projectName: 'Projekto pavadinimas',
        layerName: 'Sluoksnis',
        pointName: 'Taško pavadinimas',
        surveyPoint: 'Matuotas taškas',
        n: 'Matuota vertė : N',
        e: 'E',
        z: 'Z',
        unitLength: 'Ilgio mato vienetas',
      },
    },
    asbuilts: {
      name: 'Statybos rezultatai',
      fields: {
        execStartDate: 'Vykdymo pradžia',
        execEndDate: 'Vykdymo pabaiga',
        startPeriod: 'Įsigijimo laikotarpis nuo',
        endPeriod: 'Įsigijimo laikotarpis iki',
        execStatus: 'Būsena',
        url: '',
        csvType: 'Duomenų formatas',
        createUser: 'Sukurti vartotoją',
      },
      execStatus: {
        complete: 'Užbaigtas',
        inprogress: 'Apdorojimas',
        error: 'Klaida',
        completewithoutdata: 'Užbaigtas (Nėra duomenų)',
      },
    },
    retrofitBasicInfos: {
      name: 'Bazinė informacija',
      fields: {
        uuid: 'UUID',
        retrofitDistributorId: 'Platintojas',
        retrofitRegionId: 'Regionas',
        corporationId: 'Įmonės ID',
        corporationName: 'Įmonės pavadinimas',
        basicInfoManufacturer: 'Gamintojas',
        basicInfoModel: 'Modelis',
        basicInfoProductNumber: 'Gaminio Nr.',
        basicInfoSerialNumber: 'Serijos Nr.',
        lastUpdated: 'Paskutinė atnaujinimas',
        description: 'Aprašymas',
        ownerId: 'Įmonės ID',
        ownerName: 'Įmonės pavadinimas',
      },
      tag: {
        retrofitBasicInfosDetail: 'Bazinė informacija',
        sendBack: 'Siųsti atgal',
      },
      sendBack: {
        fields: {
          site: 'Objektas',
          corporation: 'Įmonė naudojama',
          ntripSettingInfo: 'Ntrip ID',
          toRetrofit: 'Alternatyvusis valdiklis',
          notificationEmailAddress: 'El.paštas',
        },
      },
    },
    retrofitBulkActions: {
      name: 'Masinė registracija',
      fields: {
        uploadFileName: 'Failo pavadinimas',
        execStartDate: 'Vykdymo pradžios data ir laikas',
        execEndDate: 'Vykdymo pabaigos data ir laikas',
        execStatus: 'Būsena',
        uploadFile: 'Užregistruotas failas',
        resultFile: 'Registracijos rezultatai',
      },
      execStatus: {
        complete: 'Užbaigtas',
        inprogress: 'Apdorojimas',
        error: 'Klaida',
      },
    },
    retrofitAdminCorporationBulkActions: {
      name: 'Administratoriaus bendrovės masinė veikla',
      fields: {
        uploadFileName: 'Failo pavadinimas',
        execStartDate: 'Vykdymo pradžios data ir laikas',
        execEndDate: 'Vykdymo pabaigos data ir laikas',
        execStatus: 'Būsena',
        uploadFile: 'Užregistruotas failas',
        resultFile: 'Registracijos rezultatai',
      },
      execStatus: {
        complete: 'Užbaigtas',
        inprogress: 'Apdorojimas',
        error: 'Klaida',
      },
    },
    tasks: {
      name: 'Užduotis',
      fields: {
        name: 'Pavadinimas',
        status: 'Būsena',
        description: 'Aprašymas',
        projectSourceLayerId: 'Sluoksnis',
        retrofitIds: 'Tikslinė mašina',
        expiredDate: 'Galiojimo data',
        createDate: 'Galiojimo data',
        rectangle: 'Palaikomas intervalas',
        lastUpdated: 'Paskutinė atnaujinimas',
      },
      status: {
        error: 'Klaida',
        converting: 'Konvertavimas',
        sending: 'Perdavimas',
        sent: 'Perdavimas',
        failtosend: 'Perdavimas nepavyko',
      },
    },
    compactionMaterials: {
      name: 'Medžiagos',
      fields: {
        name: 'Pavadinimas',
      },
    },
    weathers: {
      name: 'Oro sąlygos',
      fields: {
        name: 'Pavadinimas',
      },
    },
    operators: {
      name: 'Operatoriai',
      fields: {
        name: 'Pavadinimas',
      },
    },
    compactionWorkAreas: {
      name: 'Suspaudimo darbo sritys',
      fields: {
        name: 'Darbo sričių pavadinimai',
        blockSize: 'Bloko dydis',
        description: 'Aprašymas',
      },
    },
    compactionLayers: {
      name: 'Suspaudimo sluoksniai',
      fields: {
        name: 'Pavadinimas',
        thickness: 'Storis',
        passCount: 'Perėjimų skaičius',
        compactionMaterialId: 'Medžiaga',
        inUse: 'Būsena',
        description: 'Aprašymas',
      },
      inUse: {
        true: 'naudojama',
        false: 'Nenaudojamas',
      },
    },
    gnssSettings: {
      name: 'Ntrip nustatymas',
      fields: {
        registCorporationName: 'Užregistruota',
        ownerCorporationId: 'Įmonės ID',
        ownerCorporationName: 'Įmonės pavadinimas',
        name: 'Pavadinimas',
        url: 'Serverio URL',
        portnum: 'Prievadas',
        userId: 'ID',
        password: 'Slaptažodis',
        retrofitId: 'Tikslinė mašina',
        status: 'Būsena',
        updateDate: 'Paskutinė atnaujinimas',
      },
      status: {
        waitforsend: 'Konvertavimas',
        sending: 'Perdavimas',
        sent: 'Perdavimas',
        failtosend: 'Perdavimas nepavyko',
      },
    },
    supportMode: {
      name: 'Palaikymo režimas',
      fields: {
        enabled: 'Įjungti palaikymo režimą',
        corporationId: 'Įmonės ID',
        corporationName: 'Įmonės pavadinimas',
        siteId: 'Objekto ID',
        siteName: 'Objektas',
        latitude: 'Platuma',
        longitude: 'Ilguma',
        workPeriodStartDate: 'Tikras statybos laikotarpis (pradžios data)',
        workPeriodEndDate: 'Tikras statybos laikotarpis (pabaigos data)',
        photoUrl: 'Svetainės paveikslėlio URL',
        description: 'Aprašymas',
      },
    },
    userNotices: {
      name: 'Pranešimas',
      noticeType: {
        emergencyimportant: 'Skubus/Svarbu',
        emergency: 'Skubus',
        important: 'Svarbu',
      },
      fields: {
        noticeType: 'Svarba',
        noticeTypes: 'Svarba',
        noticeCategory: 'Pranešimo tipas',
        title: 'Pavadinimas',
        content: 'Turinys',
        publicationStartDate: 'Pradžios data ir laikas (UTC)',
        publicationEndDate: 'Pabaigos data ir laikas(UTC)',
        file: 'Priedai',
        language: 'Kalba',
        emergency: 'Skubus',
        important: 'Svarbu',
      },
    },
    firmwares: {
      name: 'Firmware',
      fields: {
        version: 'Versija',
        compatibilityLevel: 'Suderinamumo lygis',
        firmwareFile: 'Firmware Failas',
        firmwareFileCheckSum: 'Firmware patikrinimo suma',
        firstInitializationFile: 'Inicializacijos failas 1',
        firstInitializationFileCheckSum:
          'Inicializacijos failo 1 kontrolinis įrašas',
        secondInitializationFile: 'Inicializacijos failas 2',
        secondInitializationFileCheckSum:
          'Inicializacijos failo 2 kontrolinis įrašas',
      },
    },
    bucketShares: {
      name: 'Kibilo bendradarbiavimas',
      fields: {
        fromBucketId: 'Iš kibiro ID',
        corporationId: 'Įmonės ID',
        name: 'Pavadinimas',
      },
    },
    extensionarmShare: {
      name: 'Išplėtimo rankenos bendrinimas',
      fields: {
        name: 'Pavadinimas',
      },
    },
    retrofitCorporationLicenses: {
      name: 'LICENCIJA',
      fields: {
        licenseId: 'Licencijos ID',
        licenseType: 'Tipas',
        issuedAt: 'Išleidimo data',
        expiresAt: 'Galiojimo data',
        retrofitId: 'Mašinos',
        comment: 'Aprašymas',
        licenseStatus: 'Būsena',
        licensePermission: 'LICENCIJA',
        productType: 'Produkto pavadinimas',
      },
      licenseStatus: {
        unlicense: 'Neužskirta',
        disable: 'Neteisingas',
        enable: 'Galiojantis',
      },
    },
    retrofitNamePlateFiles: {
      fields: {
        corporationName: 'Įmonės pavadinimas',
        namePlates: 'Foto',
      },
    },
    siteConfigures: {
      fields: {
        unitLength: 'Ilgio mato vienetas',
      },
    },
    geofences: {
      name: 'Geografinė zona',
      fields: {
        type: 'Tipas',
        name: 'Pavadinimas',
        dimension: 'Matmenys',
        trigger: 'Skatiklis',
        valid: 'Galiojantis',
        alertType: 'Įspėjimo tipas',
        startDate: 'Pradžios data',
        endDate: 'Pabaigos data',
        transparency: 'Skaidrus',
        elevation: 'Pakilimas',
        height: 'Aukštis',
        radius: 'Spindulys',
        lastUpdated: 'Paskutinė atnaujinimas',
        points: 'Palaikomas intervalas',
        thickness: 'Storis',
      },
      type: {
        line: 'Linija',
        polygon: 'Poligonas',
        circle: 'Ratas',
        point: 'Taškas',
        wall: 'Sienelė',
      },
      dimension: {
        twodimension: '2D',
        threedimension: '3D',
      },
      valid: {
        true: 'Galiojantis',
        false: 'Neteisingas',
      },
      alertType: {
        attention: 'Dėmesys',
        notice: 'Pastaba',
        warning: 'Įspėjimas',
        caution: 'Atsargiai',
        danger: 'Pavojus',
      },
      transparency: {
        true: 'Skaidrus',
        false: 'Nepermatomas',
      },
    },
    geofenceAlerts: {
      name: 'Perspėjimai',
      fields: {
        timeStamp: 'Aptikimo data ir laikas',
        geofenceName: 'Geofencijos pavadinimas',
        alertType: 'Įspėjimo tipas',
        collisionPoint: 'Susidūrimo taškas',
        retrofit: {
          machineInfoMachineName: 'Mašinos',
        },
      },
      alertType: {
        attention: 'Dėmesys',
        notice: 'Pastaba',
        warning: 'Įspėjimas',
        caution: 'Atsargiai',
        danger: 'Pavojus',
      },
    },
    ntrips: {
      name: 'Ntrip informacijos valdymas',
      fields: {
        name: 'Naudotojo ID',
        ntripPass: 'Slaptažodis',
        serial: 'Serijos Nr.',
        customerCorporationId: 'Įmonės ID',
        customerCorporationName: 'Įmonės pavadinimas',
        ntrip: 'Būsena',
        numberOfExchanges: 'Keičių skaičius',
        id: 'ID',
        licenceId: 'Licencijos ID',
        dateOfRegistration: 'Registracijos data',
        billingToCustomers: 'Sąskaitos klientams',
        paymentToKomatsu: 'Mokėjimas Komatsu',
        applicantId: 'Peticijos teikėjo ID',
        applicant: 'Peticijos teikėjas',
        requestCorporationId: 'Pareikalaukite įmonės ID',
        requestCorporationName: 'Pareikalaukite įmonės pavadinimo',
        isUsed: 'Naudojama',
      },
      ntrip: {
        publish: 'Apmokėta',
        stop: 'Sustabdyti',
        delete: 'Ištrintas',
      },
    },
    retrofitAlternateRegists: {
      name: 'Valdiklio įgaliojimo registracija',
      fields: {
        id: 'ID',
        retrofitId: 'retrofitId',
        fromCorporationId: 'iš įmonės ID',
        fromCorporationName: 'Valdiklio įgaliojimo registracija',
        toCorporationId: 'Naudotojo',
        toCorporationName: 'Įmonės pavadinimas',
        isApproved: 'Būsena',
        createDate: 'Peticijos pateikimo data',
        actualDate: 'Patvirtinimo data',
        transferUsers: 'El.paštas',
        email: 'El.paštas',
        lastUpdated: 'Paskutinė atnaujinimas',
      },
      isApproved: {
        true: 'Patvirtinta',
        false: 'Nesutikta',
      },
    },
    pendingApprovals: {
      name: 'Laukiama patvirtinimo',
      fields: {
        approvalType: 'Tipas',
        requestedBy: 'Peticijos teikėjo įmonė',
        lastUpdated: 'Paskutinė atnaujinimas',
      },
      approvalType: {
        assignment: 'Užduotis',
        transfer: 'Perdavimas',
        agent: 'Valdiklio įgaliojimo registracija',
      },
    },
    retrofitSendbacks: {
      name: 'Siųsti atgal',
      fields: {
        fromRetrofitId: 'Iš perplanavimo',
        toRetrofitId: 'Alternatyvusis valdiklis',
        notificationEmailAddress: 'El.paštas',
        siteId: 'Objektas',
        corporationId: 'Įmonės ID',
        corporationName: 'Įmonė naudojama',
        ntripId: 'Ntrip Info',
        isComplete: 'Siuntimas atgal baigtas',
      },
    },
  },
};

export default message;
